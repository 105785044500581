import "./ExperimentModal.css";

import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { EXPERIMENT_STATUS } from "../../constants.js";
import ExperimentChartModal from "./ExperimentChartModal.jsx";
import ExperimentErrorModal from "./ExperimentErrorModal.jsx";
import LoadingImg from "../../components/loading.jsx";
import _ from "lodash";
import experimentInfoImg from "../../static/img/experiment_info.jpg";
import experimentTemplate from "./template.csv";
import { fetchCrops } from "../../actions/crops.js";

function ExperimentModal({
  setBlockFormVisibility,
  blockSelected,
  blockFormVisibility,
  editionModeActivated,
  setEditionModeActivated,
  crops,
  listBlocks,
  isIncompletExperiment,
  removeExperimentLayers,
  experimentStatus,
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [blockDescription, setBlockDescription] = useState("");
  const [plotWidth, setPlotWidth] = useState("");
  const [plotLength, setPlotLength] = useState("");
  const [alertModal, setAlertModal] = useState("");
  const [cropSelected, setCropSelected] = useState(null);

  // csv file
  const [selectedFile, setSelectedFile] = useState(null);
  const [finishReadingFile, setFinishReadingFile] = useState(false);
  const [fileKey, setFilekey] = useState("");
  const [fileUploadedInfo, setFileUploadedInfo] = useState({
    file_Name: null,
    max_X: null,
    min_X: null,
    max_Y: null,
    min_Y: null,
    number_microplot_to_process: null,
    number_buffer: null,
  });

  const [csvFileMessagesError, setCsvFileMessagesError] = useState([]);

  // chart information
  const [chartFormVisibility, setChartFormVisibility] = useState(false);
  const [dataMicroPlotToShow, setDataMicroPlotToShow] = useState([]);
  const [chartIsDrawing, setChartIsDrawing] = useState(false);
  const [blockBorder, setBlockBorder] = useState({
    rectangle: null,
  });

  useEffect(() => {
    setDataMicroPlotToShow([]);
    if (selectedFile) readFile();
  }, [selectedFile]);

  useEffect(() => {
    if (blockFormVisibility) {
      dispatch(fetchCrops());
      showPropertiesOnFormBlock();
    }
  }, [blockFormVisibility]);

  const showPropertiesOnFormBlock = () => {
    setCropSelected(blockSelected.properties.crop_id);
    setName(blockSelected.properties.block_name);
    setPlotWidth(blockSelected.properties.plot_width);
    setPlotLength(blockSelected.properties.plot_length);
    setBlockDescription(blockSelected.properties.block_description);
    setFilekey(blockSelected.properties.file_key);
    if (blockSelected.properties.file)
      setSelectedFile(blockSelected.properties.file);
  };

  const validateFormBlock = () => {
    let newProperties;
    if (
      name.trim() &&
      plotWidth &&
      plotWidth > 0 &&
      plotLength &&
      plotLength > 0 &&
      cropSelected &&
      (selectedFile || fileKey)
    ) {
      // test experiment name uniqueness
      let nameExist = false;
      listBlocks.forEach((element) => {
        if (
          element.polygon._leaflet_id !== blockSelected.polygon._leaflet_id &&
          name.trim().toUpperCase() ===
            element.properties.block_name.trim().toUpperCase()
        )
          nameExist = true;
      });
      if (nameExist) {
        setAlertModal("Plot map name must be unique");
      } else {
        newProperties = {
          block_name: name.trim(),
          block_description: blockDescription.trim(),
          plot_width: plotWidth,
          plot_length: plotLength,
          // I do not fix file key yet
          file_key: blockSelected.properties.file_key,
          crop_id: cropSelected,
          file: selectedFile,
          site: blockSelected.properties.site,
          uuid: blockSelected.properties.uuid,
        };

        blockSelected.properties = newProperties;
        blockSelected.polygon.setPopupContent(name).openPopup();

        // clean form field
        setName("");
        setBlockDescription("");
        setPlotWidth("");
        setPlotLength("");
        setSelectedFile(null);
        setBlockFormVisibility(false);
        setAlertModal("");
        setCropSelected({});
      }
    } else {
      setAlertModal("Please fully fill out the fields above.");
    }
  };

  const cancelAndCloseModal = () => {
    if (isIncompletExperiment)
      removeExperimentLayers(blockSelected.polygon._leaflet_id);
    // clean form field
    setName("");
    setBlockDescription("");
    setPlotWidth("");
    setPlotLength("");
    setSelectedFile(null);
    setAlertModal("");
    setCropSelected({});
    setBlockFormVisibility(false);
  };

  const readFile = () => {
    if (selectedFile) {
      var reader = new FileReader();
      reader.onload = function (progressEvent) {
        let fileData = reader.result;

        // read file line By line
        let [headerLine, ...lines] = fileData.split(/[\r\n]+/g);
        _.remove(lines, (line) => line.length < 2);

        //Get separator , or ;
        const sep =
          headerLine.split(";").length > headerLine.split(",").length
            ? ";"
            : ",";
        headerLine = headerLine.split(sep)?.map((i) => i.toLowerCase());
        let csv_object = lines.map((line) =>
          line.split(sep).reduce(
            (object, value, index) => ({
              ...object,
              [headerLine[index]]: value,
            }),
            {}
          )
        );
        if (validateAttributes(headerLine)) {
          csv_object = _.map(csv_object, (line) => ({
            ...line,
            x: parseInt(line.x),
            y: parseInt(line.y),
            symbol: "square",
            fill: line.plot_id.toLowerCase() === "buffer" ? "red" : "green",
            label: `${line.plot_id}\nX: ${line.x}\nY: ${line.y}`,
          }));
          if (validFileData(csv_object)) readFileLines(csv_object);
          else setSelectedFile(null);
        } else {
          setSelectedFile(null);
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  const readFileLines = (lines) => {
    let minX = _.minBy(lines, (i) => i.x).x;
    let maxX = _.maxBy(lines, (i) => i.x).x;
    let minY = _.minBy(lines, (i) => i.y).y;
    let maxY = _.maxBy(lines, (i) => i.y).y;
    setFileUploadedInfo({
      file_Name: selectedFile.name,
      max_X: maxX,
      min_X: minX,
      max_Y: maxY,
      min_Y: minY,
      number_microplot_to_process: Object.keys(
        _.filter(lines, (i) => i.plot_id.toLowerCase() !== "buffer")
      ).length,
      number_buffer: Object.keys(
        _.filter(lines, (i) => i.plot_id.toLowerCase() === "buffer")
      ).length,
    });

    let rectangleSide = [];
    for (let i = minX; i < maxX + 1; i++) {
      let column = lines.filter((element) => element.x === i);
      let previousColumn = lines.filter((element) => element.x === i - 1);
      let maxPreviousColumn = _.maxBy(previousColumn, (i) => i.y);
      let minPreviousColumn = _.minBy(previousColumn, (i) => i.y);
      let maxColumn = _.maxBy(column, (i) => i.y);
      let minColumn = _.minBy(column, (i) => i.y);

      if (maxColumn !== undefined) {
        rectangleSide.push({
          x: maxColumn.x,
          y: maxColumn.y,
          symbol: maxColumn.symbol,
          fill: maxColumn.fill,
          label: `${maxColumn.plot_id}\nX: ${maxColumn.x}\nY: ${maxColumn.y}`,
        });
        rectangleSide.push({
          x: minColumn.x,
          y: minColumn.y,
          symbol: minColumn.symbol,
          fill: minColumn.fill,
          label: `${minColumn.plot_id}\nX: ${minColumn.x}\nY: ${minColumn.y}`,
        });

        if (maxPreviousColumn !== undefined) {
          if (maxPreviousColumn.y !== maxColumn.y)
            for (
              let i = Math.min(maxColumn.y, maxPreviousColumn.y);
              i < Math.max(maxColumn.y, maxPreviousColumn.y);
              i++
            ) {
              let microplotInfo = _.find(previousColumn, (microplot) => {
                return microplot.y === i;
              });
              if (microplotInfo === undefined)
                microplotInfo = _.find(column, (microplot) => {
                  return microplot.y === i;
                });
              if (microplotInfo !== undefined)
                rectangleSide.push({
                  x: microplotInfo.x,
                  y: microplotInfo.y,
                  symbol: "square",
                  fill: microplotInfo.fill,
                  label: `${microplotInfo.plot_id}\nX: ${microplotInfo.x}\nY: ${microplotInfo.y}`,
                });
            }
          if (minPreviousColumn.y !== minColumn.y)
            for (
              let i = Math.min(minColumn.y, minPreviousColumn.y);
              i < Math.max(minColumn.y, minPreviousColumn.y);
              i++
            ) {
              let microplotInfo = _.find(previousColumn, (microplot) => {
                return microplot.y === i;
              });
              if (microplotInfo === undefined)
                microplotInfo = _.find(column, (microplot) => {
                  return microplot.y === i;
                });
              if (microplotInfo !== undefined)
                rectangleSide.push({
                  x: microplotInfo.x,
                  y: microplotInfo.y,
                  symbol: "square",
                  fill: microplotInfo.fill,
                  label: `${microplotInfo.plot_id}\nX: ${microplotInfo.x}\nY: ${microplotInfo.y}`,
                });
            }
        }
      }
    }
    for (let j = minY; j < maxY + 1; j++) {
      let line = lines.filter((element) => element.y === j);
      let previousLine = lines.filter((element) => element.y === j - 1);
      let maxPreviousLine = _.maxBy(previousLine, (i) => i.x);
      let minPreviousLine = _.minBy(previousLine, (i) => i.x);
      let maxLine = _.maxBy(line, (i) => i.x);
      let minLine = _.minBy(line, (i) => i.x);

      if (maxLine !== undefined) {
        rectangleSide.push({
          x: maxLine.x,
          y: maxLine.y,
          symbol: maxLine.symbol,
          fill: maxLine.fill,
          label: `${maxLine.plot_id}\nX: ${maxLine.x}\nY: ${maxLine.y}`,
        });
        rectangleSide.push({
          x: minLine.x,
          y: minLine.y,
          symbol: minLine.symbol,
          fill: minLine.fill,
          label: `${minLine.plot_id}\nX: ${minLine.x}\nY: ${minLine.y}`,
        });

        if (maxPreviousLine !== undefined) {
          if (maxPreviousLine.x !== maxLine.x)
            for (
              let i = Math.min(maxLine.x, maxPreviousLine.x);
              i < Math.max(maxLine.x, maxPreviousLine.x);
              i++
            ) {
              let microplotInfo = _.find(previousLine, (microplot) => {
                return microplot.x === i;
              });
              if (microplotInfo === undefined)
                microplotInfo = _.find(line, (microplot) => {
                  return microplot.x === i;
                });
              if (microplotInfo !== undefined)
                rectangleSide.push({
                  x: microplotInfo.x,
                  y: microplotInfo.y,
                  symbol: "square",
                  fill: microplotInfo.fill,
                  label: `${microplotInfo.plot_id}\nX: ${microplotInfo.x}\nY: ${microplotInfo.y}`,
                });
            }
          if (minPreviousLine.x !== minLine.x)
            for (
              let i = Math.min(minPreviousLine.x, minLine.x);
              i < Math.max(minPreviousLine.x, minLine.x);
              i++
            ) {
              let microplotInfo = _.find(previousLine, (microplot) => {
                return microplot.x === i;
              });
              if (microplotInfo === undefined)
                microplotInfo = _.find(line, (microplot) => {
                  return microplot.x === i;
                });
              if (microplotInfo !== undefined)
                rectangleSide.push({
                  x: microplotInfo.x,
                  y: microplotInfo.y,
                  symbol: "square",
                  fill: microplotInfo.fill,
                  label: `${microplotInfo.plot_id}\nX: ${microplotInfo.x}\nY: ${microplotInfo.y}`,
                });
            }
        }
      }
    }
    setBlockBorder({
      rectangle: rectangleSide,
    });

    setDataMicroPlotToShow([
      ...rectangleSide,
      ..._.filter(lines, (i) => i.plot_id.toLowerCase() === "buffer"),
    ]);
    setFinishReadingFile(true);
  };

  const validateAttributes = (headerLine) => {
    let attributeId = headerLine.includes("plot_id");
    let attributeX = headerLine.includes("x");
    let attributeY = headerLine.includes("y");
    if (!(attributeId && attributeX && attributeY)) {
      setCsvFileMessagesError([
        {
          errorMessage: `Your csv file must include at least this attributs Plot_id, X, Y`,
        },
      ]);
      return false;
    }
    return true;
  };

  const validFileData = (lines) => {
    let listPlot_ID = [];
    let errors_list = [];

    try {
      if (lines.length === 0) {
        errors_list.push({ errorMessage: "File is empty" });
      } else {
        lines.forEach((line, index) => {
          if (line.plot_id.trim().length === 0)
            errors_list.push({
              errorMessage: `Empty plot_id at line ${index + 2}`,
            });
          else if (line.plot_id.toLowerCase() !== "buffer")
            listPlot_ID.push(line.plot_id);
          if (isNaN(line.x))
            errors_list.push({
              errorMessage: `X is not a number at line ${index + 2}`,
            });
          if (isNaN(line.y))
            errors_list.push({
              errorMessage: `Y is not a number at line ${index + 2}`,
            });
          if (errors_list.length >= 5) throw errors_list;
        });

        if (new Set(listPlot_ID).size !== listPlot_ID.length)
          errors_list.push({
            errorMessage: `Plot_id must be unique.`,
          });
      }

      if (errors_list.length) throw errors_list;
    } catch (errors_list) {
      setCsvFileMessagesError(errors_list);
      return false;
    }
    return true;
  };

  return (
    <div>
      <Modal isOpen={blockFormVisibility} className="experiment-modal">
        <ModalHeader>Plot map information</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="experiment-modal-content">
                <img
                  className="w-100"
                  src={experimentInfoImg}
                  alt="Plot map information"
                />
              </div>
            </Col>
            <Col>
              {alertModal && (
                <Alert className="mx-auto col-10 text-center" color="danger">
                  {alertModal}
                </Alert>
              )}
              <form id="blockForm" className="me-2">
                <div className="mb-3 mt-2">
                  <label className="col-sm-3 col-form-label text-end my-auto me-4">
                    Label *
                  </label>
                  <div className="col">
                    <input
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      disabled={!editionModeActivated}
                    />
                  </div>
                </div>

                <div className="mb-3 ">
                  <label className="col-sm-3 col-form-label text-end my-auto me-4">
                    Plot width (m) *
                  </label>
                  <div className="col">
                    <input
                      className="form-control"
                      value={plotWidth}
                      onChange={(e) => setPlotWidth(e.target.value)}
                      type="number"
                      min="0"
                      disabled={!editionModeActivated}
                    />
                  </div>
                </div>

                <div className="mb-3 ">
                  <label className="col-sm-3 col-form-label text-end my-auto me-4">
                    Plot length (m) *
                  </label>
                  <div className="col">
                    <input
                      className="form-control"
                      value={plotLength}
                      onChange={(e) => setPlotLength(e.target.value)}
                      type="number"
                      min="0"
                      disabled={!editionModeActivated}
                    />
                  </div>
                </div>

                <div className="mb-3 ">
                  <label className="col-sm-3 col-form-label text-end my-auto me-4">
                    Crop *
                  </label>
                  <div className="col">
                    <Input
                      type="select"
                      value={cropSelected}
                      onChange={(e) => setCropSelected(e.target.value)}
                      disabled={!editionModeActivated}
                    >
                      <option className="font-italic" key="" value="">
                        --Unset--
                      </option>

                      {crops.map((crop) => (
                        <option key={crop.id} value={crop.id}>
                          {crop.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>

                <div className="mb-3 ">
                  <label className="col-sm-3 col-form-label text-end my-auto me-4">
                    Description
                  </label>
                  <div className="col">
                    <input
                      className="form-control"
                      value={blockDescription}
                      onChange={(e) => setBlockDescription(e.target.value)}
                      type="text"
                      disabled={!editionModeActivated}
                    />
                  </div>
                </div>
                {editionModeActivated && fileKey && !selectedFile && (
                  <div>
                    <div className="mb-3">
                      <label className="col-sm-3 col-form-label text-end my-auto me-4 ">
                        Plot map csv file
                      </label>
                      <div className="col">
                        <h5>
                          Already provided
                          <i className="fa fa-check-circle text-success"></i>
                        </h5>
                      </div>
                    </div>
                    <div className="btn btn-danger centerButton btn-file ms-4">
                      <span> Modify csv file </span>
                      <input
                        type="file"
                        name="file"
                        accept=".csv, application/vnd.ms-excel"
                        onChange={(event) => {
                          setFinishReadingFile(false);
                          setSelectedFile(event.target.files[0]);
                        }}
                        required
                      />
                    </div>
                  </div>
                )}
                {editionModeActivated && !selectedFile && !fileKey && (
                  <div className="mb-3">
                    <label className="col-sm-3 col-form-label text-end my-auto">
                      Upload your csv file * &nbsp;
                    </label>

                    <div className="col">
                      <div className="mb-3 files">
                        <input
                          type="file"
                          name="file"
                          accept=".csv, application/vnd.ms-excel"
                          onChange={(event) =>
                            setSelectedFile(event.target.files[0])
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!editionModeActivated && (
                  <div className="mb-3">
                    <label className="col-sm-3 col-form-label text-end my-auto me-4">
                      Plot map csv file
                    </label>
                    <div className="col">
                      <h5>
                        {fileKey === "" ? (
                          <>
                            No file found for this block{" "}
                            <i className="fa fa-times-circle text-danger" />
                          </>
                        ) : (
                          <>
                            Already provided{" "}
                            <i className="fa fa-check-circle text-success" />
                          </>
                        )}
                      </h5>
                    </div>
                  </div>
                )}

                <div
                  hidden={
                    (selectedFile && !finishReadingFile) || chartIsDrawing
                      ? ""
                      : "hidden"
                  }
                >
                  <LoadingImg visible />
                  <h1 className="text_loading">Loading...</h1>
                </div>

                {editionModeActivated &&
                  selectedFile &&
                  finishReadingFile &&
                  !chartIsDrawing && (
                    <div className="file-recap">
                      <span>
                        <i className="fa fa-file-text-o" /> Your CSV file recap
                      </span>
                      <div className="file-recap-row">
                        <Col xs="4" className="text-end ">
                          <label className="col-form-label fw-bold me-4">
                            File name
                          </label>
                        </Col>
                        <Col xs="8">
                          <label className="col-form-label">
                            {fileUploadedInfo.file_Name}
                          </label>
                        </Col>
                      </div>
                      <div className="file-recap-row">
                        <Col xs="4" className="text-end ">
                          <label className="col-form-label fw-bold me-4">
                            X
                          </label>
                        </Col>
                        <Col xs="8">
                          <label className="col-form-label">
                            from {fileUploadedInfo.min_X} to{" "}
                            {fileUploadedInfo.max_X}
                          </label>
                        </Col>
                      </div>
                      <div className="file-recap-row">
                        <Col xs="4" className="text-end ">
                          <label className="col-form-label fw-bold me-4">
                            Y
                          </label>
                        </Col>
                        <Col xs="8">
                          <label className="col-form-label">
                            from {fileUploadedInfo.min_Y} to{" "}
                            {fileUploadedInfo.max_Y}
                          </label>
                        </Col>
                      </div>
                      <div className="file-recap-row">
                        <Col xs="5" className="text-end ">
                          <label className="col-form-label fw-bold me-4">
                            Nb of plots to process
                          </label>
                        </Col>
                        <Col xs="7">
                          <label className="col-form-label">
                            {fileUploadedInfo.number_microplot_to_process}
                          </label>
                        </Col>
                      </div>

                      <div className="file-recap-row">
                        <Col xs="5" className="text-end ">
                          <label className="col-form-label fw-bold me-4">
                            Nb of buffers
                          </label>
                        </Col>
                        <Col xs="7">
                          <label className="col-form-label">
                            {fileUploadedInfo.number_buffer}
                          </label>
                        </Col>
                      </div>
                      <div className="file-recap-row">
                        <Col xs="5" className="text-end ">
                          <label className="col-form-label fw-bold me-4">
                            Total of plots
                          </label>
                        </Col>
                        <Col xs="7">
                          <label className="col-form-label">
                            {fileUploadedInfo.number_microplot_to_process +
                              fileUploadedInfo.number_buffer}
                          </label>
                        </Col>
                      </div>
                      <Row className="g-0">
                        <Col className="file-recap-buttons">
                          <div className="btn btn-danger btn-file">
                            Modify your file
                            <input
                              type="file"
                              name="file"
                              accept=".csv, application/vnd.ms-excel"
                              onChange={(event) => {
                                setFinishReadingFile(false);
                                setSelectedFile(event.target.files[0]);
                              }}
                              required
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={() => {
                              setChartIsDrawing(true);
                              setChartFormVisibility(true);
                            }}
                          >
                            View file result
                          </button>
                        </Col>
                      </Row>
                    </div>
                  )}
              </form>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <a
            className="btn btn-primary me-auto"
            href={experimentTemplate}
            download="plot_map_template.csv"
          >
            Download csv template <i className="fa fa-file-text-o" />
          </a>
          {!editionModeActivated &&
            experimentStatus < EXPERIMENT_STATUS.SYNCHRONIZED && (
              <Button
                className="btn action-btn btn-primary"
                onClick={() => {
                  setEditionModeActivated(true);
                }}
              >
                Open edition mode
              </Button>
            )}

          {editionModeActivated && (
            <Button
              className="btn action-btn btn-primary"
              onClick={validateFormBlock}
            >
              Validate
            </Button>
          )}
          <Button
            className="btn action-btn btn-secondary"
            onClick={cancelAndCloseModal}
          >
            {editionModeActivated ? "Cancel" : "Close"}
          </Button>
        </ModalFooter>
      </Modal>

      <ExperimentErrorModal
        csvFileMessagesError={csvFileMessagesError}
        setCsvFileMessagesError={setCsvFileMessagesError}
      />

      <ExperimentChartModal
        setChartIsDrawing={setChartIsDrawing}
        chartFormVisibility={chartFormVisibility}
        setChartFormVisibility={setChartFormVisibility}
        fileUploadedInfo={fileUploadedInfo}
        dataMicroPlotToShow={dataMicroPlotToShow}
        blockBorder={blockBorder}
      />
    </div>
  );
}
function mapStateToProps(state) {
  return {
    crops: state.crops.all,
  };
}

const callbacks = {
  fetchCrops,
};

export default connect(mapStateToProps, callbacks)(ExperimentModal);
