import {
  COMPANY_CONTRACT_UPDATE_ALERT,
  RESET_COMPANY,
  RESET_COMPANY_CONTRACTS,
  SET_COMPANY_CONTRACTS
} from "../actions/actionTypes";

import dataTableBehavior from "./dataTableBehavior";

const contractsDataTable = dataTableBehavior("companyContracts");

const initialState = {
  id: null,
  name: "",
  contracts: [],
  contractUpdateAlert: "",
  contractsTable: contractsDataTable(undefined, {}), // eslint-disable-line no-undefined
  createContractAlert: ""
};

export default function company(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY_CONTRACTS:
      return {
        ...state,
        id: action.company.id,
        name: action.company.name,
        contracts: action.contracts,
        contractUpdateAlert: ""
      };

    case RESET_COMPANY:
      return initialState;

    case RESET_COMPANY_CONTRACTS:
      return {
        ...state,
        contracts: []
      };

    case COMPANY_CONTRACT_UPDATE_ALERT:
      return {
        ...state,
        contractUpdateAlert: action.contractUpdateAlert
      };

    default:
      return {
        ...state,
        contractsTable: contractsDataTable(state.contractsTable, action)
      };
  }
}
