import { requestFileUploadURL } from "./backendRequests";

function uploadFileToUrl(file, keyUrl) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", keyUrl.url, false);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        resolve(keyUrl);
      }
    };

    xhr.onerror = () => {
      reject(new Error("Could not upload file"));
    };

    // set mime type, not the cloverfield data type
    xhr.setRequestHeader("Content-Type", file.type);

    xhr.send(file);
  });
}

export function uploadFileToExternalStorage(file, site, user) {
  return requestFileUploadURL(site, user).then(response => {
    return uploadFileToUrl(file, response);
  });
}
