import PropTypes from "prop-types";
import { memo } from "react";

const ImageItem = memo(({ image, date, fetchImage, onClick, current }) => {
  if (!image) fetchImage(date);
  return (
    <div className={`img-wrapper ${current ? "current" : ""}`}>
      {image ? (
        image.url ? (
          <img
            className="clickable"
            src={image.url}
            alt=""
            style={{ minHeight: 50 }}
            onClick={() => onClick(date)}
          />
        ) : (
          <div className="img-skeleton no-image" />
        )
      ) : (
        <div className="img-skeleton" />
      )}

      <span className="informative p-1">
        <i className="fa-solid fa-image" /> {date}
      </span>
    </div>
  );
});

export const ImageHistory = ({
  trial,
  images,
  trialDate,
  handleImageClick,
  fetchImage,
}) => {
  return (
    <div className="image-history gap-1">
      {trial.trial_dates.map((date) => (
        <ImageItem
          key={date}
          image={images[date]}
          date={date}
          fetchImage={fetchImage}
          onClick={handleImageClick}
          current={date === trialDate}
        />
      ))}
      <div className="scroll-end font-style-italic">
        <i className="fa fa-check-circle" /> History begins here
      </div>
    </div>
  );
};

ImageItem.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  date: PropTypes.string.isRequired,
  fetchImage: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  current: PropTypes.bool.isRequired,
};

ImageHistory.propTypes = {
  trial: PropTypes.object.isRequired,
  images: PropTypes.object.isRequired,
  trialDate: PropTypes.string.isRequired,
  handleImageClick: PropTypes.func.isRequired,
  fetchImage: PropTypes.func.isRequired,
};
