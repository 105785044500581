import { Button, Col, Nav, NavItem, NavLink, Row, Table } from "reactstrap";
import React, { Component, useState } from "react";
import {
  SESSION_STATUS,
  SESSION_UPLOAD_TOOLTIP_INCOMPLETE,
  SESSION_UPLOAD_TOOLTIP_PARTIAL,
  SESSION_UPLOAD_TOOLTIP_SUCCESS,
} from "../constants";

import ResultDownloadModal from "./resultDownloadModal";
import UploadSessionModal from "../uploadsessions/uploadSessionModal";
import _ from "lodash";
import classnames from "classnames";
import { fixHeaders } from "../components/dataTable";
import moment from "moment";
import { useFetch } from "../hooks/useFetch";
import { useHistory } from "react-router-dom";

const Header = ({ contract, site }) => (
  <h3 className="text-center green-color pb-3">
    {contract + " - " + site.display_name}
  </h3>
);

const IconSessionUpload = ({ status }) => (
  <i
    className={
      "fa fa-lg fa-circle " +
      classnames({
        "text-success": [
          SESSION_STATUS.Completed,
          SESSION_STATUS.Synchronized,
        ].includes(status),
        "text-danger": [
          SESSION_STATUS.Initialized,
          SESSION_STATUS.InProgress,
        ].includes(status),
        "text-warning": [SESSION_STATUS.Partial].includes(status),
      })
    }
    data-tooltip-id="tooltip"
    data-tooltip-content={
      [SESSION_STATUS.Initialized, SESSION_STATUS.InProgress].includes(status)
        ? SESSION_UPLOAD_TOOLTIP_INCOMPLETE
        : [SESSION_STATUS.Partial].includes(status)
        ? SESSION_UPLOAD_TOOLTIP_PARTIAL
        : SESSION_UPLOAD_TOOLTIP_SUCCESS
    }
    data-tooltip-variant={
      [SESSION_STATUS.Initialized, SESSION_STATUS.InProgress].includes(status)
        ? "error"
        : [SESSION_STATUS.Partial].includes(status)
        ? "warning"
        : "success"
    }
    aria-hidden="true"
  />
);

const MissionInfo = ({ site }) => (
  <Row className="pb-3">
    <Col xs="6" className="text-center">
      <span className="fw-bold">Crop : </span>
      {site.crop}
    </Col>
    <Col xs="6" className="text-center">
      <span className="fw-bold">Microplots : </span>
      {site.nb_microplots}
    </Col>
  </Row>
);

const Tabs = ({ mode, onModeChange, isUploadSessionVisible }) => (
  <div className="mb-3">
    <Nav tabs>
      <NavItem>
        <NavLink
          className={classnames({
            active: mode === "missions",
            collapseToggleTitle: mode !== "missions",
          })}
          onClick={() => onModeChange("missions")}
        >
          Missions
        </NavLink>
      </NavItem>

      {isUploadSessionVisible && (
        <NavItem>
          <NavLink
            className={classnames({
              active: mode === "upload",
              collapseToggleTitle: mode !== "upload",
            })}
            onClick={() => onModeChange("upload")}
          >
            Upload sessions
          </NavLink>
        </NavItem>
      )}
    </Nav>
  </div>
);

const UploadSessionTable = ({ contract, sessions }) => {
  const history = useHistory();
  const [uploadSessionSelected, setUploadSessionSelected] = useState(null);
  const handleUploadSessionRowClick = (e, us) => {
    e.preventDefault();
    setUploadSessionSelected(us);
  };

  const handleCloseModal = () => {
    setUploadSessionSelected(null);
  };
  return sessions.length === 0 ? (
    <div>No upload sessions exist.</div>
  ) : (
    <>
      <UploadSessionModal
        us={uploadSessionSelected}
        isOpen={uploadSessionSelected !== null}
        closeModal={() => handleCloseModal()}
        company={contract.company}
        withRedirect={true}
      />

      <Table striped>
        {fixHeaders(" ", "Acquisition Date", "Created on", "Created by", " ")}
        <tbody>
          {_.sortBy(sessions, "acquisition_date")?.map((s) => (
            <tr
              key={s.id}
              onClick={(e) => handleUploadSessionRowClick(e, s)}
              className="clickable-row"
            >
              <td>
                <IconSessionUpload status={s.status} />
              </td>
              <td>{s.acquisition_date}</td>
              <td>{moment(s.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
              <td>{s.user}</td>
              <td>
                <Button
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Edit"
                  className="p-0"
                  color="link"
                  onClick={() => history.push(`/upload/${s.id}`)}
                >
                  <i className="fa fa-lg fa-pencil" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const UploadSessions = ({ site, contract }) => {
  const [sessions] = useFetch(`/api/client/sites/${site.id}/upload_sessions`);

  const history = useHistory();
  return (
    <>
      <Row className="pb-3">
        <Col>
          <Button
            className="float-end"
            color="primary"
            onClick={() =>
              history.push(
                `/upload?contractId=${contract.id}&siteId=${site.id}`
              )
            }
          >
            New upload session
          </Button>
        </Col>
      </Row>
      {sessions === null ? (
        <div>Loading sessions</div>
      ) : (
        <UploadSessionTable contract={contract} sessions={sessions} />
      )}
    </>
  );
};

class DetailContract extends Component {
  state = {
    isResultDownloadOpenedModal: false,
    resultDownloadMission: null,
    mode: "missions",
  };

  openModalResultDownload(event, mission) {
    event.stopPropagation();
    this.setState({
      isResultDownloadOpenedModal: true,
      resultDownloadMission: mission,
    });
  }

  closeResultDownloadModal() {
    this.setState({
      isResultDownloadOpenedModal: false,
    });
  }

  componentDidUpdate() {
    if (!this.props.contract.upload_template && this.state.mode === "upload")
      this.setState({ mode: "missions" });
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    return (
      <Col xs="6">
        <div className="section full-height">
          {this.props.trial && (
            <ResultDownloadModal
              trial={this.props.trial}
              mission={this.state.resultDownloadMission}
              isOpen={this.state.isResultDownloadOpenedModal}
              onClose={() => {
                this.closeResultDownloadModal();
              }}
              user={this.props.user}
            />
          )}

          <Button
            data-tooltip-id="tooltip"
            data-tooltip-content="Close"
            className="pull-right"
            color="link"
            onClick={() => this.props.closePanel()}
          >
            <i className="fa fa-lg fa-close text-secondary" />
          </Button>

          <Header contract={this.props.contract?.name} site={this.props.site} />

          <MissionInfo site={this.props.site} />

          <Tabs
            mode={this.state.mode}
            onModeChange={(mode) => this.setState({ mode })}
            isUploadSessionVisible={this.props.contract.upload_template}
          />

          {this.state.mode === "missions" ? (
            <Col className="ms-2">
              <Row>
                <Col xs="9" className="ps-2 likeTh">
                  Mission
                </Col>
                <Col xs="3" className="likeTh" />
              </Row>
              {_.sortBy(this.props.missions, "date").map(
                (mission, missionIndex) => (
                  <React.Fragment key={missionIndex}>
                    <Row
                      className={classnames({
                        "rounded detail-row": true,
                        "bg-lightgrey": missionIndex % 2 === 0,
                      })}
                    >
                      <Col xs="9" className="my-auto ps-2">
                        {mission.date}
                      </Col>
                      <Col xs="3" className="my-auto text-end">
                        {mission.date && (
                          <>
                            <Button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="View results"
                              className="p-0"
                              color="link"
                              onClick={() =>
                                this.props.openResultMapFromSiteOnDate(
                                  this.props.trial,
                                  mission.date
                                )
                              }
                            >
                              <i className="fa fa-lg fa-globe" />
                            </Button>
                            {this.props.trial?.links && (
                              <Button
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Download results"
                                className="p-0 ms-2"
                                color="link"
                                onClick={(e) =>
                                  this.openModalResultDownload(e, mission)
                                }
                              >
                                <i className="download_icon" />
                              </Button>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </React.Fragment>
                )
              )}
            </Col>
          ) : (
            this.props.contract.upload_template && (
              <UploadSessions
                site={this.props.site}
                contract={this.props.contract}
              />
            )
          )}
        </div>
      </Col>
    );
  }
}

export default DetailContract;
