import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import PropTypes from "prop-types";
import React from "react";
import { generatePassword } from "../services/utils";

class UserUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.lastname = React.createRef();
    this.firstname = React.createRef();
    this.phone = React.createRef();
    this.email = React.createRef();
    if (!this.props.user.id) this.password = React.createRef();

    this.state = {
      companySelected: this.props.user?.company?.id,
      password: generatePassword(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onOpened = () => {
    this.setState({
      password: generatePassword(),
      companySelected: this.props.user?.company?.id ?? "",
    });
  };

  generateNewPassword = (event) => {
    event.preventDefault();
    this.setState({ password: generatePassword() });
  };

  copyClipBoard = (event, ref) => {
    event.preventDefault();
    ref.select();
    document.execCommand("copy");
  };

  handleSubmit(event) {
    event.preventDefault();
    const user = {
      id: this.props.user.id,
      lastname: this.lastname.current.value,
      firstname: this.firstname.current.value,
      phone: this.phone.current.value,
      email: this.email.current.value,
      company_id: this.state.companySelected,
    };
    if (!this.props.user.id) {
      user.password = this.password.current.value;
      user.password_confirmation = this.password.current.value;
      user.access = true;
    }
    this.props.onSubmit(user);
  }

  onChangeCompany(company) {
    this.setState({ companySelected: company });
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          onOpened={() => this.onOpened()}
          className="modal-lg"
        >
          <ModalHeader>Update user</ModalHeader>
          <ModalBody>
            <div>
              {this.props.alert !== "" ? (
                <Alert className="mx-auto col-10 text-center" color="danger">
                  {this.props.alert}
                </Alert>
              ) : null}
              <form id="userForm" onSubmit={this.handleSubmit}>
                <div className="form-container mx-auto">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label text-end my-auto">
                      Last name *
                    </label>
                    <div className="col">
                      <input
                        className="form-control"
                        defaultValue={this.props.user.lastname}
                        type="text"
                        ref={this.lastname}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label text-end my-auto">
                      First name
                    </label>
                    <div className="col">
                      <input
                        className="form-control"
                        defaultValue={this.props.user.firstname}
                        type="text"
                        ref={this.firstname}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label text-end my-auto">
                      Company *
                    </label>
                    <div className="col">
                      <Input
                        required
                        type="select"
                        value={this.state.companySelected}
                        onChange={(e) => {
                          this.onChangeCompany(e.target.value);
                        }}
                      >
                        <option className="font-italic" key="" value="">
                          --Unset--
                        </option>
                        {this.props.companies.all.map((company) => (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label text-end my-auto">
                      Phone
                    </label>
                    <div className="col">
                      <input
                        className="form-control"
                        defaultValue={this.props.user.phone}
                        type="tel"
                        pattern="\+?\d+"
                        ref={this.phone}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label text-end my-auto">
                      Email *
                    </label>
                    <div className="col">
                      <input
                        className="form-control"
                        defaultValue={this.props.user.email}
                        type="email"
                        ref={this.email}
                        required
                      />
                    </div>
                  </div>
                  {this.props.user.id === null && (
                    <>
                      <div className="mb-3 row">
                        <label className="col-sm-3 col-form-label text-end my-auto">
                          Password *
                        </label>
                        <div className="col-5">
                          <input
                            className="form-control"
                            value={this.state.password}
                            type="text"
                            ref={this.password}
                            required
                            readOnly
                          />
                        </div>
                        <div className="col-2 my-auto">
                          <a
                            href="#!"
                            className="ms-2"
                            onClick={(e) => this.generateNewPassword(e)}
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Generate password."
                          >
                            <i className="fa fa-lg fa-refresh btn-link" />
                          </a>
                          <a
                            href="#!"
                            className="ms-3"
                            onClick={(e) =>
                              this.copyClipBoard(e, this.password.current)
                            }
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Copy password"
                          >
                            <i className="fa fa-lg fa-copy btn-link" />
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn action-btn"
              type="submit"
              form="userForm"
            >
              {this.props.user.id ? "Update" : "Create"}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                this.props.onClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

UserUpdateModal.propTypes = {
  alert: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default UserUpdateModal;
