import { SET_ALL_TREATMENTS } from "../actions/actionTypes";
import dataTableBehavior from "./dataTableBehavior";

const dataTable = dataTableBehavior("allTreatments", {
  sortField: "finished_processing_at",
  sortOrder: "desc"
});

const initialState = {
  all: [],
  table: dataTable(undefined, {}) // eslint-disable-line no-undefined
};

export default function allTreatments(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_TREATMENTS:
      return {
        ...state,
        all: action.treatments
      };

    default:
      return {
        ...state,
        table: dataTable(state.table, action)
      };
  }
}

export function filterTreatments(someTreatments, filter) {
  if (filter === "") {
    return someTreatments;
  }
  const found = someTreatments.filter(treatment => {
    return Object.keys(treatment).some(key => {
      const value = treatment[key]
        ? treatment[key].toString().toLowerCase()
        : "";
      return value.includes(filter);
    });
  });
  return found;
}
