import { CHANGE_PASSWORD_RESET } from "./actionTypes";
import { addDangerAlert } from "./alerts";
import { requestUpdatePassword } from "../services/backendRequests";

function changePasswordReset(updates) {
  return {
    type: CHANGE_PASSWORD_RESET,
    updates
  };
}

export function changePassword(password) {
  return dispatch => {
    dispatch(changePasswordReset({ password }));
  };
}

export function changePasswordConfirmation(passwordConfirmation) {
  return dispatch => {
    dispatch(changePasswordReset({ passwordConfirmation }));
  };
}

export function submitNewPassword(password, passwordConfirmation, urlParams) {
  return dispatch => {
    requestUpdatePassword(password, passwordConfirmation, urlParams)
      .catch(err => dispatch(addDangerAlert(err)))
      .then(response => {
        let passwordResetStatus = {};
        if (response.success) {
          passwordResetStatus = {
            success: true,
            messages: [response.message]
          };
        } else {
          passwordResetStatus = {
            success: false,
            messages: response.errors.full_messages
          };
        }
        dispatch(changePasswordReset({ alerts: passwordResetStatus }));
      });
  };
}
