import "./advancedFilteringRecap.css";

import { Badge, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";

import { BlackListWhiteList } from "../blackListWhiteList/BlackListWhiteList";
import { GroupListManager } from "./GroupListManager";
import { HighlightedText } from "../../../../../components/highlightedText/HighlightedText";
import PropTypes from "prop-types";
import { PropertyName } from "../../../../../components/PropertyName/PropertyName";
import { Virtuoso } from "react-virtuoso";
import { dateSort } from "../../../../utils";
import { deleteFilteringProfileFilter } from "../../../../../actions/resultMap";
import { selectExcludedGroupsSet } from "../../../../../selectors/resultMap";
import { truncateNumber } from "../../../../../services/utils";
import { useGetPropertyCategory } from "../../../../../hooks/useGetPropertyCategory";

const TraitFilterDetails = ({ trait }) => {
  const getPropertyCategory = useGetPropertyCategory();

  // To keep working with previous $ format
  const legalName = trait.technical_name.replaceAll("$", "|");
  const category = getPropertyCategory(legalName);

  return (
    <div className="d-flex justify-content-between align-items-start w-100">
      <span className="traits-text" style={{ color: category.color }}>
        <PropertyName technicalName={trait.technical_name} showIcon />
      </span>
      <span className="text-end">
        {`${truncateNumber(trait.threshold[0], 2)} → ${truncateNumber(
          trait.threshold[1],
          2
        )}`}
      </span>
    </div>
  );
};

const DateFilterDetails = ({ filter }) => {
  const dispatch = useDispatch();

  return (
    <div className="date-filter-details">
      <div className="d-flex">
        <Badge className="hiphen-badge active">{filter.date}</Badge>{" "}
        <div className="linker" />
        <Badge pill className="hiphen-badge small">
          <i className="fa fa-ban discrete-icon" />{" "}
          {filter.excludedGroups.length}
        </Badge>
        <i
          className="fa fa-close discrete-icon clickable"
          onClick={() => dispatch(deleteFilteringProfileFilter(filter.date))}
        />
      </div>
      <div className="date-filter-details-traits p-2 pe-0">
        {filter.traits.map((trait) => (
          <TraitFilterDetails key={trait.technical_name} trait={trait} />
        ))}
      </div>
    </div>
  );
};

const ExcludedGroup = ({ group, search, isBlacklisted }) => {
  return (
    <div className="group-list-item p-2" key={group}>
      <span>
        <HighlightedText text={group} highlight={search} />{" "}
        {!isBlacklisted && <i className="fa-solid fa-filter discrete-icon" />}
      </span>
      <BlackListWhiteList group={group} />
    </div>
  );
};

export const AdvancedFilteringRecap = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState("");
  const [showBlacklisted, setShowBlacklisted] = useState(true);

  const filteringProfile = useSelector(
    (state) => state.resultMap.filteringProfile
  );

  const totalTraitsCount = useMemo(
    () => filteringProfile.filters.flatMap((filter) => filter.traits).length,
    [filteringProfile]
  );

  const excludedGroupsSet = useSelector(selectExcludedGroupsSet);
  const excludedGroups = useMemo(
    () => Array.from(excludedGroupsSet),
    [excludedGroupsSet]
  );
  const notExcludedGroupsLength = data.length - excludedGroups.length;
  const isExpanded = expanded && filteringProfile.scope;

  const groupsToDisplay = useMemo(
    () =>
      excludedGroups
        .filter(
          (group) =>
            (showBlacklisted || !filteringProfile.blacklist.includes(group)) &&
            group.toLowerCase().includes(search.toLowerCase())
        )
        .toSorted(),
    [excludedGroups, filteringProfile.blacklist, search, showBlacklisted]
  );

  return (
    <div className={`advanced-filtering-recap ${isExpanded ? "exp" : ""}`}>
      <div
        className="advanced-filtering-recap-header p-3 d-flex clickable"
        onClick={() =>
          setExpanded((prev) => (filteringProfile.scope ? !prev : prev))
        }
      >
        <div className="summary d-flex justify-content-center gap-2 pe-2">
          <small>
            {data.length &&
              ((notExcludedGroupsLength * 100) / data.length).toFixed(1)}
            %
          </small>
          <Badge className="hiphen-badge active">
            {notExcludedGroupsLength} <i className="fa fa-check" />
          </Badge>
          <Badge className="hiphen-badge filtered-out">
            <i className="fa fa-ban discrete-icon" /> {excludedGroups.length}
          </Badge>
          <small>
            {data.length &&
              ((excludedGroups.length * 100) / data.length).toFixed(1)}
            %
          </small>
        </div>
        {filteringProfile.scope ? (
          <div>
            <span className="traits-text">
              <i className="fa-solid fa-filter" />{" "}
              {`${totalTraitsCount} trait${totalTraitsCount !== 1 ? "s" : ""}`}
            </span>{" "}
            across{" "}
            <Badge className="hiphen-badge small active font-weight-bold">
              {filteringProfile.filters.length}
            </Badge>{" "}
            {`date${filteringProfile.filters.length !== 1 ? "s" : ""}`}
          </div>
        ) : (
          <div className="no-filters">No scope defined yet</div>
        )}
        <i
          className={`fa fa-chevron-up ${isExpanded ? "fa-rotate-180" : ""} ${
            !filteringProfile.scope ? "invisible" : ""
          }`}
        />
      </div>
      {isExpanded && (
        <div className="advanced-filtering-recap-body p-2 pt-0">
          <div className="d-flex flex-column basis-50 align-items-stretch flex-grow-1">
            <div className="pb-0 d-flex gap-2">
              <Input
                bsSize="sm"
                type="search"
                placeholder="Search among filtered out groups"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span
                className="d-flex gap-1 align-items-end"
                data-tooltip-id="tooltip"
                data-tooltip-content="Show groups from block list"
              >
                <Input
                  type="checkbox"
                  checked={showBlacklisted}
                  onChange={() => setShowBlacklisted(!showBlacklisted)}
                />
                <i className="fa-solid fa-minus-circle discrete-icon" />
              </span>
            </div>
            <div className="group-list flex-grow-1 p-2">
              <Virtuoso
                data={groupsToDisplay}
                computeItemKey={(_, group) => group}
                itemContent={(_, group) => (
                  <ExcludedGroup
                    group={group}
                    isBlacklisted={filteringProfile.blacklist.includes(group)}
                    search={search}
                  />
                )}
              />
            </div>
          </div>
          <div className="d-flex flex-column w-50 p-2 pt-0 pb-0 align-items-stretch">
            <div className="date-list">
              {filteringProfile.filters
                .toSorted((a, b) => dateSort(a.date, b.date))
                .map((filter) => (
                  <DateFilterDetails key={filter.date} filter={filter} />
                ))}
            </div>
            <GroupListManager filteringProfile={filteringProfile} />
          </div>
        </div>
      )}
    </div>
  );
};

TraitFilterDetails.propTypes = {
  trait: PropTypes.object.isRequired,
};

ExcludedGroup.propTypes = {
  group: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  isBlacklisted: PropTypes.bool.isRequired,
};

AdvancedFilteringRecap.propTypes = {
  data: PropTypes.array.isRequired,
};

DateFilterDetails.propTypes = {
  filter: PropTypes.object.isRequired,
};
