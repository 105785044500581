import { Badge, Collapse } from "reactstrap";

import { AdvancedFilteringItem } from "./AdvancedFilteringItem";
import DEFAULT_TRAIT_ICON from "../../../../../static/img/default_trait_icon.png";
import { OTHER_NUMERICAL_PROPERTIES_GROUP } from "../../../../../constants";
import PropTypes from "prop-types";

export const AdvancedFilteringGroup = ({
  group,
  scopeIsDefined,
  expanded,
  activeNumericalMetrics,
  handleCollapse,
  thresholds,
  initialThresholds,
  datasets,
  controlData,
  handleThresholdChange,
}) => {
  const count = group.metrics.filter((metric) =>
    activeNumericalMetrics.includes(metric.technical_name)
  ).length;
  const isOtherNumericalPropertiesGroup =
    group.name === OTHER_NUMERICAL_PROPERTIES_GROUP;
  return (
    <div
      className={`trait-list-advanced-group ${
        !scopeIsDefined ? "disabled" : ""
      } ${isOtherNumericalPropertiesGroup ? "other-numerical-properties" : ""}`}
    >
      <div
        className="trait-list-advanced-group-header d-flex align-items-center justify-content-between clickable"
        onClick={() => handleCollapse(group.name)}
      >
        <div className="d-flex align-items-center gap-2">
          <img src={group.icon ?? DEFAULT_TRAIT_ICON} alt="" />
          <span>{group.name}</span>
          <Badge className="hiphen-badge small opacity-50">
            {`${group.metrics.length} trait${
              group.metrics.length !== 1 ? "s" : ""
            }`}
          </Badge>
        </div>
        <div className="d-flex align-items-center">
          {Boolean(count) && scopeIsDefined && (
            <Badge
              pill
              className={`advanced-filter-badge me-2 ${
                isOtherNumericalPropertiesGroup ? "other" : ""
              }`}
            >
              {count}
            </Badge>
          )}
          <i
            className={`fa fa-chevron-down discrete-icon me-2 ${
              expanded ? "fa-rotate-180" : ""
            } ${!scopeIsDefined ? "invisible" : ""}`}
          />
        </div>
      </div>

      <Collapse className="advanced-collapse-body" isOpen={expanded}>
        {scopeIsDefined &&
          group.metrics.map((metric) => (
            <AdvancedFilteringItem
              key={`${expanded}${metric.technical_name}`}
              trait={metric}
              active={activeNumericalMetrics.includes(metric.technical_name)}
              threshold={
                thresholds.find(
                  ({ technical_name }) =>
                    technical_name === metric.technical_name
                ).threshold
              }
              initialThreshold={
                initialThresholds.find(
                  ({ technical_name }) =>
                    technical_name === metric.technical_name
                ).threshold
              }
              datasets={datasets}
              controlData={controlData}
              handleThresholdChange={handleThresholdChange}
            />
          ))}
      </Collapse>
    </div>
  );
};

AdvancedFilteringGroup.propTypes = {
  group: PropTypes.shape({
    uuid: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string.isRequired,
    metrics: PropTypes.arrayOf(
      PropTypes.shape({
        technical_name: PropTypes.string.isRequired,
        range: PropTypes.arrayOf(PropTypes.number).isRequired,
      })
    ).isRequired,
  }).isRequired,
  scopeIsDefined: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  activeNumericalMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCollapse: PropTypes.func.isRequired,
  thresholds: PropTypes.arrayOf(
    PropTypes.shape({
      technical_name: PropTypes.string.isRequired,
      threshold: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ).isRequired,
  initialThresholds: PropTypes.arrayOf(
    PropTypes.shape({
      technical_name: PropTypes.string.isRequired,
      threshold: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ).isRequired,
  datasets: PropTypes.exact({
    includedData: PropTypes.array.isRequired,
    excludedData: PropTypes.array.isRequired,
    dataToInclude: PropTypes.array.isRequired,
    dataToExclude: PropTypes.array.isRequired,
  }),
  controlData: PropTypes.array.isRequired,
  handleThresholdChange: PropTypes.func.isRequired,
};
