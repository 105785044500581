import "./figureZoom.css";

import PropTypes from "prop-types";

function zoom(event) {
  const e = event.nativeEvent;
  const zoomer = event.currentTarget;
  const offsetX = e.offsetX ? e.offsetX : e.pageX;
  const offsetY = e.offsetY ? e.offsetY : e.pageY;
  const x = (offsetX / zoomer.offsetWidth) * 100;
  const y = (offsetY / zoomer.offsetHeight) * 100;
  zoomer.style.backgroundPosition = x + "% " + y + "%";
}
export const FigureZoom = ({ src, onClick }) => {
  return (
    <div
      className="zoom"
      onMouseMove={zoom}
      style={{
        backgroundImage: `url(${src})`,
      }}
      onClick={onClick}
    >
      <img src={src} alt="" />
    </div>
  );
};

FigureZoom.propTypes = {
  src: PropTypes.string,
};
