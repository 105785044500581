import {
  RESET_CLIENT_PROVISION,
  SET_CLIENT_PROVISION,
  SET_CLIENT_PROVISIONS,
  SET_REFRESHING_CLIENT_PROVISIONS,
} from "../actions/actionTypes";

import _ from "lodash";

const initialState = {
  contracts: [],
  currentProvision: { trial: "", provision_services: [] },
  provisionsByContract: {},
  refreshing: false,
};

export default function client(state = initialState, action) {
  switch (action.type) {
    case SET_CLIENT_PROVISIONS:
      const { provisions } = action;
      const contracts = _(provisions)
        .map("contract")
        .uniqBy("id")
        .value();
      const provisionsByContract = _.groupBy(provisions, "contract.id");
      return {
        ...state,
        contracts,
        provisionsByContract,
      };

    case SET_REFRESHING_CLIENT_PROVISIONS:
      return {
        ...state,
        refreshing: action.refreshing,
      };

    case SET_CLIENT_PROVISION:
      const { provision } = action;
      return {
        ...state,
        currentProvision: provision,
      };

    case RESET_CLIENT_PROVISION:
      return {
        ...state,
        currentProvision: initialState.currentProvision,
      };

    default:
      return state;
  }
}
