import {
  RESET,
  RESET_TREATMENTS,
  SET_SUBSCRIPTION_CHANNEL,
  SET_TREATMENT,
  SET_TREATMENTS,
  UPDATE_TREATMENT
} from "../actions/actionTypes";
import _ from "lodash";
import dataTableBehavior from "./dataTableBehavior";

const dataTable = dataTableBehavior("treatments", {
  sortField: "finished_processing_at",
  sortOrder: "desc"
});

const initialState = {
  all: [],
  channel: null,
  table: dataTable(undefined, {}) // eslint-disable-line no-undefined
};

export default function treatments(state = initialState, action) {
  switch (action.type) {
    case SET_TREATMENTS:
      return {
        ...state,
        all: action.treatments
      };

    case SET_SUBSCRIPTION_CHANNEL:
      return {
        ...state,
        channel: action.channel
      };

    case SET_TREATMENT:
      const allExistingTreatments = _.clone(state.all);
      const treatmentIndex = _.findIndex(allExistingTreatments, {
        id: action.treatment.id
      });

      if (treatmentIndex === -1) {
        // if the treatment does not exist, add it
        allExistingTreatments.push(action.treatment);
      } else {
        // otherwise replace it
        allExistingTreatments.splice(treatmentIndex, 1, action.treatment);
      }

      return {
        ...state,
        all: allExistingTreatments
      };

    case UPDATE_TREATMENT:
      // Find and replace the old treatment with the new updated one
      const allTreatments = _.clone(state.all);
      const updatedTreatmentIndex = _.findIndex(allTreatments, {
        id: action.newTreatment.id
      });
      allTreatments.splice(updatedTreatmentIndex, 1, action.newTreatment);

      return {
        ...state,
        all: allTreatments
      };

    case RESET_TREATMENTS:
      return {
        ...state,
        all: []
      };

    case RESET:
      return initialState;

    default:
      return {
        ...state,
        table: dataTable(state.table, action)
      };
  }
}

export function mapTreatmentStatusForClient(someTreatments) {
  return someTreatments.map(treatment => {
    return {
      ...treatment,
      status: treatment.status === "completed" ? "completed" : "in progress"
    };
  });
}

export function rejectDummyTreatments(someTreatments) {
  return someTreatments.filter(treatment => {
    return treatment.status !== "created";
  });
}
