import "./roleForm.css";
import { Form, Input } from "reactstrap";
import {
  availableRolesForSelection,
  belongsToGroup,
  hasAdminAccess,
  labelForUserRole
} from "../services/roles";
import PropTypes from "prop-types";
import React from "react";
import { changeUserRole } from "../actions/users";
import { connect } from "react-redux";

const RoleForm = ({
  userDetails,
  currentUser,
  availableRoles,
  onChangeUserRole
}) => {
  function handleChangeRole(event, user) {
    event.preventDefault();
    onChangeUserRole(user.id, event.target.value);
  }

  function renderRoleSelection() {
    return (
      <Form inline className="justify-content-center">
        <Input
          type="select"
          className="select-input-table"
          value={userDetails.role}
          disabled={currentUser.id === userDetails.id}
          onChange={event => handleChangeRole(event, userDetails)}
        >
          {availableRoles.map(role => (
            <option key={role.label} value={role.value}>
              {role.label}
            </option>
          ))}
        </Input>
      </Form>
    );
  }

  const canChangeUserRole =
    hasAdminAccess(currentUser) && belongsToGroup(currentUser, userDetails);

  return (
    <div>
      {canChangeUserRole
        ? renderRoleSelection()
        : labelForUserRole(userDetails)}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.self,
    availableRoles: availableRolesForSelection(state.user.self)
  };
}

const callbacks = {
  onChangeUserRole: changeUserRole
};

export default connect(
  mapStateToProps,
  callbacks
)(RoleForm);

RoleForm.propTypes = {
  availableRoles: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  onChangeUserRole: PropTypes.func.isRequired
};
