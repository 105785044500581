import "./page404.css";

import { Button } from "reactstrap";
import cloverfieldLogo from "../static/img/cloverfield_icon_base.png";

export const Page404 = () => {
  return (
    <div className="page-404">
      <img
        className="cloverfield-logo"
        src={cloverfieldLogo}
        alt="cloverfield-logo"
      />
      <h1 className="text-404">404</h1>
      <h2>Page not found</h2>
      <hr></hr>
      <Button color="primary" href="/">
        Bring me back home
      </Button>
    </div>
  );
};
