import "./plotRatingConfig.css";

import { Badge, Button, Collapse } from "reactstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { EditableLabel } from "../../components/editableLabel/EditableLabel";
import PropTypes from "prop-types";
import { useState } from "react";

export const PlotRatingConfigItem = ({
  plotRatingConfigItem,
  deleteItem,
  updateItem,
  labelList,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = plotRatingConfigItem.options;
    const [removedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removedItem);

    const newData = { ...plotRatingConfigItem, options: items };
    updateItem(newData);
  };

  const handleNewOption = () => {
    let newIndex = plotRatingConfigItem.options.length + 1;
    let newOption = `Option ${newIndex}`;
    while (plotRatingConfigItem.options.includes(newOption)) {
      newIndex += 1;
      newOption = `Option ${newIndex}`;
    }

    const newData = {
      ...plotRatingConfigItem,
      options: [...plotRatingConfigItem.options, newOption],
    };
    updateItem(newData);
  };

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleDeleteOption = (index) => {
    const newOptions = [...plotRatingConfigItem.options];
    newOptions.splice(index, 1);
    const newData = {
      ...plotRatingConfigItem,
      options: newOptions,
    };
    updateItem(newData);
  };

  const handleChangeLabel = (newValue) => {
    if (labelList.includes(newValue)) return;
    const newData = {
      ...plotRatingConfigItem,
      label: newValue,
    };
    updateItem(newData);
  };

  const handleChangeOption = (index, newValue) => {
    if (!plotRatingConfigItem.options.includes(newValue)) {
      const newData = {
        ...plotRatingConfigItem,
        options: plotRatingConfigItem.options.map((option, idx) =>
          idx === index ? newValue : option
        ),
      };
      updateItem(newData);
    }
  };

  return (
    <>
      <div className="item-field-container">
        <div className="item-field-container-label">
          <EditableLabel
            value={plotRatingConfigItem.label}
            onChange={handleChangeLabel}
          />
        </div>

        <i
          className={`fa fa-chevron-${isCollapsed ? "down" : "up"} clickable`}
          aria-hidden="true"
          onClick={handleCollapse}
        />
        <i
          className="fa fa-lg fa-trash text-danger clickable"
          aria-hidden="true"
          onClick={deleteItem}
        />
      </div>

      <Collapse isOpen={!isCollapsed}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {plotRatingConfigItem.options.map((item, index) => (
                  <div className="plot-rating-item ms-2" key={item}>
                    {/* if color is not valid "primary secondary...", the background color is overriden by the background color of the style */}

                    <div className="index-option">
                      <Badge
                        pill
                        className="w-100"
                        color="invalid background color"
                      >
                        {index + 1}
                      </Badge>
                    </div>
                    <Draggable draggableId={item.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="plot-rating-options"
                        >
                          <i
                            className="fa fa-th fa-lg discrete-icon fa-rotate-90 square-icon"
                            aria-hidden="true"
                          />
                          <div className="plot-rating-options-option">
                            <EditableLabel
                              value={item.toString()}
                              onChange={(newValue) =>
                                handleChangeOption(index, newValue)
                              }
                            />
                          </div>
                          <i
                            className="fa fa-times clickable text-danger"
                            aria-hidden="true"
                            onClick={() => handleDeleteOption(index)}
                          />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          className="clickable hiphen-badge small rounded-pill d-flex align-items-center ms-4 gap-2 mt-1"
          onClick={handleNewOption}
        >
          <i className="fa fa-plus-circle add-icon" aria-hidden="true" /> Add
          option
        </Button>
      </Collapse>
    </>
  );
};

PlotRatingConfigItem.propTypes = {
  plotRatingConfigItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  labelList: PropTypes.arrayOf(PropTypes.string).isRequired,
};
