import { Button, Table } from "reactstrap";
import React, { Component } from "react";
import {
  changeAccess,
  deleteUser,
  fetchUsers,
  resetUserAlert,
  updateUserInformation,
} from "../actions/users";

import AdminFilterHeader from "../components/AdminFilterHeader/AdminFilterHeader";
import LoadingImg from "../components/loading";
import PageTitle from "../components/pageTitle";
import PropTypes from "prop-types";
import RoleForm from "./roleForm";
import UserUpdateModal from "./userUpdateModal";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchCompanies } from "../actions/companies";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedUser: {},
      isOpenedModal: false,
      search: "",
    };
    this.handleCreateUser = this.handleCreateUser.bind(this);
  }

  handleFilterChange = (event) => {
    this.setState({ search: event.target.value });
  };

  componentDidMount() {
    this.props.handleRefresh();
    this.props.fetchCompanies();
  }

  resetState() {
    this.setState({
      updatedUser: {},
      isOpenedModal: false,
    });
  }

  handleChangeAccess(event, user) {
    event.preventDefault();
    this.props.changeAccess(user.id, event.target.checked);
  }

  handleCreateUser(event) {
    const user = {
      id: null,
      lastname: "",
      firstname: "",
      phone: "",
      email: "",
      company_id: null,
    };
    this.handleUpdateUser(event, user);
  }

  handleUpdateUser(event, user) {
    event.preventDefault();
    this.setState({
      updatedUser: user,
      isOpenedModal: true,
    });
  }

  handleDeleteUser(event, user) {
    event.preventDefault();
    // eslint-disable-next-line no-alert
    const confirmation = window.confirm(
      `Are you sure you want to permanently delete "${user.fullname}"?`
    );
    if (confirmation) this.props.onDeleteUser(user);
  }

  cancelModal() {
    this.props.resetUserAlert();
    this.resetState();
  }

  render() {
    const search = this.state.search.toLowerCase();
    const filteredUsers =
      search === ""
        ? this.props.users
        : this.props.users.filter((user) => {
            return (
              (user.fullname && user.fullname.toLowerCase().includes(search)) ||
              (user.email && user.email.toLowerCase().includes(search)) ||
              (user.phone && user.phone.toLowerCase().includes(search)) ||
              (user.company && user.company.name.toLowerCase().includes(search))
            );
          });
    return (
      <>
        <UserUpdateModal
          alert={this.props.userUpdateAlert}
          isOpen={this.state.isOpenedModal || this.props.userUpdateAlert !== ""}
          onClose={() => {
            this.cancelModal();
          }}
          onSubmit={(user) => {
            this.props.onUpdateUser(user);
            this.setState({ isOpenedModal: false });
          }}
          user={this.state.updatedUser}
          companies={this.props.companies}
        />

        <div className="page-content container-fluid">
          <PageTitle title="Users" />

          <div className="col-12 section full-height">
            <AdminFilterHeader
              onButtonClick={this.handleCreateUser}
              onFilterChange={this.handleFilterChange}
              onRefresh={this.props.handleRefresh}
              refreshing={this.props.refreshing}
              buttonName="New user"
              search={this.state.search}
            />

            <div className="row mt-51">
              <div className="col-12">
                {!this.props.refreshing && (
                  <Table striped>
                    <thead>
                      <tr>
                        <th>
                          <span>Last name</span>
                        </th>
                        <th>
                          <span>First name</span>
                        </th>
                        <th>
                          <span>Company</span>
                        </th>
                        <th>
                          <span>Email</span>
                        </th>
                        <th>
                          <span>Phone</span>
                        </th>
                        <th className="text-center">
                          <span>Role</span>
                        </th>
                        <th className="text-center">
                          <span>Access</span>
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {filteredUsers.map((user) => (
                        <tr key={user.id}>
                          <td>{user.lastname}</td>
                          <td>{user.firstname}</td>
                          <td>{user.company ? user.company.name : ""}</td>
                          <td>{user.email}</td>
                          <td>{user.phone}</td>
                          <td className="text-center">
                            <RoleForm userDetails={user} />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              onChange={(event) =>
                                this.handleChangeAccess(event, user)
                              }
                              disabled={this.props.currentUser.id === user.id}
                              checked={user.access}
                            />
                          </td>
                          <td className="alignItemsCenter">
                            <Button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Edit user information"
                              className="p-0"
                              color="link"
                              onClick={(event) =>
                                this.handleUpdateUser(event, user)
                              }
                            >
                              <i className="fa fa-lg fa-pencil" />
                            </Button>
                            <Button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Delete user"
                              data-tooltip-variant="error"
                              className="ms-2 p-0"
                              color="link danger"
                              disabled={this.props.currentUser.id === user.id}
                              onClick={(event) =>
                                this.handleDeleteUser(event, user)
                              }
                            >
                              <i className="fa fa-lg fa-trash" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <LoadingImg visible={this.props.refreshing} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const users = state.users.all;
  const sortedUsers = _.orderBy(
    users,
    [(user) => user.fullname?.toLowerCase()],
    ["asc"]
  );
  return {
    currentUser: state.user.self,
    users: sortedUsers,
    userUpdateAlert: state.users.updateAlert,
    refreshing: state.users.table.refreshing,
    companies: state.companies,
  };
}

const callbacks = {
  changeAccess,
  resetUserAlert,
  handleRefresh: fetchUsers,
  onDeleteUser: deleteUser,
  onUpdateUser: updateUserInformation,
  fetchCompanies,
};

export default connect(mapStateToProps, callbacks)(Users);

Users.propTypes = {
  currentUser: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  refreshing: PropTypes.bool.isRequired,
  userUpdateAlert: PropTypes.string.isRequired,
  changeAccess: PropTypes.func.isRequired,
  resetUserAlert: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
};
