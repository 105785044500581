import "./attributes.css";

import { AttributeSelector } from "./AttributeSelector";
import { Collapse } from "reactstrap";
import { LabelledSeparator } from "../../../../../../components/labelledSeparator/LabelledSeparator";
import { MAX_ATTRIBUTES_SELECT_VALUES } from "../../../../../../constants";
import PropTypes from "prop-types";
import { setAttributes } from "../../../../../../actions/resultMap";
import { useDispatch } from "react-redux";
import { useState } from "react";

export const Attributes = ({ selectedAttributes, attributes }) => {
  const [isAttributesOpen, setIsAttributesOpen] = useState(false);

  const dispatch = useDispatch();

  const setSelectedAttributes = (attributes) => {
    dispatch(setAttributes(attributes));
  };

  const toggleAttributes = () => setIsAttributesOpen(!isAttributesOpen);

  const sortedAttributesOptions = Object.entries(attributes)
    .map(([label, options]) => ({
      label,
      values: options,
    }))
    .sort((a, b) => {
      // Prioritize length < 50, then sort alphabetically
      if (
        a.values.length < MAX_ATTRIBUTES_SELECT_VALUES &&
        b.values.length >= MAX_ATTRIBUTES_SELECT_VALUES
      )
        return -1;
      if (
        a.values.length >= MAX_ATTRIBUTES_SELECT_VALUES &&
        b.values.length < MAX_ATTRIBUTES_SELECT_VALUES
      )
        return 1;
      return a.label.localeCompare(b.label);
    });

  return (
    <>
      <LabelledSeparator
        className="gap-2 mt-1 clickable"
        onClick={toggleAttributes}
      >
        <span>Other Attributes</span>{" "}
        <i
          className={`fa-solid fa-chevron-down discrete-icon ${
            isAttributesOpen ? "fa-rotate-180" : ""
          }`}
        />
      </LabelledSeparator>

      <Collapse isOpen={isAttributesOpen}>
        {sortedAttributesOptions.map(({ label, values }) => (
          <AttributeSelector
            key={label}
            label={label}
            values={values}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={setSelectedAttributes}
          />
        ))}
      </Collapse>
    </>
  );
};

Attributes.propTypes = {
  selectedAttributes: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
};
