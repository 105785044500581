import "./ControlSection.css";

import {
  ANALYTICS_EVENTS,
  FEATURE_PROPERTY_CATEGORIES,
} from "../../../../constants";
import {
  fetchResultMapFeatures,
  setSelectedContract,
} from "../../../../actions/resultMap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";

import { Button } from "reactstrap";
import { FloatingCard } from "../../../../components/floatingCard/FloatingCard";
import ResultDownloadModal from "../../../../clientDashboard/resultDownloadModal";
import Select from "react-select";
import { SimpleMode } from "./SimpleMode";
import { UploadYourOwnData } from "../uploadYourOwnData/UploadYourOwnData";
import { hasAnalyticsRole } from "../../../../users/rolesUtil";
import { hasBackofficeAccess } from "../../../../services/roles";
import { selectSortedTrials } from "../../../../selectors/user";
import { uniqueArrayOfObject } from "../../../utils";
import { useClickAway } from "react-use";
import { useHistory } from "react-router-dom";
import { useKeyboardShortcut } from "../../../../hooks/useKeyboardShortcut";
import { useTracking } from "../../../../analytics";

export const ControlSection = () => {
  const sortedUserTrials = useSelector(selectSortedTrials);
  const user = useSelector(({ user }) => user);
  const [trial, trialDate, refreshing, selectedContract, features] =
    useSelector(({ resultMap }) => [
      resultMap.trial,
      resultMap.trial_date,
      resultMap.refreshing,
      resultMap.selectedContract,
      resultMap.features,
    ]);

  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  // Pre filtered
  const filteredContracts = useMemo(
    () => user.contracts.filter(({ roles }) => hasAnalyticsRole(roles)),
    [user.contracts]
  );

  // Calculate companies from user contracts
  const companies = uniqueArrayOfObject(
    filteredContracts.map((contract) => contract.company),
    "id"
  );
  const companiesOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }));
  const [selectedCompany, setSelectedCompany] = useState();
  const [simpleMode, setSimpleMode] = useState(true);

  const componentRef = useRef();
  useClickAway(componentRef, () => {
    if (trial && !simpleMode) setSimpleMode(true);
  });

  // Default set to simple mode on trial change
  const [prevTrial, setPrevTrial] = useState(null);
  if (prevTrial !== trial) {
    setSimpleMode(true);
    setPrevTrial(trial);
  }

  const [uploadIsOpen, setUploadIsOpen] = useState(false);

  // If only one contract, Select it by default
  useEffect(() => {
    if (filteredContracts.length === 1 && !selectedContract)
      dispatch(setSelectedContract(filteredContracts[0]));
  }, [dispatch, filteredContracts, selectedContract]);

  useKeyboardShortcut("u", () => setUploadIsOpen(true));

  return (
    <div className="powerdash-component control-section" ref={componentRef}>
      {user.contractsLoading && !trial ? (
        <div className="text-center font-style-italic">
          We are loading your campaigns...
        </div>
      ) : sortedUserTrials.length === 0 ? (
        <div className="text-center font-style-italic">
          No campaign available
        </div>
      ) : simpleMode && trial ? (
        <SimpleMode
          onClick={() => setSimpleMode(false)}
          trial={trial}
          refreshing={refreshing}
        />
      ) : (
        <>
          {filteredContracts.length === 1 && (
            <span className="font-weight-bold">
              <i className="fa-solid fa-file-signature" />{" "}
              {filteredContracts[0].name}
            </span>
          )}
          {companiesOptions.length > 1 && (
            <Select
              isClearable
              options={companiesOptions}
              value={selectedCompany}
              onChange={(value) => {
                setSelectedCompany(value);
                if (value)
                  dispatch(
                    setSelectedContract(
                      filteredContracts.find(
                        ({ company }) => company.id === value.value
                      )
                    )
                  );
              }}
              isDisabled={refreshing}
              placeholder="Filter by company"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  fontSize: "large",
                }),
              }}
            />
          )}
          {filteredContracts.length > 1 && (
            <Select
              isClearable={!trial}
              options={
                selectedCompany
                  ? filteredContracts.filter(
                      ({ company }) => company.id === selectedCompany.value
                    )
                  : filteredContracts
              }
              value={selectedContract}
              id="contract"
              name="contract"
              onChange={(selectedOption) => {
                dispatch(setSelectedContract(selectedOption));
              }}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id.toString()}
              isDisabled={refreshing}
              placeholder="Select campaign"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  fontSize: "large",
                }),
              }}
            />
          )}
          <Select
            options={sortedUserTrials.filter((userTrial) =>
              selectedContract?.sites.map(({ id }) => id).includes(userTrial.id)
            )}
            value={trial}
            id="trial"
            name="trial"
            onChange={(newTrial) => {
              dispatch(
                fetchResultMapFeatures(newTrial, newTrial.trial_dates.at(-1))
              );
            }}
            getOptionLabel={({ display_name }) => display_name}
            getOptionValue={({ id }) => id.toString()}
            isDisabled={refreshing || selectedContract == null}
            placeholder="Select site"
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                fontSize: "large",
              }),
            }}
          />
        </>
      )}
      {trial && (
        <>
          <div className="d-flex gap-1">
            {hasBackofficeAccess(user.self) && (
              <Button
                data-tooltip-id="tooltip"
                data-tooltip-content="Open in administration"
                size="sm"
                onClick={() =>
                  history.push(
                    `/administration/company/${trial.company_id}/contract/${trial.contract_id}`
                  )
                }
              >
                <i className="fa-solid fa-lg fa-cogs" />
              </Button>
            )}
            <Button
              disabled={features.length === 0}
              className="hiphen-green-button flex-grow-1 text-ellipsis"
              size="sm"
              onClick={() => setIsDownloadModalOpen(true)}
            >
              <i className="fa-solid fa-download" /> Download results
            </Button>
          </div>
          {features.length > 0 && (
            <>
              <Button
                className="hiphen-green-button"
                style={{
                  backgroundColor: FEATURE_PROPERTY_CATEGORIES.IMPORTED.color,
                }}
                onClick={() => {
                  setUploadIsOpen(!uploadIsOpen);
                  trackEvent(ANALYTICS_EVENTS.OPEN_UPLOAD_YOUR_OWN_DATA);
                }}
                size="sm"
              >
                <i className="fa-solid fa-upload me-1" />
                Upload my custom data
              </Button>
              {uploadIsOpen && (
                <FloatingCard hide={() => setUploadIsOpen(false)}>
                  <UploadYourOwnData
                    trial={trial}
                    setUploadIsOpen={setUploadIsOpen}
                  />
                </FloatingCard>
              )}
            </>
          )}
          <ResultDownloadModal
            trial={trial}
            mission={{ date: trialDate }}
            isOpen={isDownloadModalOpen}
            onClose={() => setIsDownloadModalOpen(false)}
            user={user}
            trackEvent={trackEvent}
          />
        </>
      )}
    </div>
  );
};
