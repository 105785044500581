import {
  ANALYTICS_EVENTS,
  DEFAULT_ERROR_MESSAGE,
  DOWNLOAD_WILL_BEGIN,
  DOWNLOAD_WITHOUT_RESULT,
} from "../constants";
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import download from "downloadjs";

class ResultDownloadModal extends React.Component {
  state = {
    downloadMessage: null,
    errorMessage: null,
  };

  onClose = () => {
    this.setState({
      downloadMessage: null,
      errorMessage: null,
    });
  };

  downloadFile = (url) => {
    this.setState({
      downloadMessage: DOWNLOAD_WILL_BEGIN,
      errorMessage: null,
    });
    fetch(url, {
      headers: this.props.user.identity,
    }).then((response) => {
      if (response.ok)
        response.blob().then((blob) => {
          var filename = "";
          var disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1])
              filename = matches[1].replace(/['"]/g, "");
          }
          const indexOfDot = filename.lastIndexOf(".");
          const fileExtension = filename.slice(indexOfDot + 1);

          this.props.trackEvent(ANALYTICS_EVENTS.MAP_DOWNLOAD_BUTTON, {
            downloadFormat: fileExtension,
          });

          download(blob, filename);
          setTimeout(function () {
            window.URL.revokeObjectURL(url);
          }, 100);
        });
      else if (response.status === 404)
        this.setState({ errorMessage: DOWNLOAD_WITHOUT_RESULT });
      else this.setState({ errorMessage: DEFAULT_ERROR_MESSAGE });

      this.setState({ downloadMessage: null });
    });
  };

  render() {
    const links = _.get(
      this.props.trial,
      `links.download-results.${this.props.mission?.date}`,
      []
    );
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClosed={this.onClose}
        className="modal-sm"
      >
        <ModalHeader>
          <p>Download results</p>
          <span>
            {this.props.trial?.display_name} {this.props.mission?.date}
          </span>
        </ModalHeader>
        <ModalBody>
          {this.state.errorMessage && (
            <Alert className="text-center" color="danger">
              {this.state.errorMessage}
            </Alert>
          )}
          <Row className="mb-2 p-0">
            <Col sm="12" className="text-center fw-bold">
              Format(s)
            </Col>
          </Row>
          {!this.state.downloadMessage &&
            _.map(links, (link, index) => (
              <Row key={index} className="mb-2 p-0">
                <Col sm="12" className="text-center">
                  <Button
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Download results"
                    className="p-0"
                    color="link"
                    onClick={() => this.downloadFile(link)}
                  >
                    {index}
                  </Button>
                </Col>
              </Row>
            ))}
          {this.state.downloadMessage && (
            <Row>
              <span className="text-center">{this.state.downloadMessage}</span>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.props.onClose()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ResultDownloadModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  trial: PropTypes.object.isRequired,
};

export default ResultDownloadModal;
