import { useDispatch, useSelector } from "react-redux";

import { MAP_LAYER_TYPES } from "../../constants";
import PropTypes from "prop-types";
import { PropertyName } from "../../components/PropertyName/PropertyName";
import ReactSlider from "react-slider";
import { WorldMapLegendGrad } from "./WorldMapLegendGrad";
import { selectColorScale } from "../../selectors/resultMap";
import { upsertMapLayer } from "../../actions/resultMap";
import { useState } from "react";
import { useWMSColorScale } from "../../hooks/useWMSColorScale";

export const WorldMapLegendItem = ({ layer }) => {
  const trait = useSelector(({ resultMap }) => resultMap.selectedTrait);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();

  const traitColorScale = useSelector(selectColorScale);
  const wmsColorScale = useWMSColorScale();

  const colorScale =
    (layer.type === MAP_LAYER_TYPES.GEOJSON && traitColorScale) ||
    wmsColorScale;

  return (
    <div className="powerdash-component legend-item gap-1 mt-2">
      <div className="legend-item-header d-flex align-items-center gap-2 pe-1 ps-2">
        <i
          className={`fa-solid fa-chevron-right clickable discrete-icon
            ${!isCollapsed ? "fa-rotate-90" : ""}`}
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
        <div className="legend-item-vector">
          <i className={layer.type.icon} />
        </div>

        <div className="flex-grow-1 ps-1">
          <ReactSlider
            disabled={!layer.visible}
            value={layer.opacity}
            step={0.01}
            min={0}
            max={1}
            onAfterChange={(value) => {
              dispatch(upsertMapLayer({ ...layer, opacity: value }));
            }}
            className={`cloverfield-slider ${!layer.visible ? "disabled" : ""}`}
            thumbClassName="cloverfield-slider-thumb"
            trackClassName="cloverfield-slider-track"
          />
        </div>
        <div className="text-center" style={{ width: "2em" }}>
          <i
            className={`fa-solid discrete-icon clickable ${
              layer.visible ? "fa-eye" : "fa-eye-slash"
            }`}
            onClick={() => {
              setIsCollapsed(layer.visible);
              dispatch(upsertMapLayer({ ...layer, visible: !layer.visible }));
            }}
          />
        </div>
      </div>
      {!isCollapsed && (
        <>
          {layer.type === MAP_LAYER_TYPES.GEOJSON &&
            (trait.technical_name ? (
              <PropertyName
                showIcon
                showUnit
                technicalName={trait.technical_name}
              />
            ) : (
              "Plot Map"
            ))}
          {layer.type === MAP_LAYER_TYPES.ORTHO && (
            <span>{layer.name === "plain" ? "Orthomosaic" : layer.name}</span>
          )}
          {colorScale &&
            colorScale.minValue != null &&
            colorScale.maxValue != null && (
              <WorldMapLegendGrad
                colorScale={colorScale}
                dynamic={layer.type === MAP_LAYER_TYPES.GEOJSON}
              />
            )}
        </>
      )}
    </div>
  );
};

WorldMapLegendItem.propTypes = {
  layer: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(MAP_LAYER_TYPES)).isRequired,
    opacity: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
  }).isRequired,
};
