import "./labelledSeparator.css";

import PropTypes from "prop-types";

export const LabelledSeparator = ({
  className,
  children,
  visible = true,
  onClick,
}) => {
  return (
    visible && (
      <div
        className={`${className ?? ""} labelled-separator d-flex pt-1`}
        onClick={() => onClick?.()}
      >
        <div className="labelled-separator-line" />
        <div>{children}</div>
        <div className="labelled-separator-line" />
      </div>
    )
  );
};

LabelledSeparator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
};
