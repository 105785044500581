import "./componentPlaceholder.css";

import PropTypes from "prop-types";

/*
 * Basic placeholder with big icon and display text
 */
export const ComponentPlaceholder = ({ icon, text }) => {
  return (
    <div className="component-placeholder">
      <i className={`fa fa-5x ${icon} discrete-icon`} />
      {text}
    </div>
  );
};

ComponentPlaceholder.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
};
