import { Col, Row, Table } from "reactstrap";
import { OPTIONS_SYSTEM, SESSION_STATUS } from "../constants";
import React, { useEffect, useRef, useState } from "react";
import { clickableRows, fixHeaders } from "../components/dataTable";
import {
  fetchCompanyContracts,
  resetCompanyContracts,
} from "../actions/company";
import { fetchSites, resetSites } from "../actions/sites";

import LoadingImg from "../components/loading";
import PageTitle from "../components/pageTitle";
import PropTypes from "prop-types";
import Select from "react-select";
import UploadSessionModal from "./uploadSessionModal";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchAllUploadSessions } from "../services/backendRequests";
import { fetchCompanies } from "../actions/companies";
import moment from "moment";
import queryString from "query-string";
import { useDidMountEffect } from "../hooks/useDidMountEffect";
import { useLocation } from "react-router-dom";

const UploadSessions = ({
  user,
  companies,
  contracts,
  sites,
  fetchCompanies,
  fetchCompanyContracts,
  fetchSites,
  resetCompanyContracts,
  resetSites,
}) => {
  const location = useLocation();
  const { company, contract, site } = queryString.parse(location.search);
  const [companyId, setCompanyId] = useState(company);
  const [contractId, setContractId] = useState(contract);
  const [siteId, setSiteId] = useState(site);
  const [system, setSystem] = useState();
  const [status, setStatus] = useState(SESSION_STATUS.Completed);
  const [uploadSessions, setUploadSessions] = useState([]);
  const [uploadSessionSelected, setUploadSessionSelected] = useState(null);
  const [loading, setLoading] = useState(true);

  const initialUploadSessions = useRef();

  useEffect(() => {
    const initializeData = async () => {
      await resetCompanyContracts();
      await resetSites();
      await fetchCompanies();
      setLoading(true);
      const uploadSessions = await fetchAllUploadSessions(user, status);
      initialUploadSessions.current = _.sortBy(uploadSessions, "created_at");
      let list = initialUploadSessions.current;

      if (!site && !contract && company) {
        list = initialUploadSessions.current?.filter(
          (us) => us.company.id === companyId
        );
        await fetchCompanyContracts(companies.find((i) => i.id === companyId));
      }

      if (!site && contract)
        list = initialUploadSessions.current?.filter(
          (us) => us.contract_info.id === contractId
        );

      if (site)
        list = initialUploadSessions.current?.filter(
          (us) => us.site.id === siteId
        );

      setUploadSessions(list);
      setLoading(false);
      if (contractId) fetchSites({ id: contractId });
    };
    initializeData();
  }, []);

  const applyEffect = (fetch, elementId, elementAtt, reset = undefined) => {
    if (elementId && initialUploadSessions.current) {
      fetch({ id: elementId });
      if (reset) reset();
      setUploadSessions(
        initialUploadSessions.current?.filter(
          (us) => us[elementAtt].id === elementId
        )
      );
    }
  };

  useDidMountEffect(() => {
    applyEffect(
      (e) => fetchCompanyContracts(e),
      companyId,
      "company",
      () => {
        setContractId();
        setSiteId();
      }
    );
  }, [companyId]);

  useDidMountEffect(() => {
    applyEffect(
      (e) => fetchSites(e),
      contractId,
      "contract_info",
      () => setSiteId()
    );
  }, [contractId]);

  useDidMountEffect(() => {
    applyEffect((e) => e, siteId, "site");
  }, [siteId]);

  useDidMountEffect(() => {
    if (system && initialUploadSessions.current) {
      let list = initialUploadSessions.current?.filter(
        (us) => us.contract_info.system === system
      );
      if (companyId) list = list?.filter((us) => us.company.id === companyId);
      if (contractId)
        list = list?.filter((us) => us.contract_info.id === contractId);
      if (siteId) list = list?.filter((us) => us.site.id === siteId);
      setUploadSessions(list);
    }
  }, [system]);

  const reloadData = () => {
    setLoading(true);
    fetchAllUploadSessions(user, status, system).then(async (data) => {
      initialUploadSessions.current = _.sortBy(data, "created_at");
      let list = initialUploadSessions.current;
      if (!siteId && !contractId && companyId)
        list = initialUploadSessions.current?.filter(
          (us) => us.company.id === companyId
        );
      if (!siteId && contractId)
        list = initialUploadSessions.current?.filter(
          (us) => us.contract_info.id === contractId
        );
      if (siteId)
        list = initialUploadSessions.current?.filter(
          (us) => us.site.id === siteId
        );
      setUploadSessions(list);
      setLoading(false);
    });
  };

  useDidMountEffect(() => {
    reloadData();
  }, [status, system]);

  const handleUploadSessionRowClick = (e, us) => {
    e.preventDefault();
    setUploadSessionSelected(us);
  };

  const handleCloseModal = () => {
    setUploadSessionSelected(null);
    reloadData();
  };

  const selectComponent = (label, options, value, onChange, name = "name") => {
    return (
      <>
        <Col xs="1">
          <label className="col-form-label green-color">{label}</label>
        </Col>
        <Col xs="3">
          <Select
            options={options.map((c) => {
              return { value: c.id, label: c[name] };
            })}
            value={{
              value: value,
              label: _.get(
                options.find((c) => c.id === value),
                name
              ),
            }}
            onChange={(e) => onChange(e.value)}
            isSearchable={true}
          />
        </Col>
      </>
    );
  };

  return (
    <>
      <UploadSessionModal
        us={uploadSessionSelected}
        isOpen={uploadSessionSelected !== null}
        closeModal={() => handleCloseModal()}
        company={
          companies.filter((c) => c.id === uploadSessionSelected?.company.id)[0]
        }
      />

      <div className="page-content container-fluid">
        <PageTitle title="Upload Sessions" />

        <div className="col-12 section full-height">
          <Row className="pb-2">
            {selectComponent("Company", companies, companyId, (p) =>
              setCompanyId(p)
            )}
            {selectComponent("Contract", contracts, contractId, (p) =>
              setContractId(p)
            )}
            {selectComponent(
              "Site",
              sites,
              siteId,
              (p) => setSiteId(p),
              "display_name"
            )}
          </Row>
          <Row className="pb-2">
            <Col xs="1">
              <label className="col-form-label green-color">System</label>
            </Col>
            <Col xs="3">
              <Select
                options={OPTIONS_SYSTEM}
                value={OPTIONS_SYSTEM.find((c) => c.value === system)}
                onChange={(e) => setSystem(e.value)}
                isSearchable={true}
              />
            </Col>
            <Col xs="1">
              <label className="col-form-label green-color">Status</label>
            </Col>
            <Col xs="3">
              <Select
                options={SESSION_STATUS.iterateKeys().map((k) => {
                  return { value: k, label: SESSION_STATUS[k] };
                })}
                value={{ value: status, label: SESSION_STATUS[status] }}
                onChange={(e) => setStatus(e.value)}
                isSearchable={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12 text-center pb-2">
              <Table striped hover>
                {fixHeaders(
                  "Company",
                  "Campaign",
                  "Site",
                  "System",
                  "Acquisition at",
                  "Created by",
                  "Created at",
                  "Updated at",
                  "Status"
                )}
                <tbody>
                  {!loading &&
                    uploadSessions?.map((us) =>
                      clickableRows(
                        handleUploadSessionRowClick,
                        us,
                        us.id,
                        us.company.name,
                        us.contract_info.name,
                        us.site.display_name,
                        OPTIONS_SYSTEM.find(
                          (e) => e.value === us.contract_info.system
                        )?.label,
                        moment(us.acquisition_date).format(
                          "YYYY-MM-DD HH:mm:ss"
                        ),
                        us.user,
                        moment(us.created_at).format("YYYY-MM-DD HH:mm:ss"),
                        moment(us.updated_at).format("YYYY-MM-DD HH:mm:ss"),
                        SESSION_STATUS[us.status]
                      )
                    )}
                </tbody>
              </Table>
              <LoadingImg visible={loading} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const companies = state.companies.all;
  const sortedCompanies = _.orderBy(
    companies,
    [(company) => company.name.toLowerCase()],
    ["asc"]
  );

  const contracts = state.company.contracts;
  const sortedContracts = _.orderBy(
    contracts,
    [(contract) => contract.name.toLowerCase()],
    ["asc"]
  );

  const sites = state.sites.all;
  const sortedSites = _.orderBy(
    sites,
    [(site) => site.display_name?.toLowerCase()],
    ["asc"]
  );

  return {
    user: state.user,
    companies: sortedCompanies,
    contracts: sortedContracts,
    sites: sortedSites,
  };
}

const callbacks = {
  fetchCompanies,
  fetchCompanyContracts,
  fetchSites,
  resetCompanyContracts,
  resetSites,
};

export default connect(mapStateToProps, callbacks)(UploadSessions);

UploadSessions.propTypes = {
  user: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  fetchSites: PropTypes.func.isRequired,
  resetCompanyContracts: PropTypes.func.isRequired,
  resetSites: PropTypes.func.isRequired,
};
