import "./missionChat.css";

import {
  Badge,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  requestCreateMissionsMonitoringComment,
  requestFetchContractInfo,
  requestMarkAsReadMissionMonitoringComment,
} from "../../../../services/backendRequests";

import { COMPANY_HIPHEN } from "../../../../constants";
import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { addDangerAlert } from "../../../../actions/alerts";
import classnames from "classnames";
import { dateSort } from "../../../utils";
import { hasBackofficeAccess } from "../../../../services/roles";
import { hasMonitoringRole } from "../../../../users/rolesUtil";
import hiphenLogo from "../../../../static/img/Hiphen_logo.png";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

/*
 * Modal opened to post a new comment
 */
const NewCommentModal = ({
  isOpen,
  closeModal,
  isBackOffice,
  element,
  user,
  refresh,
}) => {
  const [usersToSend, setUsersToSend] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [messageContent, setMessageContent] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    let unmounted = false;
    if (hasBackofficeAccess(user.self))
      requestFetchContractInfo({ ...element.contract_info }, user).then(
        (res) => {
          if (!unmounted) setUsersToSend(res.users);
        }
      );
    return () => {
      unmounted = true;
    };
  }, [element.contract_info, user]);

  const reset = () => {
    setSelectedRecipients([]);
    setMessageContent("");
  };

  const sendMsg = () => {
    // Prevent empty
    if (
      messageContent === "" ||
      (user.self.company.name === COMPANY_HIPHEN && !selectedRecipients.length)
    )
      return;
    requestCreateMissionsMonitoringComment(element, user, {
      recipient_ids: selectedRecipients.map(({ value }) => value),
      comment: messageContent,
    })
      .then(() => {
        closeModal();
        refresh();
        reset();
      })
      .catch((err) => {
        dispatch(addDangerAlert(err));
        closeModal();
        refresh();
        reset();
      });
  };

  return (
    <Modal isOpen={isOpen} className="modal-lg mission-chat">
      <ModalHeader>Leave a new comment</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label>Send to</Label>
          {isBackOffice ? (
            <ReactSelect
              options={usersToSend
                ?.filter(
                  (userToSend) =>
                    hasMonitoringRole(userToSend.roles) &&
                    userToSend.id !== user.self.id &&
                    userToSend.company?.name !== COMPANY_HIPHEN
                )
                .map((userToSend) => {
                  return { label: userToSend.fullname, value: userToSend.id };
                })}
              value={selectedRecipients}
              onChange={(value) => {
                setSelectedRecipients(value ?? []);
              }}
              isMulti
            />
          ) : (
            <span>
              <img
                className="hiphen-logo ms-1"
                src={hiphenLogo}
                alt="hiphen-logo"
              ></img>
              Hiphen
            </span>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Content</Label>
          <Input
            type="textarea"
            value={messageContent}
            onChange={(event) => setMessageContent(event.target.value)}
          ></Input>
        </FormGroup>
        <ModalFooter>
          <Button className="send-btn" onClick={sendMsg}>
            Send new comment
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              reset();
              closeModal();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

/*
 * Chat between hiphen and client, showing comments posted by each other
 */
export const MissionChat = ({
  comments,
  isBackOffice,
  element,
  user,
  refresh,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const userCanRead = (comment) => {
    if (!hasBackofficeAccess(user.self))
      return comment.recipients.some(
        (recipient) => recipient.id === user.self.id
      );
    else
      return comment.recipients.some(
        (recipient) => recipient.company?.name === COMPANY_HIPHEN
      );
  };

  const Comment = ({ comment }) => {
    Comment.propTypes = {
      comment: PropTypes.object.isRequired,
    };
    // Mark as read btn
    const MarkAsReadButton = ({ read, onClick }) => {
      MarkAsReadButton.propTypes = {
        read: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
      };

      return (
        <>
          {!read && (
            <Badge className="mark-as-read clickable" onClick={onClick}>
              MARK AS READ
            </Badge>
          )}
        </>
      );
    };

    const markAsRead = () => {
      // If user is hiphen, then he can respond for any hiphen in recipient therefore we find a hiphen one
      // Else this means the user is explicitly targeted in the recipents and is found by id
      const recipientToUpdate = comment.recipients.find(({ id, company }) =>
        user.self.company.name === COMPANY_HIPHEN
          ? company.name === COMPANY_HIPHEN
          : id === user.self.id
      );
      recipientToUpdate.reading_date = moment();
      setMarkedAsRead(true);
      requestMarkAsReadMissionMonitoringComment(
        user,
        comment,
        recipientToUpdate,
        moment()
      ).then(() => refresh());
    };

    const authorIsHiphen = comment.author.company?.name === COMPANY_HIPHEN;
    const author = authorIsHiphen ? COMPANY_HIPHEN : comment.author.fullname;
    const recipients = comment.recipients;
    const userIsHiphen = user.self.company?.name === COMPANY_HIPHEN;
    const userIsRecipient = userIsHiphen
      ? recipients.some(({ company }) => company.name === COMPANY_HIPHEN) // If user is hiphener: check if message is destinated to any hiphen
      : recipients.some(({ id }) => id === user.self.id); // else check if user is recipient
    const [markedAsRead, setMarkedAsRead] = useState(
      recipients.some(
        // message is unread by user only if he is a recipient
        (recipient) =>
          (recipient.id === user.self.id ||
            (userIsHiphen && recipient.company?.name === COMPANY_HIPHEN)) &&
          recipient.reading_date != null
      )
    );
    const wrapperClasses = classnames("message-wrapper", {
      emitter: authorIsHiphen,
      unread: !markedAsRead && userIsRecipient,
    });

    return (
      <div className={wrapperClasses}>
        <div className="powerdash-component message">
          <div className="message-header">
            <span>
              {authorIsHiphen && (
                <img
                  className="hiphen-logo"
                  src={hiphenLogo}
                  alt="hiphen-logo"
                ></img>
              )}
              {author}
            </span>
            <small>
              {moment(comment.created_at).format("YYYY-MM-DD HH:mm")}{" "}
            </small>
          </div>

          {comment.comment}

          <div className="message-footer">
            {comment.recipients.map((recipient) => {
              return (
                <small
                  key={recipient.id}
                  className={recipient.reading_date ? "recipient-read" : ""}
                >
                  {recipient.company?.name === COMPANY_HIPHEN
                    ? "Hiphen"
                    : recipient.fullname}{" "}
                  <i
                    className={`fa ${
                      recipient.reading_date ? "fa-check" : "fa-hourglass-half"
                    }`}
                  />
                </small>
              );
            })}
            {userCanRead(comment) && (
              <MarkAsReadButton read={markedAsRead} onClick={markAsRead} />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mission-chat">
      <NewCommentModal
        isOpen={modalIsOpen}
        isBackOffice={isBackOffice}
        closeModal={() => setModalIsOpen(false)}
        element={element}
        user={user}
        refresh={refresh}
      />
      <div className="mission-chat-content">
        {comments.length === 0 ? (
          <ComponentPlaceholder
            icon="fa-commenting-o"
            text="Be the first to post a comment on this element"
          />
        ) : (
          comments
            .sort((a, b) => dateSort(a.created_at, b.created_at))
            .reverse()
            .map((comment) => <Comment key={comment.id} comment={comment} />)
        )}
      </div>
      <div className="chat-input">
        <Button className="send-btn" onClick={() => setModalIsOpen(true)}>
          <i className="fa fa-lg fa-pencil" /> Leave a new comment
        </Button>
      </div>
    </div>
  );
};

NewCommentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isBackOffice: PropTypes.bool.isRequired,
  element: PropTypes.object.isRequired,
};

MissionChat.propTypes = {
  comments: PropTypes.array.isRequired,
  isBackOffice: PropTypes.bool.isRequired,
  element: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
};
