import L, { Icon } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";

import { Input } from "reactstrap";
import PropTypes from "prop-types";
import markerIcons from "./res";

const LATLONG_REG =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const DraggableMarker = ({ map }) => {
  const markerRef = useRef();
  const inputRef = useRef();
  const [isInputInvalid, setIsInputInvalid] = useState(false);
  const [iconMode, setIconMode] = useState(); //easter egg

  const icon = useMemo(() => {
    return new Icon({
      className: "draggable-marker",
      iconUrl: markerIcons[iconMode] ?? markerIcons.pin,
      iconSize: [38, 38], // size of the icon
    });
  }, [iconMode]);

  useEffect(() => {
    const initialLocation = map.getCenter();
    markerRef.current = new L.marker(initialLocation, {
      draggable: "true",
      icon,
    });

    // protect from geoman "experiment" action
    markerRef.current._pmTempLayer = true;
    markerRef.current._dragDisabled = true;

    // On dragend, display marker lat and long into input
    markerRef.current.on("dragend", () => {
      const position = markerRef.current.getLatLng();
      inputRef.current.value = `${position.lat.toFixed(
        8
      )}, ${position.lng.toFixed(8)}`;
    });
    map.addLayer(markerRef.current);
    return () => {
      // Kill marker on close
      if (markerRef.current) {
        map.removeLayer(markerRef.current);
        markerRef.current = null;
        setIsInputInvalid(false);
      }
    };
  }, [icon, map]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") setPositionMarker(e);
  };

  const setPositionMarker = (e) => {
    e.target.blur();
    const inputValue = inputRef.current.value.replaceAll(" ", "");
    if (inputValue === "") return;
    if (LATLONG_REG.test(inputValue)) {
      const [lat, lng] = inputValue.split(",");
      markerRef.current.setLatLng([parseFloat(lat), parseFloat(lng)]);
      setIsInputInvalid(false);
    } else {
      setIsInputInvalid(true);
      setIconMode(inputValue);
    }
  };

  return (
    <>
      <Input
        invalid={isInputInvalid}
        innerRef={inputRef}
        onKeyPress={handleKeyPress}
        placeholder="Latitude, Longitude"
        autoFocus
        {...(isInputInvalid && {
          "data-tooltip-id": "tooltip",
          "data-tooltip-content":
            'Accepted format: "latitude, longitude", example: "43.91665372, 4.89261713"',
          "data-tooltip-variant": "error",
          "data-tooltip-place": "bottom",
        })}
      />
      <i className="fa fa-search clickable" onClick={setPositionMarker} />
    </>
  );
};

DraggableMarker.propTypes = {
  map: PropTypes.object.isRequired,
};
