import {
  COMPANY_CONTRACT_UPDATE_ALERT,
  RESET_COMPANY,
  RESET_COMPANY_CONTRACTS,
  SET_COMPANY_CONTRACTS,
} from "./actionTypes";
import {
  requestCreateContractInfo,
  requestDeleteContractInfo,
  requestFetchCompanyContracts,
  requestUpdateContractInfo,
  requestUpdateContractUser,
  requestUpdateContractUsers,
} from "../services/backendRequests";

import { addDangerAlert } from "./alerts";
import { fetchUserContractsTrials } from "./user";
import { setRefreshing } from "./dataTable";

function refreshingCompanyContract(refreshing = true) {
  return setRefreshing("companyContracts", refreshing);
}

export function resetCompanyContracts() {
  return {
    type: RESET_COMPANY_CONTRACTS,
  };
}

function setCompanyContracts(contracts, company) {
  return {
    type: SET_COMPANY_CONTRACTS,
    contracts,
    company,
  };
}

export function resetCompany() {
  return {
    type: RESET_COMPANY,
  };
}

export function fetchCompanyContracts(company) {
  return (dispatch, getState) => {
    dispatch(refreshingCompanyContract());
    requestFetchCompanyContracts(getState().user, company)
      .then((response) => {
        dispatch(setCompanyContracts(response, company));
        dispatch(refreshingCompanyContract(false));
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

export function updateContractAlert(alertMessage) {
  return {
    type: COMPANY_CONTRACT_UPDATE_ALERT,
    contractUpdateAlert: alertMessage,
  };
}

export function resetContractAlert() {
  return (dispatch) => dispatch(updateContractAlert(""));
}

// catch added in company.jsx
export function updateContractInformation(company, contract) {
  return (dispatch, getState) =>
    requestUpdateContractInfo(contract, getState().user).then(() => {
      dispatch(fetchCompanyContracts(company));
      dispatch(fetchUserContractsTrials());
    });
}

// catch added in ContractUserModal.jsx
export function updateContractUsers(contractUserList, contractId, company) {
  return (dispatch, getState) =>
    requestUpdateContractUsers(
      contractUserList,
      contractId,
      getState().user
    ).then(() => {
      dispatch(fetchCompanyContracts(company));
      dispatch(fetchUserContractsTrials());
    });
}

export function updateContractCurrentUser(contractId, company) {
  return (dispatch, getState) => {
    requestUpdateContractUser(contractId, getState().user)
      .then(() => {
        dispatch(fetchCompanyContracts(company));
        dispatch(fetchUserContractsTrials());
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

// catch added in company.jsx
export function createContract(company, contract) {
  return (dispatch, getState) =>
    requestCreateContractInfo(company, contract, getState().user).then(() => {
      dispatch(fetchCompanyContracts(company));
    });
}

export function deleteContract(company, contract) {
  return (dispatch, getState) => {
    requestDeleteContractInfo(contract, getState().user)
      .then(() => {
        dispatch(fetchCompanyContracts(company));
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}
