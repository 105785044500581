import {
  ANALYTICS_EVENTS,
  IMPORTED_DATA_TYPES,
  RESERVED_PROPERTY_KEYS,
} from "../../../../constants";
import { useCallback, useMemo, useState } from "react";

import { Button } from "reactstrap";
import { PropTypes } from "prop-types";
import { UpdatedColumn } from "./UpdatedColumn";
import { updateFeatureProperties } from "../../../../actions/resultMap";
import { useDispatch } from "react-redux";
import { useTracking } from "../../../../analytics";

export const VerifyYourOwnData = ({ data, headers, setUploadIsOpen }) => {
  const dispatch = useDispatch();

  const { trackEvent } = useTracking();

  const validHeadersData = useMemo(
    () =>
      headers.reduce((tempValidHeadersData, header) => {
        let count = 0;
        let isCastableToNumber = true;
        if (RESERVED_PROPERTY_KEYS.includes(header)) isCastableToNumber = false;
        else
          data.forEach((row) => {
            const value = row[header];
            if (isNaN(value)) isCastableToNumber = false;
            if (value != null && value !== "") count++;
          });

        if (count > 0)
          tempValidHeadersData[header] = { count, isCastableToNumber };

        return tempValidHeadersData;
      }, {}),
    [data, headers]
  );

  const validHeaderEntries = Object.entries(validHeadersData);

  const [headerTypes, setHeaderTypes] = useState(
    validHeaderEntries.reduce(
      (tempHeaderTypes, [header, { isCastableToNumber }]) => {
        tempHeaderTypes[header] = isCastableToNumber
          ? IMPORTED_DATA_TYPES.NUMERIC
          : IMPORTED_DATA_TYPES.ATTRIBUTE;
        return tempHeaderTypes;
      },
      {}
    )
  );

  const handleSetType = (header) => (selectedOption) =>
    setHeaderTypes((prevSelectedTypes) => ({
      ...prevSelectedTypes,
      [header]: selectedOption,
    }));

  const handleValidate = useCallback(() => {
    const uploadedData = data.map((row) => {
      let newRow = {};

      headers.forEach((header) => {
        if (row[header] != null && row[header] !== "")
          newRow[header] =
            headerTypes[header] === IMPORTED_DATA_TYPES.NUMERIC &&
            header !== "cloverfieldId" &&
            header !== "id"
              ? Number(row[header])
              : String(row[header]);
      });

      return newRow;
    });

    dispatch(updateFeatureProperties(uploadedData));
    setUploadIsOpen(false);
    trackEvent(ANALYTICS_EVENTS.UPLOAD_YOUR_OWN_DATA);
  }, [data, dispatch, headerTypes, headers, setUploadIsOpen, trackEvent]);

  return (
    <>
      <div className="values-container d-flex flex-column gap-1">
        {validHeaderEntries
          .filter(([header]) => header !== "cloverfieldId" && header !== "id")
          .map(([header, { count, isCastableToNumber }]) => (
            <UpdatedColumn
              key={header}
              header={header}
              count={count}
              isCastableToNumber={isCastableToNumber}
              setType={handleSetType(header)}
              headerType={headerTypes[header]}
            />
          ))}
      </div>
      <div className="text-end">
        <Button className="hiphen-green-button" onClick={handleValidate}>
          <i className="fa-solid fa-floppy-disk me-2" />
          Save
        </Button>
      </div>
    </>
  );
};

VerifyYourOwnData.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  setUploadIsOpen: PropTypes.func.isRequired,
};
