import { Button, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  createCrop,
  deleteCrop,
  fetchCrops,
  updateCrop,
} from "../../actions/crops";

import AdminFilterHeader from "../../components/AdminFilterHeader/AdminFilterHeader";
import CropsModal from "./CropsModal";
import LoadingImg from "../../components/loading";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Crops = (props) => {
  const [openModalCrop, setOpenModalCrop] = useState(false);
  const [cropToUpdate, setCropToUpdate] = useState(null);
  const [search, setSearch] = useState("");

  const handleFilterChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredCrops =
    search === ""
      ? props.crops
      : props.crops.filter((crop) => {
          return crop.name.toLowerCase().includes(search.toLowerCase());
        });
  useEffect(() => {
    props.fetchCrops();
  }, []);

  const openCropModalForUpdate = (event, crop) => {
    event.preventDefault();
    setCropToUpdate(crop);
    setOpenModalCrop(true);
  };

  const deleteSelectedCrop = (crop) => {
    if (window.confirm(`Do you want to delete ${crop.name} ?`))
      props.deleteCrop(crop);
  };
  return (
    <div className="mt-3 ps-3">
      <CropsModal
        setCropToUpdate={setCropToUpdate}
        cropToUpdate={cropToUpdate}
        createCrop={props.createCrop}
        updateCrop={props.updateCrop}
        setOpenModalCrop={setOpenModalCrop}
        isOpen={openModalCrop}
      />

      <AdminFilterHeader
        onButtonClick={() => setOpenModalCrop(true)}
        onFilterChange={handleFilterChange}
        onRefresh={props.fetchCrops}
        refreshing={props.refreshing}
        buttonName="New crop"
        search={search}
      />
      <div className="row mt-2">
        <div className="col-12">
          {!props.refreshing && (
            <Table striped hover>
              <thead>
                <tr>
                  <th className="w-75">
                    <span>Name</span>
                  </th>
                  <th className="w-25">
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredCrops
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map((crop) => (
                    <tr key={crop.id}>
                      <td>{crop.name}</td>
                      <td>
                        <Button
                          data-tooltip-id="tooltip"
                          data-tooltip-content="Edit crop information"
                          className="ms-2 p-0"
                          color="link"
                          onClick={(event) =>
                            openCropModalForUpdate(event, crop)
                          }
                        >
                          <i className="fa fa-lg fa-pencil" />
                        </Button>
                        <Button
                          data-tooltip-id="tooltip"
                          data-tooltip-content="Delete crop"
                          data-tooltip-variant="error"
                          className="ms-2 p-0"
                          color="link"
                          onClick={() => deleteSelectedCrop(crop)}
                        >
                          <i className="fa fa-lg fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          <LoadingImg visible={props.refreshing} />
        </div>
      </div>
    </div>
  );
};

Crops.propTypes = {
  crops: PropTypes.array.isRequired,
  refreshing: PropTypes.bool.isRequired,
  fetchCrops: PropTypes.func.isRequired,
  createCrop: PropTypes.func.isRequired,
  updateCrop: PropTypes.func.isRequired,
  deleteCrop: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  return {
    crops: state.crops.all,
    refreshing: state.crops.table.refreshing,
  };
}

const callbacks = {
  fetchCrops,
  createCrop,
  updateCrop,
  deleteCrop,
};

export default connect(mapStateToProps, callbacks)(Crops);
