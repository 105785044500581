import _ from "lodash";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

import classnames from "classnames";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { Dashboard } from "@uppy/react";
import { useInterval } from "react-use";
import { FormContext } from "./FormTemplate";
import { useSelector } from "react-redux";
import { jsonContentType } from "../../services/backendRequests";

const tabStyle = { cursor: "pointer" };
export let uploadInstances = new Map();

export const resetUploadInstances = () => {
  uploadInstances = new Map();
};

const DatasetsTab = ({ number, isActive, toggle }) => {
  const context = useContext(FormContext);
  const uppy = uploadInstances.get(number);
  const [progress, setProgress] = useState();
  useInterval(() => {
    setProgress(uppy?.state.totalProgress);
  }, 500);

  return (
    <NavItem>
      <NavLink
        style={tabStyle}
        className={classnames({
          active: isActive,
          collapseToggleTitle: !isActive,
        })}
        onClick={() => toggle(number)}
      >
        Dataset {context.datasetCount + number + 1}{" "}
        {progress ? <span>({progress}%)</span> : null}
      </NavLink>
    </NavItem>
  );
};

const DatasetsUpload = ({ id, options }) => {
  const context = useContext(FormContext);
  const statusFinish = useRef(context.statusFinish);
  statusFinish.current = context.statusFinish;

  const sessionUploaderId = `${context.session.id}/${context.datasetCount}/${id}`;
  const user = useSelector(state => state.user);

  const datasetId = context.datasetCount + id + 1;

  if (!uploadInstances.has(sessionUploaderId)) {
    const uppy = Uppy({
      ...(options ?? {}),
      autoProceed: false,
      allowMultipleUploads: false,
      onBeforeUpload: () => true,
      onBeforeFileAdded: (currentFile, files) => {
        const modifiedFile = {
          ...currentFile,
          meta: {
            ...currentFile.meta,
            relativePath: context.session.id + "___" + currentFile.name,
          },
        };
        return modifiedFile;
      },
      meta: {
        session_id: context.session.id,
        category: `dataset_${datasetId}`,
        user_id: user.self.id,
      },
    });

    uppy.use(Tus, {
      resume: true,
      endpoint: process.env.REACT_APP_TUS_URL,
      chunkSize: 5 * 1024 * 1024,
      headers: user.identity,
      limit: 10,
    });

    uppy.on("complete", () => {
      const allCompleted = Array.from(uploadInstances.values()).every(
        v =>
          v.state.totalProgress === 100 ||
          (v.state.totalProgress === 0 &&
            Object.keys(v.state.files).length === 0)
      );
      if (allCompleted)
        fetch(`/api/client/upload_sessions/${context.session.id}`, {
          method: "PUT",
          body: JSON.stringify({ status: statusFinish.current }),
          headers: jsonContentType(user.identity),
        });
    });

    uppy.on("back-online", () => {
      uppy.retryAll();
    });

    uploadInstances.set(sessionUploaderId, uppy);
  }

  const uppyInstance = uploadInstances.get(sessionUploaderId);
  return (
    <Dashboard
      uppy={uppyInstance}
      hideUploadButton={true}
      proudlyDisplayPoweredByUppy={false}
      width="100%"
      height="45vh"
      showLinkToFileUploadResult={false}
    />
  );
};

const datasetsCount = new Map();
export default function DatasetUploader({ options }) {
  const context = useContext(FormContext);
  let datasetCount = datasetsCount.get(context.session.id) ?? 1;
  const [datasets, setDatasets] = useState(datasetCount);
  const [datasetArray, setDatasetsArray] = useState([0]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => setDatasetsArray([..._.range(datasets)]), [datasets]);
  useEffect(() => {
    setDatasets(1);
    setActiveTab(0);
  }, [context.datasetCount]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const disabled = context.loading;
  return (
    <div>
      <Nav tabs>
        {datasetArray.map(num => (
          <DatasetsTab
            isActive={activeTab === num}
            number={num}
            toggle={toggle}
            key={num}
          />
        ))}

        <NavItem>
          <NavLink
            disabled={disabled}
            style={tabStyle}
            onClick={() => {
              setDatasets(datasets + 1);
              datasetsCount.set(context.session.id, datasets);
              setActiveTab(datasets);
            }}
          >
            <i
              className={`fa fa-lg fa-plus text-${
                disabled ? "secondary" : "success"
              }`}
            />
          </NavLink>
        </NavItem>

        <NavItem>
          {datasets !== 1 && (
            <NavLink
              disabled={disabled}
              style={tabStyle}
              onClick={() => {
                const activeTabIsLastTab = datasets - 1 === activeTab;
                if (activeTabIsLastTab) setActiveTab(activeTab - 1);
                setDatasets(datasets - 1);
              }}
            >
              <i
                className={`fa fa-lg fa-minus text-${
                  disabled ? "secondary" : "danger"
                }`}
              />
            </NavLink>
          )}
        </NavItem>
      </Nav>

      <DatasetsUpload id={activeTab} options={options} />
    </div>
  );
}
