/* eslint-disable default-param-last */
import {
  RESET_SITES,
  RESET_SITE_TRIAL,
  SET_REFRESHING_SITE_TRIAL,
  SET_SITES,
  SET_SITE_TRIAL,
  SITE_DISPATCH_DELETION,
  SITE_UPDATE_ALERT,
} from "../actions/actionTypes";

import _ from "lodash";
import dataTableBehavior from "./dataTableBehavior";
import uuid from "uuid/v4";

const sitesDataTable = dataTableBehavior("sites");

const initialState = {
  all: [],
  alert: "",
  table: sitesDataTable(undefined, {}), // eslint-disable-line no-undefined
  siteTrial: null,
  refreshingSiteTrial: false,
  uploadSessions: [],
};

// eslint-disable-next-line max-lines-per-function
export default function sites(state = initialState, action) {
  switch (action.type) {
    case SET_SITES:
      // Add fake id to mission object
      const newSites = _.map(action.sites, site => {
        site.missions = _.map(site.missions, mission =>
          _.assign(mission, { id: uuid() })
        );
        return site;
      });
      return {
        ...state,
        all: newSites,
        alert: "",
      };

    case SET_SITE_TRIAL:
      return {
        ...state,
        siteTrial: action.siteTrial,
        refreshingSiteTrial: false,
      };

    case SET_REFRESHING_SITE_TRIAL:
      return {
        ...state,
        refreshingSiteTrial: !state.refreshingSiteTrial,
      };

    case RESET_SITES:
      return initialState;

    case RESET_SITE_TRIAL:
      return {
        ...state,
        siteTrial: null,
      };

    case SITE_UPDATE_ALERT:
      return {
        ...state,
        alert: action.updateAlert,
      };

    case SITE_DISPATCH_DELETION:
      return {
        ...state,
        all: _.filter(state.all, item => item !== action.siteDeleted),
      };

    default:
      return {
        ...state,
        table: sitesDataTable(state.table, action),
      };
  }
}
