import "./Toolbox.css";

import { useDispatch, useSelector } from "react-redux";
import { useMemo, useRef, useState } from "react";

import { ComplexSearch } from "../../powerdash/components/complexSearch/ComplexSearch";
import { DraggableMarker } from "./Tools/DraggableMarker";
import { HIPHEN_GREEN } from "../../constants";
import { LinearRuler } from "./Tools/LinearRuler";
import PropTypes from "prop-types";
import { selectFilteredFeatures } from "../../selectors/resultMap";
import { setSelectedPlotId } from "../../actions/resultMap";

const TOOLS = Object.freeze({
  SEARCH: { name: "Search Plots", icon: <i className="fa-solid fa-search" /> },
  MARKER_TOOL: {
    name: "Draggable marker tool",
    icon: <i className="fa-solid fa-map-pin" />,
  },
  LINEAR_RULER: {
    name: "Linear ruler",
    icon: <i className="fa-solid fa-ruler" alt="ruler" />,
  },
});

const Tool = ({ tool, active, onClick }) => {
  return (
    <div
      className={`toolbox-tool clickable ${active ? "active" : ""}`}
      data-tooltip-id="tooltip"
      data-tooltip-place="bottom"
      data-tooltip-content={tool.name}
      onClick={onClick}
    >
      {tool.icon}
    </div>
  );
};

export const Toolbox = ({ mapRef }) => {
  const dispatch = useDispatch();
  const features = useSelector(selectFilteredFeatures);

  // Default set to ExperimentTools if trial is not completed
  const [selectedTool, setSelectedTool] = useState(null);

  const content = useMemo(
    () => ({
      plots: {
        handleClick: (value) => {
          dispatch(setSelectedPlotId(value));
        },
        options: features.map((feature) => ({
          label: feature.displayId,
          value: feature.id,
          renderBullet: (
            <i className="fa fa-square" style={{ color: HIPHEN_GREEN }} />
          ),
        })),
      },
    }),
    [features, dispatch]
  );
  return (
    <>
      <div className="d-flex flex-column toolbox align-items-end">
        {selectedTool && (
          <div className="powerdash-component toolbox-dropdown d-flex flex-row align-items-center gap-2 p-1 ps-2 pe-2">
            {selectedTool.icon}
            {selectedTool === TOOLS.SEARCH && (
              <ComplexSearch
                inverted
                placeholder="Search Plots"
                content={content}
              />
            )}
            {selectedTool === TOOLS.MARKER_TOOL && (
              <DraggableMarker map={mapRef.current} />
            )}
            {selectedTool === TOOLS.LINEAR_RULER && (
              <LinearRuler map={mapRef.current} />
            )}
            <i
              className="fa fa-times clickable"
              onClick={() => setSelectedTool(null)}
            />
          </div>
        )}
        <div
          className={`powerdash-component mb-0 toolbox-tools d-flex flex-row align-items-center gap-2 p-1 ${
            selectedTool ? "active" : ""
          }`}
        >
          {Object.values(TOOLS).map((tool) => (
            <Tool
              key={tool.name}
              tool={tool}
              active={tool === selectedTool}
              onClick={() => {
                setSelectedTool((currentTool) =>
                  currentTool === tool ? null : tool
                );
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

Tool.propTypes = {
  tool: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }).isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

Toolbox.propTypes = {
  mapRef: PropTypes.object.isRequired,
};
