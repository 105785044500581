import { Button, Table } from "reactstrap";
import React, { Component } from "react";
import {
  createCompany,
  fetchCompanies,
  resetCompanyAlert,
  updateCompanyAlert,
  updateCompanyInformation,
} from "../../actions/companies";

import AdminFilterHeader from "../../components/AdminFilterHeader/AdminFilterHeader";
import CompanyModal from "./CompanyModal";
import CopyButton from "../../components/copyButton";
import LoadingImg from "../../components/loading";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import _ from "lodash";
import { connect } from "react-redux";
import { resetCompany } from "../../actions/company";

class Companies extends Component {
  constructor(props) {
    super(props);
    this.props.resetCompany();
    this.state = {
      updatedCompany: {},
      isCompanyOpenedModal: false,
      search: "",
    };
    this.openCompanyModalForCreation =
      this.openCompanyModalForCreation.bind(this);
  }

  handleFilterChange = (event) => {
    this.setState({ search: event.target.value });
  };

  resetState() {
    this.setState({
      updatedCompany: {},
      isCompanyOpenedModal: false,
    });
  }

  componentDidMount() {
    this.props.handleRefresh();
  }

  /** COMPANY MODAL */

  openCompanyModalForCreation(event) {
    this.setState({
      updatedCompany: {
        name: "",
      },
      isCompanyOpenedModal: true,
    });
  }

  openCompanyModalForUpdate(event, company) {
    event.stopPropagation();
    this.setState({
      updatedCompany: company,
      isCompanyOpenedModal: true,
    });
  }

  submitCompanyModal(company) {
    if (this.checkCompanyUnicity(company)) {
      if (company.id) this.props.onUpdateCompany(company);
      else this.props.onCreateCompany(company);

      this.resetState();
    }
  }

  checkCompanyUnicity(company) {
    const companiesFiltered = _.filter(
      this.props.companies,
      (item) => item.id !== company.id
    );
    if (_.filter(companiesFiltered, { name: company.name }).length > 0) {
      this.props.updateCompanyAlert("Company name must be unique.");
      return false;
    }
    return true;
  }

  cancelCompanyModal() {
    this.props.resetCompanyAlert();
    this.resetState();
  }

  handleCompanyRowClick(company) {
    this.props.history.push(`/administration/company/${company.id}`);
  }

  render() {
    const search = this.state.search.toLowerCase();
    const filteredCompanies =
      search === ""
        ? this.props.companies
        : this.props.companies.filter((company) => {
            return company.name.toLowerCase().includes(search);
          });
    return (
      <div className="mt-3 ps-3">
        <CompanyModal
          alert={this.props.companyUpdateAlert}
          isOpen={
            this.state.isCompanyOpenedModal ||
            this.props.companyUpdateAlert !== ""
          }
          onClose={() => {
            this.cancelCompanyModal();
          }}
          onSubmit={(company) => this.submitCompanyModal(company)}
          company={this.state.updatedCompany}
        />
        <AdminFilterHeader
          onButtonClick={this.openCompanyModalForCreation}
          onFilterChange={this.handleFilterChange}
          onRefresh={this.props.handleRefresh}
          refreshing={this.props.refreshing}
          buttonName="New company"
          search={this.state.search}
        />

        <div className="row mt-2">
          <div className="col-12">
            <Table striped hover>
              <thead>
                <tr>
                  <th className="w-75">
                    <span>Company</span>
                  </th>
                  <th className="w-25">
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!this.props.refreshing &&
                  filteredCompanies.map((company) => (
                    <tr
                      key={company.id}
                      onClick={() => this.handleCompanyRowClick(company)}
                      className={"clickable-row"}
                    >
                      <td>{company.name}</td>
                      <td>
                        <CopyButton
                          value={company.id}
                          datatip="Copy company UUID"
                        />
                        <Button
                          data-tooltip-id="tooltip"
                          data-tooltip-content="Edit company information"
                          className="ms-2 p-0"
                          color="link"
                          onClick={(event) =>
                            this.openCompanyModalForUpdate(event, company)
                          }
                        >
                          <i className="fa fa-lg fa-pencil" />
                        </Button>
                        <Button
                          data-tooltip-id="tooltip"
                          data-tooltip-content="View upload sessions"
                          className="ms-2 p-0"
                          color="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.history.push(
                              "/uploadsessions?company=" + company.id
                            );
                            ReactTooltip.hide();
                          }}
                        >
                          <i className="fa fa-lg fa-upload" />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <LoadingImg visible={this.props.refreshing} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const companies = state.companies.all;
  const sortedCompanies = _.orderBy(
    companies,
    [(company) => company.name.toLowerCase()],
    ["asc"]
  );
  return {
    refreshing: state.companies.table.refreshing,
    companyUpdateAlert: state.companies.updateAlert,
    companies: sortedCompanies,
  };
}

const callbacks = {
  handleRefresh: fetchCompanies,
  resetCompany,
  updateCompanyAlert,
  onUpdateCompany: updateCompanyInformation,
  onCreateCompany: createCompany,
  resetCompanyAlert,
};

export default connect(mapStateToProps, callbacks)(Companies);

Companies.propTypes = {
  companies: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  refreshing: PropTypes.bool.isRequired,
  resetCompany: PropTypes.func.isRequired,
  companyUpdateAlert: PropTypes.string.isRequired,
  resetCompanyAlert: PropTypes.func.isRequired,
  onUpdateCompany: PropTypes.func.isRequired,
  onCreateCompany: PropTypes.func.isRequired,
  updateCompanyAlert: PropTypes.func.isRequired,
};
