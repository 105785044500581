import { CHANGE_PASSWORD_RESET } from "../actions/actionTypes";

const initialState = {
  password: "",
  passwordConfirmation: "",
  alerts: {}
};

export default function passwordReset(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_RESET: {
      return {
        ...state,
        ...action.updates
      };
    }
    default:
      return state;
  }
}
