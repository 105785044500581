import "./RasterInformation.css";

import { useMap, useMapEvent } from "react-leaflet";
import { useRef, useState } from "react";

import { Marker } from "@adamscybot/react-leaflet-component-marker";
import PropTypes from "prop-types";
import { getContrastYIQ } from "../../powerdash/utils";
import { selectOrtho } from "../../selectors/resultMap";
import { truncateNumber } from "../../services/utils";
import { useClickAway } from "react-use";
import { useSelector } from "react-redux";
import { useWMSColorScale } from "../../hooks/useWMSColorScale";

const RasterInformationIcon = ({ measure, resetMeasure }) => {
  const colorScale = useWMSColorScale();
  const color = colorScale ? colorScale.func(measure.value).hex() : "#fff";
  const contrast = getContrastYIQ(color);

  const markerRef = useRef();
  useClickAway(markerRef, resetMeasure);

  return (
    <div className="raster-information-icon" ref={markerRef}>
      <div
        className="raster-information-icon-over p-1 font-weight-bold"
        style={{ backgroundColor: color, color: contrast }}
      >
        {truncateNumber(measure.value)}
      </div>
      <i
        className="fa-solid fa-lg fa-location-crosshairs"
        style={{ color: contrast }}
      />
    </div>
  );
};

RasterInformationIcon.propTypes = {
  measure: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
};

export const RasterInformation = () => {
  const map = useMap();
  const [measure, setMeasure] = useState(null);
  const ortho = useSelector(selectOrtho);

  useMapEvent("click", async (e) => {
    const bounds = map.getBounds();
    const mapSize = map.getSize();
    const crs = map.options.crs;

    // Convert lat/lng to image coordinates (x, y) in pixels
    const sw = crs.project(bounds.getSouthWest());
    const ne = crs.project(bounds.getNorthEast());

    const url = `${
      process.env.REACT_APP_GEOSERVER_URL
    }?service=WMS&version=1.1.1&request=GetFeatureInfo&layers=${ortho}&query_layers=${ortho}&styles=&bbox=${
      sw.x
    },${sw.y},${ne.x},${ne.y}&width=${mapSize.x}&height=${
      mapSize.y
    }&info_format=application/json&x=${Math.trunc(
      e.containerPoint.x
    )}&y=${Math.trunc(e.containerPoint.y)}&feature_count=1&crs=${crs.code}`;

    try {
      const response = await fetch(url);
      const { features } = await response.json();
      if (features[0])
        setMeasure({
          latlng: e.latlng,
          value: features[0].properties.GRAY_INDEX,
        });
    } catch {}
  });

  return (
    measure?.value !== undefined && (
      <Marker
        key={measure.latlng}
        position={measure.latlng}
        icon={
          <RasterInformationIcon
            measure={measure}
            resetMeasure={() => setMeasure(null)}
          />
        }
      />
    )
  );
};
