import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import Select from "react-select";

export default function NewUploadForm({
  onCreate,
  onEdit,
  contract,
  contracts,
  setContract,
  sites,
  site,
  sessions,
  setSite,
  acquisitionDate,
  setAcquisitionDate,
}) {
  const sessionsExists = sessions.find(
    (s) => s.acquisition_date === acquisitionDate
  );

  return (
    <Form>
      <FormGroup>
        <Label>Campaign</Label>
        <Select
          value={contracts.find((c) => c.value === contract)}
          options={contracts}
          onChange={(e) => setContract(e?.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Site</Label>
        <Select
          value={sites.find((s) => s.value === site)}
          options={sites}
          isDisabled={sites.length === 0}
          onChange={(e) => setSite(e?.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Mission Date</Label>
        <i
          className="fa fa-lg fa-info-circle icon-properties mt-0 ms-2"
          data-tooltip-id="tooltip"
          data-tooltip-content="Please select the date at which you acquired images."
          data-tooltip-place="right"
        />
        <Input
          type="date"
          value={acquisitionDate}
          onChange={(e) => setAcquisitionDate(e.target.value)}
        />
      </FormGroup>
      <Button
        type="submit"
        className="float-end"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          sessionsExists ? onEdit(sessionsExists) : onCreate();
        }}
      >
        {sessionsExists ? "Edit" : "Create"}
      </Button>
    </Form>
  );
}
