import "./mainListFilters.css";

import { Badge, Button, Input } from "reactstrap";

import PropTypes from "prop-types";
import { humanize } from "../../../utils";
import { useMemo } from "react";

/*
 * Section with filters applied to main list
 */
export const MainListFilters = ({
  filterSiteName,
  setFilterSiteName,
  softSelectedStatus,
  setSoftSelectedStatus,
  softSelectedSubStatus,
  setSoftSelectedSubStatus,
  setSelectedStatus,
  getStatusColor,
  exportFilteredMissionMonitoringCsv,
  exportIsLoading,
  exportDisabled,
  softGroupingObject,
  setSoftGroupingObject,
  generateCombinationString,
}) => {
  const combinationString = useMemo(
    () =>
      softGroupingObject ? generateCombinationString(softGroupingObject) : "",
    [generateCombinationString, softGroupingObject]
  );

  return (
    <div className="main-list-filters">
      <div className="filters">
        <Input
          className="site-name-input"
          placeholder="Filter by site"
          value={filterSiteName}
          onChange={(e) => setFilterSiteName(e.target.value)}
        />
        <div className="filter-badges">
          {softGroupingObject && (
            <Badge
              color="secondary"
              className="group-filter clickable"
              title="Remove filter"
              onClick={() => setSoftGroupingObject(null)}
            >
              {combinationString}
              <i className="fa fa-lg fa-close" />
            </Badge>
          )}
          {softSelectedStatus && (
            <Badge
              // if color is not valid "primary secondary...", the background color is overriden by the background color of the style
              color="invalid background color"
              pill
              style={{ background: getStatusColor(softSelectedStatus) }}
              className="group-filter clickable"
              title="Remove filter"
              onClick={() => {
                setSoftSelectedStatus(null);
                setSelectedStatus(undefined);
              }}
            >
              {humanize(softSelectedStatus)}
              <i className="fa fa-lg fa-close" />
            </Badge>
          )}
          {softSelectedSubStatus && (
            <Badge
              pill
              style={{ background: getStatusColor(softSelectedSubStatus) }}
              className="group-filter clickable"
              title="Remove filter"
              onClick={() => setSoftSelectedSubStatus(null)}
            >
              {humanize(softSelectedSubStatus)}
              <i className="fa fa-lg fa-close" />
            </Badge>
          )}
        </div>
        <div>
          <Button
            onClick={exportFilteredMissionMonitoringCsv}
            data-tooltip-id="tooltip"
            data-tooltip-content="Download CSV"
            data-tooltip-variant="dark"
            style={{ background: "gray" }}
            disabled={exportIsLoading || exportDisabled}
          >
            <i
              className={`fa fa-lg ${
                exportIsLoading ? "fa-cog fa-spin" : "fa-download"
              }`}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

MainListFilters.propTypes = {
  filterSiteName: PropTypes.string.isRequired,
  setFilterSiteName: PropTypes.func.isRequired,
  setSoftSelectedStatus: PropTypes.func.isRequired,
  setSoftSelectedSubStatus: PropTypes.func.isRequired,
  setSelectedStatus: PropTypes.func.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  exportFilteredMissionMonitoringCsv: PropTypes.func.isRequired,
  exportIsLoading: PropTypes.bool.isRequired,
  setSoftGroupingObject: PropTypes.func.isRequired,
  generateCombinationString: PropTypes.func.isRequired,
  exportDisabled: PropTypes.bool,
  softSelectedStatus: PropTypes.string,
  softSelectedSubStatus: PropTypes.string,
  filterGroupValue: PropTypes.object,
  softGroupingObject: PropTypes.object,
};
