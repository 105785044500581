import {
  SET_CONSUMPTION,
  SET_MONTH_FOR_CONSUMPTION
} from "../actions/actionTypes";

const initialState = {
  selectedMonth: new Date(),
  group_headers: [],
  chain_rows: [],
  total_row: []
};

export default function consumption(state = initialState, action) {
  switch (action.type) {
    case SET_CONSUMPTION:
      return {
        ...state,
        ...action.consumption
      };
    case SET_MONTH_FOR_CONSUMPTION:
      return {
        ...state,
        selectedMonth: action.month
      };
    default:
      return state;
  }
}
