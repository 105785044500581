import {
  selectMergedTraits,
  selectNumericalPropertiesMetricsAndAggregatedFeatures,
} from "../../../../selectors/resultMap";

import { COMMON_PLOT_CONFIG } from "../../../constants";
import { HIPHEN_GREEN } from "../../../../constants";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { truncateNumber } from "../../../../services/utils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const PLOT_STYLE = { width: "100%", height: "100%" };

const layout = {
  autosize: true,
  margin: { l: 50, r: 50, t: 30, b: 30 },
  plot_bgcolor: "transparent",
  paper_bgcolor: "transparent",
  legend: {
    orientation: "h",
    yanchor: "top",
    xanchor: "center",
    x: "0.5",
  },
  colorway: [HIPHEN_GREEN, "darkgray"],
  polar: {
    bgcolor: "transparent",
    angularaxis: {
      fixedrange: true,
      linewidth: 0.5,
    },
    radialaxis: {
      linewidth: 0,
      ticks: "",
      showticklabels: false,
      fixedrange: true,
      range: [0, 1],
    },
  },
};

export const RadarChart = ({ data, exportName }) => {
  const [, traitsMetrics] = useSelector(
    selectNumericalPropertiesMetricsAndAggregatedFeatures
  );
  const traits = useSelector(selectMergedTraits);

  const preparedData = useMemo(() => {
    const mappedProperties = [];
    const mappedPropertiesMeans = [];
    const theta = [];
    const text = [];
    const meansText = [];

    traits.forEach(({ technical_name, name }) => {
      if (
        data.properties[technical_name] != null &&
        traitsMetrics[technical_name]
      ) {
        mappedProperties.push(
          truncateNumber(
            data.properties[technical_name] / traitsMetrics[technical_name].max
          )
        );
        mappedPropertiesMeans.push(
          truncateNumber(
            traitsMetrics[technical_name].mean /
              traitsMetrics[technical_name].max
          )
        );
        theta.push(name);
        text.push(
          `${name}: ${truncateNumber(
            data.properties[technical_name]
          )} (max. ${truncateNumber(traitsMetrics[technical_name]?.max)})`
        );
        meansText.push(
          `Average ${name}: ${truncateNumber(
            traitsMetrics[technical_name].mean
          )}`
        );
      }
    });

    // To complete line on chart
    mappedProperties.push(mappedProperties[0]);
    mappedPropertiesMeans.push(mappedPropertiesMeans[0]);
    theta.push(theta[0]);
    text.push(text[0]);
    meansText.push(meansText[0]);

    return [
      // Plot
      {
        name: "Plot",
        type: "scatterpolargl",
        r: mappedProperties,
        mode: "lines",
        theta: theta,
        fill: "toself",
        hovertemplate: "%{text}<extra></extra>",
        text: text,
        line: {
          width: 1.7,
        },
      },
      //Mean
      {
        name: "Date average",
        type: "scatterpolargl",
        r: mappedPropertiesMeans,
        mode: "lines",
        theta: theta,
        fill: "none",
        text: meansText,
        hovertemplate: "%{text}<extra></extra>",
        line: {
          dash: "dash",
          width: 1.7,
        },
      },
    ];
  }, [data.properties, traits, traitsMetrics]);

  const config = useMemo(
    () => ({
      toImageButtonOptions: {
        filename: exportName,
      },
      ...COMMON_PLOT_CONFIG,
    }),
    [exportName]
  );

  return (
    <Plot
      useResizeHandler
      data={preparedData}
      layout={layout}
      config={config}
      style={PLOT_STYLE}
    />
  );
};

RadarChart.propTypes = {
  data: PropTypes.object.isRequired,
  exportName: PropTypes.string.isRequired,
};
