import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useMemo, useState } from "react";

import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import { ROLES } from "../../constants";
import { UsersRolesForm } from "./UsersRolesForm";

export const ContractUsersModal = ({
  contractUsers,
  isOpen,
  onUpdate,
  onClose,
}) => {
  const [alert, setAlert] = useState("");
  const [contractUserList, setContractUserList] = useState(
    /**
     * @returns {Array.<{ user: { id: number; fullname: string }, roles: Array.<{label: string, value: string}> }>}
     */
    () => [
      { user: null, roles: [] },
      ...contractUsers.map(({ id, fullname, roles, company }) => ({
        user: { id, fullname, company },
        roles: roles.map((userRole) =>
          ROLES.find((role) => role.value === userRole)
        ),
      })),
    ]
  );

  // Filter empty select user for user count and to send to API
  const validUsers = useMemo(
    () => contractUserList.filter(({ user }) => !!user),
    [contractUserList]
  );

  const onUpdateClick = async () => {
    // API endpoint expects Array<{ id: number; roles: string[] }>
    const formattedUsers = validUsers.map(({ user, roles }) => ({
      id: user.id,
      roles: roles.map(({ value }) => value),
    }));

    try {
      await onUpdate(formattedUsers);
    } catch (error) {
      setAlert(error);
    }
  };

  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalHeader cssModule={{ "modal-title": "w-100" }}>
        Update Campaign Users
        <div className="float-end d-flex align-items-center">
          <span className="pt-1">{validUsers.length}</span>
          <FontAwesome name="user" size="lg" className="ms-1" />
        </div>
      </ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={!!alert} toggle={() => setAlert("")}>
          {alert}
        </Alert>
        <UsersRolesForm
          contractUserList={contractUserList}
          setContractUserList={setContractUserList}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="btn action-btn"
          onClick={onUpdateClick}
        >
          Update
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ContractUsersModal.propTypes = {
  contractUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullname: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
