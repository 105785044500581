import { useRef, useState } from "react";

import PropTypes from "prop-types";
import { setColorScaleStep } from "../../actions/resultMap";
import { truncateNumber } from "../../services/utils";
import { useDispatch } from "react-redux";

const DRAG_SENSITIVITY = 0.1;

export const WorldMapLegendGrad = ({ colorScale, dynamic }) => {
  const [mouseDown, setMouseDown] = useState(false); // Track mouse down on color scale
  const mouseOffsetX = useRef(0);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`grad mt-1 ${dynamic ? "dynamic" : ""}`}
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => {
          setMouseDown(false);
        }}
        onMouseLeave={() => setMouseDown(false)}
        onDoubleClick={() => {
          if (dynamic) dispatch(setColorScaleStep(5));
        }}
        onMouseMove={(e) => {
          // When cursor moves on color scale, we calculate new step based
          // on previous step value and current mouse movement on X axis
          if (mouseDown) {
            if (e.movementX)
              mouseOffsetX.current = mouseOffsetX.current + e.movementX;
            else mouseOffsetX.current = 0;

            if (Math.abs(mouseOffsetX.current) > DRAG_SENSITIVITY * 100) {
              const newStep = colorScale.step + Math.sign(mouseOffsetX.current);
              if (newStep > 1 && newStep <= 10) {
                dispatch(setColorScaleStep(newStep));
                mouseOffsetX.current = 0;
              }
            }
          }
        }}
      >
        {Array.from({ length: colorScale.step }, (_, index) => (
          <span
            key={index}
            className="grad-step m-0 p-0"
            style={{
              backgroundColor: colorScale.func(
                colorScale.minValue +
                  (index / colorScale.step) *
                    (colorScale.maxValue - colorScale.minValue)
              ),
            }}
          />
        ))}
        {dynamic && <span className="step-size">{colorScale.step}</span>}
      </div>
      <div className="d-flex justify-content-between">
        <span>{truncateNumber(colorScale.minValue)}</span>
        <span>
          {truncateNumber((colorScale.maxValue + colorScale.minValue) / 2)}
        </span>
        <span>{truncateNumber(colorScale.maxValue)}</span>
      </div>
    </>
  );
};

WorldMapLegendGrad.propTypes = {
  colorScale: PropTypes.shape({
    step: PropTypes.number.isRequired,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    func: PropTypes.func.isRequired,
  }),
  dynamic: PropTypes.bool,
};
