import { Col, Row } from "reactstrap";

import PropTypes from "prop-types";
import React from "react";

const PageTitle = ({ title, badge }) => (
  <Row>
    <Col className="pageTitle">
      <span className="h2">{title} </span>
      {badge}
    </Col>
  </Row>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  badge: PropTypes.node,
};

export default PageTitle;
