import "./Chart.css";

import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import { ComponentTitle } from "../../../components/componentTitle/componentTitle";
import { ExpandButton } from "../../../components/expandButton/expandButton";
import PropTypes from "prop-types";
import { useState } from "react";

/*
 * Chart component is a wrapper for plotly charts that handles the loading and error states
 * It adds the component title and expand behaviour
 */
export const Chart = ({
  icon,
  title,
  topRightContent,
  isLoading,
  missingData,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      key={expanded}
      className={`powerdash-component ${expanded ? "expanded" : ""}`}
    >
      <ComponentTitle
        title={missingData || isLoading ? "" : title}
        icon={icon}
        topRightContent={
          <>
            {topRightContent}
            <ExpandButton expanded={expanded} setExpanded={setExpanded} />
          </>
        }
      />
      {isLoading ? (
        <ComponentPlaceholder
          icon="fa-cog fa-spin"
          text="Data for this chart is loading, this may take a while"
        />
      ) : missingData ? (
        <ComponentPlaceholder icon="fa-area-chart" />
      ) : (
        children
      )}
    </div>
  );
};

Chart.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  missingData: PropTypes.bool,
  title: PropTypes.node,
  topRightContent: PropTypes.node,
};
