import { RESET, SET_CROPS } from "../actions/actionTypes";

import dataTableBehavior from "./dataTableBehavior";

const dataTable = dataTableBehavior("crops");

const initialState = {
  all: [],
  table: dataTable(undefined, {}),
};

export default function crops(state = initialState, action) {
  switch (action.type) {
    case SET_CROPS:
      return {
        ...state,
        all: action.crops,
      };
    case RESET:
      return initialState;
    default:
      return {
        ...state,
        table: dataTable(state.table, action),
      };
  }
}
