import {
  SET_TREATMENT_CHAINS,
  UPDATE_TREATMENT_CHAIN_ARCHIVAL
} from "../actions/actionTypes";
import _ from "lodash";
import dataTableBehavior from "./dataTableBehavior";

const dataTable = dataTableBehavior("treatmentChains", {
  sortField: "name"
});

const initialState = {
  all: [],
  table: dataTable(undefined, {}) // eslint-disable-line no-undefined
};

export default function treatmentChains(state = initialState, action) {
  switch (action.type) {
    case SET_TREATMENT_CHAINS:
      return {
        ...state,
        all: action.treatmentChains
      };
    case UPDATE_TREATMENT_CHAIN_ARCHIVAL:
      const { all } = state;
      const updatedChains = all.map(chain => {
        if (chain.id === action.treatmentChainId) {
          return { ...chain, ...{ is_archived: action.isArchived } };
        }
        return chain;
      });
      return {
        ...state,
        all: updatedChains
      };
    default:
      return {
        ...state,
        table: dataTable(state.table, action)
      };
  }
}

export function availableTreatmentChains(someTreatmentChains) {
  return _.reject(someTreatmentChains, "is_archived");
}

export function mapInputVisibility(treatmentChain) {
  return _.mapValues(treatmentChain.input_parameters, "visible");
}

export function isScalarParam(parameter) {
  return ["scalar", "pathPrefixedScalar"].includes(parameter.type);
}

export function isFileParam(parameter) {
  return ["file", "folder"].includes(parameter.type);
}

export function isRequiredFileParam(parameter) {
  return isFileParam(parameter) && parameter.visible;
}

export function requiredFileParams(treatmentChain) {
  return _.pickBy(treatmentChain.input_parameters, isRequiredFileParam);
}

export function mergeAttributesWithDefaultScalars(attributes, treatmentChain) {
  const defaultValues = _.chain(treatmentChain.input_parameters)
    .pickBy(isScalarParam)
    .mapValues("defaultValue")
    .value();

  return {
    ...defaultValues,
    ...attributes
  };
}

export function filterScalars(attributes, treatmentChain) {
  const scalarKeys = _.chain(treatmentChain.input_parameters)
    .pickBy(isScalarParam)
    .keys()
    .value();

  return _.pick(attributes, scalarKeys);
}
