import { memo, useMemo } from "react";

import { ADVANCED_FILTERING_DATASET_COLORS } from "../../../../constants";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import ReactSlider from "react-slider";

const PLOT_CONFIG = Object.freeze({
  responsive: true,
  displayModeBar: false,
});

export const AdvancedChart = memo(
  ({
    trait,
    threshold,
    datasets,
    handleThresholdChange,
    step,
    controlData,
    disabled,
    color,
  }) => {
    const layout = useMemo(() => {
      return {
        bargap: 0.1,
        plot_bgcolor: "transparent",

        autosize: true,
        barmode: "stack",
        margin: { l: 5, r: 5, t: 0, b: 5 },
        paper_bgcolor: "transparent",
        yaxis: {
          fixedrange: true,
          showticklabels: false,
          showgrid: false,
          zeroline: false,
        },
        xaxis: {
          range: trait.range,
          fixedrange: true,
          showticklabels: false,
        },
      };
    }, [trait]);

    // Histogram data is constructed based on given datasets containing arrays for each different color to display
    const mergedData = useMemo(() => {
      const scatterData = {
        x: [],
        y: [],
        type: "scatter",
        mode: "markers",
        showlegend: false,
        text: [],
        hovertemplate: "Control Variety<br><b>%{text}</b><extra></extra>",
        marker: {
          color: [],
          symbol: "triangle-down",
          size: 10,
        },
      };

      controlData.forEach(({ group, properties, color }) => {
        if (properties[trait.technical_name] != null) {
          scatterData.x.push(properties[trait.technical_name].mean);
          scatterData.y.push(0);
          scatterData.text.push(group);
          scatterData.marker.color.push(color);
        }
      });

      const colors = {
        ...ADVANCED_FILTERING_DATASET_COLORS,
        includedData: color,
      };

      /*
       * Create a histogram for each dataset and concats it with scatterData
       */
      return Object.entries(datasets)
        .filter(([_, dataset]) => dataset.length > 0)
        .map(([key, dataset]) => ({
          x: dataset.map(
            ({ properties }) => properties[trait.technical_name]?.mean
          ),
          type: "histogram",
          showlegend: false,
          hoverinfo: "skip",
          autobinx: false,
          autobiny: true,
          nbinsx: 50,
          marker: { color: colors[key] },
        }))
        .concat(scatterData);
    }, [color, controlData, datasets, trait.technical_name]);

    return (
      <>
        <Plot
          data={mergedData}
          layout={layout}
          config={PLOT_CONFIG}
          style={{ height: "100px", opacity: disabled ? 0.4 : 1 }}
        />
        <div className="advanced-slider">
          {!disabled && (
            <ReactSlider
              pearling
              minDistance={step}
              value={threshold}
              step={step}
              min={trait.range[0]}
              max={trait.range[1]}
              onChange={(value) =>
                handleThresholdChange(trait.technical_name, value)
              }
              thumbClassName="slider-thumb advanced-slider-thumb"
              trackClassName="slider-track"
            />
          )}
        </div>
      </>
    );
  }
);

AdvancedChart.propTypes = {
  trait: PropTypes.object.isRequired,
  threshold: PropTypes.array.isRequired,
  handleThresholdChange: PropTypes.func.isRequired,
  datasets: PropTypes.exact({
    includedData: PropTypes.array.isRequired,
    excludedData: PropTypes.array.isRequired,
    dataToInclude: PropTypes.array.isRequired,
    dataToExclude: PropTypes.array.isRequired,
  }),
  step: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string.isRequired,
};
