import "./missionMonitoringDashboard.css";
import "../powerdash.css";

import { Badge, Col, Container, Row } from "reactstrap";
import { LABEL_ALL, LABEL_OVERVIEW } from "../../constants";
import {
  MISSION_MONITORING_STATIC_GROUPS,
  MISSION_MONITORING_STATIC_STATUS,
  MiSSION_MONITORING_COMMENT_COUNT,
  MiSSION_MONITORING_FLAG_COUNT,
  MiSSION_MONITORING_STATUS,
  MiSSION_MONITORING_SUBSTATUS,
} from "../constants";
import {
  contractIsActive,
  formatDate,
  getStatusColor,
  occurrences,
  sumKey,
  uniqueArrayOfObject,
} from "../utils";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ComponentPlaceholder } from "../components/componentPlaceholder/componentPlaceholder";
import { DataCard } from "./components/dataCard/dataCard";
import { DetailGrid } from "./components/detailGrid/detailGrid";
import { ExtraFilters } from "./components/extraFilters/extraFilters";
import { FilterSection } from "./components/filterSection/FilterSection";
import { Legend } from "./components/legend/legend";
import LoadingImg from "../../components/loading";
import { MainList } from "./components/mainList/mainList";
import { MainListFilters } from "./components/mainListFilters/mainListFilters";
import ReactSelect from "react-select";
import { SelectList } from "../components/selectList/selectList";
import download from "downloadjs";
import { fetchMissionsMonitoring } from "../../actions/missionMonitoring";
import moment from "moment";
import { requestFetchMissionMonitoring } from "../../services/backendRequests";
import slugify from "slugify";
import { useSelector } from "react-redux";

export const shouldBeFilteredByGroups = (elem, groupingObject) => {
  return Object.keys(elem.groups).every(
    (group) =>
      !groupingObject[group] ||
      groupingObject[group].includes(elem.groups[group])
  );
};

export const MissionMonitoringDashboard = () => {
  const user = useSelector((state) => state.user);
  // Page is loading waiting for contracts refetch
  const [loading, setLoading] = useState(true);

  // remove inactive contracts (ended or not started)
  const activeContracts = useMemo(
    () => user.contracts.filter((contract) => contractIsActive(contract)),
    [user.contracts]
  );

  // Calculate companies from user contracts
  const companies = uniqueArrayOfObject(
    activeContracts.map((contract) => contract.company),
    "id"
  );

  const [selectedCompany, setSelectedCompany] = useState({
    label: companies[0]?.name,
    value: companies[0]?.id,
  });
  // contracts are refiltered based on the selected company
  const contracts = useMemo(
    () =>
      activeContracts.filter(
        (contract) => contract.company.id === selectedCompany?.value
      ),
    [selectedCompany, activeContracts]
  );

  // Selected contracts are the one ticked by user
  const [selectedContracts, setSelectedContracts] = useState([]);

  // Selected contracts are based on contracts and remapped on label value format
  useEffect(() => {
    setSelectedContracts(
      contracts.map((contract) => {
        return { label: contract.name, value: contract.id };
      })
    );
    clearFilters();
  }, [contracts]);

  // Mission monitorings elements fetched
  const [missionMonitorings, setMissionMonitorings] = useState([]);

  const refresh = useCallback(() => {
    setLoading(true);
    fetchMissionsMonitoring(
      selectedContracts.map((contract) => contract.value),
      user
    )
      .then((res) => {
        setMissionMonitorings(res);
        setLoading(false);
      })
      .catch((error) => console.error("Error: ", error));
  }, [selectedContracts, user]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const statusList = useMemo(
    () => [
      ...new Set([
        ...MISSION_MONITORING_STATIC_STATUS, // Static status sorted
        ...missionMonitorings.map((mm) => mm.status), // Additionnal found status
      ]),
    ],
    [missionMonitorings]
  ); // Defined static status

  // Filters
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [filterUnreadComments, setFilterUnreadComments] = useState(false);
  const [filterActiveFlags, setFilterActiveFlags] = useState(false);
  // Soft filters
  const [filterSiteName, setFilterSiteName] = useState("");
  const [softSelectedStatus, setSoftSelectedStatus] = useState(undefined);
  const [softSelectedSubStatus, setSoftSelectedSubStatus] = useState(undefined);
  const [softGroupingObject, setSoftGroupingObject] = useState(null);
  // Grouping

  // Group keys defined (groups are set in fetchMissionMonitoring: static groups + custom_data keys)
  const groups =
    missionMonitorings.length > 0
      ? Object.keys(missionMonitorings[0].groups)
      : Object.values(MISSION_MONITORING_STATIC_GROUPS);
  const [groupingObject, setGroupingObject] = useState({});

  // Reset grouping object on selectedContracts change
  const [prevSelectedContracts, setPrevSelectedContracts] = useState(null);
  if (selectedContracts !== prevSelectedContracts) {
    setPrevSelectedContracts(selectedContracts);
    setGroupingObject({});
  }

  const groupOptions = useMemo(() => {
    let options = {};
    groups.forEach((group) => {
      options[group] = [
        ...new Set(missionMonitorings.map((elem) => elem.groups[group])),
      ];
    });
    return options;
  }, [groups, missionMonitorings]);

  const addAllGroupValues = (group) => {
    setGroupingObject({
      ...groupingObject,
      [group]: groupOptions[group],
    });
  };

  const removeAllGroupValues = (group) => {
    setGroupingObject((curr) => {
      const copy = { ...curr };
      delete copy[group];
      return copy;
    });
  };

  const addGroupValue = (group, value) => {
    setGroupingObject({
      ...groupingObject,
      [group]: [...new Set([...(groupingObject[group] ?? []), value])],
    });
  };

  const removeGroupValue = (group, value) => {
    const newGroupValue = [
      ...new Set(groupingObject[group].filter((val) => val !== value)),
    ];
    setGroupingObject((curr) => {
      if (newGroupValue.length > 0) {
        return {
          ...groupingObject,
          [group]: newGroupValue,
        };
      } else {
        const copy = { ...curr };
        delete copy[group];
        return copy;
      }
    });
  };

  const clearFilters = () => {
    setSelectedStatus(undefined);
    setSoftSelectedStatus(undefined);
    setFilterUnreadComments(false);
    setFilterActiveFlags(false);
    setGroupingObject({});
    setSoftGroupingObject(null);
  };

  const clearGroups = () => {
    setGroupingObject({});
    setSoftGroupingObject(null);
  };

  // Above filters applied to MMs
  const filteredMissionMonitorings = useMemo(() => {
    // If filtered on group
    return missionMonitorings.filter((elem) => {
      if (filterActiveFlags && elem.count_active_flags === 0)
        // Flags and comments
        return false;
      if (filterUnreadComments && elem.count_unread_comments === 0)
        return false;
      // Status
      if (selectedStatus !== undefined && elem.status !== selectedStatus)
        return false;
      return true;
    });
  }, [
    filterActiveFlags,
    filterUnreadComments,
    missionMonitorings,
    selectedStatus,
  ]);

  const sitesCount = useMemo(
    () =>
      [...new Set(filteredMissionMonitorings.map(({ site }) => site.id))]
        .length,
    [filteredMissionMonitorings]
  );

  // Mission monitorings filtered using soft filters
  const softFilteredMissionMonitorings = useMemo(() => {
    const usedGroupingObject =
      softGroupingObject != null ? softGroupingObject : groupingObject;
    return filteredMissionMonitorings.filter((elem) => {
      // Filter on status or substatus if selected
      if (
        (softSelectedStatus && softSelectedStatus !== elem.status) ||
        (softSelectedSubStatus && softSelectedSubStatus !== elem.substatus)
      )
        return false;
      // Filter sites by name
      if (
        !elem.site.display_name
          .toLowerCase()
          .includes(filterSiteName.toLowerCase())
      )
        return false;
      // Filter by group key and value
      return shouldBeFilteredByGroups(elem, usedGroupingObject);
    });
  }, [
    filteredMissionMonitorings,
    softSelectedStatus,
    softSelectedSubStatus,
    filterSiteName,
    softGroupingObject,
    groupingObject,
  ]);

  // substatus in filtered missionMonitorings, filled when a status is selected
  const subStatus = useMemo(() => {
    return selectedStatus !== undefined
      ? Array.from(
          new Set(
            filteredMissionMonitorings.map((element) => element.substatus)
          )
        )
      : [];
  }, [filteredMissionMonitorings, selectedStatus]);

  // variable used by pie charts to determine level to count: status or substatus
  const dataCardKeyToCount =
    selectedStatus === undefined
      ? MiSSION_MONITORING_STATUS
      : MiSSION_MONITORING_SUBSTATUS;
  // Status list based on status or substatus if a status is selected
  const currentStatusList =
    selectedStatus === undefined ? statusList : subStatus;

  // Function that guarantees status color
  // Returns the base color when status, substatus color when a status is selected
  const getStatusColorNow = (status) => {
    return getStatusColor(statusList, status, subStatus, selectedStatus);
  };

  // On status click in legend
  const handleStatusClick = (status) => {
    if (selectedStatus === status) {
      setSelectedStatus(undefined);
      setSoftSelectedStatus(undefined);
    } else {
      setSelectedStatus(status);
      setSoftSelectedStatus(status);
    }
    setSoftSelectedSubStatus(null);
  };

  // On status click in a pie chart
  const handlePieClick = (label, soft) => {
    if (statusList.includes(label)) {
      if (!soft) handleStatusClick(label);
      else setSoftSelectedStatus(label);

      setSoftSelectedSubStatus(null);
    } else {
      setSoftSelectedStatus(null);
      setSoftSelectedSubStatus(label);
    }
  };

  const handleGroupClick = (label, combination) => {
    setSoftGroupingObject(Object.keys(combination).length ? combination : null);
    handlePieClick(label, true);
  };

  const [exportIsLoading, setExportIsLoading] = useState(false);
  const exportFilteredMissionMonitoringCsv = () => {
    setExportIsLoading(true);
    const fileName = `Monitoring_${slugify(
      selectedCompany.label,
      "_"
    )}_${moment().toJSON()}.csv`;
    const replacer = (key, value) => {
      return value == null || value === "∅" ? "" : value;
    };

    const promises = softFilteredMissionMonitorings.map((element) =>
      requestFetchMissionMonitoring(element, user)
    );

    Promise.all(promises).then((values) => {
      // All step names existing in values
      const uniqueSteps = [
        ...new Set(
          values.flatMap(({ steps }) => steps.map(({ name }) => name))
        ),
      ];

      const items = values.map((element) => {
        const item = {
          "Trial name": element.site.display_name,
          "Acquisition date": formatDate(element.acquisition_date),
          Status: element.status.replaceAll("_", " "),
          Substatus: element.substatus.replaceAll("_", " "),
          "Sensor type": element.sensor_type,
          Crop: element.crop,
          ...element.custom_data, // spread custom data
          Flags: element.flags
            .filter(({ closing_date }) => closing_date === null)
            .map(({ name, created_at }) => `${name}: ${formatDate(created_at)}`)
            .join(" | ")
            .trim(),
        };
        // Find every occurence of step name and spread date values
        uniqueSteps.forEach(
          (stepName) =>
            (item[stepName] = formatDate(
              element.steps.find(({ name }) => name === stepName)?.date
            ))
        );

        return item;
      });

      const header = Object.keys(items[0]);
      const csv = [
        header.join(";"), // header row first
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(";")
        ),
      ].join("\n");
      download(csv, fileName);
      setExportIsLoading(false);
    });
  };

  const generateCombinationString = (combination) => {
    return Object.values(combination).length > 0
      ? Object.entries(combination)
          .map(([group, value]) => {
            return group === MISSION_MONITORING_STATIC_GROUPS.CONTRACT
              ? contracts.find((contract) => contract.id === value)?.name
              : value;
          })
          .join("·")
      : LABEL_ALL;
  };

  return (
    <>
      <LoadingImg visible={loading} />
      <Container
        fluid
        className="page-content d-flex flex-column light-theme powerdash mission-monitoring-dashboard "
      >
        {companies.length === 0 ? (
          <ComponentPlaceholder text="It seems that you have no campaign available for visualization on this page" />
        ) : (
          <Row className="h-100 g-0">
            <Col className="main-content" sm="6">
              <Row className="content-row g-0">
                <Col className="col" xs="3">
                  <div className="powerdash-component contract-selector">
                    {companies.length > 1 ? (
                      <ReactSelect
                        options={companies.map((company) => ({
                          label: company.name,
                          value: company.id,
                        }))}
                        onChange={setSelectedCompany}
                        value={selectedCompany}
                        isDisabled={loading}
                      />
                    ) : (
                      <h4 className="company-name">{selectedCompany.label}</h4>
                    )}
                    <SelectList
                      options={contracts.map((contract) => {
                        return { value: contract.id, label: contract.name };
                      })}
                      value={selectedContracts}
                      setValue={setSelectedContracts}
                      isMulti
                      isDisabled={loading}
                    />
                  </div>
                </Col>
                <Col className="col" xs="6">
                  <DataCard
                    data={filteredMissionMonitorings}
                    title={LABEL_OVERVIEW}
                    topRightContent={
                      <>
                        <Badge className="overview-badge">
                          {`${sitesCount} site${sitesCount > 1 ? "s" : ""}`}
                        </Badge>
                        <Badge className="overview-badge">
                          {`${filteredMissionMonitorings.length} date${
                            filteredMissionMonitorings.length > 1 ? "s" : ""
                          }`}
                        </Badge>
                      </>
                    }
                    statusList={currentStatusList}
                    keyToCount={dataCardKeyToCount}
                    getStatusColor={getStatusColorNow}
                    showLegend={selectedStatus !== undefined}
                    onClick={handlePieClick}
                  />
                </Col>

                <Col className="col legend-col" xs="3">
                  <Legend
                    statusList={statusList}
                    occurences={occurrences(
                      statusList,
                      missionMonitorings,
                      "status"
                    )}
                    subStatus={subStatus}
                    selectedStatus={selectedStatus}
                    handleStatusClick={handleStatusClick}
                    getStatusColor={getStatusColorNow}
                  />
                  <ExtraFilters
                    flagCount={sumKey(
                      filteredMissionMonitorings,
                      MiSSION_MONITORING_FLAG_COUNT
                    )}
                    filterActiveFlags={filterActiveFlags}
                    setFilterActiveFlags={setFilterActiveFlags}
                    commentCount={sumKey(
                      filteredMissionMonitorings,
                      MiSSION_MONITORING_COMMENT_COUNT
                    )}
                    filterUnreadComments={filterUnreadComments}
                    setFilterUnreadComments={setFilterUnreadComments}
                  />
                </Col>
              </Row>
              <Row className="g-0">
                <Col className="col" sm="12">
                  <FilterSection
                    groups={groups}
                    groupOptions={groupOptions}
                    groupingObject={groupingObject}
                    addGroupValue={addGroupValue}
                    removeGroupValue={removeGroupValue}
                    clearGroups={clearGroups}
                    contracts={contracts}
                    addAllGroupValues={addAllGroupValues}
                    removeAllGroupValues={removeAllGroupValues}
                  />
                </Col>
              </Row>
              <Row className="extend-row g-0">
                <DetailGrid
                  data={filteredMissionMonitorings}
                  statusList={currentStatusList}
                  getStatusColor={getStatusColorNow}
                  keyToCount={dataCardKeyToCount}
                  handleGroupClick={handleGroupClick}
                  groupingObject={groupingObject}
                  generateCombinationString={generateCombinationString}
                />
              </Row>
            </Col>
            <Col className="col" sm="6">
              <MainListFilters
                filterSiteName={filterSiteName}
                setFilterSiteName={setFilterSiteName}
                softSelectedStatus={softSelectedStatus}
                setSoftSelectedStatus={setSoftSelectedStatus}
                softSelectedSubStatus={softSelectedSubStatus}
                setSoftSelectedSubStatus={setSoftSelectedSubStatus}
                setSelectedStatus={setSelectedStatus}
                getStatusColor={getStatusColorNow}
                exportFilteredMissionMonitoringCsv={
                  exportFilteredMissionMonitoringCsv
                }
                exportIsLoading={exportIsLoading}
                exportDisabled={softFilteredMissionMonitorings.length === 0} // Disable export if filtered list is empty
                softGroupingObject={softGroupingObject}
                setSoftGroupingObject={setSoftGroupingObject}
                generateCombinationString={generateCombinationString}
              />
              <MainList
                contracts={contracts}
                elements={softFilteredMissionMonitorings}
                statusList={statusList}
                getStatusColor={getStatusColorNow}
                refresh={refresh}
                user={user}
              />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
