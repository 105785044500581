import "./uploadYourOwnData.scss";

import { ANALYTICS_EVENTS, HIPHEN_GREEN } from "../../../../constants";
import { Alert, Badge, Button } from "reactstrap";
import { useCallback, useEffect, useRef, useState } from "react";

import { Dashboard } from "@uppy/react";
import Papa from "papaparse";
import { PropTypes } from "prop-types";
import Uppy from "@uppy/core";
import { VerifyYourOwnData } from "./VerifyYourOwnData";
import download from "downloadjs";
import { useSelector } from "react-redux";
import { useTracking } from "../../../../analytics";

export function UploadYourOwnData({ trial, setUploadIsOpen }) {
  const [falseDataImported, setFalseDataImported] = useState(false);
  const [clientData, setClientData] = useState(null);

  const features = useSelector(({ resultMap }) => resultMap.features);

  const uppyRef = useRef(
    Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["text/csv"],
      },
    })
  );

  const { trackEvent } = useTracking();

  const downloadCSVTemplate = useCallback(() => {
    const csvRows = features
      .map(
        (feature) =>
          `${feature.id};${feature.displayId};${feature.genotype || ""};${
            feature.modality || ""
          };${feature.layer || ""};${feature.experiment || ""};${
            feature.properties.control_variety || ""
          }\n`
      )
      .join("");

    const csvData = `cloverfieldId;id;genotype;modality;layer;experiment;control_variety\n${csvRows}`;
    const fileName = `template_${trial.display_name}_${features[0].date}`;
    download(csvData, fileName, "text/csv");
    trackEvent(ANALYTICS_EVENTS.UPLOAD_YOUR_OWN_DATA_DOWNLOAD_CSV_TEMPLATE);
  }, [features, trackEvent, trial.display_name]);

  const uppy = uppyRef.current;

  useEffect(() => {
    uppy.on("file-added", (file) => {
      Papa.parse(file.data, {
        header: true,
        skipEmptyLines: "greedy",
        transformHeader: (header, index) =>
          header.trim() === "" ? `no_name_${index + 1}` : header.trim(),
        complete: (results) => {
          if (results.meta.fields.includes("cloverfieldId")) {
            setClientData(results);
            setFalseDataImported(false);
            trackEvent(ANALYTICS_EVENTS.ADD_FILE_UPLOAD_YOUR_OWN_DATA);
          } else {
            setFalseDataImported(true);
          }
        },
      });
    });
    return () => {
      uppy.close();
    };
  }, [trackEvent, uppy]);

  return (
    <div className="upload-own-data d-flex flex-column gap-2">
      <div className="upload-own-data-section d-flex flex-column gap-2 p-2">
        <span className="d-flex flex-row gap-1 align-items-center font-weight-bold">
          <div className="upload-own-data-section-num">1</div>
          Download template
        </span>
        <Button
          size="sm"
          className="hiphen-green-button"
          onClick={downloadCSVTemplate}
        >
          <i className="fa-solid fa-file-csv me-1" />
          Download CSV template
        </Button>
      </div>
      <div className="upload-own-data-section d-flex flex-column gap-2 p-2">
        <span className="d-flex flex-row gap-1 align-items-center font-weight-bold">
          <div className="upload-own-data-section-num">2</div>
          Fill columns using plot Id
        </span>
        <div>
          <span className="font-style-italic me-1">
            Add new columns, modify already provided values, etc. <br />
            Do not modify or remove column
          </span>
          <span className="attribute font-weight-bold">cloverfieldId</span>
        </div>
      </div>
      <div className="upload-own-data-section d-flex flex-column gap-2 p-2">
        <span className="d-flex flex-row gap-1 align-items-center font-weight-bold">
          <div className="upload-own-data-section-num">3</div>
          Drop your edited CSV file
        </span>
        {falseDataImported ? (
          <Alert color="danger">
            <i className="fa fa-minus-circle me-1" />
            Uploaded file is invalid:{" "}
            <span className="font-weight-bold">cloverfieldId</span> column is
            missing
          </Alert>
        ) : clientData ? (
          <Badge
            className="hiphen-badge active w-100"
            style={{ color: HIPHEN_GREEN }}
          >
            <i className="fa-solid fa-lg fa-check-circle me-1" /> Data
            successfully uploaded
          </Badge>
        ) : (
          <>
            <span className="font-style-italic">
              Data uploaded here will not be persisted to our database
            </span>
            <Dashboard uppy={uppy} width="100%" height="15vh" />
          </>
        )}
      </div>
      <div className="upload-own-data-section d-flex flex-column gap-2 p-2">
        <span className="d-flex flex-row gap-1 align-items-center font-weight-bold">
          <div className="upload-own-data-section-num">4</div>
          Validate imported data
        </span>
        {clientData && (
          <VerifyYourOwnData
            data={clientData.data}
            headers={clientData.meta.fields}
            setUploadIsOpen={setUploadIsOpen}
          />
        )}
      </div>
    </div>
  );
}

UploadYourOwnData.propTypes = {
  trial: PropTypes.object.isRequired,
  setUploadIsOpen: PropTypes.func.isRequired,
};
