import "./missionDetailsCard.css";

import {
  Badge,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  MISSION_MONITORING_FLAG_COLORS,
  MISSION_MONITORING_STATIC_FLAGS,
  MISSION_MONITORING_THUMB_FLAG,
  MISSION_MONITORING_THUMB_STEP,
} from "../../../constants";
import {
  dateSort,
  formatDate,
  getContrastYIQ,
  getFlagColor,
  humanize,
} from "../../../utils";
import {
  fetchHccMission,
  fetchUploadSession,
  requestCreateMissionsMonitoringFlag,
  requestDeleteMissionMonitoring,
  requestDeleteMissionsMonitoringFlag,
  requestFetchMissionMonitoring,
  requestUpdateMissionsMonitoringFlag,
} from "../../../../services/backendRequests";
import {
  hasAdminAccess,
  hasBackofficeAccess,
} from "../../../../services/roles";
import { useEffect, useMemo, useState } from "react";

import { AcquisitionReport } from "../acquisitionReport/AcquisitionReport";
import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import { ComponentTitle } from "../../../components/componentTitle/componentTitle";
import CopyButton from "../../../../components/copyButton";
import { ExpandButton } from "../../../components/expandButton/expandButton";
import { MissionChat } from "../missionChat/missionChat";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import UploadSessionModal from "../../../../uploadsessions/uploadSessionModal";
import classnames from "classnames";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

/*
 * Timeline of mm, dates on steps and flagss
 */

const HistoryTimeLine = ({ color, steps, flags }) => {
  // Concatenation and generisation of flags and steps in a single list and set type flag or step, date

  const timeLineThumbs = [
    ...steps.map((step) => {
      return {
        ...step,
        date: step.date,
        type: MISSION_MONITORING_THUMB_STEP,
        name: humanize(step.name),
      };
    }),
    ...flags.map((flag) => {
      return {
        ...flag,
        date: flag.created_at,
        type: MISSION_MONITORING_THUMB_FLAG,
        name: humanize(flag.name),
      };
    }),
  ].sort((a, b) => dateSort(a.date, b.date));

  const Thumb = ({ thumb }) => {
    Thumb.propTypes = {
      thumb: PropTypes.object.isRequired,
    };

    const [popoverVisible, setPopoverVisible] = useState(false);
    const iconClasses = classnames("fa", {
      "fa-flag fa-lg": thumb.type === MISSION_MONITORING_THUMB_FLAG,
    });

    const thumbColor =
      thumb.type === MISSION_MONITORING_THUMB_FLAG ? "none" : color;
    const iconColor =
      thumb.type === MISSION_MONITORING_THUMB_FLAG
        ? getFlagColor(thumb)
        : getContrastYIQ(thumbColor);

    return (
      <div className="thumb-wrapper">
        <span className="thumb-date">{formatDate(thumb.date)}</span>
        <div
          className="thumb"
          style={{ background: thumbColor }}
          onMouseEnter={() => setPopoverVisible(true)}
          onMouseLeave={() => setPopoverVisible(false)}
        >
          {popoverVisible && (
            <div className="thumb-popover">
              <span>{formatDate(thumb.date)}</span>
              <span>{thumb.name}</span>
            </div>
          )}
          <div>
            <i className={iconClasses} style={{ color: iconColor }}></i>
          </div>
        </div>

        <span className="thumb-label">{thumb.name}</span>
      </div>
    );
  };

  return (
    <>
      <div className="history-time-line">
        <div className="line" style={{ background: color }}>
          {timeLineThumbs
            .sort((a, b) => {
              return moment(b.date).isAfter(moment(a.date)) ? -1 : 1;
            })
            .map((thumb) => (
              <Thumb key={thumb.id} thumb={thumb} />
            ))}
        </div>
      </div>
    </>
  );
};

/*
 * Details of timeline, raised flags and comments on a mission monitoring
 */
export const MissionDetailsCard = ({
  element,
  getStatusColor,
  refresh,
  user,
  alwaysExpanded,
  acquisitionReportResults,
}) => {
  const [steps, setSteps] = useState([]);
  const [flags, setFlags] = useState([]);
  const [comments, setComments] = useState([]);
  const [uploadSession, setUploadSession] = useState(null);
  const [hccMission, setHccMission] = useState(null);

  const acquisitionReport = useMemo(() => {
    return acquisitionReportResults?.rows.find(
      ({ date }) => date === element.acquisition_date
    );
  }, [acquisitionReportResults, element.acquisition_date]);

  const date = useMemo(
    () => formatDate(element.acquisition_date),
    [element.acquisition_date]
  );
  useEffect(() => {
    const fetchHccMissionData = async () => {
      try {
        const hccMission = await fetchHccMission(user, acquisitionReport.uuid);
        setHccMission(hccMission);
      } catch {}
    };

    if (acquisitionReport?.uuid) fetchHccMissionData();
  }, [user, acquisitionReport]);

  const userTrials = useSelector((state) => user.trials);
  // Verify in user trials if there is one that matches id and has results on this date
  // because mission monitorings might not match
  const resultsAvailable = useMemo(() => {
    const dates =
      userTrials.find(({ id }) => id === element.site.id)?.trial_dates ?? [];
    return dates.includes(date);
  }, [date, element.site.id, userTrials]);

  const refreshMissionDetailsCard = () => {
    requestFetchMissionMonitoring(element, user).then((res) => {
      setSteps(res.steps);
      setFlags(res.flags);
      setComments(res.comments);
    });
  };

  const history = useHistory();

  const openResultsMap = () => {
    history.push(`/map/${element.site.id}/${date}`);
  };

  useEffect(() => {
    let unmounted = false;
    requestFetchMissionMonitoring(element, user).then((res) => {
      if (!unmounted) {
        setSteps(res.steps);
        setFlags(res.flags);
        setComments(res.comments);
      }
    });
    if (element.upload_session_id)
      fetchUploadSession({ id: element.upload_session_id }, user)
        .then((res) => {
          if (!unmounted) setUploadSession(res);
        })
        .catch((err) => {
          if (!unmounted) console.error(err);
        });

    // TO prevent memory leaks
    return () => {
      unmounted = true;
    };
  }, [element, element.upload_session_id, user]);

  const [expanded, setExpanded] = useState(alwaysExpanded ?? false);
  const [newFlagLabel, setNewFlagLabel] = useState(null);
  const [usModalIsOpen, setUsModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const componentClassNames = classnames(
    "powerdash-component mission-details-card",
    {
      expanded: expanded,
    }
  );

  const raiseFlag = () => {
    if (!newFlagLabel) return;
    const newFlag = {
      name: newFlagLabel.value,
      created_at: moment(),
      closing_date: null,
    };
    requestCreateMissionsMonitoringFlag(element, user, newFlag).then((res) =>
      setFlags([...flags, res])
    );

    setNewFlagLabel(null);
    refresh();
  };

  const resolveFlag = (flag) => {
    const closing_date = moment();
    requestUpdateMissionsMonitoringFlag(
      { id: flag.id, closing_date: closing_date },
      user
    ).then(() => refresh());
    setFlags(
      flags.map((f) => {
        if (f.id === flag.id) return { ...f, closing_date: closing_date };
        else return f;
      })
    );
  };

  const deleteFlag = (flag) => {
    requestDeleteMissionsMonitoringFlag(flag, user).then((res) =>
      setFlags(flags.filter((f) => f.id !== flag.id))
    );
  };

  const MissionDeleteModal = ({ isOpen, toggle }) => {
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Mission Monitoring</ModalHeader>
        <ModalBody>
          Do you want to delete
          <br />
          <span style={{ fontWeight: "bold" }}>
            {element.site.display_name}
            {" | "}
            {date}
          </span>{" "}
          ?<br /> This operation is irreversible.
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              requestDeleteMissionMonitoring(element, user).then(refresh);
              toggle();
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      {uploadSession && usModalIsOpen && (
        <UploadSessionModal
          us={uploadSession}
          isOpen={usModalIsOpen}
          closeModal={() => setUsModalIsOpen(false)}
        />
      )}
      <MissionDeleteModal
        isOpen={deleteModalIsOpen}
        toggle={() => setDeleteModalIsOpen(!deleteModalIsOpen)}
      />
      <div className={componentClassNames}>
        <ComponentTitle
          icon="fa-cogs discrete-icon"
          title={`${date} | ${element.site.display_name}`}
          topRightContent={
            <>
              {resultsAvailable && (
                <Button
                  data-tooltip-id="tooltip"
                  data-tooltip-content="View results"
                  className="p-0"
                  color="link"
                  onClick={openResultsMap}
                >
                  <i className="fa fa-lg fa-globe" />
                </Button>
              )}
              <Badge className="status-badge" pill>
                {humanize(element.substatus)}
              </Badge>
              <Badge
                // if color is not valid "primary secondary...", the background color is overriden by the background color of the style
                color="invalid background color"
                pill
                style={{
                  background: getStatusColor(element.status),
                  color: getContrastYIQ(getStatusColor(element.status)),
                }}
                onClick={openResultsMap}
              >
                {humanize(element.status)}
              </Badge>

              {uploadSession && (
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => setUsModalIsOpen(true)}
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Associated upload session"
                >
                  <i className="fa fa-upload" />
                </Button>
              )}

              {hasBackofficeAccess(user.self) && (
                <CopyButton
                  value={`${window.location.origin}/monitoring/${element.id}`}
                  datatip="Copy direct url"
                />
              )}

              {hasAdminAccess(user.self) && (
                <i
                  className="fa fa-lg fa-trash clickable"
                  style={{
                    color: MISSION_MONITORING_FLAG_COLORS.RAISED,
                  }}
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Delete Mission"
                  onClick={() => setDeleteModalIsOpen(true)}
                />
              )}

              {!alwaysExpanded && (
                <ExpandButton expanded={expanded} setExpanded={setExpanded} />
              )}
            </>
          }
        />
        <Container fluid>
          <Row>
            <HistoryTimeLine
              element={element}
              color={getStatusColor(element.status)}
              flags={flags}
              steps={steps}
            />
          </Row>
          {hccMission && (
            <AcquisitionReport expanded={expanded} hccMission={hccMission} />
          )}
          <Row className={expanded ? "" : "h-100"}>
            <Col sm="4" className="p-3 col">
              <div className="powerdash-component">
                <ComponentTitle icon="fa-flag discrete-icon" title="Flags" />
                {flags.length === 0 ? (
                  <ComponentPlaceholder text="no flags" />
                ) : (
                  <ul className="mission-monitoring-dashboard-list flag-list">
                    {flags.map((flag) => {
                      return (
                        <li
                          className="flag-item"
                          key={flag.id}
                          data-tooltip-place="left"
                          data-tooltip-id="tooltip"
                          data-tooltip-html={`${formatDate(
                            flag.created_at
                          )}<br>${humanize(flag.name)}<br>
                          ${
                            flag.closing_date
                              ? `<i>solved: ${formatDate(
                                  flag.closing_date
                                )}</i>`
                              : ""
                          }`}
                        >
                          {hasAdminAccess(user.self) && (
                            <i
                              className="fa fa-lg fa-close clickable delete-flag"
                              style={{
                                color: MISSION_MONITORING_FLAG_COLORS.RAISED,
                              }}
                              title="Delete flag"
                              onClick={() => deleteFlag(flag)}
                            />
                          )}
                          <i
                            className="fa fa-lg fa-flag"
                            style={{ color: getFlagColor(flag) }}
                          />
                          <div className="flag-text">
                            <span>
                              {formatDate(flag.created_at)}{" "}
                              {hasBackofficeAccess(user.self) &&
                                flag.closing_date === null && (
                                  <Badge
                                    // if color is not valid "primary secondary...", the background color is overriden by the background color of the style
                                    color="invalid background color"
                                    className="resolve-btn clickable"
                                    onClick={() => resolveFlag(flag)}
                                    pill
                                    style={{
                                      background:
                                        MISSION_MONITORING_FLAG_COLORS.RESOLVED,
                                    }}
                                  >
                                    <i className="fa fa-check" /> resolve
                                  </Badge>
                                )}
                            </span>
                            <span>{humanize(flag.name)}</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <div className="flag-raiser">
                  {hasBackofficeAccess(user.self) && (
                    <>
                      <ReactSelect
                        className="flag-select"
                        options={MISSION_MONITORING_STATIC_FLAGS.map((flag) => {
                          return { label: flag, value: flag };
                        })}
                        placeholder="Add flag"
                        value={newFlagLabel}
                        onChange={(value) => setNewFlagLabel(value)}
                        menuPlacement="top"
                      />
                      <Button
                        outline
                        color="danger"
                        onClick={() => raiseFlag()}
                      >
                        <i className="fa fa-flag" />
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col sm="8" className="p-0 col">
              <MissionChat
                comments={comments}
                isBackOffice={hasBackofficeAccess(user.self)}
                element={element}
                user={user}
                refresh={() => {
                  refreshMissionDetailsCard();
                  refresh();
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

HistoryTimeLine.propTypes = {
  element: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

MissionDetailsCard.propTypes = {
  element: PropTypes.object.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  alwaysExpanded: PropTypes.bool,
  acquisitionReportResults: PropTypes.object,
};
