import "./passwordReset.css";

import React, { Component } from "react";
import {
  changePassword,
  changePasswordConfirmation,
  submitNewPassword,
} from "../actions/passwordReset";

import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";

class PasswordEdition extends Component {
  componentDidUpdate() {
    if (!_.isEmpty(this.props.alerts) && this.props.alerts.success) {
      setTimeout(() => {
        window.location = "/";
      }, 5000);
    }
  }

  displayRedirectionLink() {
    if (!this.props.alerts.success) {
      return null;
    }
    return (
      <p>
        You will be redirected soon, or <a href="/">click here</a>.
      </p>
    );
  }

  displayAlerts() {
    if (_.isEmpty(this.props.alerts)) {
      return null;
    }

    return (
      <div className="row alert">
        <div className="col-10 mx-auto">
          {this.props.alerts.messages.map((message) => {
            return (
              <Alert
                key={message}
                className="stacked"
                color={this.props.alerts.success ? "success" : "danger"}
              >
                {message}
              </Alert>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="text-center my-5">
            <span className="h1">Password reset</span>
          </div>

          <form className="row">
            <div className="col" />
            <div className="form-container text-center col">
              <div className="fo />p row">
                <label className="col-sm-2 col-form-label text-end my-auto">
                  New password
                </label>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    type="password"
                    value={this.props.password}
                    onChange={(event) =>
                      this.props.onChangePassword(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-sm-2 col-form-label text-end my-auto">
                  Confirm password
                </label>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    type="password"
                    value={this.props.passwordConfirmation}
                    onChange={(event) =>
                      this.props.onChangePasswordConfirmation(
                        event.target.value
                      )
                    }
                  />
                </div>
                <div className="col-md-12">
                  <button
                    className="btn btn-primary"
                    disabled={!this.props.submissionIsPossible}
                    type="submit"
                    onClick={(event) => {
                      event.preventDefault();
                      this.props.onSubmitNewPassword(
                        this.props.password,
                        this.props.passwordConfirmation,
                        this.props.location.search
                      );
                    }}
                  >
                    Reset password
                  </button>
                </div>
              </div>
              {this.displayAlerts()}
              {this.displayRedirectionLink()}
            </div>
            <div className="col" />
          </form>
        </div>
      </div>
    );
  }
}

PasswordEdition.propTypes = {
  location: PropTypes.object.isRequired,
  password: PropTypes.string.isRequired,
  passwordConfirmation: PropTypes.string.isRequired,
  alerts: PropTypes.object.isRequired,
  submissionIsPossible: PropTypes.bool.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onChangePasswordConfirmation: PropTypes.func.isRequired,
  onSubmitNewPassword: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  const { password, passwordConfirmation, alerts } = store.passwordReset;
  return {
    password,
    passwordConfirmation,
    alerts,
    submissionIsPossible: password !== "",
  };
}

const callbacks = {
  onChangePassword: changePassword,
  onChangePasswordConfirmation: changePasswordConfirmation,
  onSubmitNewPassword: submitNewPassword,
};

export default connect(mapStateToProps, callbacks)(PasswordEdition);
