export function parseUrlParams(searchParams) {
  let vars = searchParams;
  if (vars.startsWith("?")) {
    vars = vars.slice(1);
  }
  vars = vars.split("&");
  let res = {};
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split("=");
    if (pair[0] !== "") {
      res = {
        ...res,
        [pair[0]]: unescape(pair[1])
      };
    }
  }
  return res;
}
