import alerts from "./alerts";
import allTreatments from "./allTreatments";
import application from "./application";
import client from "./client";
import companies from "./companies";
import company from "./company";
import { connectRouter } from "connected-react-router";
import consumption from "./consumption";
import contracts from "./contracts";
import crops from "./crops";
import dashboard from "./dashboard";
import passwordReset from "./passwordReset";
import { persistCombineReducers } from "redux-persist";
import provision from "./provision";
import provisions from "./provisions";
import requests from "./requests";
import resultMap from "./resultMap";
import sites from "./sites";
import storage from "redux-persist/lib/storage";
import systems from "./systems";
import traits from "./traits";
import treatmentChainEdition from "./treatmentChainEdition";
import treatmentChains from "./treatmentChains";
import treatmentCreation from "./treatmentCreation";
import treatments from "./treatments";
import user from "./user";
import users from "./users";

const persistConfig = { key: "root", storage, whitelist: ["user"] };

export default history =>
  persistCombineReducers(persistConfig, {
    alerts,
    allTreatments,
    application,
    client,
    companies,
    company,
    sites,
    traits,
    resultMap,
    consumption,
    contracts,
    crops,
    provision,
    provisions,
    dashboard,
    passwordReset,
    systems,
    treatmentChainEdition,
    treatmentChains,
    treatmentCreation,
    treatments,
    requests,
    user,
    users,
    router: connectRouter(history),
  });
