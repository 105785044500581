import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import classnames from "classnames";

const CopyButton = ({ value, datatip }) => {
  const [copied, setCopied] = useState(null);

  useEffect(() => {
    let timer = setTimeout(() => {
      setCopied(null);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [copied]);

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <CopyToClipboard
        text={value}
        onCopy={() => {
          setCopied(value);
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          data-tooltip-id="tooltip"
          data-tooltip-content={datatip}
          className="p-0"
          color="link"
        >
          <i
            className={classnames({
              "fa fa-lg": true,
              "fa-clipboard": copied !== value,
              "fa-check": copied === value,
            })}
          />
        </Button>
      </CopyToClipboard>
    </span>
  );
};

CopyButton.propTypes = {
  value: PropTypes.string.isRequired,
  datatip: PropTypes.string.isRequired,
};

export default CopyButton;
