import "leaflet-linear-measurement/src/Leaflet.LinearMeasurement.js";
import "leaflet-linear-measurement/sass/Leaflet.LinearMeasurement.scss";

import { useEffect, useState } from "react";

import { Badge } from "reactstrap";
import { Control } from "leaflet";
import { HIPHEN_GREEN } from "../../../constants";

const SYSTEMS = ["metric", "imperial"];

export const LinearRuler = ({ map }) => {
  const [system, setSystem] = useState(SYSTEMS[0]);
  useEffect(() => {
    const control = new Control.LinearMeasurement({
      color: HIPHEN_GREEN,
      unitSystem: system,
      show_azimut: true,
    });
    control.addTo(map);
    control.initRuler();
    return () => map.removeControl(control);
  }, [map, system]);

  return (
    <>
      <span>Linear ruler</span>
      <Badge
        className="clickable"
        color="invalid"
        style={{ backgroundColor: HIPHEN_GREEN, color: "black" }}
        onClick={() =>
          setSystem(system === SYSTEMS[0] ? SYSTEMS[1] : SYSTEMS[0])
        }
      >
        {system}
      </Badge>
    </>
  );
};
