import "./plotRatingConfig.css";

import { Button } from "reactstrap";
import { PlotRatingConfigItem } from "./plotRatingConfigItem";
import PropTypes from "prop-types";
import { requestUpdatePlotRatingConfig } from "../../services/backendRequests";
import { useState } from "react";

export const PlotRatingConfig = ({ contract, user }) => {
  const INITIAL_PLOT_RATING_CONFIG = JSON.parse(
    JSON.stringify(contract.plot_rating_config)
  );
  const [plotRatingConfig, setPlotRatingConfig] = useState(
    INITIAL_PLOT_RATING_CONFIG
  );
  const [clicked, setClicked] = useState(false);

  const handleNewRatingconfigItem = () => {
    let newIndex = plotRatingConfig.length + 1;
    let newLabel = `Item ${newIndex}`;
    const labelList = plotRatingConfig.map((item) => item.label);
    while (labelList.includes(newLabel)) {
      newIndex += 1;
      newLabel = `Item ${newIndex}`;
    }

    setPlotRatingConfig([
      ...plotRatingConfig,
      { label: newLabel, options: [] },
    ]);
  };

  const updateItem = (index, plotRatingItem) => {
    const updatedList = [...plotRatingConfig];
    updatedList[index] = plotRatingItem;
    setPlotRatingConfig(updatedList);
  };

  const deleteItem = (index) => {
    const confirmation = window.confirm(
      "Do you really want to delete this item ?"
    );

    if (confirmation) {
      const updatedList = [...plotRatingConfig];
      updatedList.splice(index, 1);
      setPlotRatingConfig(updatedList);
    }
  };
  const updatePlotRatingConfig = async () => {
    try {
      setClicked(true);
      await requestUpdatePlotRatingConfig(user, contract, plotRatingConfig);
      window.location.reload();
    } catch {}
  };
  return (
    <>
      <div className="plot-rating-config pe-1 d-flex flex-column gap-1">
        <Button
          className="clickable hiphen-badge rounded-pill d-flex align-items-center gap-2"
          onClick={handleNewRatingconfigItem}
        >
          <i className="fa fa-plus-circle add-icon" aria-hidden="true" />
          Add new rating item
        </Button>

        {plotRatingConfig.map((plotRatingConfigItem, index) => (
          <PlotRatingConfigItem
            key={plotRatingConfigItem.label}
            plotRatingConfigItem={plotRatingConfigItem}
            deleteItem={() => deleteItem(index)}
            updateItem={(newPlotRatingConfigItem) =>
              updateItem(index, newPlotRatingConfigItem)
            }
            labelList={plotRatingConfig.map((item) => item.label)}
          />
        ))}
      </div>
      <Button
        disabled={
          JSON.stringify(plotRatingConfig) ===
          JSON.stringify(contract.plot_rating_config)
        }
        className="hiphen-green-button m-auto me-0 d-flex align-items-center mt-3"
        onClick={updatePlotRatingConfig}
      >
        <i className="fa fa-save me-1" />
        <span>Save</span>
        {clicked ? <i className="fa fa-spinner fa-pulse ms-1" /> : ""}
      </Button>
    </>
  );
};

PlotRatingConfig.propTypes = {
  contract: PropTypes.shape({
    plot_rating_config: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  user: PropTypes.object.isRequired,
};
