import { Progress } from "reactstrap";
import PropTypes from "prop-types";
import { truncateNumber } from "../../../../../services/utils";

export const Metric = ({ name, value, tip, href }) => {
  if (!value) return null;
  return (
    <div
      data-tooltip-id="tooltip"
      data-tooltip-content={tip}
      data-tooltip-place="right"
    >
      <a href={href} target="_blank" rel="noreferrer">
        {name} <i className="fa fa-external-link discrete-icon" />
      </a>

      <Progress value={Math.abs(value * 100)} />
      <span className="value">{truncateNumber(value)}</span>
    </div>
  );
};

Metric.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string,
  value: PropTypes.number,
  tip: PropTypes.string,
};
