export const STATUS_PENDING_VALUE = "pending";
export const STATUS_ARCHIVED_VALUE = "archived";
export const STATUS_ACTIVE_VALUE = "active";

const PROVISION_STATUS_LIST = {
  [STATUS_PENDING_VALUE]: {
    label: "In preparation",
    newStatusDetails: {
      value: STATUS_ACTIVE_VALUE,
      label: "Activate",
      icon: "fa-check",
      tooltip:
        "Activate this provision. Users will see it and will be able to work on it."
    }
  },
  [STATUS_ACTIVE_VALUE]: {
    label: "Active",
    newStatusDetails: {
      value: STATUS_ARCHIVED_VALUE,
      label: "Archive",
      icon: "fa-archive",
      tooltip:
        "Archive this provision. " +
        "Users will not be able to work on it anymore but will still have access to the history. " +
        "You can restart it later."
    }
  },
  [STATUS_ARCHIVED_VALUE]: {
    label: "Archived",
    newStatusDetails: {
      value: STATUS_ACTIVE_VALUE,
      label: "Restart",
      icon: "fa-undo",
      tooltip:
        "Restart this provision service. Users will be able to work on it again."
    }
  }
};

export function labelForProvisionStatus(status) {
  return PROVISION_STATUS_LIST[status].label;
}

export function switchDetailsForProvisionStatus(status) {
  return PROVISION_STATUS_LIST[status].newStatusDetails;
}

export function switchValueForProvisionStatus(status) {
  return PROVISION_STATUS_LIST[status].newStatusDetails.value;
}
