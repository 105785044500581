import {
  requestCreateCrop,
  requestDeleteCrop,
  requestFetchCrops,
  requestUpdateCrop,
} from "../services/backendRequests";

import { SET_CROPS } from "./actionTypes";
import { addDangerAlert } from "./alerts";
import { setRefreshing } from "./dataTable";

function setCrops(crops) {
  return {
    type: SET_CROPS,
    crops,
  };
}
function refreshingCrops(refreshing = true) {
  return setRefreshing("crops", refreshing);
}

export function fetchCrops() {
  return (dispatch, getState) => {
    dispatch(refreshingCrops());
    requestFetchCrops(getState().user)
      .then((response) => {
        dispatch(setCrops(response));
        dispatch(refreshingCrops(false));
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

export function createCrop(crop) {
  return (dispatch, getState) => {
    requestCreateCrop(crop, getState().user)
      .then(() => {
        dispatch(fetchCrops());
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

export function deleteCrop(crop) {
  return (dispatch, getState) => {
    requestDeleteCrop(crop, getState().user)
      .then(() => {
        dispatch(fetchCrops());
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

export function updateCrop(crop) {
  return (dispatch, getState) => {
    requestUpdateCrop(crop, getState().user)
      .then(() => {
        dispatch(fetchCrops());
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}
