import "./componentTitle.css";

import PropTypes from "prop-types";

/* Title for powerdash component:
 *  Displays a title with an optional icon + content in the top right corner
 *  Top right content is a jsx object to display
 */
export const ComponentTitle = ({ title, icon, topRightContent, onClick }) => {
  return (
    <div
      className={`component-title ${onClick != null ? "clickable" : ""}`}
      onClick={onClick}
    >
      {icon != null && <i className={`fa fa-lg ${icon} discrete-icon`} />}
      <div className="title">{title}</div>
      <div className="top-right-content">{topRightContent}</div>
    </div>
  );
};

ComponentTitle.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.string,
  topRightContent: PropTypes.object,
};
