export function removeElementFromArray(array, element) {
  const newArray = array.filter((item) => item !== element);
  return newArray;
}

export function mean(array) {
  let sum = 0;
  array.forEach((x) => (sum += x));
  return sum / array.length;
}

export function dateSort(a, b) {
  var keyA = new Date(a.date),
    keyB = new Date(b.date);
  // Compare the 2 dates
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
}
