import {
  ADD_ALERT,
  CLEAR_ALL_ALERTS,
  REMOVE_ALERT
} from "../actions/actionTypes";

const initialState = {
  all: []
};

export default function alerts(state = initialState, action) {
  switch (action.type) {
    case ADD_ALERT:
      return {
        ...state,
        all: [...state.all, { style: action.style, message: action.message }]
      };
    case REMOVE_ALERT:
      const allAlerts = state.all.slice();
      allAlerts.splice(action.index, 1);
      return {
        ...state,
        all: allAlerts
      };
    case CLEAR_ALL_ALERTS:
      return {
        ...state,
        all: []
      };
    default:
      return state;
  }
}
