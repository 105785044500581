import "./TileLayerControl.css";

import { MAP_TILE_LAYERS } from "../../constants";
import PropTypes from "prop-types";

export const TileLayerControl = ({
  selectedTileLayer,
  setSelectedTileLayer,
}) => (
  <div className="powerdash-component tilelayer-control d-flex flex-row gap-1">
    {Object.values(MAP_TILE_LAYERS).map((layer) => (
      <div
        key={layer.name}
        className={`tilelayer-option clickable ${layer.name} ${
          selectedTileLayer === layer ? "active" : ""
        }`}
        onClick={() => setSelectedTileLayer(layer)}
      />
    ))}
  </div>
);

TileLayerControl.propTypes = {
  selectedTileLayer: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  setSelectedTileLayer: PropTypes.func.isRequired,
};
