import { MAX_ATTRIBUTES_SELECT_VALUES } from "../../../../../../constants";
import { NULL_GROUP_LABEL } from "../../../../../constants";
import PropTypes from "prop-types";
import { PropertyName } from "../../../../../../components/PropertyName/PropertyName";
import ReactSelect from "react-select";
import { ScopeItemSelector } from "../ScopeItemSelector/ScopeItemSelector";
import { useState } from "react";

const customStyles = {
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: "150px",
    overflowY: "auto",
  }),
};

export const AttributeSelector = ({
  label,
  values,
  selectedAttributes,
  setSelectedAttributes,
}) => {
  const [isSelectorUnlocked, setIsSelectorUnlocked] = useState(false);

  const handleSelectionChange = (key, selected) => {
    const selectedValues = selected ? selected.map((item) => item.value) : [];
    const newSelectedOptions = {
      ...selectedAttributes,
      [key]: selectedValues,
    };
    setSelectedAttributes(newSelectedOptions);
  };

  const toggleSelector = () => {
    setIsSelectorUnlocked((prev) => !prev);
  };

  const handleSelectAllAttributes = (key, options) => {
    const selectedValuesForKey = selectedAttributes[key] || [];

    if (selectedValuesForKey.length === options.length) {
      const newSelectedOptions = { ...selectedAttributes, [key]: [] };
      setSelectedAttributes(newSelectedOptions);
    } else {
      const newSelectedOptions = {
        ...selectedAttributes,
        [key]: options,
      };
      setSelectedAttributes(newSelectedOptions);
    }
  };

  const isHidden =
    values.length > MAX_ATTRIBUTES_SELECT_VALUES && !isSelectorUnlocked;

  return (
    <div>
      <ScopeItemSelector
        labelContent={
          <>
            <PropertyName showIcon technicalName={label} />
            {isSelectorUnlocked &&
              values.length > MAX_ATTRIBUTES_SELECT_VALUES && (
                <span
                  className="show-hide-button ms-2 mb-2 clickable"
                  onClick={toggleSelector}
                >
                  Hide
                </span>
              )}
          </>
        }
        selected={selectedAttributes[label]}
        options={values}
        handleSelectAll={() => handleSelectAllAttributes(label, values)}
      />
      {isHidden ? (
        <div className="hidden-options-container p-2">
          <span className="hidden-options-message">
            {`Attribute selection is hidden because it exceeds ${MAX_ATTRIBUTES_SELECT_VALUES} options and might affect performance.`}
          </span>
          <span
            className="show-hide-button ms-2 mb-2 clickable"
            onClick={toggleSelector}
          >
            Show
          </span>
        </div>
      ) : (
        <ReactSelect
          className="attribute"
          value={(selectedAttributes[label] || []).map((option) => ({
            value: option,
            label: option ?? NULL_GROUP_LABEL,
          }))}
          options={values.map((option) => ({
            value: option,
            label: option ?? NULL_GROUP_LABEL,
          }))}
          isMulti
          onChange={(selected) => handleSelectionChange(label, selected)}
          closeMenuOnSelect={false}
          styles={customStyles}
          isHidden={isHidden}
        />
      )}
    </div>
  );
};

AttributeSelector.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  selectedAttributes: PropTypes.object.isRequired,
  setSelectedAttributes: PropTypes.func.isRequired,
};
