import {
  INC_UPLOAD_PROGRESS,
  RESET,
  RESET_DROP_FILES,
  RESET_UPLOADING,
  RESET_UPLOAD_PROGRESS,
  SET_DROP_FILES,
  SET_SERVICE_FOR_CREATION,
  SET_TREATMENT_CHAIN_FOR_CREATION,
  SET_UPLOADING,
  TREATMENT_SUBMITTED,
  UPDATE_NEW_ATTRIBUTES
} from "../actions/actionTypes";
import { UPLOAD_DONE, UPLOAD_EMPTY } from "../constants";

const initialAttributes = {
  name: "",
  acquisition_time: new Date().getTime()
};

const initialFiles = {
  METADATA_TYPE: [],
  DATASET_TYPE: [],
  CONFIG_TYPE: [],
  CAMERA_PROFILE_TYPE: [],
  PHENOSCRIPT_LABEL_MARKERS_TYPE: [],
  PHENOSCRIPT_PLOTS_TYPE: [],
  PHENOSCRIPT_REFLECTANCE_TYPE: [],
  PHENOSCRIPT_TRAINING_TYPE: [],
  PHENOSCRIPT_VIGNETTES_TYPE: [],
  PHOTOSCAN_PROJECT_TYPE: [],
  SVM_TRAININGSET_TYPE: [],
  VECTOR_CONFIG_TYPE: [],
  CAMERA_SETUP_TYPE: [],
  GRP_PARAMETERS_TYPE: [],
  NNT_PARAMETERS_TYPE: [],
  LUT_PARAMETERS_TYPE: [],
  ADDITIONAL_DATA_TYPE: []
};

const initialState = {
  service: {
    name: "",
    provision: {
      trial: "",
      contract: {
        name: ""
      }
    }
  },
  treatmentChain: {
    name: ""
  },
  uploading: {},
  newAttributes: initialAttributes,
  files: initialFiles,
  uploadProgress: {
    status: UPLOAD_EMPTY,
    triggers: 0,
    total: 0,
    uploaded: 0
  },
  submitting: "NO"
};

export default function treatments(state = initialState, action) {
  switch (action.type) {
    case SET_SERVICE_FOR_CREATION:
      return {
        ...state,
        service: action.service
      };
    case SET_TREATMENT_CHAIN_FOR_CREATION:
      return {
        ...state,
        treatmentChain: action.treatmentChain
      };
    case SET_UPLOADING:
      return {
        ...state,
        uploading: action.treatment
      };
    case UPDATE_NEW_ATTRIBUTES: {
      return {
        ...state,
        newAttributes: {
          ...state.newAttributes,
          ...action.attributes
        }
      };
    }
    case RESET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: {
          status: action.status || UPLOAD_EMPTY,
          triggers: action.triggers || 0,
          total: action.total || 0,
          uploaded: 0
        }
      };
    case INC_UPLOAD_PROGRESS:
      const { uploadProgress } = state;
      const uploaded = uploadProgress.uploaded + action.increase;
      const status =
        uploaded === uploadProgress.total ? UPLOAD_DONE : uploadProgress.status;

      return {
        ...state,
        uploadProgress: {
          ...uploadProgress,
          status,
          uploaded
        }
      };
    case SET_DROP_FILES:
      return {
        ...state,
        files: {
          ...state.files,
          [action.fileType]: action.files
        }
      };
    case RESET_DROP_FILES:
      return {
        ...state,
        files: {
          ...state.files,
          [action.fileType]: []
        }
      };
    case TREATMENT_SUBMITTED:
      return {
        ...state,
        submitting: action.submitting
      };
    case RESET_UPLOADING:
      return {
        ...state,
        submitting: "NO",
        uploading: {},
        newAttributes: initialAttributes,
        files: initialFiles
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export function datasetIsUploaded(state) {
  return state.uploadProgress.status === UPLOAD_DONE;
}
