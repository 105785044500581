import "./PropertyBadge.css";

import { Badge } from "reactstrap";
import { MODALITY_PROPERTY } from "../../../constants";
import { NULL_GROUP_LABEL } from "../../../powerdash/constants";
import PropTypes from "prop-types";
import { PropertyName } from "../../PropertyName/PropertyName";
import { createModalityBadgeStyle } from "../../../powerdash/utils";
import { truncateNumber } from "../../../services/utils";
import { useGetPropertyCategory } from "../../../hooks/useGetPropertyCategory";

export const PropertyBadge = (props) => {
  const {
    technicalName,
    value,
    isHighlighted,
    setHighlighted,
    propertyFilter = "",
  } = props;
  const getPropertyCategory = useGetPropertyCategory();
  const category = getPropertyCategory(technicalName);

  return (
    <Badge
      className={`property-badge ${category.className}`}
      color="invalid" // Programatically set
      style={{
        backgroundColor: category.light,
        outline: isHighlighted ? `3px solid ${category.color}` : "none",
      }}
      onClick={setHighlighted}
    >
      <PropertyName {...props} showIcon highlight={propertyFilter} />
      <span
        className="value"
        style={
          technicalName === MODALITY_PROPERTY
            ? createModalityBadgeStyle(value)
            : undefined
        }
      >
        {value == null ? NULL_GROUP_LABEL : truncateNumber(value)}
      </span>
    </Badge>
  );
};

PropertyBadge.propTypes = {
  technicalName: PropTypes.string.isRequired,
  propertyFilter: PropTypes.string,
  isHighlighted: PropTypes.bool,
  setHighlighted: PropTypes.func,
  value: PropTypes.any,
};
