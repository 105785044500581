import "./highlightedText.css";

import PropTypes from "prop-types";

// Construct a fragmented span with the highlighted text style set
export const HighlightedText = ({ text, highlight }) => {
  const escapedHighlightWithoutParentheses = highlight.replace(
    /[.*+?^${}()|[\]\\]/g,
    "\\$&"
  );

  const parts = text.split(
    new RegExp(`(${escapedHighlightWithoutParentheses})`, "gi")
  );
  return (
    <span>
      {parts.map((part, i) => (
        <span
          className={
            part.toLowerCase() === highlight.toLowerCase() ? "highlight" : ""
          }
          key={i}
        >
          {part}
        </span>
      ))}
    </span>
  );
};

HighlightedText.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
};
