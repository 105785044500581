/* eslint-disable react-hooks/exhaustive-deps */
import "./analyticsdashboard.css";

import { useDispatch, useSelector } from "react-redux";

import { BreederReport } from "./BreederReport";
import { ComponentPlaceholder } from "../components/componentPlaceholder/componentPlaceholder";
import { Container } from "reactstrap";
import LoadingImg from "../../components/loading";
import { fetchTraitGroups } from "../../actions/traits";
import { hasAnalyticsRole } from "../../users/rolesUtil";
import { useEffectOnce } from "react-use";

/*
 * Analytics tool: powerful analytics dashboards
 * Single page app embedded in cloverfield to display intelligence and analytics
 * about calculated traits and crops fields.
 * Powerful modular templates can be implemented and embedded here
 */
export const AnalyticsDashboard = () => {
  const resultMap = useSelector((state) => state.resultMap);
  const traitGroupsFetched = useSelector(
    (state) => state.traits.traitGroupsFetched
  );
  const dispatch = useDispatch();

  useEffectOnce(() => {
    if (!traitGroupsFetched) dispatch(fetchTraitGroups());
  });

  return (
    <Container
      fluid
      className="page-content d-flex flex-column powerdash light-theme"
    >
      {/* Change template here */}
      {resultMap.trial &&
      (!hasAnalyticsRole(resultMap.selectedContract.roles) ||
        resultMap.traitsListForMap.length === 0) ? (
        <ComponentPlaceholder text="Selected Trial is not available for analytics" />
      ) : (
        <BreederReport />
      )}
      {(resultMap.refreshing || !traitGroupsFetched) && (
        <div className="loading-screen">
          <LoadingImg visible />
        </div>
      )}
    </Container>
  );
};
