import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFetch = (url, options = {}) => {
  const [loading, setLoading] = useState(false);
  const [refetchToggle, setRefetch] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const { identity } = user;
  const { skip } = options;

  const refetch = () => setRefetch((v) => !v);

  useEffect(() => {
    if (options.skip) {
      return;
    }
    setLoading(true);
    fetch(url, { ...options, headers: identity }).then((r) => {
      if (r.ok) {
        r.json().then((j) => {
          setResponse(j);
          setError(null);
          setLoading(false);
        });
      } else {
        setError(r);
      }
    });
  }, [url, skip, identity, refetchToggle]);

  return [response, { error, refetch, loading }];
};
