import "./complexSearch.css";

import { Badge, Input } from "reactstrap";
import { useEffect, useMemo, useRef, useState } from "react";

import { GroupedVirtuoso } from "react-virtuoso";
import { HighlightedText } from "../../../components/highlightedText/HighlightedText";
import PropTypes from "prop-types";
import { capitalize } from "../../utils";

const ComplexSearchOption = ({
  option,
  handleSelection,
  input,
  CustomComponent,
}) => {
  return (
    <div
      tabIndex="0"
      className="search-results-option clickable me-1 ms-1"
      onClick={() => handleSelection(option)}
      onKeyDown={({ key }) => {
        if (key === "Enter") handleSelection(option);
      }}
    >
      <div className="search-results-option-bullet">{option.renderBullet}</div>
      {CustomComponent ? (
        <CustomComponent input={input} option={option} />
      ) : (
        <HighlightedText text={option.label} highlight={input} />
      )}
    </div>
  );
};

/*
 * Complex search is composed of an input and a list that unfolds when input is focused
 * and displays a list of elements filtered on the input string and separated by categories
 * It takes an object in the shape of category keys containing options and a handleClick function
 */
export const ComplexSearch = ({
  placeholder,
  content,
  handleClickDefault,
  closeOnSelect = true,
  inverted,
  disabled,
}) => {
  const [input, setInput] = useState("");
  const [isFocused, setFocused] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const filteredEntries = useMemo(
    () =>
      Object.entries(content)
        .filter(([category]) =>
          selectedCategory ? category === selectedCategory : true
        )
        .map(([category, content]) => [
          category,
          {
            ...content,
            options: content.options.filter(({ label }) =>
              label.toLowerCase().includes(input.toLowerCase())
            ),
          },
        ]),
    [content, input, selectedCategory]
  );

  const filteredOptions = filteredEntries.flatMap(([, { options }]) => options);

  const wrapperRef = useRef(null);

  useEffect(() => {
    // Set unfocused state if clicked on outside of search or results component
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target))
        setFocused(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="complex-search-wrapper" ref={wrapperRef}>
      <Input
        className={`complex-search-input ${disabled ? "disabled" : ""}`}
        type="search"
        placeholder={placeholder}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onFocus={() => setFocused(true)}
        disabled={disabled}
      />
      {isFocused && (
        <div
          className={`search-results vertical-stack ${
            inverted ? "inverted" : ""
          }`}
        >
          <div className="search-results-header">
            {selectedCategory && (
              <Badge
                pill
                className="hiphen-badge small clickable"
                onClick={() => setSelectedCategory(null)}
              >
                <i className="fa fa-close discrete-icon" />
              </Badge>
            )}
            {filteredEntries.map(([category]) => (
              <Badge
                pill
                key={category}
                className={`hiphen-badge small clickable ${
                  category === selectedCategory ? "active" : ""
                }`}
                onClick={() =>
                  setSelectedCategory((current) =>
                    current === category ? null : category
                  )
                }
              >
                {capitalize(category)}
              </Badge>
            ))}
          </div>
          <div className="search-results-body">
            <GroupedVirtuoso
              groupCounts={filteredEntries.map(
                ([, { options }]) => options.length
              )}
              groupContent={(index) => (
                <>
                  <div className="search-results-category p-1">
                    {filteredEntries[index][0]}
                  </div>
                  {filteredEntries[index][1].options.length === 0 && (
                    <div className="search-results-category-placeholder">
                      No results
                    </div>
                  )}
                </>
              )}
              itemContent={(index, groupIndex) => {
                const [, { handleClick, CustomComponent }] =
                  filteredEntries[groupIndex];

                const onClick = handleClick || handleClickDefault;
                const handleSelection = (option) => {
                  setInput("");
                  onClick(option.value);
                  if (closeOnSelect) setFocused(false);
                };

                const option = filteredOptions[index];

                return (
                  <ComplexSearchOption
                    option={option}
                    handleSelection={handleSelection}
                    input={input}
                    CustomComponent={CustomComponent}
                  />
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ComplexSearchOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    renderBullet: PropTypes.node,
  }),
  handleSelection: PropTypes.func.isRequired,
  input: PropTypes.string.isRequired,
  CustomComponent: PropTypes.elementType,
};

ComplexSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  handleClickDefault: PropTypes.func,
  closeOnSelect: PropTypes.bool,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
};
