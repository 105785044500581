import { SET_TRAITS } from "./actionTypes";
import { addDangerAlert } from "./alerts";
import { requestFetchTraitGroups } from "../services/backendRequests";

export function fetchTraitGroups() {
  return (dispatch, getState) => {
    requestFetchTraitGroups(getState().user)
      .then((response) => {
        dispatch({ type: SET_TRAITS, traitGroups: response });
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}
