import "./traitSelector.css";

import {
  removeTraitFromAucData,
  setSelectedTrait,
} from "../../../../actions/resultMap";
import { useDispatch, useSelector } from "react-redux";

import DEFAULT_TRAIT_ICON from "../../../../static/img/default_trait_icon.png";
import { Input } from "reactstrap";
import { OTHER_NUMERICAL_PROPERTIES_GROUP } from "../../../../constants";
import { PropertyName } from "../../../../components/PropertyName/PropertyName";
import { TraitDisplayCard } from "../../../../glossary/TraitDisplayCard";
import classNames from "classnames";
import { selectMergedTraits } from "../../../../selectors/resultMap";
import { useState } from "react";
import { useTraitGroups } from "../../useTraitGroups";

/* Trait item of trait list
 * shows trait icon + trait name, trait glossary card on hover
 * sets selectedTrait on click and visualized trait btn
 */
const TraitItem = ({ trait, setHoverTrait, highlight }) => {
  const selectedTrait = useSelector((state) => state.resultMap.selectedTrait);
  const dispatch = useDispatch();

  const deleteAddonTrait = (event) => {
    event.stopPropagation();
    dispatch(removeTraitFromAucData(trait.technical_name));
  };

  // Active / inactive / selected
  const traitItemClasses = classNames("trait-item clickable", {
    active: selectedTrait?.technical_name === trait.technical_name,
    addon: trait.isAddon,
    other:
      !trait.isAddon &&
      trait.traitGroup.name === OTHER_NUMERICAL_PROPERTIES_GROUP,
  });

  return (
    <div
      tabIndex="0"
      className={traitItemClasses}
      onClick={() => {
        dispatch(setSelectedTrait(trait));
      }}
      onKeyDown={({ key }) => {
        if (key === "Enter") dispatch(setSelectedTrait(trait));
      }}
      onMouseEnter={() => setHoverTrait(trait)}
      onMouseLeave={() => setHoverTrait(null)}
    >
      <PropertyName
        showIcon={trait.isAddon}
        technicalName={trait.technical_name}
        highlight={highlight}
      />

      {trait.isAddon && (
        <i
          className="fa fa-trash"
          data-tooltip-variant="warning"
          data-tooltip-id="tooltip"
          data-tooltip-content="Delete integrative trait"
          data-tooltip-place="right"
          onClick={deleteAddonTrait}
        />
      )}
    </div>
  );
};

/* Trait list: displays vertical list of traitItems
 */
export const TraitSelector = () => {
  const [traitFilter, setTraitFilter] = useState("");
  const [hoverTrait, setHoverTrait] = useState(null);

  const traits = useSelector(selectMergedTraits);

  const traitGroups = useTraitGroups(
    traits.filter((trait) =>
      trait.name
        .replaceAll("_", " ")
        .toLowerCase()
        .includes(traitFilter.toLowerCase())
    )
  );

  return (
    <div className="powerdash-component trait-selector">
      <div className="trait-search">
        <Input
          bsSize="sm"
          type="search"
          placeholder={`Traits (${traits.length})`}
          disabled={traits.length === 0}
          value={traitFilter}
          onChange={(event) => setTraitFilter(event.target.value)}
        />
        <i className="fa fa-lg fa-search discrete-icon"></i>
      </div>
      <div className="trait-list">
        {traitGroups.map((group) => (
          <div
            className={`trait-list-group ${
              group.name === OTHER_NUMERICAL_PROPERTIES_GROUP ? "other" : ""
            }`}
            key={group.name}
          >
            <div className="trait-list-group-header">
              <div className="d-flex align-items-center">
                <img src={group.icon ?? DEFAULT_TRAIT_ICON} alt="" />
                <span className="ms-1">{group.name}</span>
              </div>
            </div>
            {group.Traits.sort((a, b) => a.name.localeCompare(b.name)).map(
              (trait) => (
                <TraitItem
                  key={trait.technical_name}
                  trait={trait}
                  setHoverTrait={setHoverTrait}
                  highlight={traitFilter}
                />
              )
            )}
          </div>
        ))}
      </div>
      {hoverTrait &&
        !hoverTrait.isAddon &&
        hoverTrait.traitGroup.name !== OTHER_NUMERICAL_PROPERTIES_GROUP && (
          <div className="trait-display-card-wrapper">
            <TraitDisplayCard trait={hoverTrait} />
          </div>
        )}
    </div>
  );
};
