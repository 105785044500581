import "./ExperimentTools.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import * as L from "leaflet";

import { Badge, Button } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { addSuccessAlert, addWarningAlert } from "../../actions/alerts";
import { connect, useSelector } from "react-redux";
import {
  createClientExperiment,
  deleteClientExperiment,
  updateClientExperiment,
} from "../../actions/experiment";

import { EXPERIMENT_STATUS } from "../../constants";
import ExperimentModal from "./ExperimentModal";
import ExperimentStatusModal from "./ExperimentStatusModal";
import PropTypes from "prop-types";
import mapCoordinates from "geojson-apply-right-hand-rule";
import { updateClientSiteExperimentStatus } from "../../actions/sites";
import { uploadFileToExternalStorage } from "../../services/upload";
import { useDispatch } from "react-redux";

var listBlocks = [];
var IdExperimentsToDelete = [];
var block = {};
var idTimeoutAlert = null;

const ExperimentTools = ({
  map,
  site,
  experiments,
  user,
  linearRulerRef,
  createClientExperiment,
  deleteClientExperiment,
  updateClientExperiment,
  updateClientSiteExperimentStatus,
}) => {
  const dispatch = useDispatch();

  const [editionModeActivated, setEditionModeActivated] = useState(false);
  const [discardDrawPolygon, setDiscardDrawPolygon] = useState(false);
  const [blockFormVisibility, setBlockFormVisibility] = useState(false);
  const [myPolygon, setMyPolygon] = useState(null);
  const [myMarkerO, setMyMarkerO] = useState(null);
  const [blockOfRemovedRepere, setBlockOfRemovedRepere] = useState(null);
  const [removedRepere, setRemovedRepere] = useState(null);
  const [blockSelected, setBlockSelected] = useState(null);
  const [discardChange, setDiscardChange] = useState(false);
  const [isIncompletExperiment, setIsIncompletExperiment] = useState(true);
  const isMount = useRef("false");
  const [openExperimentStatusModal, setOpenExperimentStatusModal] =
    useState(false);

  const features = useSelector(({ resultMap }) => resultMap.features);

  useEffect(() => {
    isMount.current = true;
    // add geomanTool to the map
    listBlocks = [];
    IdExperimentsToDelete = [];
    addGeomanToolToMap(map);
    // draw experiments retreived from database
    drawRetrievedExperiments(experiments);

    return () => {
      isMount.current = false;
      //Deactivate geoman edition mode
      map.pm.disableGlobalEditMode();
      //Remove events
      map.off("pm:drawstart");
      map.off("pm:create");
      //Remove existing experiments (polygons + markers)
      removeLayersDrawn();
      //Hide the toolbar if visible
      if (map.pm.controlsVisible()) map.pm.removeControls();
    };
  }, []);

  useEffect(() => {
    //only used at unmount to destroy ongoing experiment drawing
    return () => {
      if (!isMount.current) eventDiscardDrawPolygon(map, false);
    };
  }, [myPolygon, myMarkerO]);

  useEffect(() => {
    removeLayersDrawn();
    drawRetrievedExperiments(experiments);
  }, [experiments]);

  useEffect(() => {
    if (discardChange) {
      // cancel change and remove the polygon and the marker from the map
      removeLayersDrawn();
      IdExperimentsToDelete = [];
      drawRetrievedExperiments(experiments);
      setDiscardChange(false);
      // turn off edition mode
      setEditionModeActivated(false);
    }
  }, [discardChange]);

  useEffect(() => {
    map.pm.toggleControls();
    // open the pop of all the polygons when we turn off or turn on the edition mode
    listBlocks.forEach((block) => {
      block.polygon.openPopup();
    });
    if (!editionModeActivated) map.pm.disableGlobalEditMode();
  }, [editionModeActivated]);

  // use it to initialize the local block
  const initializeBlock = () => {
    block = {
      properties: {
        block_name: "",
        block_description: "",
        plot_width: "",
        plot_length: "",
        crop_id: "",
        site: site,
        file_key: "",
        file: null,
        uuid: "",
      },
      polygon: null,
      repere: {
        origin: null,
        abscisseX: null,
        ordonneeY: null,
      },
    };
  };

  const drawRetrievedExperiments = (listExperiments) => {
    if (listExperiments !== null) {
      listExperiments.forEach((blockInGeoJson) => {
        initializeBlock();
        let markerO, markerX, markerY;
        blockInGeoJson.features.features.forEach((feature) => {
          switch (feature.id) {
            case "O":
              // draw the marker O and add it to the map
              markerO = drawMarkerFromDatabaseData(
                "#b30000",
                "O",
                feature.geometry.coordinates
              );
              break;
            case "X":
              // draw the marker X and add it to the map
              markerX = drawMarkerFromDatabaseData(
                "#0000b5",
                "X",
                feature.geometry.coordinates
              );
              break;
            case "Y":
              // draw the marker Y and add it to the map
              markerY = drawMarkerFromDatabaseData(
                "#B407AD",
                "Y",
                feature.geometry.coordinates
              );
              break;
            case "Polygon":
              setPolygon(
                drawPolygonFromDataBaseData(
                  feature.geometry.coordinates[0],
                  feature.properties.block_name
                )
              );
              block.properties = feature.properties;
              block.properties.crop_id = blockInGeoJson.crop.id;
              block.properties.site = blockInGeoJson.site;
              block.properties.uuid = blockInGeoJson.id;
              block.properties.file_key = blockInGeoJson.file_key;
              break;
            default:
              console.log(
                "Error in extracting feature geometry type from GeoJson format"
              );
              break;
          }
        });

        // find the position of each marker in his polygon
        let positionOriginInPolygon = getPositionMarkerInPoylgon(
          markerO.getLatLng(),
          block.polygon.getLatLngs()[0]
        );
        let positionAbscisseXInPolygon = getPositionMarkerInPoylgon(
          markerX.getLatLng(),
          block.polygon.getLatLngs()[0]
        );
        let positionOrdonneeYInPolygon = getPositionMarkerInPoylgon(
          markerY.getLatLng(),
          block.polygon.getLatLngs()[0]
        );
        setRepere("O", {
          marker: markerO,
          positionOriginInPolygon: positionOriginInPolygon,
        });
        setRepere("X", {
          marker: markerX,
          positionAbscisseXInPolygon: positionAbscisseXInPolygon,
        });
        setRepere("Y", {
          marker: markerY,
          positionOrdonneeYInPolygon: positionOrdonneeYInPolygon,
        });
        // add the retrieved block to the listblock
        addBlockToList(block);
      });
      initializeBlock();
      // hide the middle vertex of the polygon to limit in 4 vertex in each polygon
      map.pm.setGlobalOptions({
        hideMiddleMarkers: true,
        allowSelfIntersection: false,
      });
    }
  };

  // draw marker and return it
  const drawMarkerFromDatabaseData = (colorMarker, NamePoint, coordinates) => {
    let icon = setMarkerIcon(colorMarker, NamePoint);
    // draw the marker
    let marker = new L.marker([coordinates[1], coordinates[0]], {
      pmIgnore: false,
      icon: icon,
    });
    // add the marker to the map
    marker.addTo(map);
    // add the event of the marker
    editMaker(marker);
    removeMarker(map, marker);
    return marker;
  };

  // draw polygon from db
  const drawPolygonFromDataBaseData = (polygonPoint, experimentName) => {
    let latLngPointsFormat = [];
    for (let i = 0; i < 4; i++) {
      let latlngPoint = [polygonPoint[i][1], polygonPoint[i][0]];
      latLngPointsFormat.push(latlngPoint);
    }
    return drawPolygon(map, latLngPointsFormat, experimentName);
  };

  // remove the polygons and the markers from the map when we cancel change
  const removeLayersDrawn = () => {
    listBlocks.forEach((element) => {
      map.removeLayer(element.polygon);
      map.removeLayer(element.repere.origin.marker);
      map.removeLayer(element.repere.abscisseX.marker);
      map.removeLayer(element.repere.ordonneeY.marker);
    });

    listBlocks = [];
  };

  // cancel the change then we will re-import expirements from database
  const cancelChange = () => {
    if (window.confirm("Do you want to remove all changes ?"))
      setDiscardChange(true);
  };

  const setBlock = (updatedBlock) => {
    block = updatedBlock;
  };

  // add new block to the listblocks
  const addBlockToList = (newBlock) => {
    listBlocks.push(newBlock);
  };

  const setPolygon = (polygon) => {
    block.polygon = polygon;
    setMyPolygon(polygon);
  };

  const setRepere = (nomPoint, infoMarker) => {
    switch (nomPoint) {
      case "O":
        block.repere.origin = infoMarker;
        setMyMarkerO(infoMarker.marker);
        break;
      case "X":
        block.repere.abscisseX = infoMarker;
        setMyMarkerO(null);
        setMyPolygon(null);
        break;
      case "Y":
        block.repere.ordonneeY = infoMarker;
        break;
      default:
        break;
    }
  };

  const removeRepere = () => {
    block.repere = {
      origin: null,
      abscisseX: null,
      ordonneeY: null,
    };
  };

  // toggle the visibility of drawing tool
  const toggleModeEdtion = () => {
    if (editionModeActivated) {
      eventDiscardDrawPolygon(map);
      saveAndExportBlocks(listBlocks);
    }
    setEditionModeActivated(!editionModeActivated);
  };

  // we use it to save end export change
  const saveAndExportBlocks = (listBlocks) => {
    removeAlert();
    IdExperimentsToDelete.forEach(async (idExperiment) => {
      await deleteClientExperiment(idExperiment, site);
    });
    listBlocks.forEach(async (element) => {
      if (element.properties.uuid)
        if (element.properties.file)
          // client modify file of experiment
          uploadFileToExternalStorage(element.properties.file, site, user).then(
            async (response) => {
              element.properties.file_key = response.file_key;
              await updateClientExperiment(blockTGeoJsonFormat(element), site);
            }
          );
        else await updateClientExperiment(blockTGeoJsonFormat(element), site);
      else
        uploadFileToExternalStorage(element.properties.file, site, user).then(
          async (response) => {
            element.properties.file_key = response.file_key;
            await createClientExperiment(site, blockTGeoJsonFormat(element));
          }
        );
    });
    saveExperimentStatus(EXPERIMENT_STATUS.DRAFT);
    manageAlert("Change are successfully saved", false, true);
    IdExperimentsToDelete = [];
  };

  // convert the block's element to GeoJson format
  const blockTGeoJsonFormat = (block) => {
    // convert system point to Geojson
    let OriginInGeoJson = convertPointToGeoJsonFormat(
      "O",
      block.repere.origin.marker.toGeoJSON(14).geometry.coordinates
    );
    let abscisseXInGeoJson = convertPointToGeoJsonFormat(
      "X",
      block.repere.abscisseX.marker.toGeoJSON(14).geometry.coordinates
    );
    let ordonneYInGeoJson = convertPointToGeoJsonFormat(
      "Y",
      block.repere.ordonneeY.marker.toGeoJSON(14).geometry.coordinates
    );

    let geoJsonFormat = {
      crop_id: parseInt(block.properties.crop_id, 10),
      site: site,
      file_key: block.properties.file_key,
      features: {
        type: "FeatureCollection",
        features: [
          OriginInGeoJson,
          abscisseXInGeoJson,
          ordonneYInGeoJson,
          polygonToGeoJson(block.polygon, block.properties),
        ],
      },
    };
    // retrun block in Geojson format
    if (block.properties.uuid) {
      geoJsonFormat.id = block.properties.uuid;
      return geoJsonFormat;
    }
    return geoJsonFormat;
  };

  // convert polygon to Geojson and add block's properties to it
  const polygonToGeoJson = (polygon, blockProperties) => {
    let poylgonToGeoJson = polygon.toGeoJSON(14);
    let polygonGeometry = poylgonToGeoJson.geometry;
    poylgonToGeoJson.geometry = mapCoordinates(polygonGeometry);
    poylgonToGeoJson.properties = {
      block_name: blockProperties.block_name,
      block_description: blockProperties.block_description,
      plot_width: blockProperties.plot_width,
      plot_length: blockProperties.plot_length,
    };
    poylgonToGeoJson.id = "Polygon";
    return poylgonToGeoJson;
  };

  // convert marker to GeoJson Format
  const convertPointToGeoJsonFormat = (nomPointRepere, GeoJsonCoorinates) => {
    return {
      type: "Feature",
      id: nomPointRepere,
      geometry: {
        type: "Point",
        coordinates: GeoJsonCoorinates,
      },
      properties: {},
    };
  };

  // add drawing toot to the map
  const addGeomanToolToMap = (map) => {
    map.pm.addControls({
      position: "topright",
      drawCircle: false,
      cutPolygon: false,
      drawCircleMarker: false,
      drawRectangle: false,
      drawPolyline: false,
      drawMarker: false,
      rotateMode: false,
      drawText: false,
    });

    const customTranslation = {
      tooltips: {
        placeMarker: "Click to place marker",
        firstVertex: "Click to place first vertex",
        continueLine: "Click to continue drawing",
        finishPoly: "Click to continue drawing",
      },
    };

    map.pm.setLang("customName", customTranslation, "en");

    // hide the middle Markers in edit mode from Polygon.
    map.pm.setGlobalOptions({
      hideMiddleMarkers: true,
      allowSelfIntersection: false,
    });
    // when we activate drawing polygon
    eventDrawPolygon(map);
    eventCreateLayer(map);

    return null;
  };

  const showButtonDiscardDrawPolygon = () => {
    setDiscardDrawPolygon(true);
  };

  const hideButtonDiscardDrawPolygon = () => {
    setDiscardDrawPolygon(false);
  };

  // event when we cancel draw the expirement
  const eventDiscardDrawPolygon = (map, keepVisibleTools = true) => {
    // disable draw mode
    map.pm.disableDraw();

    if (myMarkerO) {
      map.removeLayer(myMarkerO);
      setMyMarkerO(null);
    }
    //remove layer of this block from map
    if (myPolygon)
      if (blockOfRemovedRepere !== null) {
        let lastBlockState = blockOfRemovedRepere;
        lastBlockState.repere = removedRepere;
        removedRepere.origin.marker.addTo(map);
        removedRepere.abscisseX.marker.addTo(map);
        removedRepere.ordonneeY.marker.addTo(map);
        map.pm.disableGlobalRemovalMode();
        addBlockToList(lastBlockState);
        hideButtonDiscardDrawPolygon();
        enableControlVisibility(map);
        initializeBlock();
        setBlockOfRemovedRepere(null);
        setRemovedRepere(null);
      } else {
        map.removeLayer(myPolygon);
        setMyPolygon(null);
      }

    removeAlert();
    if (discardDrawPolygon) setDiscardDrawPolygon(!discardDrawPolygon);

    if (keepVisibleTools) enableControlVisibility(map);

    initializeBlock();
  };

  // event draw polygon
  const eventDrawPolygon = (map) => {
    map.on("pm:drawstart", ({ workingLayer }) => {
      // will be useful when we draw repere after we deleted it
      disableControlVisibility(map);
      showButtonDiscardDrawPolygon();
      let numberOfVertex = 1;
      let polygonPoints = [];
      let polygonDrawed;

      workingLayer.on("pm:vertexadded", (e) => {
        polygonPoints.push(Object.values(e.latlng));

        if (numberOfVertex === 4) {
          initializeBlock();
          map.pm.disableDraw();
          polygonDrawed = drawPolygon(map, polygonPoints, "");
          setPolygon(polygonDrawed);
          map.pm.setGlobalOptions({ snappable: true });
          manageAlert("Please choose the origin O.", false, true);
          enableDrawMarker(map, "#b30000", "O");
        }
        numberOfVertex += 1;
      });
    });
  };

  // draw polygon and add it to the map
  const drawPolygon = (map, polygonPoints, nomBlock) => {
    // create the polygon
    let polygonDrawed = L.polygon(polygonPoints).addTo(map);
    // add event click and event mouseover to the polygon
    polygonDrawed.on("click", polygonClicked);
    polygonDrawed.on("mouseover", mouseOverOnPolygon);

    let p = new L.Popup({
      closeOnClick: false,
      autoClose: false,
      autoPan: false,
      closeButton: false,
      className: "custom-popup",
    });
    polygonDrawed.bindPopup(p);
    if (nomBlock !== "") {
      p.setContent(nomBlock);
      polygonDrawed.openPopup();
    }
    //add event edit and remove polygon
    editPolygon(polygonDrawed);
    removePolygon(map, polygonDrawed);

    return polygonDrawed;
  };

  const editPolygon = (myPolygon) => {
    // launched when we are editing the polygon
    myPolygon.on("pm:edit", ({ layer }) => {
      myPolygon.closePopup();
      removeAlert();
      let polygonPointsLatLng = layer.getLatLngs()[0];
      // change the marker postion
      listBlocks.forEach((element) => {
        if (element.polygon._leaflet_id === layer._leaflet_id) {
          let positionOrigin = element.repere.origin.positionOriginInPolygon;
          let positionX = element.repere.abscisseX.positionAbscisseXInPolygon;
          let positionY = element.repere.ordonneeY.positionOrdonneeYInPolygon;
          let newCoordsOrigin = polygonPointsLatLng[positionOrigin];
          let newCoordsX = polygonPointsLatLng[positionX];
          let newCoordsY = polygonPointsLatLng[positionY];

          if (element.repere.origin.marker.getLatLng() !== newCoordsOrigin)
            element.repere.origin.marker.setLatLng(newCoordsOrigin);

          if (element.repere.abscisseX.marker.getLatLng() !== newCoordsX)
            element.repere.abscisseX.marker.setLatLng(newCoordsX);

          if (
            element.repere.ordonneeY.marker.getLatLng().lat !==
              newCoordsY.lat ||
            element.repere.ordonneeY.marker.getLatLng().lng !== newCoordsY.lng
          )
            element.repere.ordonneeY.marker.setLatLng(newCoordsY);
        }
      });
    });
  };

  const removeExperimentLayers = (idPolygon) => {
    let indexExperiment = listBlocks.findIndex(
      (e) => e.polygon._leaflet_id === idPolygon
    );
    if (indexExperiment != -1) {
      map.removeLayer(listBlocks[indexExperiment].repere.origin.marker);
      map.removeLayer(listBlocks[indexExperiment].repere.abscisseX.marker);
      map.removeLayer(listBlocks[indexExperiment].repere.ordonneeY.marker);
      map.removeLayer(listBlocks[indexExperiment].polygon);
      listBlocks.splice(indexExperiment, 1);
    }
    setIsIncompletExperiment(true);
  };

  const removePolygon = (map, polygon) => {
    // launched when we remove the polygon
    polygon.on("pm:remove", ({ layer }) => {
      let confirmation = window.confirm("Do you want to delete the plot map ?");
      if (confirmation) {
        let idPolygon = layer._leaflet_id;
        let indexExperiment = listBlocks.findIndex(
          (e) => e.polygon._leaflet_id === idPolygon
        );
        if (indexExperiment != -1) {
          map.removeLayer(listBlocks[indexExperiment].repere.origin.marker);
          map.removeLayer(listBlocks[indexExperiment].repere.abscisseX.marker);
          map.removeLayer(listBlocks[indexExperiment].repere.ordonneeY.marker);
          if (listBlocks[indexExperiment].properties.uuid)
            IdExperimentsToDelete.push(
              listBlocks[indexExperiment].properties.uuid
            );

          listBlocks.splice(indexExperiment, 1);
          manageAlert("The plot map has been successfully deleted", true, true);
        }
      } else {
        layer.addTo(map);
      }
    });
  };

  // launched when we click on the polygon
  const polygonClicked = (e) => {
    let popup = e.target.getPopup();
    e.target.closePopup();
    e.target.unbindPopup();
    e.target.bindPopup(popup);

    if (
      !map.pm.globalDrawModeEnabled() &&
      !map.pm.globalEditModeEnabled() &&
      !map.pm.globalRemovalModeEnabled() &&
      !map.pm.globalDragModeEnabled() &&
      !linearRulerRef?.isActive()
    ) {
      setBlockSelected(
        listBlocks.find(
          (block) => block.polygon._leaflet_id === e.target._leaflet_id
        )
      );
      setIsIncompletExperiment(false);
      setBlockFormVisibility(true);
    }
  };

  // launched when we pass the mouse enters the polygon
  const mouseOverOnPolygon = (e) => {
    let polygonFounded = listBlocks.find(
      (block) => block.polygon._leaflet_id === e.target._leaflet_id
    );
    if (
      polygonFounded &&
      !map.pm.globalEditModeEnabled() &&
      !linearRulerRef?.isActive()
    )
      polygonFounded.polygon.openPopup();
  };

  // draw the marker
  const enableDrawMarker = (map, colorMarker, nomMarker) => {
    map.pm.enableDraw("Marker", {
      snappable: true,
      snapDistance: 100,
      markerStyle: {
        icon: setMarkerIcon(colorMarker, nomMarker),
        draggable: true,
      },
    });
  };

  // marker icon
  const setMarkerIcon = (color, nomMarker) => {
    let myIcon = L.divIcon({
      className: "custom-div-icon",
      html:
        "<div style='background-color:" +
        color +
        ";' class='marker-pin'><h1 class='nomMarker'>" +
        nomMarker +
        "</h1></div>",
      iconSize: [30, 42],
      iconAnchor: [15, 42],
      popupAnchor: [0, -20],
    });

    return myIcon;
  };

  // draw the repere
  const eventCreateLayer = (map) => {
    map.on("pm:create", (e) => {
      if (e.shape === "Marker") {
        if (block.repere.origin === null) {
          removeAlert();
          let positionOrigin = getPositionMarkerInPoylgon(
            e.layer.getLatLng(),
            block.polygon.getLatLngs()[0]
          );
          if (positionOrigin >= 0) {
            setRepere("O", {
              marker: e.marker,
              positionOriginInPolygon: positionOrigin,
            });
            // add Pop up to the marker
            map.pm.disableDraw("Marker");
            //add event's marker
            removeMarker(map, e.marker);
            editMaker(e.marker);
            idTimeoutAlert = null;
            // draw the marker X
            manageAlert(
              "Please choose the abscisse X an angle of the plot map adjacent to the origin marker.",
              false,
              true
            );
            enableDrawMarker(map, "#0000b5", "X");
          } else {
            map.removeLayer(e.layer);
            manageAlert(
              "Invalid marker position. Please choose an angle of the plot map drawn."
            );
          }
        } else if (block.repere.abscisseX === null) {
          removeAlert();
          // Validate the marker X position
          let positionOrigin = block.repere.origin.positionOriginInPolygon;
          let positionAbscisseX = getPositionMarkerInPoylgon(
            e.layer.getLatLng(),
            block.polygon.getLatLngs()[0]
          );
          let validation = validateTheMarkerXPosition(
            positionOrigin,
            positionAbscisseX
          );

          if (validation) {
            setRepere("X", {
              marker: e.marker,
              positionAbscisseXInPolygon: positionAbscisseX,
            });
            // add Pop up to the marker
            map.pm.disableDraw("Marker");
            removeMarker(map, e.marker);
            editMaker(e.marker);

            // make all layers not snappable during draw
            map.pm.setGlobalOptions({ snappable: false });

            // draw marker Y automatically
            let positionO = block.repere.origin.positionOriginInPolygon;
            let positionX = block.repere.abscisseX.positionAbscisseXInPolygon;
            let positionY = defineMarkerYPostion(positionO, positionX);
            let coordMarkerY = block.polygon.getLatLngs()[0][positionY];

            // define the Icon of marker Y
            let iconY = setMarkerIcon(" #B407AD", "Y");
            // draw the marker Y
            let markerY = new L.marker([coordMarkerY.lat, coordMarkerY.lng], {
              pmIgnore: false,
              icon: iconY,
            });
            L.PM.reInitLayer(markerY);
            markerY.addTo(map);

            editMaker(markerY);
            removeMarker(map, markerY);

            setRepere("Y", {
              marker: markerY,
              positionOrdonneeYInPolygon: positionY,
            });
            addBlockToList(block);
            setBlockSelected(block);
            hideButtonDiscardDrawPolygon();
            enableControlVisibility(map);
            // active when we draw first time
            if (block.properties.block_name === "") {
              setIsIncompletExperiment(true);
              setBlockFormVisibility(true);
            }
            initializeBlock();
            setBlockOfRemovedRepere(null);
          } else {
            map.removeLayer(e.layer);
            manageAlert(
              "Invalid marker position. Please choose an angle adjacent to the origin marker of the plot map drawn."
            );
          }
        } else {
          map.pm.disableDraw("Marker");
        }
      } else if (e.shape === "Polygon" && e.layer.getLatLngs()[0].length < 4) {
        manageAlert("Polygon must have exactly 4 vertex.", true, false);
        map.removeLayer(e.layer);
        hideButtonDiscardDrawPolygon();
        enableControlVisibility(map);
      }
    });
  };

  const disableControlVisibility = (map) => {
    if (map.pm.controlsVisible()) map.pm.toggleControls();
  };

  const enableControlVisibility = (map) => {
    if (!map.pm.controlsVisible()) map.pm.toggleControls();
  };

  const removeAlert = () => {
    if (idTimeoutAlert) {
      clearTimeout(idTimeoutAlert);
      idTimeoutAlert = null;
    }
  };

  // add the error or the success alert
  const manageAlert = (msg, clearAll = false, isSuccessAlert = false) => {
    if (clearAll) removeAlert();

    if (isSuccessAlert) dispatch(addSuccessAlert(msg));
    else dispatch(addWarningAlert(msg));
  };

  // launched when we remove the marker
  const removeMarker = (map, marker) => {
    marker.on("pm:remove", ({ layer }) => {
      // When we remove the marker we will remove his repere and forced the client to redraw the polygon's repere
      if (window.confirm("Do you want to remove the plot map orientation ?")) {
        let idSelectedMarker = marker._leaflet_id;
        listBlocks.forEach((element, index) => {
          let markerOrigin = element.repere.origin.marker,
            idOrigin = markerOrigin._leaflet_id;
          let markerX = element.repere.abscisseX.marker,
            idAbscisseX = markerX._leaflet_id;
          let markerY = element.repere.ordonneeY.marker,
            idOrdonneeY = markerY._leaflet_id;

          if (
            idSelectedMarker === idOrigin ||
            idSelectedMarker === idAbscisseX ||
            idSelectedMarker === idOrdonneeY
          ) {
            setBlockOfRemovedRepere(element);
            setRemovedRepere(element.repere);
            map.removeLayer(markerOrigin);
            map.removeLayer(markerX);
            map.removeLayer(markerY);
            setBlock(element);
            setMyPolygon(element.polygon);
            removeRepere();
            listBlocks.splice(index, 1);
            removeAlert();
            manageAlert(
              "Please choose the origin O of the plot map.",
              false,
              true
            );
            enableDrawMarker(map, "#a80a02", "O");
          }
        });
      } else {
        layer.addTo(map);
      }
    });
  };

  // modify the marker position
  const editMaker = (marker) => {
    marker.on("pm:edit", ({ layer }) => {
      let idMarkerSelected = layer._leaflet_id,
        positionMarkeurSelectionne,
        polygonePointsLatLng,
        indexBlockOfTheMarker;
      listBlocks.forEach((element, index) => {
        let idOrigin = element.repere.origin.marker._leaflet_id;
        let idAbscisseX = element.repere.abscisseX.marker._leaflet_id;
        let idOrdonneeY = element.repere.ordonneeY.marker._leaflet_id;

        if (idOrigin === idMarkerSelected) {
          positionMarkeurSelectionne =
            element.repere.origin.positionOriginInPolygon;
          polygonePointsLatLng = element.polygon.getLatLngs()[0];
          indexBlockOfTheMarker = index;
        } else if (idAbscisseX === idMarkerSelected) {
          positionMarkeurSelectionne =
            element.repere.abscisseX.positionAbscisseXInPolygon;
          polygonePointsLatLng = element.polygon.getLatLngs()[0];
          indexBlockOfTheMarker = index;
        } else if (idOrdonneeY === idMarkerSelected) {
          positionMarkeurSelectionne =
            element.repere.ordonneeY.positionOrdonneeYInPolygon;
          polygonePointsLatLng = element.polygon.getLatLngs()[0];
          indexBlockOfTheMarker = index;
        }
      });

      polygonePointsLatLng[positionMarkeurSelectionne] = {
        lat: layer.getLatLng().lat,
        lng: layer.getLatLng().lng,
      };

      listBlocks[indexBlockOfTheMarker].polygon.setLatLngs(
        polygonePointsLatLng
      );
      listBlocks[indexBlockOfTheMarker].polygon.closePopup();
      map.pm.enableGlobalEditMode();
    });
  };

  // get the position of the marker in his polygon
  const getPositionMarkerInPoylgon = (point, polygonCorners) => {
    return polygonCorners.findIndex(
      (e) => e.lat === point.lat && e.lng === point.lng
    );
  };

  // validate the position of the marker X
  const validateTheMarkerXPosition = (positionOrigin, positionAbscisseX) => {
    let validation = false;
    if (positionAbscisseX >= 0)
      if (positionOrigin === 3)
        validation =
          positionAbscisseX === 0 || positionAbscisseX === 2 ? true : false;
      else if (positionOrigin === 0)
        validation =
          positionAbscisseX === 3 || positionAbscisseX === 1 ? true : false;
      else
        validation =
          positionAbscisseX === positionOrigin + 1 ||
          positionAbscisseX === positionOrigin - 1
            ? true
            : false;

    return validation;
  };

  // we draw automatically the marker Y so we get his postion in the polygon and to draw our marker Y
  const defineMarkerYPostion = (positionOrigin, positionAbscisseX) => {
    let positionAbscisseY;
    if (positionOrigin === 3)
      positionAbscisseY = positionAbscisseX === 0 ? 2 : 0;
    else if (positionOrigin === 0)
      positionAbscisseY = positionAbscisseX === 3 ? 1 : 3;
    else
      positionAbscisseY =
        positionAbscisseX === positionOrigin + 1
          ? positionOrigin - 1
          : positionOrigin + 1;

    return positionAbscisseY;
  };

  const saveExperimentStatus = (status) => {
    site.experiment_status = status;
    updateClientSiteExperimentStatus(site);
  };

  return (
    <div
      className={`powerdash-component experiment-tools ${
        editionModeActivated ? "active" : ""
      } d-flex flex-column gap-1`}
    >
      <div className="d-flex flex-row gap-1 align-items-center justify-content-between">
        <div className="d-flex flex-row gap-1 align-items-center">
          <i className="fa-solid fa-lg fa-object-group" />
          <span>Plot Map</span>
        </div>
        {editionModeActivated ? (
          <span>
            <i className="fa-solid fa-pen-to-square" /> Editing
          </span>
        ) : (
          site.experiment_status > EXPERIMENT_STATUS.NOT_STARTED &&
          site.experiment_status < EXPERIMENT_STATUS.SYNCHRONIZED && (
            <div
              className="d-flex flex-row gap-1 align-items-center clickable"
              onClick={() => setOpenExperimentStatusModal(true)}
            >
              Status
              <Badge
                className={`p-1 ${
                  EXPERIMENT_STATUS.properties[site.experiment_status].color
                }`}
              >
                {EXPERIMENT_STATUS.properties[site.experiment_status].label}{" "}
              </Badge>
              <i className="fa-solid fa-pen" />
            </div>
          )
        )}
      </div>
      <div className="d-flex flex-row gap-1 align-items-center justify-content-end">
        {editionModeActivated && !discardDrawPolygon && (
          <>
            <Button
              size="sm"
              onClick={() => {
                cancelChange();
              }}
            >
              <i className="fa-solid fa-undo" />
            </Button>
            <Button
              size="sm"
              color="success"
              onClick={() => saveAndExportBlocks(listBlocks)}
            >
              <i className="fa-solid fa-save" />
            </Button>
          </>
        )}
        {discardDrawPolygon && (
          <div>
            <Button size="sm" onClick={() => eventDiscardDrawPolygon(map)}>
              Cancel
            </Button>
          </div>
        )}
        {site.experiment_status < EXPERIMENT_STATUS.SYNCHRONIZED &&
          !features.length && (
            <Button
              className="hiphen-green-button"
              size="sm"
              onClick={() => toggleModeEdtion()}
            >
              {editionModeActivated ? (
                <span>
                  Save <b>&amp;</b> Exit
                </span>
              ) : (
                <span>
                  Draw plot map <i className="fa-solid fa-pen-to-square" />
                </span>
              )}
            </Button>
          )}
      </div>
      <ExperimentModal
        setBlockFormVisibility={setBlockFormVisibility}
        blockSelected={blockSelected}
        blockFormVisibility={blockFormVisibility}
        editionModeActivated={editionModeActivated}
        setEditionModeActivated={setEditionModeActivated}
        listBlocks={listBlocks}
        isIncompletExperiment={isIncompletExperiment}
        removeExperimentLayers={removeExperimentLayers}
        experimentStatus={site.experiment_status}
      />
      <ExperimentStatusModal
        openExperimentStatusModal={openExperimentStatusModal}
        setOpenExperimentStatusModal={setOpenExperimentStatusModal}
        saveExperimentStatus={saveExperimentStatus}
      />
    </div>
  );
};

function mapStateToProps(store) {
  return {
    site: store.resultMap.trial,
    experiments: store.resultMap.experiments,
    user: store.user,
  };
}

const callbacks = {
  createClientExperiment,
  updateClientSiteExperimentStatus,
  deleteClientExperiment,
  updateClientExperiment,
};

export default connect(mapStateToProps, callbacks)(ExperimentTools);

ExperimentTools.propTypes = {
  site: PropTypes.object.isRequired,
  experiments: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  createClientExperiment: PropTypes.func.isRequired,
  updateClientSiteExperimentStatus: PropTypes.func.isRequired,
  deleteClientExperiment: PropTypes.func.isRequired,
  updateClientExperiment: PropTypes.func.isRequired,
};
