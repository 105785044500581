import "./Timeline.css";

import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import ReactSlider from "react-slider";
import { fetchResultMapFeatures } from "../../../../actions/resultMap";
import { formatDate } from "../../../utils";
import moment from "moment";

/* Timeline is a custom react slider to display horizontally every available date (flight)
 * Select the date to display by moving the thumb on the line (similar to any video player slider)
 * marks are evenly placed for every available flight
 * can be aucmode: two thumbs for a range
 */
export const Timeline = ({
  dates,
  selectedDate,
  aucDatesIndexes,
  setAucDatesIndexes,
  aucModeChecked,
}) => {
  const dispatch = useDispatch();
  const trial = useSelector(({ resultMap }) => resultMap.trial);

  return (
    <div className="powerdash-component timeline overflowing">
      <div className="powerdash-component-overflowing">
        <div className="timeline-slider">
          {dates.map((date, index) => (
            <div
              key={date}
              data-tooltip-id="tooltip"
              data-tooltip-content={formatDate(date)}
              className={`date-mark ${date === selectedDate ? "active" : ""} ${
                aucModeChecked && aucDatesIndexes.includes(index)
                  ? "auc-selected"
                  : ""
              }`}
              onClick={() =>
                dispatch(fetchResultMapFeatures(trial, date, false))
              }
            >
              <span>{moment(date).format("MMM")}</span>
              <span>{moment(date).format("DD")}</span>
            </div>
          ))}
        </div>
        {aucModeChecked && dates.length > 1 && (
          <div className="timeline-slider-auc-wrapper">
            <div className="timeline-slider-auc">
              <ReactSlider
                value={aucDatesIndexes}
                min={0}
                minDistance={1}
                max={dates.length - 1}
                onChange={(val) => setAucDatesIndexes(val)}
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                marks={[...Array(dates?.length).keys()]}
                markClassName="date-mark"
                pearling
                renderMark={(props) => <div {...props}></div>}
                renderThumb={(props) => <div {...props}></div>}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Timeline.propTypes = {
  dates: PropTypes.array.isRequired,
  aucModeChecked: PropTypes.bool,
  aucDatesIndexes: PropTypes.array,
  setAucDatesIndexes: PropTypes.func,
  selectedDate: PropTypes.string,
};
