import PropTypes from "prop-types";
import React from "react";

const Placeholder = ({ children, message }) => (
  <div className="section-placeholder text-center">
    {children}
    <span className="h4">{message}</span>
  </div>
);

Placeholder.propTypes = {
  children: PropTypes.element,
  message: PropTypes.string
};

Placeholder.defaultProps = {
  children: null,
  message: "Loading..."
};

export default Placeholder;
