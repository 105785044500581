import { requestFetchMissionsMonitoring } from "../services/backendRequests";

export async function fetchMissionsMonitoring(contractUuids, user) {
  if (contractUuids.length === 0) return [];
  const baseMissionMonitorings = await requestFetchMissionsMonitoring(
    contractUuids,
    user
  );

  return baseMissionMonitorings.map((missionMonitoring) => {
    return {
      ...missionMonitoring,
      groups: {
        campaign: missionMonitoring.contract_info.id,
        country: missionMonitoring.site.country,
        crop: missionMonitoring.crop,
        sensor_type: missionMonitoring.sensor_type,
        ...missionMonitoring.custom_data,
      },
    };
  });
}
