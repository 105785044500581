import { SET_SYSTEMS } from "../actions/actionTypes";

const initialState = {
  all: []
};

export default function contracts(state = initialState, action) {
  switch (action.type) {
    case SET_SYSTEMS:
      return {
        ...state,
        all: action.systems
      };
    default:
      return state;
  }
}
