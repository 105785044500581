import { AutoLinkNode, LinkNode } from "@lexical/link";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";

import { CodeNode } from "@lexical/code";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { ImageNode } from "../richTextEditor/ImageNode";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import PropTypes from "prop-types";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { useMemo } from "react";

export function RichTextEditor({ defaultEditorState }) {
  const initialConfig = useMemo(
    () => ({
      namespace: "RichTextEditor",
      onError: (error) => console.error(error),
      editable: false,
      editorState: defaultEditorState
        ? JSON.stringify(defaultEditorState)
        : null,
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        AutoLinkNode,
        LinkNode,
        ImageNode,
      ],
    }),
    [defaultEditorState]
  );
  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={
          <div className="editor-container">
            <ContentEditable />
          </div>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
    </LexicalComposer>
  );
}
RichTextEditor.propTypes = {
  defaultEditorState: PropTypes.object,
};
