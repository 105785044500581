import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import React, { useEffect } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryZoomContainer,
} from "victory";

export default function ExperimentChartModal({
  fileUploadedInfo,
  dataMicroPlotToShow,
  chartFormVisibility,
  setChartFormVisibility,
  setChartIsDrawing,
}) {
  useEffect(() => {
    setChartIsDrawing(false);
  }, []);

  const getEntireDomain = () => {
    return {
      x: [fileUploadedInfo.min_X - 0.1, fileUploadedInfo.max_X + 2],
      y: [fileUploadedInfo.min_Y - 1, fileUploadedInfo.max_Y + 5],
    };
  };

  return (
    <div>
      <Modal isOpen={chartFormVisibility} className="modal-xl">
        <ModalHeader>Please check your buffers position</ModalHeader>
        <ModalBody>
          <Row>
            <Col xl="8">
              <Row>
                <Col xs="5" className="text-end">
                  <label className="col-form-label fw-bold">File name</label>
                </Col>
                <Col xs="7">
                  <label className="col-form-label">
                    {fileUploadedInfo.file_Name}
                  </label>
                </Col>
              </Row>
              <Row>
                <Col xs="5" className="text-end">
                  <label className="col-form-label fw-bold">
                    Number of plots to process
                  </label>
                </Col>
                <Col xs="7">
                  <label className="col-form-label">
                    {fileUploadedInfo.number_microplot_to_process}
                  </label>
                </Col>
              </Row>
              <Row>
                <Col xs="5" className="text-end">
                  <label className="col-form-label fw-bold">
                    Number of buffers
                  </label>
                </Col>
                <Col xs="7">
                  <label className="col-form-label">
                    {fileUploadedInfo.number_buffer}
                  </label>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="5" className="text-end">
                  <label className="col-form-label fw-bold">
                    Total of plots
                  </label>
                </Col>
                <Col xs="7">
                  <label className="col-form-label">
                    {fileUploadedInfo.number_microplot_to_process +
                      fileUploadedInfo.number_buffer}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col xs="4">
              <Row>
                <Col>
                  <div id="carre" className="red" />
                  <h6 style={{ display: "inline-block" }}>Buffer</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div id="carre" className="green" />
                  <h6 style={{ display: "inline-block" }}>External plot</h6>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <VictoryChart
                domain={getEntireDomain()}
                theme={VictoryTheme.material}
                width={1000}
                height={400}
                padding={{ top: 10, bottom: 50, left: 80, right: 50 }}
                containerComponent={
                  <VictoryZoomContainer
                    allowZoom={
                      fileUploadedInfo.max_X > 40 || fileUploadedInfo.max_Y > 40
                        ? true
                        : false
                    }
                    minimumZoom={{ x: 40, y: 40 }}
                  />
                }
              >
                <VictoryAxis
                  label="X Axis"
                  style={{
                    axisLabel: { fontSize: 20, padding: 30 },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  label="Y Axis"
                  style={{
                    axisLabel: { fontSize: 20, padding: 30 },
                  }}
                />
                <VictoryScatter
                  labelComponent={<VictoryTooltip />}
                  size={3}
                  data={dataMicroPlotToShow}
                  style={{
                    data: {
                      fill: ({ datum }) => datum.fill,
                    },
                  }}
                />
              </VictoryChart>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="btn"
            type="submit"
            form="userForm"
            onClick={() => {
              setChartIsDrawing(false);
              setChartFormVisibility(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
