import "./filterSection.css";

import {
  MISSION_MONITORING_STATIC_GROUPS,
  NULL_GROUP_LABEL,
} from "../../../constants";
import { capitalize, humanize } from "../../../utils";

import { Badge } from "reactstrap";
import { ComponentTitle } from "../../../components/componentTitle/componentTitle";
import { Hr } from "../../../../components/Hr";
import PropTypes from "prop-types";
import clearFiltersIcon from "../../../../static/img/clear-filters.png";
import { useState } from "react";

const GroupMaker = ({
  group,
  values,
  groupValues,
  addGroupValue,
  removeGroupValue,
  contracts,
  addAllGroupValues,
  removeAllGroupValues,
}) => {
  const groupLength = groupValues.length;
  const allValuesSelected = groupLength === values.length;
  return (
    <div className="group-maker">
      <ComponentTitle
        title={humanize(capitalize(group))}
        topRightContent={<small>{`${groupLength}/${values.length}`}</small>}
      />
      <Hr />
      <div className="groups">
        <Badge
          pill
          className={`hiphen-badge all clickable ${
            allValuesSelected ? "active" : ""
          }`}
          onClick={() =>
            allValuesSelected
              ? removeAllGroupValues(group)
              : addAllGroupValues(group)
          }
        >
          <i className="fa fa-check" />
        </Badge>
        {values.map((value) => {
          // Verify for each value if it is active or not
          // Either included in corresponding group or not
          const active = groupValues.includes(value);
          const label =
            group === MISSION_MONITORING_STATIC_GROUPS.CONTRACT
              ? contracts.find(({ id }) => id === value)?.name
              : value ?? NULL_GROUP_LABEL;
          return (
            <Badge
              pill
              key={value}
              className={`hiphen-badge clickable ${active ? "active" : ""}`}
              onClick={() =>
                active
                  ? removeGroupValue(group, value)
                  : addGroupValue(group, value)
              }
            >
              {label}
            </Badge>
          );
        })}
      </div>
    </div>
  );
};

export const FilterSection = ({
  groups,
  groupOptions,
  groupingObject,
  addGroupValue,
  removeGroupValue,
  clearGroups,
  contracts,
  addAllGroupValues,
  removeAllGroupValues,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="powerdash-component monitoring-filter-section">
      <ComponentTitle
        title="Group by"
        icon="fa-cubes"
        topRightContent={
          <div className="group-makers-compact">
            {!expanded &&
              Object.entries(groupingObject)
                .filter(([, values]) => values.length > 0)
                .map(([group, values]) => {
                  return (
                    <Badge className="group-makers-compact-badge" key={group}>
                      <span>{humanize(capitalize(group))}</span>
                      <small>{values.length}</small>
                    </Badge>
                  );
                })}
            <Badge
              className="clear-groups clickable"
              data-tooltip-id="tooltip"
              data-tooltip-content="Clear groups"
              data-tooltip-variant="dark"
              onClick={(event) => {
                event.stopPropagation();
                clearGroups();
              }}
            >
              {expanded && <>Clear groups</>}
              <img src={clearFiltersIcon} alt="clear groups" />
            </Badge>
            <i
              className={`fa fa-chevron-down discrete-icon ${
                expanded ? "expanded-icon" : ""
              }`}
            />
          </div>
        }
        onClick={() => {
          setExpanded(!expanded);
        }}
      />
      {expanded && (
        <div className="group-makers">
          {groups.map((group) => (
            <GroupMaker
              key={group}
              group={group}
              values={groupOptions[group]}
              groupValues={groupingObject[group] ?? []}
              addGroupValue={addGroupValue}
              removeGroupValue={removeGroupValue}
              contracts={contracts}
              addAllGroupValues={addAllGroupValues}
              removeAllGroupValues={removeAllGroupValues}
            />
          ))}
        </div>
      )}
    </div>
  );
};

FilterSection.propTypes = {
  groupingObject: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  groupOptions: PropTypes.object.isRequired,
  addGroupValue: PropTypes.func.isRequired,
  removeGroupValue: PropTypes.func.isRequired,
  clearGroups: PropTypes.func.isRequired,
  contracts: PropTypes.array.isRequired,
  addAllGroupValues: PropTypes.func.isRequired,
  removeAllGroupValues: PropTypes.func.isRequired,
};

GroupMaker.propTypes = {
  group: PropTypes.string.isRequired,
  groupValues: PropTypes.array.isRequired,
  values: PropTypes.array,
  addGroupValue: PropTypes.func.isRequired,
  removeGroupValue: PropTypes.func.isRequired,
};
