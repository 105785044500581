import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Col, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { datasetToTitleCase, humanFileSize } from "../services/utils";
import {
  deleteUploadSession,
  fetchUploadSession,
} from "../services/backendRequests";
import { fixHeaders, rows } from "../components/dataTable";

import { FILE_TYPE_LABEL } from "../constants";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { hasAdminAccess } from "../services/roles";
import { hasBackofficeAccess } from "../services/roles";
import moment from "moment";
import { useHistory } from "react-router-dom";

export const statTable = (stat) => {
  return (
    <Row>
      <Col xs="12" className="px-3">
        <Table striped className="mb-0">
          {fixHeaders("Category", "Count", "Size", "Updated at")}
          <tbody>
            {_.sortBy(stat, ["updated_at"]).map((stat) => (
              <React.Fragment key={stat.category}>
                {rows(
                  datasetToTitleCase(
                    FILE_TYPE_LABEL[stat.category] ?? stat.category
                  ),
                  stat.count,
                  humanFileSize(stat.size),
                  moment(stat.updated_at).format("YYYY-MM-DD HH:mm:ss")
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

const UploadSessionModal = (props) => {
  const history = useHistory();
  const [us, setUS] = useState(props.us);
  const [error, setError] = useState();
  const handleKeyEsc = (e) => {
    if (e.keyCode === 27) props.closeModal();
  };

  useEffect(() => {
    if (props.us)
      fetchUploadSession(props.us, props.user).then((e) => {
        setUS(e);
        document.addEventListener("keydown", handleKeyEsc, false);
      });

    return () => {
      document.removeEventListener("keydown", handleKeyEsc, false);
    };
  }, [props.us]);

  const handleDeleteUploadSession = (us, user) => {
    if (window.confirm("Do you really want to delete this upload session ?"))
      deleteUploadSession(us, user)
        .then(() => props.closeModal())
        .catch((err) => setError(err));
  };

  if (!us) return "";

  const AttrRow = (props) => {
    return (
      <Row className="mb-1">
        <Col xs="3">
          <label className="col-form-label ps-4">{props.label}</label>
        </Col>
        <Col xs="9">
          <div className="col-form-label ps-1">{props.value}</div>
        </Col>
      </Row>
    );
  };

  const TitleSep = (props) => {
    return (
      <Row className="my-2">
        <Col xs="12">
          <label className="col-form-label green-color fw-bold">
            {props.title}
          </label>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <Modal isOpen={props.isOpen} className="modal-lg">
        <ModalHeader>Upload session detail</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <TitleSep title="Generic information" />
          <AttrRow label="Company" value={props.company?.name} />
          <AttrRow label="Campaign" value={us.contract_info.name} />
          <AttrRow label="Site" value={us.site.display_name} />
          <AttrRow label="Acquisition date" value={us.acquisition_date} />
          <AttrRow label="Created by" value={us.user} />
          <AttrRow
            label="Created on"
            value={moment(us.created_at).format("YYYY-MM-DD HH:mm:ss")}
          />
          <Row className="mb-1">
            <Col xs="3">
              <label className="col-form-label ps-4">Comment</label>
            </Col>
            <Col xs="9">
              <label className="col-form-label ps-1">{us.comment}</label>
            </Col>
          </Row>

          <TitleSep title="Dataset statistics" />
          {us.statistics.length > 0 ? (
            statTable(us.statistics)
          ) : (
            <Row>
              <Col className="text-center">
                <span>No data available</span>
              </Col>
            </Row>
          )}

          <TitleSep title="Detail on data" />
          {Object.keys(us.scalar_data).length > 0 ? (
            Object.entries(us.scalar_data)?.map(([k, v]) => (
              <AttrRow
                key={k}
                label={datasetToTitleCase(FILE_TYPE_LABEL[k] ?? k)}
                value={v}
              />
            ))
          ) : (
            <Row>
              <Col className="text-center">
                <span>No data available</span>
              </Col>
            </Row>
          )}

          {(hasBackofficeAccess(props.user.self) ||
            hasAdminAccess(props.user.self)) && (
            <>
              <TitleSep title="Technical info" />
              <AttrRow label="Upload session" value={us.id} />
              <AttrRow label="Company" value={props.company?.id} />
              <AttrRow label="Campaign" value={us.contract_info.id} />
              <AttrRow label="Site" value={us.site.id} />
              <AttrRow
                label="Upload template"
                value={us.upload_template?.name}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {hasAdminAccess(props.user.self) && (
            <Button
              color="danger"
              className="me-auto"
              onClick={() => handleDeleteUploadSession(us, props.user)}
            >
              Delete
            </Button>
          )}
          {props.withRedirect && (
            <Button
              color="primary"
              className="pe-2"
              onClick={() => history.push(`/upload/${us.id}`)}
            >
              Edit
            </Button>
          )}
          <Button color="secondary" onClick={() => props.closeModal()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(UploadSessionModal);

UploadSessionModal.propTypes = {
  us: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};
