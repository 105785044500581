import {
  RESET,
  RESET_USER_CONTRACTS_TRIALS,
  SET_USER,
  SET_USER_CONTRACTS,
  SET_USER_CONTRACTS_LOADING,
  SET_USER_CONTRACTS_TRIALS,
  SET_USER_SIDEBAR_COLLAPSE,
} from "../actions/actionTypes";

import _ from "lodash";
import { extendUserWithRole } from "../services/roles";

const initialState = {
  identity: {},
  self: {},
  trials: [],
  contracts: [],
  sidebarCollapse: true,
  contractsLoading: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        identity: action.identity,
        self: extendUserWithRole(action.user),
      };

    case SET_USER_CONTRACTS:
      const contracts_ = action.contracts.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return {
        ...state,
        contracts: contracts_,
        contractsLoading: false,
      };

    case SET_USER_CONTRACTS_TRIALS:
      //asc dates sorting
      const trials = action.trials;
      trials.forEach((trial) => trial.trial_dates.sort());

      return {
        ...state,
        contracts: action.contracts.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        trials: trials,
        contractsLoading: false,
      };

    case SET_USER_SIDEBAR_COLLAPSE:
      return {
        ...state,
        sidebarCollapse: !state.sidebarCollapse,
      };

    case RESET_USER_CONTRACTS_TRIALS:
      return {
        ...state,
        trials: [],
        contracts: [],
      };

    case SET_USER_CONTRACTS_LOADING:
      return {
        ...state,
        contractsLoading: !state.contractsLoading,
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
}
