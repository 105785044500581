import "./advancedFiltering.scss";

import { Button, OffcanvasBody, Progress } from "reactstrap";
import {
  selectFilteredOutGroupsSet,
  selectMergedTraits,
} from "../../../../selectors/resultMap";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ADVANCED_FILTERING_DATASET_COLORS } from "../../../constants";
import { ANALYTICS_EVENTS } from "../../../../constants";
import { AdvancedFilteringGroup } from "./advancedFilteringItem/AdvancedFilteringGroup";
import { AdvancedFilteringRecap } from "./advancedFilteringRecap/AdvancedFilteringRecap";
import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import { FilteringProfileManager } from "./filteringProfileManager/FilteringProfileManager";
import PropTypes from "prop-types";
import { compareArrays } from "../../../utils";
import { setFilteringProfileFilter } from "../../../../actions/resultMap";
import { useTracking } from "../../../../analytics";
import { useTraitGroups } from "../../useTraitGroups";

export function AdvancedFiltering({
  numericalPropertiesMetrics,
  toggleOffcanvas,
  data,
  getColor,
}) {
  const [selectedDate, filteringProfile, focusedVarieties] = useSelector(
    ({ resultMap }) => [
      resultMap.trial_date,
      resultMap.filteringProfile,
      resultMap.focusedVarieties,
    ]
  );

  const traits = useSelector(selectMergedTraits);

  // Traits from the filtering profile that are not represented in data
  const missingTraits = useMemo(() => {
    const missingTraits = [];
    filteringProfile.filters.forEach((filter) => {
      filter.traits.forEach((trait) => {
        if (
          trait.isAddon &&
          !traits.find((t) => t.technical_name === trait.technical_name)
        )
          missingTraits.push(trait.technical_name);
      });
    });
    return missingTraits;
  }, [filteringProfile.filters, traits]);

  const dispatch = useDispatch();

  const excludedGroups = useSelector(selectFilteredOutGroupsSet);

  const [expandedGroups, setExpandedGroups] = useState([]);

  const traitGroups = useTraitGroups(traits);

  const numericalPropertiesGroupsWithRanges = useMemo(() => {
    const traitGroupsWithRange = traitGroups.map((group) => ({
      ...group,
      metrics: group.Traits.map((trait) => ({
        ...trait,
        range: [
          numericalPropertiesMetrics[trait.technical_name]?.min,
          numericalPropertiesMetrics[trait.technical_name]?.max,
        ],
      })),
    }));

    return traitGroupsWithRange.filter(({ metrics }) => metrics.length > 0);
  }, [numericalPropertiesMetrics, traitGroups]);

  const metricsWithRanges = useMemo(
    () => numericalPropertiesGroupsWithRanges.flatMap(({ metrics }) => metrics),
    [numericalPropertiesGroupsWithRanges]
  );

  // Set initial thresholds values based on existingDateFilter if already applied to this DATE
  // Initialize to default trait range if not
  const initialThresholds = useMemo(() => {
    const existingCurrentDateFilter = filteringProfile.filters.find(
      ({ date }) => date === selectedDate
    );
    return metricsWithRanges.map(({ technical_name, range }) => {
      if (existingCurrentDateFilter) {
        const existingFilter = existingCurrentDateFilter.traits.find(
          (trait) => trait.technical_name === technical_name
        );
        if (existingFilter) return existingFilter;
      }
      return {
        technical_name,
        threshold: range,
      };
    });
  }, [filteringProfile.filters, metricsWithRanges, selectedDate]);

  const [thresholds, setThresholds] = useState(initialThresholds);

  const activeNumericalMetrics = useMemo(() => {
    return metricsWithRanges
      .filter((metric) => {
        const threshold = thresholds.find(
          (threshold) => threshold.technical_name === metric.technical_name
        );
        return !compareArrays(metric.range, threshold.threshold);
      })
      .map(({ technical_name }) => technical_name);
  }, [metricsWithRanges, thresholds]);

  const controlData = useMemo(() => {
    if (!getColor) return [];

    const result = [];

    data.forEach((featuresGroup) => {
      if (
        featuresGroup.isControl &&
        focusedVarieties.includes(featuresGroup.group)
      )
        result.push({
          ...featuresGroup,
          color: getColor(featuresGroup.group),
        });
    });

    return result;
  }, [data, getColor, focusedVarieties]);

  // Categorizes data into included, excluded, to be included, and to be excluded datasets for histogram display based on filters, thresholds, and date selection.
  const datasets = useMemo(() => {
    const includedData = [];
    const excludedData = [];
    const dataToInclude = [];
    const dataToExclude = [];

    data.forEach((featuresGroup) => {
      const isOutOfRanges = thresholds.some((trait) => {
        const traitValue = featuresGroup.properties[trait.technical_name]?.mean;
        if (traitValue == null) return false;
        return (
          traitValue < trait.threshold[0] || traitValue > trait.threshold[1]
        );
      });

      const isExcluded = filteringProfile.filters.some((filter) => {
        return (
          filter.date !== selectedDate &&
          filter.excludedGroups.includes(featuresGroup.group)
        );
      });

      let arrayToPush = includedData;

      if (excludedGroups.has(featuresGroup.group))
        if (isExcluded || isOutOfRanges) arrayToPush = excludedData;
        else arrayToPush = dataToInclude;
      else if (isOutOfRanges) arrayToPush = dataToExclude;

      arrayToPush.push(featuresGroup);
    });

    return {
      includedData,
      dataToInclude,
      dataToExclude,
      excludedData,
    };
  }, [
    data,
    excludedGroups,
    filteringProfile.filters,
    selectedDate,
    thresholds,
  ]);

  const handleCollapse = (groupUuid) => {
    setExpandedGroups((prevGroups) => {
      if (!filteringProfile.scope) return prevGroups;
      const isGroupOpen = prevGroups.includes(groupUuid);
      if (isGroupOpen) return prevGroups.filter((uuid) => uuid !== groupUuid);
      else return [...prevGroups, groupUuid];
    });
  };

  const handleThresholdChange = useCallback(
    (technical_name, threshold) =>
      setThresholds((prevState) => {
        const trait = traits.find(
          (trait) => trait.technicalName === technical_name
        );
        const metricsThresholdsCopy = prevState.filter(
          (metric) => metric.technical_name !== technical_name
        );
        metricsThresholdsCopy.push({
          technical_name,
          threshold,
          isAddon: trait?.isAddon,
        });

        return metricsThresholdsCopy;
      }),
    [traits]
  );

  // Dispatch the addition/update of filter object for current date.
  // Only thresholds that have changed since initial trait range should be saved
  const { trackEvent } = useTracking();
  const applyFilters = () => {
    dispatch(
      setFilteringProfileFilter({
        date: selectedDate,
        traits: thresholds.filter(({ technical_name }) =>
          activeNumericalMetrics.includes(technical_name)
        ),
        excludedGroups: datasets.excludedData
          .concat(datasets.dataToExclude)
          .map(({ group }) => group),
      })
    );

    trackEvent(ANALYTICS_EVENTS.AF_APPLY_FILTER);
  };

  const thresholdsChangedSinceLastSave = !compareArrays(
    initialThresholds.toSorted(({ technical_name: a }, { technical_name: b }) =>
      a.localeCompare(b)
    ),
    thresholds.toSorted(({ technical_name: a }, { technical_name: b }) =>
      a.localeCompare(b)
    )
  );

  return (
    <>
      <OffcanvasBody className="trait-list-advanced p-2">
        <FilteringProfileManager
          filteringProfile={filteringProfile}
          toggleOffcanvas={toggleOffcanvas}
          invalidScope={data.length === 0}
        />
        {data.length > 0 &&
          (missingTraits.length ? (
            <>
              <div className="h-25">
                <ComponentPlaceholder
                  icon="fa fa-warning"
                  text="Filtering profile cannot be modified because the following traits are missing and must be recomputed"
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                {missingTraits.map((trait) => (
                  <div
                    className="missing-trait p-2 d-flex gap-2 align-items-center"
                    key={trait}
                  >
                    {trait}
                  </div>
                ))}
              </div>
            </>
          ) : (
            numericalPropertiesGroupsWithRanges.map((group) => (
              <AdvancedFilteringGroup
                key={group.name}
                group={group}
                expanded={expandedGroups.includes(group.name)}
                scopeIsDefined={Boolean(filteringProfile.scope)}
                activeNumericalMetrics={activeNumericalMetrics}
                handleCollapse={handleCollapse}
                thresholds={thresholds}
                initialThresholds={initialThresholds}
                controlData={controlData}
                datasets={datasets}
                handleThresholdChange={handleThresholdChange}
              />
            ))
          ))}
      </OffcanvasBody>
      {filteringProfile.scope && (
        <div
          className={`p-2 d-flex justify-content-end align-items-center gap-2 apply-filters ${
            thresholdsChangedSinceLastSave ? "active" : ""
          }`}
        >
          {missingTraits.length ? (
            <div className="d-flex flex-grow-1 align-items-center justify-content-center gap-2">
              <i className="fa fa-warning" /> Invalid filtering profile
            </div>
          ) : (
            <>
              <Progress multi>
                {Object.entries(datasets).map(([key, value]) => (
                  <Progress
                    key={key}
                    bar
                    value={(value.length * 100) / data.length}
                    style={{
                      backgroundColor: ADVANCED_FILTERING_DATASET_COLORS[key],
                    }}
                  />
                ))}
              </Progress>
              <span
                className={`diff plus ${
                  datasets.dataToInclude.length ? "active" : ""
                }`}
              >{`+${datasets.dataToInclude.length}`}</span>
              <span
                className={`diff minus ${
                  datasets.dataToExclude.length ? "active" : ""
                }`}
              >{`-${datasets.dataToExclude.length}`}</span>
            </>
          )}
          <Button
            size="sm"
            className="hiphen-green-button"
            disabled={!thresholdsChangedSinceLastSave}
            onClick={applyFilters}
          >
            <i className="fa-solid fa-filter" /> Apply Filters
          </Button>
          <Button
            size="sm"
            disabled={!thresholdsChangedSinceLastSave}
            onClick={() => setThresholds(initialThresholds)}
          >
            Cancel
          </Button>
        </div>
      )}
      <AdvancedFilteringRecap data={data} />
    </>
  );
}

AdvancedFiltering.propTypes = {
  toggleOffcanvas: PropTypes.func.isRequired,
  numericalPropertiesMetrics: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  getColor: PropTypes.func,
};
