import { Button, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import AdminFilterHeader from "../../components/AdminFilterHeader/AdminFilterHeader";
import LoadingImg from "../../components/loading";
import PropTypes from "prop-types";
import TemplateModal from "./TemplateModal";
import { addDangerAlert } from "../../actions/alerts";
import { jsonContentType } from "../../services/backendRequests";
import { template } from "lodash";
import { useFetch } from "../../hooks/useFetch";
import { useState } from "react";

const useHandleResponse = (data, dispatch) => {
  if (!data.ok)
    data.json().then((d) => {
      dispatch(addDangerAlert(d.message));
    });

  return data;
};

const newTemplate = (template, identity, dispatch) =>
  fetch("/api/upload_templates", {
    method: "POST",
    body: JSON.stringify(template),
    headers: jsonContentType(identity),
  }).then((data) => useHandleResponse(data, dispatch));

const updateTemplate = (template, identity, dispatch) =>
  fetch(`/api/upload_templates/${template.id}`, {
    method: "PUT",
    body: JSON.stringify(template),
    headers: jsonContentType(identity),
  }).then((data) => useHandleResponse(data, dispatch));

const deleteTemplate = (id, identity, dispatch) =>
  fetch(`/api/upload_templates/${id}`, {
    method: "DELETE",
    body: JSON.stringify(template),
    headers: jsonContentType(identity),
  }).then((data) => useHandleResponse(data, dispatch));

function TemplateRow({ template, onDelete, onUpdate }) {
  const { id, name, vector, active } = template;
  const [openEditModal, setEditModal] = useState(false);

  return (
    <tr key={id}>
      <td>{id}</td>
      <td>{name}</td>
      <td>{vector}</td>
      <td>
        {active ? (
          <i className="fa fa-lg fa-check text-success" />
        ) : (
          <i className="fa fa-lg fa-times text-danger" />
        )}
      </td>
      <td>
        <Button
          color="link"
          className="ms-2 p-0"
          data-tooltip-id="tooltip"
          data-tooltip-content="Delete template"
          data-tooltip-variant="error"
          onClick={() => onDelete(id)}
        >
          <i className="fa fa-lg fa-trash" />
        </Button>
        <Button
          color="link"
          data-tooltip-id="tooltip"
          data-tooltip-content="Edit template information"
          className="ms-2 p-0"
          onClick={() => setEditModal(true)}
        >
          <i className="fa fa-lg fa-pencil" />
        </Button>

        <TemplateModal
          id={id}
          defaults={template}
          onSubmit={(t) => onUpdate(t)}
          isOpen={openEditModal}
          setOpen={setEditModal}
        />
      </td>
    </tr>
  );
}

export default function UploadTemplates() {
  const [templates, { refetch, loading }] = useFetch("/api/upload_templates");
  const hasTemplates = !loading && (templates ?? []).length === 0;
  const identity = useSelector((state) => state.user.identity);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const handleFilterChange = (event) => {
    setSearch(event.target.value);
  };
  const filteredTemplates = !templates
    ? []
    : search === ""
    ? templates
    : templates.filter((template) => {
        return (
          template.name.toLowerCase().includes(search.toLowerCase()) ||
          template.vector.toLowerCase().includes(search.toLowerCase())
        );
      });

  return (
    <div className="mt-3 ps-3">
      <AdminFilterHeader
        onButtonClick={() => setOpenModal(true)}
        onFilterChange={handleFilterChange}
        onRefresh={refetch}
        refreshing={loading}
        search={search}
        buttonName="New template"
      />
      {!loading && (
        <>
          <Table striped hover>
            <thead>
              <tr>
                <th>
                  <span>Id</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Vector</span>
                </th>
                <th>
                  <span>Active</span>
                </th>
                <th>
                  <span>Actions</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredTemplates
                .sort((a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
                .map((t) => (
                  <TemplateRow
                    key={t.id}
                    {...{
                      template: t,
                      onUpdate: (template) => {
                        updateTemplate(template, identity, dispatch).then(() =>
                          refetch()
                        );
                      },
                      onDelete: (id) => {
                        deleteTemplate(id, identity, dispatch).then(() =>
                          refetch()
                        );
                      },
                    }}
                  />
                ))}
            </tbody>
          </Table>
          {hasTemplates ? <div className="m-2">No templates</div> : null}
          <TemplateModal
            isOpen={openModal}
            setOpen={setOpenModal}
            onSubmit={(e) => {
              newTemplate(e, identity, dispatch).then(() => {
                refetch();
              });
            }}
          />
        </>
      )}
      <LoadingImg visible={loading} />
    </div>
  );
}
TemplateRow.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    vector: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
