import {
  SET_CONFIGURATION_MODE,
  SET_TREATMENT_CHAIN,
  UPDATE_TREATMENT_CHAIN_CONFIGURATION,
  UPDATE_TREATMENT_CHAIN_INPUT_PARAMETER
} from "../actions/actionTypes";

const initialState = {
  newConfiguration: {
    name: "",
    chain_type: "",
    description: "",
    traits: "",
    definition: "",
    input_parameters: {},
    prettyInputParameters: ""
  },
  initialName: "",
  template: false,
  isEditing: false,
  configurationMode: "simplified"
};

export default function treatmentChain(state = initialState, action) {
  switch (action.type) {
    case SET_TREATMENT_CHAIN:
      return {
        ...initialState,
        newConfiguration: {
          ...action.treatmentChain
        },
        initialName: action.treatmentChain.name,
        template: action.treatmentChain.template
      };
    case SET_CONFIGURATION_MODE:
      return {
        ...state,
        configurationMode: action.mode
      };
    case UPDATE_TREATMENT_CHAIN_CONFIGURATION: {
      return {
        ...state,
        newConfiguration: {
          ...state.newConfiguration,
          ...action.configuration
        },
        isEditing: true
      };
    }
    case UPDATE_TREATMENT_CHAIN_INPUT_PARAMETER: {
      const { name, update } = action;

      const input_parameters = {
        ...state.newConfiguration.input_parameters,
        [name]: {
          ...state.newConfiguration.input_parameters[name],
          ...update
        }
      };

      return {
        ...state,
        newConfiguration: {
          ...state.newConfiguration,
          input_parameters
        },
        isEditing: true
      };
    }
    default:
      return state;
  }
}
