import { HIPHEN_GREEN } from "../constants";

export const MISSION_MONITORING_STATIC_STATUS = Object.freeze([
  "PROCESSING",
  "ON HOLD",
  "DELIVERED",
  "ABORTED",
]);

export const MISSION_MONITORING_STATIC_GROUPS = Object.freeze({
  CONTRACT: "campaign",
  COUNTRY: "country",
  CROP: "crop",
  SENSOR: "sensor_type",
});

export const MISSION_MONITORING_STATIC_FLAGS = Object.freeze([
  "Dataset incomplete",
  "Dataset incorrect",
  "Images blurred",
  "Images overexposed",
  "Low resolution",
  "GCP(s) missing",
  "GCP coordinates missing",
  "GCP coordinates incorrect",
  "Plot file missing",
  "Plot file incorrect",
  "Radiometric calibration",
  "Weeds infestation",
  "Other",
]);

export const MISSION_MONITORING_STATIC_STATUS_ICONS = Object.freeze({
  ON_HOLD: "fa-pause-circle",
  DELIVERED: "fa-check-circle",
  PROCESSING: "fa-refresh",
  ABORTED: "fa-ban",
});

export const MISSION_MONITORING_STATIC_STATUS_COLORS = Object.freeze({
  ON_HOLD: "#ffd166",
  DELIVERED: "#06d6a0",
  PROCESSING: "#0A67C9",
  ABORTED: "#e3e3e3",
});
export const ACQUISITION_REPORT_STATUS_COLORS = Object.freeze({
  Validated: "#54D62C",
  Warning: "#FFC107",
  Unknown: "#637381",
  Rejected: "#FF4842",
});

export const MISSION_MONITORING_COLORWAY = Object.freeze([
  "#58508d",
  "#e32986",
  "#bc5090",
  "#ff6361",
  "#ffa600",
]);

export const MISSION_MONITORING_FLAG_COLORS = Object.freeze({
  RAISED: "#e00719",
  RESOLVED: "#0b9e12",
});

export const MiSSION_MONITORING_STATUS = "status";
export const MiSSION_MONITORING_SUBSTATUS = "substatus";

export const MISSION_MONITORING_THUMB_STEP = "thumb_step";
export const MISSION_MONITORING_THUMB_FLAG = "thumb_flag";

export const MiSSION_MONITORING_FLAG_COUNT = "count_active_flags";
export const MiSSION_MONITORING_COMMENT_COUNT = "count_unread_comments";

export const NULL_GROUP_LABEL = "N/A";

export const COMMENT_ID_QUERY_PARAM = "comment";

export const POWERDASH_DATE_FORMAT = "YYYY-MM-DD";

export const MAX_GENOTYPE_SELECTION = 600;
export const CONTROL_VARIETY_YES = "yes";

export const EXPORT_FORMATS = Object.freeze({
  CSV: { ext: ".csv", mime: "text/csv" },
  JSON: { ext: ".json", mime: "text/json" },
});

export const CHART_MODEBAR_BUTTONS_TO_REMOVE = [
  "pan",
  "select2d",
  "zoom2d",
  "lasso2d",
  "zoomIn2d",
  "zoomOut2d",
  "autoScale2d",
  "resetScale2d",
];

export const COMMON_PLOT_CONFIG = {
  responsive: true,
  displaylogo: false,
  modeBarButtonsToRemove: CHART_MODEBAR_BUTTONS_TO_REMOVE,
};

export const ADVANCED_FILTERING_DATASET_COLORS = Object.freeze({
  includedData: HIPHEN_GREEN,
  dataToInclude: "#D6E8B3",
  dataToExclude: "#FFA500",
  excludedData: "lightgray",
});
