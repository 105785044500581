import {
  requestCreateClientExperiment,
  requestDeleteClientExperiment,
  requestFetchClientSiteExperiments,
  requestFetchSiteExperiments,
  requestUpdateClientExperiment,
} from "../services/backendRequests";

import { SET_SITE_EXPERIMENTS } from "./actionTypes";
import { addDangerAlert } from "./alerts";

function setSiteExperiments(experiments) {
  return {
    type: SET_SITE_EXPERIMENTS,
    experiments,
  };
}

export function fetchExperiments(site) {
  return (dispatch, getState) => {
    requestFetchSiteExperiments(site, getState().user)
      .then(response => {
        dispatch(setSiteExperiments(response));
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}

export function fetchClientExperiments(site) {
  return (dispatch, getState) => {
    requestFetchClientSiteExperiments(site, getState().user)
      .then(response => {
        dispatch(setSiteExperiments(response));
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}

export function createClientExperiment(site, experiment) {
  return (dispatch, getState) => {
    requestCreateClientExperiment(site, getState().user, experiment)
      .then(() => {
        dispatch(fetchClientExperiments(site));
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}

export function deleteClientExperiment(experimentId, site) {
  return (dispatch, getState) => {
    requestDeleteClientExperiment(experimentId, getState().user)
      .then(() => {
        dispatch(fetchClientExperiments(site));
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}

export function updateClientExperiment(experiment, site) {
  return (dispatch, getState) => {
    requestUpdateClientExperiment(experiment, getState().user)
      .then(() => {
        dispatch(fetchClientExperiments(site));
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}
