import "./extraFilters.css";

import { ComponentTitle } from "../../../components/componentTitle/componentTitle";
import { HIPHEN_GREEN } from "../../../../constants";
import { MISSION_MONITORING_FLAG_COLORS } from "../../../constants";

const ExtraFilterItem = ({ active, onClick, label, value, color, icon }) => {
  return (
    <div
      className={`extra-filter clickable ${active ? "" : "inactive"}`}
      style={{
        background: color,
        boxShadow: `0px 4px 8px -3px ${color}`,
      }}
      onClick={() => onClick(!active)}
    >
      <i
        className={`fa fa-lg ${icon}`}
        style={{
          color: active ? "white" : color,
        }}
      ></i>
      <span className="extra-filter-label">{label}</span>
      <span>{value}</span>
    </div>
  );
};

export const ExtraFilters = ({
  filterActiveFlags,
  setFilterActiveFlags,
  flagCount,
  filterUnreadComments,
  setFilterUnreadComments,
  commentCount,
}) => {
  return (
    <div className="extra-filters">
      <ComponentTitle title="Filter on activity" />
      <ExtraFilterItem
        active={filterActiveFlags}
        onClick={setFilterActiveFlags}
        label="Flags"
        value={flagCount}
        color={MISSION_MONITORING_FLAG_COLORS.RAISED}
        icon="fa-flag"
      />
      <ExtraFilterItem
        active={filterUnreadComments}
        onClick={setFilterUnreadComments}
        label="Comments"
        value={commentCount}
        color={HIPHEN_GREEN}
        icon="fa-commenting"
      />
    </div>
  );
};
