import { SET_USERS, USER_UPDATE_ALERT } from "./actionTypes";
import { addDangerAlert, addSuccessAlert } from "./alerts";
import { hasAdminAccess, hasBackofficeAccess } from "../services/roles";
import {
  requestDeleteUser,
  requestFetchUsers,
  requestUpdateUser,
  requestUpdateUserRole
} from "../services/backendRequests";
import { setRefreshing } from "./dataTable";

function refreshingUsers(refreshing = true) {
  return setRefreshing("users", refreshing);
}

function setUsers(users) {
  return {
    type: SET_USERS,
    users
  };
}

export function fetchUsers() {
  return (dispatch, getState) => {
    dispatch(refreshingUsers());
    requestFetchUsers(getState().user)
      .then(response => {
        dispatch(setUsers(response));
        dispatch(refreshingUsers(false));
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}

export function changeUserRole(userToUpdate, role) {
  return (dispatch, getState) => {
    const { user } = getState();

    dispatch(refreshingUsers());
    requestUpdateUserRole(
      userToUpdate,
      {
        admin: hasAdminAccess({ role }),
        group_id: hasBackofficeAccess({ role }) ? user.self.group.id : null
      },
      user
    )
      .then(() => {
        dispatch(fetchUsers());
        dispatch(addSuccessAlert("User role was updated"));
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}

export function changeAccess(userToUpdate, accessFlag) {
  return (dispatch, getState) => {
    dispatch(refreshingUsers());
    requestUpdateUser(
      userToUpdate,
      {
        access: accessFlag
      },
      getState().user
    )
      .then(() => {
        dispatch(fetchUsers());
        dispatch(addSuccessAlert("User access was updated"));
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}

function updateUserAlert(alertMessage) {
  return {
    type: USER_UPDATE_ALERT,
    updateAlert: alertMessage
  };
}

export function resetUserAlert() {
  return dispatch => dispatch(updateUserAlert(""));
}

export function updateUserInformation(user) {
  return (dispatch, getState) => {
    requestUpdateUser(
      user.id,
      user,
      getState().user
    )
      .then(() => {
        dispatch(refreshingUsers());
        dispatch(fetchUsers());
      })
      .catch(err => dispatch(updateUserAlert(err)));
  };
}

export function deleteUser(user) {
  return (dispatch, getState) => {
    requestDeleteUser(user.id, getState().user)
      .then(() => {
        dispatch(fetchUsers());
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}
