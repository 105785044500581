import "./MapModeSelector.css";

import { useDispatch, useSelector } from "react-redux";

import { MAP_MODES } from "../../constants";
import { hasAnalyticsRole } from "../../users/rolesUtil";
import { setMapMode } from "../../actions/resultMap";
import { useKeyboardShortcut } from "../../hooks/useKeyboardShortcut";

export const MapModeSelector = () => {
  const user = useSelector(({ user }) => user);
  const [trial, selectedContract, mapMode] = useSelector(({ resultMap }) => [
    resultMap.trial,
    resultMap.selectedContract,
    resultMap.mapMode,
  ]);

  const filteredContracts = user.contracts.filter(({ roles }) =>
    hasAnalyticsRole(roles)
  );

  const dispatch = useDispatch();

  useKeyboardShortcut(
    "w",
    () => {
      filteredContracts.length > 1 && dispatch(setMapMode(MAP_MODES.MARKERS));
    },
    [filteredContracts]
  );
  useKeyboardShortcut(
    "x",
    () => {
      selectedContract && dispatch(setMapMode(MAP_MODES.CLUSTER));
    },
    [selectedContract]
  );
  useKeyboardShortcut(
    "c",
    () => {
      trial && dispatch(setMapMode(MAP_MODES.TRIAL));
    },
    [trial]
  );

  return (
    <div className="map-mode-selector">
      {selectedContract && (
        <>
          {filteredContracts.length > 1 && (
            <div
              className={`map-mode-selector-button clickable ${
                mapMode === MAP_MODES.MARKERS ? "active" : ""
              }`}
              onClick={() => dispatch(setMapMode(MAP_MODES.MARKERS))}
            >
              <i className="fa-solid fa-lg fa-earth-europe" />
            </div>
          )}
          {(filteredContracts.length > 1 || trial) && (
            <div
              className={`map-mode-selector-button clickable ${
                mapMode === MAP_MODES.CLUSTER ? "active" : ""
              }`}
              onClick={() => dispatch(setMapMode(MAP_MODES.CLUSTER))}
            >
              <i className="fa-solid fa-lg fa-map-location-dot" />
            </div>
          )}
        </>
      )}
      {trial && (
        <div
          className={`map-mode-selector-button clickable large ${
            mapMode === MAP_MODES.TRIAL ? "active" : ""
          }`}
          onClick={() => {
            dispatch(setMapMode(MAP_MODES.TRIAL));
          }}
        >
          <i className="fa fa-lg fa-th" />
        </div>
      )}
    </div>
  );
};
