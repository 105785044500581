import {
  RESET,
  SET_ABORTED_TREATMENTS,
  SET_PENDING_PROVISIONS
} from "../actions/actionTypes";
import dataTableBehavior from "./dataTableBehavior";

const abortedTable = dataTableBehavior("abortedTreatments", {
  sortField: "acquisition_time",
  sortOrder: "desc"
});

const pendingTable = dataTableBehavior("pendingProvisions", {
  sortField: "place"
});

const initialState = {
  abortedTreatments: [],
  abortedTreatmentsTable: abortedTable(undefined, {}), // eslint-disable-line no-undefined
  pendingProvisions: [],
  pendingProvisionsTable: pendingTable(undefined, {}) // eslint-disable-line no-undefined
};

export default function treatments(state = initialState, action) {
  switch (action.type) {
    case RESET:
      return initialState;
    case SET_ABORTED_TREATMENTS:
      return {
        ...state,
        abortedTreatments: action.treatments
      };
    case SET_PENDING_PROVISIONS:
      return {
        ...state,
        pendingProvisions: action.provisions
      };
    default:
      return {
        ...state,
        abortedTreatmentsTable: abortedTable(
          state.abortedTreatmentsTable,
          action
        ),
        pendingProvisionsTable: pendingTable(
          state.pendingProvisionsTable,
          action
        )
      };
  }
}
