import { hasAnalyticsRole, hasFeaturesRole } from "../users/rolesUtil";

import { createSelector } from "reselect";

export const selectSortedTrials = createSelector(
  (state) => state.user,
  (user) =>
    user.trials
      .filter((trial) => {
        const roles = user.contracts.find(
          ({ id }) => id === trial.contract_id
        )?.roles;
        return hasAnalyticsRole(roles) || hasFeaturesRole(roles);
      })
      .sort((trial1, trial2) =>
        trial1.display_name.localeCompare(trial2.display_name)
      )
);

export const selectFilteredTrials = createSelector(
  selectSortedTrials,
  ({ resultMap }) => resultMap.selectedContract,
  (trials, selectedContract) =>
    selectedContract
      ? trials.filter(({ contract_id }) => contract_id === selectedContract.id)
      : trials
);
