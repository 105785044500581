import {
  RESET_SITES,
  RESET_SITE_TRIAL,
  SET_REFRESHING_SITE_TRIAL,
  SET_SITES,
  SET_SITE_TRIAL,
  SITE_DISPATCH_DELETION,
  SITE_UPDATE_ALERT,
} from "./actionTypes";
import {
  requestCreateSite,
  requestDeleteSite,
  requestFetchClientSites,
  requestFetchSites,
  requestFetchTrialBySite,
  requestUpdateClientSite,
  requestUpdateClientSiteExperimentStatus,
  requestUpdateSite,
} from "../services/backendRequests";

import { addDangerAlert } from "./alerts";
import { setRefreshing } from "./dataTable";

function refreshingSites(refreshing = true) {
  return setRefreshing("sites", refreshing);
}

function setSites(sites) {
  return {
    type: SET_SITES,
    sites,
  };
}

function setSiteTrial(siteTrial) {
  return {
    type: SET_SITE_TRIAL,
    siteTrial,
  };
}

function setRefreshingSiteTrial() {
  return {
    type: SET_REFRESHING_SITE_TRIAL,
  };
}

function resetSiteTrial() {
  return {
    type: RESET_SITE_TRIAL,
  };
}

export function resetSites() {
  return {
    type: RESET_SITES,
  };
}

export function fetchClientSites(contract) {
  return (dispatch, getState) => {
    dispatch(refreshingSites());
    requestFetchClientSites(contract, getState().user)
      .then((response) => {
        dispatch(setSites(response));
        dispatch(refreshingSites(false));
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

export function updateClientSite(site) {
  return (dispatch, getState) => {
    dispatch(resetSiteAlert());
    requestUpdateClientSite(site, getState().user).catch((err) =>
      dispatch(updateSiteAlert(err))
    );
  };
}

export function updateClientSiteExperimentStatus(site) {
  return (dispatch, getState) => {
    dispatch(resetSiteAlert());
    requestUpdateClientSiteExperimentStatus(site, getState().user).catch(
      (err) => dispatch(updateSiteAlert(err))
    );
  };
}

export function fetchSites(contract) {
  return (dispatch, getState) => {
    dispatch(refreshingSites());
    requestFetchSites(contract, getState().user)
      .then((response) => {
        dispatch(setSites(response));
        dispatch(refreshingSites(false));
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

export function updateSiteAlert(alertMessage) {
  return {
    type: SITE_UPDATE_ALERT,
    updateAlert: alertMessage,
  };
}

function deletionSiteAction(site) {
  return {
    type: SITE_DISPATCH_DELETION,
    siteDeleted: site,
  };
}

export function resetSiteAlert() {
  return (dispatch) => dispatch(updateSiteAlert(""));
}

export function updateSite(contract, site) {
  return (dispatch, getState) => {
    dispatch(resetSiteAlert());
    requestUpdateSite(site, getState().user)
      .then((site) => dispatch(fetchSites(contract)))
      .catch((err) => dispatch(updateSiteAlert(err)));
  };
}

export function createSite(contract, site) {
  return (dispatch, getState) => {
    dispatch(resetSiteAlert());
    requestCreateSite(contract, site, getState().user)
      .then(() => dispatch(fetchSites(contract)))
      .catch((err) => dispatch(updateSiteAlert(err)));
  };
}

export function deleteSite(site) {
  return (dispatch, getState) => {
    if (site.id !== "")
      requestDeleteSite(site, getState().user)
        .then(() => dispatch(deletionSiteAction(site)))
        .catch((err) => dispatch(addDangerAlert(err)));
    else dispatch(deletionSiteAction(site));
  };
}

export function fetchTrial(site) {
  return (dispatch, getState) => {
    dispatch(setRefreshingSiteTrial());
    dispatch(resetSiteTrial());
    requestFetchTrialBySite(site, getState().user)
      .then((response) => {
        dispatch(setSiteTrial(response));
      })
      .catch(() => {
        dispatch(setRefreshingSiteTrial());
      }); //Error not raised in this case
  };
}

export function resetTrial() {
  return (dispatch) => {
    dispatch(resetSiteTrial());
  };
}
