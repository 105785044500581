import {
  Alert,
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  selectInternalUsers,
  selectUsersWithCompany,
} from "../../selectors/users";

import { OPTIONS_SYSTEM } from "../../constants";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import Switch from "react-switch";
import { UsersRolesForm } from "./UsersRolesForm";
import { connect } from "react-redux";
import { resetContractAlert } from "../../actions/company";
import { useFetch } from "../../hooks/useFetch";

// eslint-disable-next-line react/prop-types
function TemplateSelect({ onChange, value }) {
  const [templates] = useFetch("/api/upload_templates");
  const templatesArr = templates ?? [];
  let options = templatesArr.map((t) => ({ label: t.name, value: t.id }));
  options.unshift({ label: "--Unset--", value: "" });

  return (
    <Select
      value={value}
      isDisabled={templatesArr.length === 0}
      options={options}
      onChange={onChange}
    />
  );
}

class ContractModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      contractUserList: [{ user: null, roles: [] }],
      selectedSystemOption: null,
      selectedTemplate: null,
      hasExperiment: false,
    };
    this.name = React.createRef();
    this.start_date = React.createRef();
    this.end_date = React.createRef();
    this.system = React.createRef();
    this.internalManager = React.createRef();
    this.setContractUserList = this.setContractUserList.bind(this);
  }

  isValidForm() {
    return this.name.current.checkValidity();
  }

  handleSubmit(event) {
    if (this.isValidForm()) {
      event.preventDefault();
      const contract = {
        id: this.props.contract.id,
        active: this.state.active,
        name: this.name.current.value,
        system: this.state.selectedSystemOption
          ? this.state.selectedSystemOption.value
          : this.props.contract.system,
        internal_manager_id: this.state.selectedInternalManager
          ? this.state.selectedInternalManager.value
          : this.props.contract.internal_manager?.id,
        start_date: this.start_date.current.value,
        end_date: this.end_date.current.value,
        users: this.state.contractUserList
          .filter(({ user }) => !!user)
          .map(({ user, roles }) => ({
            id: user.id,
            roles: roles.map(({ value }) => value),
          })),
        upload_template_id: this.state.selectedTemplate?.value,
        has_experiment: this.state.hasExperiment,
      };
      this.props.onSubmit(contract);
    }
  }

  handleClick(attr, checked) {
    this.setState({ [attr]: checked });
  }

  // contractUserList functional update setter
  setContractUserList(updateFunction) {
    this.setState(({ contractUserList }) => ({
      contractUserList: updateFunction(contractUserList),
    }));
  }

  onSelectSystem(selectedSystemOption) {
    this.setState({ selectedSystemOption });
  }

  onSelectInternalManager(selectedInternalManager) {
    this.setState({ selectedInternalManager });
  }

  onSelectTemplate(selectedTemplate) {
    this.setState({ selectedTemplate });
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const { id, name } = this.props.contract.upload_template ?? {};
    const upload_template = { label: name, value: id };
    const isContractCreation = !this.props.contract.id;

    return (
      <Modal
        isOpen={this.props.isOpen}
        size="lg"
        onOpened={() =>
          this.setState({
            active: this.props.contract.active,
            hasExperiment: this.props.contract.has_experiment ?? false,
            selectedTemplate: null,
          })
        }
      >
        <ModalHeader>
          {isContractCreation ? "Create" : "Update"} campaign
        </ModalHeader>
        <ModalBody>
          <div>
            <Alert
              color="danger"
              isOpen={!!this.props.alert}
              toggle={() => this.props.resetContractAlert()}
            >
              {this.props.alert}
            </Alert>
            <form id="contractForm">
              <div className="form-container mx-auto">
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label text-end my-auto">
                    Active
                  </label>
                  <div className="col-sm-2">
                    <Switch
                      checked={this.state.active}
                      onChange={(checked) =>
                        this.handleClick("active", checked)
                      }
                    />
                  </div>
                  <label className="col-sm-3 offset-sm-2 col-form-label text-end my-auto">
                    Enable plot map
                  </label>
                  <div className="col-sm-2">
                    <Switch
                      checked={this.state.hasExperiment}
                      onChange={(checked) =>
                        this.handleClick("hasExperiment", checked)
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label text-end my-auto">
                    Name *
                  </label>
                  <div className="col">
                    <input
                      className="form-control"
                      defaultValue={this.props.contract.name}
                      type="text"
                      ref={this.name}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label text-end my-auto">
                    Campaign manager *
                  </label>
                  <div className="col">
                    <Select
                      value={
                        this.state.selectedInternalManager
                          ? this.state.selectedInternalManager
                          : this.props.internalUsers.find(
                              (s) =>
                                s.value ===
                                this.props.contract.internal_manager?.id
                            )
                      }
                      onChange={(selectedOption) =>
                        this.onSelectInternalManager(selectedOption)
                      }
                      options={this.props.internalUsers}
                      ref={this.internalManager}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label text-end my-auto">
                    System *
                  </label>
                  <div className="col">
                    <Select
                      value={
                        this.state.selectedSystemOption
                          ? this.state.selectedSystemOption
                          : this.props.selectSystem
                      }
                      onChange={(selectedOption) =>
                        this.onSelectSystem(selectedOption)
                      }
                      options={OPTIONS_SYSTEM}
                      ref={this.system}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label text-end my-auto">
                    Start date *
                  </label>
                  <div className="col">
                    <input
                      className="form-control"
                      defaultValue={this.props.contract.start_date}
                      ref={this.start_date}
                      type="date"
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label text-end my-auto">
                    End date *
                  </label>
                  <div className="col">
                    <input
                      className="form-control"
                      defaultValue={this.props.contract.end_date}
                      ref={this.end_date}
                      type="date"
                      required
                    />
                  </div>
                </div>
                {isContractCreation && (
                  <FormGroup row>
                    <Label sm={3} className="text-end">
                      User(s) &nbsp;
                      <span
                        className="icon-tooltip"
                        data-tooltip-id="tooltip"
                        data-tooltip-content="User(s) allowed to view results or plot map"
                      >
                        <i className="fa fa-info-circle" />
                      </span>
                    </Label>
                    <Col sm={9}>
                      <UsersRolesForm
                        contractUserList={this.state.contractUserList}
                        isContractCreation
                        setContractUserList={this.setContractUserList}
                      />
                    </Col>
                  </FormGroup>
                )}
                <FormGroup row>
                  <Label sm={3} className="text-end">
                    Upload template &nbsp;
                    <span
                      className="icon-tooltip"
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Select upload template used for upload session"
                    >
                      <i className="fa fa-info-circle" />
                    </span>
                  </Label>
                  <Col sm={9}>
                    <TemplateSelect
                      value={
                        this.state.selectedTemplate
                          ? this.state.selectedTemplate
                          : upload_template
                      }
                      onChange={(o) => this.onSelectTemplate(o)}
                    />
                  </Col>
                </FormGroup>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="btn action-btn"
            type="submit"
            form="contractForm"
            onClick={(event) => {
              this.handleSubmit(event);
            }}
          >
            {isContractCreation ? "Create" : "Update"}
          </Button>
          <Button color="secondary" onClick={() => this.props.onClose()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  const users = selectUsersWithCompany(state);
  const internalUsers = selectInternalUsers(state);

  const selectSystem = props.contract.system
    ? OPTIONS_SYSTEM.find((s) => s.value === props.contract.system)
    : [];

  return {
    users,
    selectSystem,
    internalUsers,
  };
}

const callbacks = { resetContractAlert };

ContractModal.propTypes = {
  alert: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  internalUsers: PropTypes.array.isRequired,
  resetContractAlert: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, callbacks)(ContractModal);
