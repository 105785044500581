import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import PropTypes from "prop-types";
import React from "react";

class CompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.name = React.createRef();
  }

  isValidForm() {
    return this.name.current.checkValidity();
  }

  handleSubmit(event) {
    if (this.isValidForm()) {
      event.preventDefault();
      const company = {
        id: this.props.company.id,
        name: this.name.current.value,
      };
      this.props.onSubmit(company);
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} className="modal-lg">
          <ModalHeader>
            {this.props.company.id ? "Update" : "Create"} company
          </ModalHeader>
          <ModalBody>
            <div>
              {this.props.alert !== "" ? (
                <Alert className="mx-auto col-10 text-center" color="danger">
                  {this.props.alert}
                </Alert>
              ) : null}
              <form id="companyForm">
                <div className="form-container mx-auto">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label text-end my-auto">
                      Name *
                    </label>
                    <div className="col">
                      <input
                        className="form-control"
                        defaultValue={this.props.company.name}
                        type="text"
                        ref={this.name}
                        required
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn action-btn"
              type="submit"
              form="companyForm"
              onClick={(event) => {
                this.handleSubmit(event);
              }}
            >
              {this.props.company.id ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={() => this.props.onClose()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

CompanyModal.propTypes = {
  alert: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default CompanyModal;
