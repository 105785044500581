import { APPLICATION_ENABLED, APPLICATION_FREEZED } from "../constants";
import { TOOGLE_FREEZE_INTERFACE } from "../actions/actionTypes";
const initialState = {
  status: APPLICATION_ENABLED
};

export default function application(state = initialState, action) {
  switch (action.type) {
    case TOOGLE_FREEZE_INTERFACE:
      return {
        ...state,
        status:
          state.status !== APPLICATION_FREEZED
            ? APPLICATION_FREEZED
            : APPLICATION_ENABLED
      };
    default:
      return state;
  }
}
