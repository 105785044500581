import { Button } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { updateContractCurrentUser } from "../actions/company";
import { useDispatch } from "react-redux";

const REMOVE_LABEL = "Remove your access to this campaign ?";
const ADD_LABEL = "Grant yourself access to this campaign ?";

export function ButtonAddRemoveUser({ contract, company, hasContractAccess }) {
  const dispatch = useDispatch();

  const onClick = (event) => {
    dispatch(updateContractCurrentUser(contract.id, company));
    event.stopPropagation();
  };

  return (
    <Button
      data-tooltip-id="tooltip"
      data-tooltip-content={hasContractAccess ? REMOVE_LABEL : ADD_LABEL}
      data-tooltip-variant={hasContractAccess ? "error" : "success"}
      className="me-2 p-0"
      color="link"
      onClick={onClick}
    >
      <i
        className={classnames({
          "fa fa-lg": true,
          "fa-user-plus": !hasContractAccess,
          "fa-user-times text-danger": hasContractAccess,
        })}
      />
    </Button>
  );
}

ButtonAddRemoveUser.propTypes = {
  hasContractAccess: PropTypes.bool.isRequired,
  contract: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};
