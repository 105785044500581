import { ADD_ALERT } from "./actionTypes";

export function addAlert(style, message) {
  return { type: ADD_ALERT, message, style };
}

export function addDangerAlert(message) {
  return addAlert("error", message);
}

export function addSuccessAlert(message) {
  return addAlert("success", message);
}

export function addWarningAlert(message) {
  return addAlert("warning", message);
}
