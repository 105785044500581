import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

export const RefreshButton = ({ refreshing, onRefresh, ...forA }) => {
  return (
    <a
      href="#!"
      onClick={(event) => {
        event.preventDefault();
        onRefresh();
      }}
      {...forA}
    >
      <FontAwesome
        name="refresh btn-link"
        disabled={refreshing}
        spin={refreshing}
      />
    </a>
  );
};

RefreshButton.propTypes = {
  refreshing: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export const SortableHeader = ({ label, onUpdateSort, className }) => {
  if (!onUpdateSort) {
    return (
      <th className={className}>
        <span>{label}</span>
      </th>
    );
  }

  return (
    <th className={className}>
      <span>{label}</span>
      <a
        href="#!"
        className="ms-1"
        onClick={(event) => {
          event.preventDefault();
          onUpdateSort();
        }}
      >
        <FontAwesome name="sort" className="collapseToggleTitle" />
      </a>
    </th>
  );
};

SortableHeader.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onUpdateSort: PropTypes.func,
};

SortableHeader.defaultProps = {
  className: "",
  onUpdateSort: null,
};

export const fixHeaders = (...label) => {
  return (
    <thead>
      <tr>
        {label.map((l, index) => (
          <th key={l + "-" + index}>
            <span>{l}</span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export const clickableRows = (handleClick, el, key, ...val) => {
  return (
    <tr
      onClick={(event) => handleClick(event, el)}
      className="clickable-row"
      key={key}
    >
      {val.map((v, i) => (
        <td key={i + key}>{v}</td>
      ))}
    </tr>
  );
};

export const rows = (...val) => {
  return (
    <tr>
      {val.map((v) => (
        <td key={v}>{v}</td>
      ))}
    </tr>
  );
};
