import "./FilteringProfileAbstract.css";

import { Badge } from "reactstrap";
import { Hr } from "../../components/Hr";
import { NULL_GROUP_LABEL } from "../../powerdash/constants";
import PropTypes from "prop-types";
import { createModalityBadgeStyle } from "../../powerdash/utils";
import { format } from "date-fns";
import { selectExcludedGroupsSet } from "../../selectors/resultMap";
import { useSelector } from "react-redux";

const MAX_ITEMS = 10;

export const FilteringProfileAbstract = ({ filteringProfile }) => {
  const totalTraitsCount = filteringProfile.filters.flatMap(
    (filter) => filter.traits
  ).length;
  const excludedGroupsSet = useSelector(selectExcludedGroupsSet);

  return (
    <div className="powerdash-component filtering-profile-abstract p-2 d-flex flex-column gap-2">
      <div>
        <div className="d-flex gap-2 align-items-center justify-content-between">
          <span className="text-ellipsis">{filteringProfile.name}</span>
          {filteringProfile.favourite && (
            <Badge className="hiphen-badge small">
              <i className="fa-solid fa-star golden" /> Favourite
            </Badge>
          )}
        </div>
        <small className="font-weight-bold discrete-text">
          {format(new Date(filteringProfile.created_at), "yyyy-MM-dd HH:mm")}
        </small>
      </div>

      <div className="scope-summary p-2 pt-1 d-flex flex-column">
        <div className="stack attribute">
          <i className="fa-solid fa-cube" />
          {filteringProfile.scope.experiments
            .slice(0, MAX_ITEMS)
            .map((experiment) => (
              <Badge
                key={experiment ?? NULL_GROUP_LABEL}
                className="hiphen-badge small ms-1 mt-1"
              >
                {experiment ?? NULL_GROUP_LABEL}
              </Badge>
            ))}
          {filteringProfile.scope.experiments.length > MAX_ITEMS && (
            <span className="small ms-1 mt-1">
              +{filteringProfile.scope.experiments.length - MAX_ITEMS}
            </span>
          )}
        </div>
        <div className="stack attribute">
          <i className="fa-solid fa-eyedropper" />
          {filteringProfile.scope.modalities
            .slice(0, MAX_ITEMS)
            .map((modality) => (
              <span
                key={modality ?? NULL_GROUP_LABEL}
                className="small ms-1 mt-1"
                style={createModalityBadgeStyle(modality)}
              >
                {modality ?? NULL_GROUP_LABEL}
              </span>
            ))}
          {filteringProfile.scope.modalities.length > MAX_ITEMS && (
            <span className="small ms-1 mt-1">
              +{filteringProfile.scope.modalities.length - MAX_ITEMS}
            </span>
          )}
        </div>
        {filteringProfile.scope.layer && (
          <div>
            <i className="fa fa-bars" /> {filteringProfile.scope.layer}
          </div>
        )}
      </div>

      <div className="d-flex align-items-center gap-2 justify-content-center justify-content-between">
        <Badge pill className="hiphen-badge small">
          <i className="fa fa-ban discrete-icon" /> {excludedGroupsSet.size}
        </Badge>
        <div className="d-flex align-items-center gap-2 justify-content-center">
          <Badge className="hiphen-badge small danger">
            <i className="fa-solid fa-minus-circle" />{" "}
            {filteringProfile.blacklist.length}
          </Badge>
          <Badge className="hiphen-badge small active">
            <i className="fa-solid fa-shield-halved" />{" "}
            {filteringProfile.whitelist.length}
          </Badge>
        </div>
      </div>

      <Hr />

      <div className="d-flex align-items-center gap-2 justify-content-center">
        <span className="traits-text">
          <i className="fa-solid fa-filter" />{" "}
          {`${totalTraitsCount} trait${totalTraitsCount !== 1 ? "s" : ""}`}
        </span>
        across
        <Badge className="hiphen-badge small active font-weight-bold">
          {filteringProfile.filters.length}
        </Badge>
        {`date${filteringProfile.filters.length !== 1 ? "s" : ""}`}
      </div>
    </div>
  );
};

FilteringProfileAbstract.propTypes = {
  filteringProfile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    blacklist: PropTypes.array,
    whitelist: PropTypes.array,
    favourite: PropTypes.bool,
  }).isRequired,
};
