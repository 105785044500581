import "./charts.css";

import { ComponentPlaceholder } from "../componentPlaceholder/componentPlaceholder";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";

export const PieChart = ({ data, getStatusColor, showLegend, onClick }) => {
  const keys = Object.keys(data);
  const values = Object.values(data);

  return (
    <>
      {keys.length === 0 ? (
        <ComponentPlaceholder icon="fa-pie-chart" text="Empty" />
      ) : (
        <Plot
          data={[
            {
              values: values,
              labels: keys,
              marker: {
                colors: keys.map((key) => getStatusColor(key)),
              },
              type: "pie",
              textinfo: "value+percent",
              texttemplate: "<b> %{value} </b> <br>%{percent}",
              hovertemplate:
                "<b>%{label}</b> <br> %{value} items <br>(%{percent}) <extra></extra>",
            },
          ]}
          layout={{
            autosize: true,
            margin: { l: 20, r: 20, t: 20, b: 20 },
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
            showlegend: showLegend,
          }}
          onLegendClick={() => {
            return false;
          }}
          config={{ responsive: true, displayModeBar: false }}
          useResizeHandler={true}
          style={{ width: "100%", height: "100%" }}
          onClick={(e) => onClick(e.points[0].label)}
        />
      )}
    </>
  );
};

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  showLegend: PropTypes.bool,
};
