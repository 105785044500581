import { HIPHEN_GREEN, THEME } from "../../../../constants";
import { useCallback, useMemo, useState } from "react";

import { COMMON_PLOT_CONFIG } from "../../../constants";
import { Chart } from "./Chart";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { PropertyName } from "../../../../components/PropertyName/PropertyName";
import { SelectionFloatingCard } from "./ComparativeScatter/SelectionFloatingCard/SelectionFloatingCard";
import { selectFilteredFeatures } from "../../../../selectors/resultMap";
import { useSelector } from "react-redux";

const PLOT_STYLE = {
  width: "100%",
  height: "85%",
};

export const ModalityProfiler = ({ trait, exportName }) => {
  const features = useSelector(selectFilteredFeatures);
  const modalities = useSelector(
    ({ resultMap }) => resultMap.distinctModalities
  );
  const [selectedPlots, setSelectedPlots] = useState([]);

  const displayData = useMemo(() => {
    const modalitiesData = {};
    modalities.forEach((modality) => {
      modalitiesData[modality ?? ""] = [];
    });

    features.forEach((feature) => {
      const { modality } = feature;
      const value = {
        id: feature.id,
        value: feature.properties[trait.technical_name],
      };
      if (value) modalitiesData[modality].push(value);
    });

    return Object.entries(modalitiesData).map(([modality, plots]) => ({
      y: plots.map(({ value }) => value),
      meta: plots.map(({ id }) => id),
      type: "box",
      name: modality,
      marker: {
        color: modality ? THEME.modalityColorHash.hex(modality) : HIPHEN_GREEN,
      },
    }));
  }, [features, modalities, trait.technical_name]);

  const layout = useMemo(
    () => ({
      autosize: true,
      margin: { r: 30, t: 0, b: 20 },
      dragmode: "select",
      plot_bgcolor: "transparent",
      paper_bgcolor: "white",
      yaxis: {
        title: {
          text: trait.name,
        },
        gridcolor: THEME.indicators,
        fixedrange: true,
        automargin: true,
      },
      xaxis: {
        automargin: true,
        gridcolor: THEME.indicators,
        fixedrange: true,
        type: "category",
      },
      font: { color: THEME.indicators, size: 15 },
      showlegend: false,
    }),
    [trait.name]
  );

  const config = useMemo(
    () => ({
      toImageButtonOptions: {
        filename: exportName,
      },
      ...COMMON_PLOT_CONFIG,
    }),
    [exportName]
  );

  // Handle selection of data points in the plot
  const handleSelection = useCallback(
    (event) => {
      if (!event) return;
      if (event.points.length === 0) return;
      const selectedFeatures = event.points.map((point) =>
        features.find(({ id }) => id === point.meta)
      );
      setSelectedPlots(selectedFeatures);
    },
    [features]
  );

  return (
    <Chart
      icon="fa-tag"
      title={
        <span>
          Modalities performance on{" "}
          <PropertyName
            showIcon
            showUnit
            technicalName={trait.technical_name}
          />
        </span>
      }
    >
      <div className="position-relative">
        <SelectionFloatingCard plots={selectedPlots} />
      </div>
      <Plot
        useResizeHandler
        data={displayData}
        onSelected={handleSelection}
        layout={layout}
        config={config}
        style={PLOT_STYLE}
      />
    </Chart>
  );
};

ModalityProfiler.propTypes = {
  trait: PropTypes.object.isRequired,
  exportName: PropTypes.string.isRequired,
};
