import "./landing.css";

import React, { Component } from "react";
import { login, signup } from "../actions/user";

import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "login",
      login: {
        email: "",
        password: "",
      },
      signup: {
        lastname: "",
        firstname: "",
        email: "",
        phone: "",
        password: "",
      },
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.handleLoginInputs = this.handleLoginInputs.bind(this);
    this.handleSignupInputs = this.handleSignupInputs.bind(this);
  }

  isTabActive(tab) {
    return this.state.activeTab === tab;
  }

  handleTabSelect(event) {
    event.preventDefault();

    const activeTab = event.currentTarget.dataset.name;

    this.setState({
      activeTab,
    });
  }

  handleLoginInputs(event) {
    const { value, name } = event.currentTarget;

    this.setState((prevState) => ({
      ...prevState,
      login: { ...prevState.login, [name]: value },
    }));
  }

  handleSignupInputs(event) {
    const { value, name } = event.currentTarget;

    this.setState((prevState) => ({
      ...prevState,
      signup: { ...prevState.signup, [name]: value },
    }));
  }

  handleLogin(event) {
    event.preventDefault();
    const { email, password } = this.state.login;
    this.props.login(email, password);
  }

  handleSignup(event) {
    event.preventDefault();
    this.props.signup(this.state.signup);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row landing-container d-flex justify-content-center">
          <div className="background-container" />

          <div className="account-section col-4">
            <div className="col-12 mx-0 icon-container" />
            <div className="account-form mx-auto">
              <ul className="nav nav-tabs account-tabs" role="tablist">
                <li
                  className="nav-item"
                  data-name="login"
                  onClick={this.handleTabSelect.bind(this)}
                >
                  <a
                    className={
                      "nav-link " + (this.isTabActive("login") ? "active" : "")
                    }
                    data-toggle="tab"
                    href="#login"
                    role="tab"
                  >
                    Log in
                  </a>
                </li>
                <li
                  className="nav-item"
                  data-name="signup"
                  onClick={this.handleTabSelect.bind(this)}
                >
                  <a
                    className={
                      "nav-link " + (this.isTabActive("signup") ? "active" : "")
                    }
                    data-toggle="tab"
                    href="#signup"
                    role="tab"
                  >
                    Sign up
                  </a>
                </li>
              </ul>

              <div className="form-container">
                {this.isTabActive("login") ? (
                  <div className="tab-pane active" id="login" role="tabpanel">
                    <div className="tab-content mt-4 container">
                      <div className="row">
                        <div className="col-12">
                          {this.props.loginMessage !== "" ? (
                            <Alert
                              className="mx-auto col-10 text-center"
                              color="danger"
                            >
                              {this.props.loginMessage}
                            </Alert>
                          ) : null}

                          <form onSubmit={this.handleLogin}>
                            <div className="mb-3 landing-input-group">
                              <label htmlFor="email">Email</label>
                              <input
                                className="form-control"
                                id="email"
                                name="email"
                                onChange={this.handleLoginInputs}
                                type="email"
                                value={this.state.login.email}
                                autoComplete="email"
                                required
                              />
                            </div>

                            <div className="mb-3 landing-input-group">
                              <label htmlFor="password">Password</label>
                              <input
                                className="form-control mb-2"
                                id="password"
                                name="password"
                                onChange={this.handleLoginInputs}
                                type="password"
                                value={this.state.login.password}
                                autoComplete="current-password"
                                required
                              />
                              <Link to="/password/askPasswordReset">
                                Forgot your password?
                              </Link>
                            </div>

                            <div className="text-center">
                              <button
                                className="btn btn-success btn-login col-6 mt-2"
                                type="submit"
                              >
                                Log in
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="tab-pane" id="signup" role="tabpanel">
                    <div className="tab-content mt-4 container">
                      <div className="row">
                        <div className="col-12">
                          {this.props.signupMessage !== "" ? (
                            <Alert
                              className="mx-auto col-10 text-center"
                              color="danger"
                            >
                              {this.props.signupMessage}
                            </Alert>
                          ) : null}
                          <form onSubmit={this.handleSignup}>
                            <div className="mb-3 landing-input-group">
                              <label htmlFor="lastname">Last name</label>
                              <input
                                className="form-control"
                                id="lastname"
                                name="lastname"
                                onChange={this.handleSignupInputs}
                                type="text"
                                value={this.state.signup.lastname}
                                autoComplete="family-name"
                                required
                              />
                            </div>

                            <div className="mb-3 landing-input-group">
                              <label htmlFor="firstname">First name</label>
                              <input
                                className="form-control"
                                id="firstname"
                                name="firstname"
                                onChange={this.handleSignupInputs}
                                type="text"
                                value={this.state.signup.firstname}
                                required
                                autoComplete="given-name"
                              />
                            </div>

                            <div className="mb-3 landing-input-group">
                              <label htmlFor="email">Email address</label>
                              <input
                                className="form-control"
                                id="email"
                                name="email"
                                onChange={this.handleSignupInputs}
                                type="email"
                                value={this.state.signup.email}
                                required
                                autoComplete="email"
                              />
                            </div>

                            <div className="mb-3 landing-input-group">
                              <label htmlFor="phone">Phone</label>
                              <input
                                className="form-control"
                                id="phone"
                                name="phone"
                                onChange={this.handleSignupInputs}
                                value={this.state.signup.phone}
                                autoComplete="tel"
                              />
                            </div>

                            <div className="mb-3 landing-input-group">
                              <label htmlFor="password">Password</label>
                              <input
                                className="form-control"
                                id="password"
                                name="password"
                                onChange={this.handleSignupInputs}
                                type="password"
                                value={this.state.signup.password}
                                autoComplete="new-password"
                                required
                              />
                            </div>

                            <div className="text-center">
                              <button
                                className="btn btn-success btn-login col-6 mt-2"
                                type="submit"
                              >
                                Sign up
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  const { loginMessage, signupMessage } = store.requests;

  return {
    loginMessage,
    signupMessage,
  };
}

Landing.propTypes = {
  loginMessage: PropTypes.string.isRequired,
  signupMessage: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  login,
  signup,
})(Landing);
