import "./floatingCard.css";

import PropTypes from "prop-types";
import { useClickAway } from "react-use";
import { useRef } from "react";

export const FloatingCard = ({ children, hide }) => {
  const cardRef = useRef(null);
  useClickAway(cardRef, hide ? hide : () => {});

  return (
    <div className="floating-card" ref={cardRef}>
      {children}
    </div>
  );
};

FloatingCard.propTypes = {
  children: PropTypes.node,
  hide: PropTypes.func,
};
