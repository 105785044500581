import "./PropertyName.css";

import { Badge } from "reactstrap";
import { HighlightedText } from "../highlightedText/HighlightedText";
import PropTypes from "prop-types";
import { selectTraitsIndexedOnTechnicalName } from "../../selectors/traits";
import { useGetPropertyCategory } from "../../hooks/useGetPropertyCategory";
import { useSelector } from "react-redux";

export const PropertyName = ({
  technicalName,
  highlight = "",
  showIcon,
  showUnit,
  hideName,
}) => {
  const traitsObject = useSelector(selectTraitsIndexedOnTechnicalName);
  const getPropertyCategory = useGetPropertyCategory();
  const category = getPropertyCategory(technicalName);

  const splitted = technicalName.split("|");
  const prefix = splitted.shift();
  const classes = splitted.map((label) => label.replaceAll("_", " "));

  const trait = traitsObject[prefix];
  const name = trait?.name ?? prefix;
  const unit = (showUnit && trait?.unit && ` (${trait.unit})`) ?? "";

  return (
    <span
      className={`property-name ${category.className}`}
      title={`${name} ${classes.join(" ")}${unit}`}
    >
      {showIcon && category.icon}{" "}
      {!hideName && (
        <>
          <HighlightedText text={name} highlight={highlight} />
          {classes.map((label) => (
            <Badge
              className="ms-1 class-badge"
              key={label}
              color="Invalid color"
              style={{
                border: `1px solid ${category.color}`,
                color: category.color,
              }}
            >
              <HighlightedText text={label} highlight={highlight} />
            </Badge>
          ))}
          {unit}
        </>
      )}
    </span>
  );
};

PropertyName.propTypes = {
  technicalName: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  showIcon: PropTypes.bool,
  showUnit: PropTypes.bool,
  hideName: PropTypes.bool,
};
