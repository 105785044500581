import { REFRESHING, RESET, SET_SORT_PARAMS } from "../actions/actionTypes";
import _ from "lodash";

export const DATA_EMPTY = "DATA_EMPTY";
export const DATA_LOADING = "DATA_LOADING";
export const DATA_READY = "DATA_READY";
export const DATA_REFRESHING = "DATA_REFRESHING";

const initialState = {
  refreshing: false,
  sortField: "",
  sortOrder: "asc"
};

export function changeSortParams(tableState, newSortField) {
  const changeField = tableState.sortField !== newSortField;
  const reverseSort = tableState.sortOrder === "asc" ? "desc" : "asc";

  return {
    sortField: newSortField,
    sortOrder: changeField ? "asc" : reverseSort
  };
}

export function sortTableData(data, tableState) {
  const { sortField, sortOrder } = tableState;
  return _.orderBy(data, [sortField], [sortOrder]);
}

export function dataStatus(data, tableState) {
  const { refreshing } = tableState;
  if (data.length === 0) {
    return refreshing ? DATA_LOADING : DATA_EMPTY;
  } else {
    return refreshing ? DATA_REFRESHING : DATA_READY;
  }
}

export default function dataTableBehavior(selector, defaults = {}) {
  const defaultState = {
    ...initialState,
    ...defaults
  };

  return (state = defaultState, action) => {
    switch (action.type) {
      case `${REFRESHING}_${selector}`:
        return {
          ...state,
          refreshing: action.refreshing
        };

      case `${SET_SORT_PARAMS}_${selector}`:
        return {
          ...state,
          ...changeSortParams(state, action.sortField)
        };

      case RESET:
        return defaultState;

      default:
        return state;
    }
  };
}
