import { CrashPage } from "./CrashPage";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ ...this.state, error: { error, errorInfo } });
    console.error(error);
  }

  render() {
    if (this.state.hasError) return <CrashPage error={this.state.error} />;

    return this.props.children;
  }
}

export default ErrorBoundary;
