import React, { useState } from "react";
import { Container, Card, Collapse, Button } from "reactstrap";
import { statTable } from "../../uploadsessions/uploadSessionModal";

export default function DatasetTable({ statistics }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container>
      <Card className="m-3">
        <Button block color="primary" onClick={() => setIsOpen(v => !v)}>
          {isOpen ? "Hide" : "Show"} Previous Datasets
        </Button>
        <Collapse isOpen={isOpen}>{statTable(statistics)}</Collapse>
      </Card>
    </Container>
  );
}
