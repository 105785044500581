import "./legend.css";

import { getContrastYIQ, humanize } from "../../../utils";

import { MISSION_MONITORING_STATIC_STATUS_ICONS } from "../../../constants";
import PropTypes from "prop-types";
import classnames from "classnames";

/*
 * Global interractable legend picting status and corresponding color,
 * Click to filter by status
 */

export const Legend = ({
  statusList,
  selectedStatus,
  handleStatusClick,
  occurences,
  getStatusColor,
}) => {
  return (
    <div className="mission-monitoring-dashboard-legend">
      {statusList.map((status) => {
        const statusIsSelected = status === selectedStatus;
        const statusClasses = classnames("status-label", {
          empty: !occurences[status],
        });

        const statusColor = getStatusColor(status, statusList);

        return (
          <div
            className={`status-item ${statusIsSelected ? "" : "inactive"}`}
            key={status}
            onClick={() => handleStatusClick(status)}
            style={{
              background: statusColor,
              boxShadow: `0px 4px 8px -3px ${statusColor}`,
              color: statusIsSelected ? getContrastYIQ(statusColor) : "black",
            }}
          >
            <i
              className={`fa fa-lg ${
                MISSION_MONITORING_STATIC_STATUS_ICONS[
                  status.replaceAll(" ", "_")
                ] ?? "fa-circle"
              }`}
              style={{
                color: statusIsSelected ? "white" : statusColor,
              }}
            ></i>
            <span className={statusClasses}>{humanize(status)}</span>{" "}
            <small>{occurences[status]}</small>
          </div>
        );
      })}
    </div>
  );
};

Legend.propTypes = {
  statusList: PropTypes.array.isRequired,
  handleStatusClick: PropTypes.func.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  occurences: PropTypes.object.isRequired,
  selectedStatus: PropTypes.string,
};
