import "./WorldMap.css";
import "leaflet/dist/leaflet.css";

import { MapContainer, TileLayer } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";

import LoadingImg from "../components/loading";
import { MAP_MODES } from "../constants";
import PropTypes from "prop-types";
import { Row } from "reactstrap";
import { TrialDisplay } from "./TrialDisplay";
import { TrialMarkerCluster } from "./TrialMarkerCluster/TrialMarkerCluster";
import { fetchUserContractsTrials } from "../actions/user";
import mapbg from "../static/img/worldmap_background.png";
import { useEffect } from "react";

const MIN_ZOOM = 3;

export const WorldMap = ({ noBg, mapMode, mapRef, selectedTileLayer }) => {
  const dispatch = useDispatch();
  const [trial, refreshing] = useSelector(({ resultMap }) => [
    resultMap.trial,
    resultMap.refreshing,
  ]);

  useEffect(() => {
    dispatch(fetchUserContractsTrials());
  }, [dispatch]);

  return (
    <Row className="flex-grow-1">
      <MapContainer
        attributionControl={false}
        ref={mapRef}
        center={[26.0, 30.0]}
        zoom={MIN_ZOOM}
        minZoom={MIN_ZOOM}
        maxZoom={30}
        zoomControl={false}
        maxBounds={[
          [-85, -180],
          [85, 180],
        ]}
        maxBoundsViscosity={1.0}
        style={{
          backgroundImage: noBg ? "none" : `url(${mapbg})`,
          backgroundSize: "1300px",
          backgroundColor: "transparent",
        }}
      >
        {trial && mapMode === MAP_MODES.TRIAL ? (
          <TrialDisplay />
        ) : (
          <TrialMarkerCluster />
        )}
        {/* Tile layers */}
        {selectedTileLayer && (
          <>
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/${selectedTileLayer.mapbox}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2FzdWJvbG8iLCJhIjoiY2tjdzRvbW8yMGFuZDMwczF6NmZkcDM2NSJ9.EhB2o6-Gz74dGmQwCXTVVA`}
              minZoom={19}
              zIndex={0}
            />
            <TileLayer url={selectedTileLayer.url} maxZoom={18} zIndex={0} />
          </>
        )}
      </MapContainer>
      {/* Prevent user actions while loading trial */}
      {refreshing && (
        <div className="loading-screen">
          <LoadingImg visible />
        </div>
      )}
    </Row>
  );
};

WorldMap.propTypes = {
  mapRef: PropTypes.object.isRequired,
  selectedTileLayer: PropTypes.shape({
    mapbox: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  mapMode: PropTypes.oneOf(Object.values(MAP_MODES)).isRequired,
  noBg: PropTypes.bool,
};
