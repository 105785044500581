import "./PropertiesMasonry.css";

import { ComponentPlaceholder } from "../../powerdash/components/componentPlaceholder/componentPlaceholder";
import { DEFAULT_BLACKLISTED_TRAITS } from "../../constants";
import PropTypes from "prop-types";
import { PropertyBadge } from "../Badges/PropertyBadge/PropertyBadge";
import { useGetPropertyCategory } from "../../hooks/useGetPropertyCategory";
import { useMemo } from "react";

export const PropertiesMasonry = ({
  feature,
  propertyFilter = "",
  highlightedProperty,
  setHighlightedProperty,
}) => {
  const getPropertyCategory = useGetPropertyCategory();

  const propertyEntries = useMemo(
    () =>
      feature &&
      Object.keys(feature.properties)
        .filter(
          (key) =>
            !DEFAULT_BLACKLISTED_TRAITS.includes(key) &&
            key.toLowerCase().includes(propertyFilter.toLowerCase())
        )
        .map((key) => ({
          key: key,
          value: feature.properties[key],
          category: getPropertyCategory(key),
        }))
        .sort(
          (a, b) =>
            a.category.priority - b.category.priority ||
            a.key.toLowerCase().localeCompare(b.key.toLowerCase())
        ),
    [feature, getPropertyCategory, propertyFilter]
  );

  return feature ? (
    <div className="properties-masonry">
      {propertyEntries.map(({ key, value }) => (
        <PropertyBadge
          key={key}
          technicalName={key}
          value={value}
          isHighlighted={highlightedProperty === key}
          setHighlighted={() =>
            setHighlightedProperty?.((prev) => (prev === key ? null : key))
          }
          propertyFilter={propertyFilter}
        />
      ))}
    </div>
  ) : (
    <ComponentPlaceholder icon="fa fa-ban" />
  );
};

PropertiesMasonry.propTypes = {
  feature: PropTypes.object,
  propertyFilter: PropTypes.string,
  highlightedProperty: PropTypes.string,
  setHighlightedProperty: PropTypes.func,
};
