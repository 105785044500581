import "./TrialList.css";

import { Input } from "reactstrap";
import { TrialBadge } from "../TrialMarkerCluster/TrialBadge";
import { selectFilteredTrials } from "../../selectors/user";
import { useSelector } from "react-redux";
import { useState } from "react";

export const TrialList = () => {
  const trials = useSelector(selectFilteredTrials);
  const [input, setInput] = useState("");
  return (
    <div className="powerdash-component trial-list d-flex flex-column gap-1 flex-grow-1">
      <span className="title d-flex gap-2 align-items-center pb-1">
        <i className="fa-solid fa-map-pin" />
        <Input
          type="search"
          bsSize="sm"
          placeholder={`Sites (${trials.length})`}
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </span>
      <div className="d-flex flex-column gap-2 overflow-auto flex-grow-1">
        {trials
          .filter(({ display_name }) =>
            display_name.toLowerCase().includes(input.toLowerCase())
          )
          .map((trial) => (
            <TrialBadge key={trial.id} trial={trial} highlight={input} />
          ))}
      </div>
    </div>
  );
};
