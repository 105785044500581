import { Component } from "react"; // eslint-disable-line sort-imports
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { refreshUser } from "../actions/user";

class RefreshUser extends Component {
  componentDidMount() {
    this.props.refreshUser();
  }

  render() {
    return this.props.children;
  }
}

RefreshUser.propTypes = {
  children: PropTypes.element.isRequired,
  refreshUser: PropTypes.func.isRequired
};

export default connect(
  null,
  { refreshUser }
)(RefreshUser);
