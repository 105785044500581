import { RESET, SET_TRAITS, TRAIT_UPDATE_ALERT } from "../actions/actionTypes";

import { traitGroupCompare } from "../services/utils";

const initialState = {
  groups: [],
  all: [],
  traitGroupsFetched: false,
};

export default function traits(state = initialState, action) {
  switch (action.type) {
    case SET_TRAITS:
      return {
        ...state,
        groups: action.traitGroups.rows
          .map((traitGroup) => ({
            ...traitGroup,
            MinBBCHStage: traitGroup.Traits[0]?.MinBBCHStage,
            MaxBBCHStage: traitGroup.Traits[0]?.MaxBBCHStage,
          }))
          .sort(traitGroupCompare),
        all: action.traitGroups.rows.flatMap((traitGroup) => {
          const { Traits, ...traitGroupData } = traitGroup;
          return Traits.map((trait) => ({
            ...trait,
            technical_name: trait.technicalName, // for compatibility purposes
            traitGroup: traitGroupData,
          }));
        }),
        traitGroupsFetched: true,
      };

    case RESET:
      return initialState;

    case TRAIT_UPDATE_ALERT:
      return {
        ...state,
        updateAlert: action.updateAlert,
      };

    default:
      return {
        ...state,
      };
  }
}
