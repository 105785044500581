import {
  FEATURE_PROPERTY_CATEGORIES,
  OTHER_NUMERICAL_PROPERTIES_GROUP,
  RESERVED_PROPERTY_KEYS,
} from "../constants";

import { selectMergedTraits } from "../selectors/resultMap";
import { selectTraitsIndexedOnTechnicalName } from "../selectors/traits";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export const useGetPropertyCategory = () => {
  const traits = useSelector(selectMergedTraits);
  const traitsObject = useSelector(selectTraitsIndexedOnTechnicalName);
  const [contracts, resultMap] = useSelector((state) => [
    state.user.contracts,
    state.resultMap,
  ]);

  const getPropertyCategory = useCallback(
    (property) => {
      const plotRatingConfig = resultMap.trial
        ? contracts.find(
            (contract) => contract.id === resultMap.trial.contract_id
          )?.plot_rating_config ?? []
        : [];
      const trait = traits.find((t) => t.technical_name === property);

      if (trait) {
        if (trait.isAddon) return FEATURE_PROPERTY_CATEGORIES.INTEGRATIVE;
        if (trait.traitGroup.name === OTHER_NUMERICAL_PROPERTIES_GROUP)
          return FEATURE_PROPERTY_CATEGORIES.IMPORTED;
        if (traitsObject[property?.split("|")[0]])
          return FEATURE_PROPERTY_CATEGORIES.HIPHEN;
      }

      if (plotRatingConfig.some((pc) => pc.label === property))
        return FEATURE_PROPERTY_CATEGORIES.RATING;
      if (RESERVED_PROPERTY_KEYS.includes(property))
        return FEATURE_PROPERTY_CATEGORIES.RESERVED;
      return FEATURE_PROPERTY_CATEGORIES.DEFAULT;
    },
    [contracts, resultMap.trial, traits, traitsObject]
  );

  return getPropertyCategory;
};
