import { traitGroupCompare } from "../../services/utils";
import { useMemo } from "react";

export const useTraitGroups = (traits) =>
  useMemo(() => {
    const groups = traits
      .toSorted((a, b) => a.name.localeCompare(b.name))
      .reduce((groups, trait) => {
        const trueTrait = trait.isAddon ? trait.baseTrait : trait;
        const group = trueTrait.traitGroup;
        if (!groups[group.uuid]) groups[group.uuid] = { ...group, Traits: [] };

        groups[group.uuid].Traits.push(trait);
        return groups;
      }, {});
    return Object.values(groups).sort(traitGroupCompare);
  }, [traits]);
