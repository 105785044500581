import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  FILE_TYPE_LABEL,
  GEO_EXT,
  GEO_EXT_TOOLTIP,
  SESSION_STATUS,
} from "../../constants";
import React, { createContext, useContext } from "react";

import DatasetUploader from "./DatasetFileUploader";

export const FormContext = createContext({
  loading: false,
  datasetCount: 0,
  onChange: () => {},
  values: {},
  session: {},
  statusFinish: SESSION_STATUS.Completed,
});

const onChangeScalar = (type, key, values, onChange) => {
  return (event) =>
    onChange({
      ...values,
      event,
      type,
      key,
      data: { [key]: event.target.value },
    });
};

function FormTemplate({ children }) {
  return <div className="m-1">{children}</div>;
}

const ScalarInput = ({ label, ...forInput }) => {
  const context = useContext(FormContext);
  const { id, type, required, helptooltip } = forInput;
  const value = context.values[id] ?? "";
  const hasValue = !!value;
  const isRequired = !hasValue && required;

  const inputProps = {
    ...forInput,
    ...(type !== "file" ? { value } : { title: value }),
    required: isRequired,
    onChange: onChangeScalar(type, id, context.values, context.onChange),
  };

  return (
    <LabelInput
      label={`${label} ${inputProps.required ? " *" : ""}`}
      helptooltip={helptooltip}
    >
      {type === "file" ? (
        <div className="input-group mb-3">
          <Input
            {...inputProps}
            className="form-control"
            disabled={context.loading}
          />
        </div>
      ) : (
        <Input {...inputProps} disabled={context.loading} />
      )}
    </LabelInput>
  );
};

const LabelInput = ({ label, helptooltip, children }) => {
  return (
    <FormGroup>
      <Label>
        {label}
        {helptooltip && (
          <i
            className="fa fa-lg fa-info-circle collapseToggleTitle"
            data-tooltip-id="tooltip"
            data-tooltip-content={helptooltip}
          />
        )}
      </Label>

      {children}
    </FormGroup>
  );
};

function UploadForm() {
  return (
    <FormTemplate name="Upload">
      <Row>
        <Col col="6">
          <DatasetUploader />
        </Col>
      </Row>
    </FormTemplate>
  );
}

function RoverForm() {
  return (
    <FormTemplate name="Rover">
      <Row>
        <Col col="6">
          <DatasetUploader />
        </Col>
      </Row>
    </FormTemplate>
  );
}

function LiteralForm() {
  return (
    <FormTemplate name="Literal">
      <Row>
        <Col col="6">
          <DatasetUploader />
        </Col>
      </Row>
    </FormTemplate>
  );
}

function UavRgbForm() {
  return (
    <FormTemplate>
      <Row>
        <Col>
          <DatasetUploader
            options={{ restrictions: { allowedFileTypes: ["image/*"] } }}
          />
        </Col>
        <Col>
          <ScalarInput label={FILE_TYPE_LABEL.sensor} type="text" id="sensor" />
          <ScalarInput
            label={FILE_TYPE_LABEL.flight_altitude}
            type="number"
            id="flight_altitude"
            min="0"
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.plot_file}
            accept={GEO_EXT.plot_file.join(",")}
            type="file"
            id="plot_file"
            helptooltip={GEO_EXT_TOOLTIP}
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.gcp_file}
            accept={GEO_EXT.gcp_file.join(",")}
            type="file"
            id="gcp_file"
            helptooltip={GEO_EXT_TOOLTIP}
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.metadata_file}
            type="file"
            id="metadata_file"
          />
        </Col>
      </Row>
    </FormTemplate>
  );
}

function UavMultispectralForm() {
  return (
    <FormTemplate>
      <Row>
        <Col>
          <DatasetUploader />
        </Col>
        <Col>
          <ScalarInput label={FILE_TYPE_LABEL.sensor} type="text" id="sensor" />
          <ScalarInput
            label={FILE_TYPE_LABEL.flight_altitude}
            type="number"
            id="flight_altitude"
            min="0"
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.plot_file}
            accept={GEO_EXT.plot_file.join(",")}
            type="file"
            id="plot_file"
            helptooltip={GEO_EXT_TOOLTIP}
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.gcp_file}
            accept={GEO_EXT.gcp_file.join(",")}
            type="file"
            id="gcp_file"
            helptooltip={GEO_EXT_TOOLTIP}
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.metadata_file}
            type="file"
            id="metadata_file"
          />
        </Col>
      </Row>
    </FormTemplate>
  );
}

function UavThermalForm() {
  return (
    <FormTemplate>
      <Row>
        <Col>
          <DatasetUploader
            options={{
              restrictions: { allowedFileTypes: ["image/*", ".tmc"] },
            }}
          />
        </Col>
        <Col>
          <ScalarInput label={FILE_TYPE_LABEL.sensor} type="text" id="sensor" />
          <ScalarInput
            label={FILE_TYPE_LABEL.flight_altitude}
            type="number"
            id="flight_altitude"
            min="0"
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.plot_file}
            accept={GEO_EXT.plot_file.join(",")}
            type="file"
            id="plot_file"
            helptooltip={GEO_EXT_TOOLTIP}
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.gcp_file}
            accept={GEO_EXT.gcp_file.join(",")}
            type="file"
            id="gcp_file"
            helptooltip={GEO_EXT_TOOLTIP}
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.metadata_file}
            type="file"
            id="metadata_file"
          />
        </Col>
      </Row>
    </FormTemplate>
  );
}

function UavArchiveForm() {
  return (
    <FormTemplate name="Archive">
      <Row>
        <Col sx="6">
          <ScalarInput
            label={FILE_TYPE_LABEL.archive_filename}
            type="text"
            id="archive_filename"
            placeholder="file.zip"
          />
          <DatasetUploader
            options={{
              restrictions: { allowedFileTypes: ["image/*", ".tmc"] },
            }}
          />
        </Col>
        <Col>
          <ScalarInput label={FILE_TYPE_LABEL.sensor} type="text" id="sensor" />
          <ScalarInput
            label={FILE_TYPE_LABEL.flight_altitude}
            type="number"
            id="flight_altitude"
            min="0"
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.plot_file}
            accept={GEO_EXT.plot_file.join(",")}
            type="file"
            id="plot_file"
            helptooltip={GEO_EXT_TOOLTIP}
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.gcp_file}
            accept={GEO_EXT.gcp_file.join(",")}
            type="file"
            id="gcp_file"
            helptooltip={GEO_EXT_TOOLTIP}
          />
          <ScalarInput
            label={FILE_TYPE_LABEL.metadata_file}
            type="file"
            id="metadata_file"
          />
        </Col>
      </Row>
    </FormTemplate>
  );
}

const UploadTemplate = {
  UavMultispectral: "uav_multispectral",
  UavThermal: "uav_thermal",
  UavRgb: "uav",
  Rover: "rover",
  Literal: "literal",
  Upload: "upload",
  UavArchive: "uav_archive",
};

const LoadingTemplate = () => <div>Loading Template...</div>;

/**
 * Returns the template form react component for a give template type.
 *
 * @param template template form you would like
 * @returns the template form react component
 */
function getFormTemplate(template) {
  switch (template) {
    case UploadTemplate.UavThermal:
      return UavThermalForm;
    case UploadTemplate.UavMultispectral:
      return UavMultispectralForm;
    case UploadTemplate.UavRgb:
      return UavRgbForm;
    case UploadTemplate.Rover:
      return RoverForm;
    case UploadTemplate.Literal:
      return LiteralForm;
    case UploadTemplate.Upload:
      return UploadForm;
    case UploadTemplate.UavArchive:
      return UavArchiveForm;
    default:
      return LoadingTemplate;
  }
}

export function renderFormTemplate(
  template,
  input,
  datasetCount,
  loading,
  statusFinish
) {
  const FormTemplate = getFormTemplate(template);
  return (
    <FormContext.Provider
      value={{
        loading,
        datasetCount,
        session: input.session,
        values: input.data,
        onChange: input.onChange,
        statusFinish,
      }}
    >
      <FormTemplate {...input} />
    </FormContext.Provider>
  );
}
