import "./advancedFilteringItem.css";

import { Input, InputGroup, InputGroupText } from "reactstrap";

import { AdvancedChart } from "./AdvancedChart";
import PropTypes from "prop-types";
import { PropertyName } from "../../../../../components/PropertyName/PropertyName";
import { compareArrays } from "../../../../utils";
import { useGetPropertyCategory } from "../../../../../hooks/useGetPropertyCategory";

/*
 * Component showing both histogram+slider and trait information below
 * to be used in the context of advanced filtering drawer
 */
export const AdvancedFilteringItem = ({
  trait,
  active,
  threshold,
  initialThreshold,
  datasets,
  controlData,
  handleThresholdChange,
}) => {
  const step =
    Math.pow(10, Math.floor(Math.log10(trait.range[1] - trait.range[0]))) / 100;
  const isNotMovable = trait.range[0] === trait.range[1];

  const getPropertyCategory = useGetPropertyCategory();
  const category = getPropertyCategory(trait.technical_name);

  const boxShadow = active ? `0 0 8px ${category.color}` : "none";

  return (
    <div
      className={`pt-2 pb-0 p-4 advanced-filtering-item ${category.className}`}
    >
      <AdvancedChart
        disabled={isNotMovable}
        threshold={threshold}
        trait={trait}
        datasets={datasets}
        handleThresholdChange={handleThresholdChange}
        controlData={controlData}
        step={step}
        color={category.color}
      />
      <div className="d-flex justify-content-between p-3">
        <PropertyName technicalName={trait.technical_name} showIcon />
        <div className="align-items-center d-flex gap-1">
          {/* Button to revert changes made to trait since last save */}
          {!compareArrays(threshold, initialThreshold) && (
            <i
              className="fa fa-undo clickable"
              onClick={() =>
                handleThresholdChange(trait.technical_name, initialThreshold)
              }
            />
          )}
          {/* Button to reset threshold to initial trait range */}
          {active && (
            <i
              className="fa fa-times clickable"
              onClick={() =>
                handleThresholdChange(trait.technical_name, trait.range)
              }
            />
          )}
          <InputGroup style={{ boxShadow }} className="threshold-input-group">
            <Input
              disabled={isNotMovable}
              type="number"
              className="threshold-input"
              value={threshold[0]}
              step={step}
              onChange={(event) => {
                if (
                  event.target.value < threshold[1] &&
                  event.target.value >= trait.range[0]
                )
                  handleThresholdChange(trait.technical_name, [
                    event.target.value,
                    threshold[1],
                  ]);
              }}
            />
            {trait.unit && <InputGroupText>{trait.unit}</InputGroupText>}
          </InputGroup>
          <i className="fa fa-long-arrow-right" />
          <InputGroup style={{ boxShadow }} className="threshold-input-group">
            <Input
              disabled={isNotMovable}
              type="number"
              className="threshold-input"
              value={threshold[1]}
              step={step}
              onChange={(event) => {
                if (
                  event.target.value > threshold[0] &&
                  event.target.value <= trait.range[1]
                )
                  handleThresholdChange(trait.technical_name, [
                    threshold[0],
                    event.target.value,
                  ]);
              }}
            />
            {trait.unit && <InputGroupText>{trait.unit}</InputGroupText>}
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

AdvancedFilteringItem.propTypes = {
  active: PropTypes.bool.isRequired,
  trait: PropTypes.object.isRequired,
  threshold: PropTypes.array.isRequired,
  initialThreshold: PropTypes.array.isRequired,
  datasets: PropTypes.exact({
    includedData: PropTypes.array.isRequired,
    excludedData: PropTypes.array.isRequired,
    dataToInclude: PropTypes.array.isRequired,
    dataToExclude: PropTypes.array.isRequired,
  }),
  handleThresholdChange: PropTypes.func.isRequired,
  controlData: PropTypes.array.isRequired,
};
