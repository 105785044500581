import {
  ANALYTICS_EVENTS,
  GEO_EXT,
  SESSION_STATUS,
  SESSION_UPLOAD_STATUS_CLIENT,
} from "../../constants";
import { Button, Form, Label, Spinner } from "reactstrap";
import React, { useEffect, useState } from "react";
import { resetUploadInstances, uploadInstances } from "./DatasetFileUploader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import DatasetsTable from "./DatasetsTable";
import Select from "react-select";
import { Upload } from "tus-js-client";
import { addWarningAlert } from "../../actions/alerts";
import { jsonContentType } from "../../services/backendRequests";
import { renderFormTemplate } from "./FormTemplate";
import { useFetch } from "../../hooks/useFetch";
import { useTracking } from "../../analytics";

const Title = ({ contract, site, date }) => {
  const formatted = new Date(date);
  return (
    <h4 className="mb-4">
      <span className="text-secondary">{contract} /</span> <b>{site}</b>
      <span className="float-end">
        {formatted.toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </span>
    </h4>
  );
};

const Comment = ({ comment, setComment, disabled }) => {
  return (
    <div className="mt-3 mb-2">
      <textarea
        value={comment}
        className="form-control"
        placeholder="Comments"
        onChange={(e) => setComment(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

const SessionUploadStatusChoice = ({ value, handleChange }) => {
  let options = [...SESSION_UPLOAD_STATUS_CLIENT];
  options.unshift({ label: "-- Select a status --", value: "" });
  return (
    <Select
      value={options.find((e) => e.value === value)}
      options={options}
      onChange={(e) => handleChange(e)}
    />
  );
};

const scalarFiles = new Map();
export default function Session() {
  const location = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [wasLoading, setWasLoading] = useState(false);

  const { sessionId } = useParams();
  const [session, { error, refetch }] = useFetch(
    `/api/client/upload_sessions/${sessionId}`
  );

  if (error) location.push("/upload");

  const uploadTemplate = session?.upload_template?.name;

  const [comment, setComment] = useState("");
  const [statusUndefined, setStatusUndefined] = useState(false);
  const [statusChoice, setStatusChoice] = useState("");
  const datasets = session?.upload_datasets || [];
  const [scalarData, setScalarData] = useState({});
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (!session) return;
    setScalarData({ ...session.scalar_data });
  }, [session?.scalar_data]);

  useEffect(() => {
    if (!session) return;
    setComment(session.comment);
  }, [session?.comment]);

  useEffect(() => {
    if (!statusChoice) setStatusUndefined(true);
    if (statusChoice) setStatusUndefined(false);
  }, [statusChoice]);

  window.onbeforeunload = () => {
    if (loading) return "Leaving this page will stop any uploads.";
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //if manually disable by the user
    if (statusUndefined) return;

    setLoading(true);

    const files = Array.from(scalarFiles.values());
    const datasets = Array.from(uploadInstances.values());

    let nbFilesIntoDataset = 0;
    //files.length single file upload do not impact upload session status update

    for (const dataset of datasets) {
      const { files: datasetFiles } = dataset.state;
      nbFilesIntoDataset += Object.keys(datasetFiles).length;
    }

    await fetch(`/api/client/upload_sessions/${sessionId}`, {
      method: "PUT",
      body: JSON.stringify({
        comment,
        scalar_data: scalarData,
        status:
          nbFilesIntoDataset > 0
            ? SESSION_STATUS.InProgress
            : statusChoice === "partial"
            ? SESSION_STATUS.Partial
            : SESSION_STATUS.Completed,
      }),
      headers: jsonContentType(user.identity),
    });

    for (const file of files) file.start();

    for (const dataset of datasets) {
      const { files: datasetFiles } = dataset.state;
      if (Object.keys(datasetFiles).length === 0) continue;
      await dataset.upload();

      const s3Key = "";

      // Create dataset
      await fetch(`/api/client/upload_sessions/${sessionId}/upload_datasets`, {
        method: "POST",
        body: JSON.stringify({
          file_count: Object.keys(datasetFiles).length,
          root_s3_key: s3Key,
        }),
        headers: jsonContentType(user.identity),
      });
    }
    setLoading(false);
    setWasLoading(true);
    resetUploadInstances();
    scalarFiles.clear();
    setTimeout(() => {
      refetch();
    }, 1000);

    trackEvent(ANALYTICS_EVENTS.UPLOAD_SAVE);
  };

  return (
    <div className="page-content below-breadcrumb container-fluid">
      {session ? (
        <Form onSubmit={onSubmit}>
          <Title
            contract={session?.contract_info.name}
            site={session?.site?.display_name}
            date={session?.acquisition_date}
          />

          {session?.statistics?.length > 0 && (
            <DatasetsTable statistics={session?.statistics} />
          )}

          {renderFormTemplate(
            uploadTemplate,
            {
              datasets,
              data: scalarData,
              onChange: (e) => {
                if (e.type === "file") {
                  const fileName = e.data[e.key].split(/[\\/]/).pop();
                  if (!fileName) return;

                  const ext = fileName.split(".").pop();
                  const ext_auth = GEO_EXT[e.key];
                  if (ext_auth && !ext_auth.includes("." + ext)) {
                    dispatch(
                      addWarningAlert(
                        "File type (." +
                          ext +
                          ") not accepted. Accepted extensions: " +
                          ext_auth.join(", ")
                      )
                    );
                    return;
                  }

                  setScalarData((s) => ({ ...s, [e.key]: fileName }));
                  const file = e.event.target.files[0];
                  scalarFiles.set(
                    e.key,
                    new Upload(file, {
                      // Endpoint is the upload creation URL from your tus server
                      endpoint: process.env.REACT_APP_TUS_URL || "",
                      // Retry delays will enable tus-js-client to automatically retry on errors
                      retryDelays: [0, 3000, 5000, 10000, 20000],
                      // Attach additional meta data about the file for the server
                      metadata: {
                        filename: file.name,
                        filetype: file.type,
                        session_id: sessionId,
                        user_id: user.id,
                        category: e.key,
                        scalar: "true",
                      },
                      headers: user.identity,
                      fingerprint: (file, options) => {
                        return Promise.resolve(
                          [
                            "tus-br",
                            sessionId,
                            file.name,
                            file.type,
                            file.size,
                            file.lastModified,
                            options?.endpoint,
                          ].join("-")
                        );
                      },
                    })
                  );
                } else {
                  setScalarData((s) => ({ ...s, ...e.data }));
                }
              },
              session,
            },
            datasets.length,
            loading,
            statusChoice === "partial"
              ? SESSION_STATUS.Partial
              : SESSION_STATUS.Completed
          )}

          <Comment
            comment={comment ?? ""}
            setComment={setComment}
            disabled={loading}
          />

          <Label>Upload session status</Label>
          <Label className="text-danger">*</Label>
          <SessionUploadStatusChoice
            value={statusChoice ?? ""}
            handleChange={(e) => setStatusChoice(e?.value ?? "")}
          />

          <div className="text-end">
            {loading && (
              <span style={{ marginRight: "1em" }}>
                <Spinner
                  style={{ width: "1em", height: "1em", marginRight: "1em" }}
                />
                This may take a while.
              </span>
            )}

            {wasLoading && !loading && (
              <span className="text-success" style={{ marginRight: "1em" }}>
                Successfully uploaded.
              </span>
            )}

            <Button
              className="ms-2 mt-2"
              color="primary"
              disabled={loading || statusUndefined}
              type="submit"
            >
              <span>Upload & Save</span>
              {loading && (
                <Spinner
                  className="ms-2"
                  style={{ width: "1em", height: "1em" }}
                  color="light"
                />
              )}
            </Button>
          </div>
        </Form>
      ) : (
        <div>Loading session information.</div>
      )}
    </div>
  );
}
