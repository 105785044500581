import { LOGIN_MESSAGE, RESET, SIGNUP_MESSAGE } from "../actions/actionTypes";

const initialState = {
  loginMessage: "",
  signupMessage: ""
};

export default function requests(state = initialState, action) {
  switch (action.type) {
    case LOGIN_MESSAGE:
      return {
        ...state,
        loginMessage: action.loginMessage
      };
    case SIGNUP_MESSAGE:
      return {
        ...state,
        signupMessage: action.signupMessage
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
