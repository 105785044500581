import { REFRESHING, SET_SORT_PARAMS } from "./actionTypes";

export function setRefreshing(domain, refreshing) {
  return {
    type: `${REFRESHING}_${domain}`,
    refreshing
  };
}

export function setSortParams(domain, sortField) {
  return {
    type: `${SET_SORT_PARAMS}_${domain}`,
    sortField
  };
}
