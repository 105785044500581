import { Badge, Col, Container, Row } from "reactstrap";
import { fetchResultMapFeatures, upsertMapLayer } from "../actions/resultMap";
import { useClickAway, useKey } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";

import { DEFAULT_MAP_LAYERS } from "../constants";
import { FilteringProfileAbstract } from "./FilteringProfileAbstract/FilteringProfileAbstract";
import { FilteringProfileBadge } from "../components/Badges/FilteringProfileBadge/FilteringProfileBadge";
import PropTypes from "prop-types";
import { TraitBadge } from "../components/Badges/TraitBadge/TraitBadge";
import { TraitSelector } from "../powerdash/analyticsDashboard/components/traitSelector/traitSelector";
import { format } from "date-fns";
import { useKeyboardShortcut } from "../hooks/useKeyboardShortcut";

export const BottomBar = ({ toggleOffcanvas }) => {
  const [trial, filteringProfile, selectedTrait, selectedDate] = useSelector(
    ({ resultMap }) => [
      resultMap.trial,
      resultMap.filteringProfile,
      resultMap.selectedTrait,
      resultMap.trial_date,
    ]
  );

  const dispatch = useDispatch();

  const [isTraitSelectorOpen, setIsTraitSelectorOpen] = useState(false);
  const traitSelectorRef = useRef(null);
  useClickAway(traitSelectorRef, () => setIsTraitSelectorOpen(false));

  const [isOrthoSelectorOpen, setIsOrthoSelectorOpen] = useState(false);
  const orthoSelectorRef = useRef(null);
  useClickAway(orthoSelectorRef, () => setIsOrthoSelectorOpen(false));

  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);
  const dateSelectorRef = useRef(null);
  useClickAway(dateSelectorRef, () => setIsDateSelectorOpen(false));

  const [isFilteringProfileAbstractOpen, setIsFilteringProfileAbstractOpen] =
    useState(false);

  const orthoLayer = useSelector(({ resultMap }) =>
    resultMap.mapLayers.find(({ id }) => id === DEFAULT_MAP_LAYERS.ORTHO_LAYER)
  );

  const ortho = orthoLayer?.name;

  const setSelectedOrtho = (orthoName) => {
    dispatch(upsertMapLayer({ ...orthoLayer, name: orthoName }));
  };

  // Press escape to close every opened selector
  useKey("Escape", () => {
    setIsTraitSelectorOpen(false);
    setIsOrthoSelectorOpen(false);
    setIsDateSelectorOpen(false);
  });

  useKeyboardShortcut("t", () => {
    setIsTraitSelectorOpen(true);
  });

  useKeyboardShortcut("d", () => {
    setIsDateSelectorOpen(true);
  });

  return (
    trial && (
      <Container fluid className="bottom-bar">
        <Row className="h-100 g-2">
          <Col className="position-relative" sm="2">
            {isDateSelectorOpen && (
              <div className="bottom-bar-popover" ref={dateSelectorRef}>
                <div className="powerdash-component d-flex flex-column gap-1 overflow-auto">
                  {trial.trial_dates.map((date) => (
                    <Badge
                      className={`hiphen-badge elevated clickable ${
                        date === selectedDate ? "active" : ""
                      }`}
                      key={date}
                      onClick={() => {
                        setIsDateSelectorOpen(false);
                        dispatch(fetchResultMapFeatures(trial, date, false));
                      }}
                    >
                      {format(date, "d MMMM yyyy")}
                    </Badge>
                  ))}
                </div>
              </div>
            )}
            <Badge
              className="hiphen-badge elevated ps-2 small d-flex align-items-center gap-2 flex-grow-1 clickable"
              onClick={() => setIsDateSelectorOpen(true)}
            >
              <i className="fa-solid fa-calendar-days discrete-icon" />
              <Badge className="hiphen-badge active flex-grow-1">
                {format(selectedDate, "EE. d MMMM yyyy")}
              </Badge>
              {trial.trial_dates.indexOf(selectedDate) + 1} /{" "}
              {trial.trial_dates.length}
            </Badge>
          </Col>
          <Col className="position-relative" sm="2">
            {isTraitSelectorOpen && (
              <div className="bottom-bar-popover" ref={traitSelectorRef}>
                <TraitSelector />
              </div>
            )}
            {Object.keys(selectedTrait).length > 0 && (
              <TraitBadge
                className="flex-grow-1"
                trait={selectedTrait}
                onClick={() => setIsTraitSelectorOpen(true)}
              />
            )}
          </Col>
          <Col className="position-relative" sm="2">
            {isOrthoSelectorOpen && (
              <div className="bottom-bar-popover" ref={orthoSelectorRef}>
                <div className="powerdash-component gap-2">
                  {Object.keys(trial.ortho_layer[selectedDate])
                    .sort((a, b) =>
                      a.toLowerCase().localeCompare(b.toLowerCase())
                    )
                    .map((orthoName) => (
                      <Badge
                        pill
                        key={orthoName}
                        onClick={() => setSelectedOrtho(orthoName)}
                        className={`hiphen-badge text-ellipsis elevated clickable text-start ${
                          ortho === orthoName ? " active" : ""
                        }`}
                        data-tooltip-id="tooltip"
                        data-tooltip-content={orthoName}
                        data-tooltip-place="right"
                      >
                        <i className="fa-solid fa-layer-group" /> {orthoName}
                      </Badge>
                    ))}
                </div>
              </div>
            )}
            {orthoLayer &&
              (ortho !== "plain" ||
                Object.keys(trial.ortho_layer[selectedDate] ?? {}).length >
                  1) && (
                <Badge
                  pill
                  className="hiphen-badge text-ellipsis elevated active clickable gap-2"
                  onClick={() => setIsOrthoSelectorOpen(true)}
                >
                  <i className="fa-solid fa-layer-group" /> {ortho}
                </Badge>
              )}
          </Col>
          <Col></Col>
          <Col className="position-relative" sm="2">
            {filteringProfile.id && isFilteringProfileAbstractOpen && (
              <div className="bottom-bar-popover">
                <FilteringProfileAbstract filteringProfile={filteringProfile} />
              </div>
            )}
            <FilteringProfileBadge
              className="flex-grow-1"
              filteringProfile={filteringProfile}
              onClick={toggleOffcanvas}
              onMouseEnter={() => setIsFilteringProfileAbstractOpen(true)}
              onMouseLeave={() => setIsFilteringProfileAbstractOpen(false)}
            />
          </Col>
        </Row>
      </Container>
    )
  );
};

BottomBar.propTypes = {
  toggleOffcanvas: PropTypes.func.isRequired,
};
