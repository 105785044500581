import "../powerdash/powerdash.css";

import { ANALYTICS_EVENTS, MAP_TILE_LAYERS } from "../constants";
import { Col, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { BottomBar } from "./BottomBar";
import PropTypes from "prop-types";
import { WorldMap } from "./WorldMap";
import { WorldMapHud } from "./WorldMapHud";
import { fetchResultMapFeatures } from "../actions/resultMap";
import { useEffectOnce } from "react-use";
import { useHistory } from "react-router-dom";
import { useTracking } from "../analytics";

export const WorldMapLayout = ({ match }) => {
  const user = useSelector(({ user }) => user);
  const [trial, trialDate, refreshing, mapMode] = useSelector(
    ({ resultMap }) => [
      resultMap.trial,
      resultMap.trial_date,
      resultMap.refreshing,
      resultMap.mapMode,
    ]
  );

  // Creating this ref to access leaflet map in siblings and hooks
  // because putting custom components within MapContainer creates styling and behaviour issues
  const mapRef = useRef();

  const [selectedTileLayer, setSelectedTileLayer] = useState(
    MAP_TILE_LAYERS.SATELLITE
  );

  // Advanced filtering
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const { trackEvent } = useTracking();

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
    trackEvent(ANALYTICS_EVENTS.MAP_AF_BUTTON);
  };

  // Fit zoom to site and reset ortho on trial change only and on remount
  const [prevTrial, setPrevTrial] = useState(null);
  if (trial !== prevTrial) setPrevTrial(trial);

  //// URL
  const dispatch = useDispatch();
  const history = useHistory();
  const { trialId, date } = match.params;

  // Load trial if specified in URL at first render
  useEffectOnce(() => {
    if (trialId) {
      const trial_selected = user.trials.find((t) => t.id === trialId);
      if (trial_selected) {
        const dateToFetch =
          date != null && trial_selected.trial_dates.includes(date)
            ? date
            : trial_selected.trial_dates[trial_selected.trial_dates.length - 1];
        dispatch(fetchResultMapFeatures(trial_selected, dateToFetch));
      } else {
        history.push("/map");
      }
    } else {
      history.push("/map");
    }
  });

  // URL Rewriting whenever trial or date changes
  useEffect(() => {
    if (trial && !refreshing)
      window.history.replaceState({}, "", `/map/${trial.id}/${trialDate}`);
  }, [refreshing, trial, trialDate]);

  return (
    <>
      <Container fluid className="g-0 overflow-hidden h-100">
        <Col className="position-relative d-flex">
          <WorldMapHud
            mapRef={mapRef}
            selectedTileLayer={selectedTileLayer}
            setSelectedTileLayer={setSelectedTileLayer}
            isOffcanvasOpen={isOffcanvasOpen}
            toggleOffcanvas={toggleOffcanvas}
          />
          <WorldMap
            mapMode={mapMode}
            mapRef={mapRef}
            selectedTileLayer={selectedTileLayer}
          />
        </Col>
      </Container>
      <BottomBar toggleOffcanvas={toggleOffcanvas} />
    </>
  );
};

WorldMapLayout.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      trialId: PropTypes.string,
      date: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
