import { Button, Input } from "reactstrap";

import PropTypes from "prop-types";
import React from "react";
import { RefreshButton } from "../dataTable";

export default function AdminFilterHeader({
  onButtonClick,
  onFilterChange,
  onRefresh,
  refreshing,
  buttonName,
  search,
}) {
  return (
    <div className="row mb-2 mt-2 align-items-center">
      <div className="col-auto me-auto">
        <Button color="primary" onClick={onButtonClick}>
          {buttonName}
        </Button>
      </div>
      <div className="col-auto ">
        <Input
          placeholder="Search..."
          value={search}
          onChange={onFilterChange}
          type="search"
        />
      </div>
      <div className="col-auto ">
        <RefreshButton refreshing={refreshing} onRefresh={onRefresh} />
      </div>
    </div>
  );
}

AdminFilterHeader.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  refreshing: PropTypes.bool.isRequired,
  buttonName: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
};
