export const hasFeaturesRole = (roles) =>
  !!roles?.find((role) => role === "view_features");

export const hasExperimentsRole = (roles) =>
  !!roles?.find((role) => role === "view_experiments");

export const hasMonitoringRole = (roles) =>
  !!roles?.find((role) => role === "view_monitoring");

export const hasAnalyticsRole = (roles) =>
  !!roles?.find((role) => role === "view_analytics");

export const hasCreateSiteRole = (roles) =>
  !!roles?.find((role) => role === "create_site");

export const hasRatePlotsRole = (roles) =>
  !!roles?.find((role) => role === "rate_plots");

export const hasEditPlotRatingConfigRole = (roles) =>
  !!roles?.find((role) => role === "edit_plot_rating_config");
