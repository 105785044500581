import {
  USER_ROLE_ADMIN,
  USER_ROLE_BACKOFFICE,
  USER_ROLE_REGULAR
} from "../constants";

export function extendUserWithRole(user) {
  let role = USER_ROLE_REGULAR;
  if (user.group) {
    if (user.admin) {
      role = USER_ROLE_ADMIN;
    } else {
      role = USER_ROLE_BACKOFFICE;
    }
  }

  return {
    ...user,
    role
  };
}

export function hasBackofficeAccess(user) {
  return [USER_ROLE_ADMIN, USER_ROLE_BACKOFFICE].includes(user.role);
}

export function hasAdminAccess(user) {
  return user.role === USER_ROLE_ADMIN;
}

export function belongsToGroup(userA, userB) {
  return !userA.group || !userB.group || userA.group.id === userB.group.id;
}

export function labelForUserRole(user) {
  const { role } = user;
  return role === USER_ROLE_REGULAR ? role : `${role} ${user.group.name}`;
}

export function availableRolesForSelection(adminUser) {
  return [
    {
      label: USER_ROLE_REGULAR,
      value: USER_ROLE_REGULAR
    },
    {
      label: `${USER_ROLE_BACKOFFICE} ${adminUser.group.name}`,
      value: USER_ROLE_BACKOFFICE
    },
    {
      label: `${USER_ROLE_ADMIN} ${adminUser.group.name}`,
      value: USER_ROLE_ADMIN
    }
  ];
}
