import { hasBackofficeAccess } from "./roles";
import { parseUrlParams } from "./urlParser";

let currentUrlFetched = {};

/* Generic Helpers */

export function jsonContentType(headers) {
  return {
    ...headers,
    "Content-Type": "application/json",
  };
}

function handleResponse(response) {
  if (!response.ok) {
    if (response.headers.get("content-type").includes("application/json"))
      return response.json().then((errors) => {
        const errorMessage = errors.errors
          ? errors.errors.join(". ")
          : errors.message || errors.error;
        return Promise.reject(errorMessage);
      });

    return Promise.reject(
      new Error(`${response.status} ${response.statusText}`)
    );
  }

  if (response.status === 204)
    // no content, early return
    return Promise.resolve(response.status);

  return response.json();
}

function handleResponseAndErrors(response) {
  return response
    .json()
    .then((json) => json)
    .catch(() => response);
}

//request next data in case of multipages answer from DynamoDB
function handleNext(
  user,
  url_api,
  data,
  property_name,
  _record = null,
  calledBy
) {
  if (calledBy != null)
    if (url_api !== currentUrlFetched[calledBy])
      return Promise.reject(new Error(`All next has been cancelled`));

  if (_record == null) _record = data[property_name];
  if (data.meta.next_page) {
    var sep = url_api.includes("?") ? "&" : "?";
    return fetch(`${url_api}${sep}next_page=${data.meta.next_page}`, {
      headers: user.identity,
    })
      .then(handleResponse)
      .then((data) =>
        handleNext(
          user,
          url_api,
          data,
          property_name,
          _record.concat(data[property_name]),
          calledBy
        )
      )
      .catch((response) =>
        Promise.reject(new Error(`${response.status} ${response.statusText}`))
      );
  }
  return _record;
}

/* General purpose */
export function sendMail(mailContent, user) {
  return fetch(`/api/client/contact`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(mailContent),
  }).then(handleResponse);
}

/* Current User and Users */
export function requestFetchUser(userId, user) {
  return fetch(`/api/users/${userId}`, {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchUsers(user) {
  return fetch("/api/users", {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestUpdateUser(userId, attrs, user) {
  if (userId)
    return fetch(`/api/users/${userId}`, {
      method: "PATCH",
      headers: jsonContentType(user.identity),
      body: JSON.stringify(attrs),
    }).then(handleResponse);
  else
    return fetch(`/api/users/add`, {
      method: "POST",
      headers: jsonContentType(user.identity),
      body: JSON.stringify(attrs),
    }).then(handleResponse);
}

export function requestUpdateUserRole(userId, attrs, user) {
  return fetch(`/api/users/${userId}/role`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(attrs),
  }).then(handleResponse);
}

export function requestPasswordResetInstructions(email) {
  return fetch("/api/users/password", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email,
      redirect_url: "/password/edit",
    }),
  }).then(handleResponseAndErrors);
}

export function requestUpdatePassword(
  password,
  passwordConfirmation,
  urlParams
) {
  const params = parseUrlParams(urlParams);
  const { token, client, uid } = params;
  const headers = {
    token,
    client,
    uid,
  };

  return fetch("/api/users/password", {
    method: "PUT",
    headers: jsonContentType({
      "access-token": headers.token,
      client: headers.client,
      uid: headers.uid,
    }),
    body: JSON.stringify({
      password,
      password_confirmation: passwordConfirmation,
    }),
  }).then(handleResponseAndErrors);
}

export function requestDeleteUser(userToDeleteId, user) {
  return fetch(`/api/users/${userToDeleteId}`, {
    method: "DELETE",
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchUserContracts(user) {
  return fetch("/api/client/contract_infos", {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchUserTrials(user) {
  var url_api = "/api/client/trials";
  return fetch(url_api, {
    headers: user.identity,
  })
    .then(handleResponse)
    .then((data) => handleNext(user, url_api, data, "trials"));
}

/* Treatment Chains */

export function requestFetchTreatmentChains(user) {
  return fetch("/api/treatment_chains", {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchTreatmentChain(treatmentChainId, user) {
  return fetch(`/api/treatment_chains/${treatmentChainId}`, {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestDuplicateChain(treatmentChainId, user) {
  return fetch("/api/treatment_chains", {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify({ original_chain_id: treatmentChainId }),
  }).then(handleResponse);
}

export function requestUpdateTreatmentChain(treatmentChain, payload, user) {
  return fetch(`/api/treatment_chains/${treatmentChain.id}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(payload),
  }).then(handleResponse);
}

/* Treatments */

export function requestFetchAbortedTreatments(user) {
  return fetch("/api/treatments/aborted", {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchTreatment(treatmentId, user) {
  return fetch(`/api/treatments/${treatmentId}`, {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestCreateTreatment(serviceId, treatmentPayload, user) {
  return fetch(`/api/provision_services/${serviceId}/treatments`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(treatmentPayload),
  }).then(handleResponse);
}

export function requestFileUploadURL(site, user) {
  return fetch(`/api/client/sites/${site.id}/experiments/attachment`, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestStartTreatment(treatment, treatmentPayload, user) {
  const url = `/api/treatments/${treatment.id}/start`;
  return fetch(url, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(treatmentPayload),
  }).then(handleResponse);
}

export function requestRecoverTreatment(treatment, user) {
  const url = `/api/treatments/${treatment.id}/recover`;
  return fetch(url, {
    method: "PATCH",
    headers: user.identity,
  }).then(handleResponse);
}

export function requestDeleteTreatment(treatmentId, user) {
  return fetch(`/api/treatments/${treatmentId}`, {
    method: "DELETE",
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchAllTreatments(user) {
  return fetch("/api/treatments", {
    headers: user.identity,
  }).then(handleResponse);
}

/* Contracts and Systems */

export function requestFetchContracts(user) {
  return fetch("/api/contracts", {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestCreateContract(newContract, user) {
  return fetch("/api/contracts", {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(newContract),
  }).then(handleResponse);
}

export function requestUpdateContract(contract, user) {
  return fetch(`/api/contracts/${contract.id}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(contract),
  }).then(handleResponse);
}

export function requestDeleteContract(contractId, user) {
  return fetch(`/api/contracts/${contractId}`, {
    method: "DELETE",
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchSystems(user) {
  return fetch("/api/systems", {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

/* Service Provisions */

export function requestFetchProvisions(contractId, user) {
  return fetch(`/api/contracts/${contractId}/provisions`, {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchPendingProvisions(user) {
  return fetch("/api/provisions/pending", {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchProvision(provisionId, user) {
  return fetch(`/api/provisions/${provisionId}`, {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchProvisionTreatments(provisionId, user) {
  return fetch(`/api/provisions/${provisionId}/treatments`, {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchProvisionService(serviceId, user) {
  return fetch(`/api/provision_services/${serviceId}`, {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestCreateProvision(contractId, provisionPayload, user) {
  return fetch(`/api/contracts/${contractId}/provisions`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(provisionPayload),
  }).then(handleResponse);
}

export function requestUpdateProvision(provisionId, provisionPayload, user) {
  return fetch(`/api/provisions/${provisionId}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(provisionPayload),
  }).then(handleResponse);
}

export function requestDeleteProvision(provisionId, user) {
  return fetch(`/api/provisions/${provisionId}`, {
    method: "DELETE",
    headers: user.identity,
  }).then(handleResponse);
}

/* Consumptions */

export function requestFetchConsumption(month, user) {
  return fetch(`/api/consumption?month=${month}`, {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

/* Client */

export function requestFetchClientProvisions(user) {
  return fetch("/api/client/provisions", {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchClientProvision(provisionId, user) {
  return fetch(`/api/client/provisions/${provisionId}`, {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchClientProvisionTreatments(provisionId, user) {
  return fetch(`/api/client/provisions/${provisionId}/treatments`, {
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchAllClientTreatments(user) {
  return fetch("/api/client/treatments", {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchClientSites(contract, user) {
  return fetch(`/api/client/contract_infos/${contract.id}/sites`, {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchClientSite(site, user) {
  return fetch(`/api/client/sites/${site.id}`, {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchTraitGroups(user) {
  return fetch("/api/client/trait_groups", {
    headers: user.identity,
  }).then(handleResponse);
}

/* Company */

export function requestFetchCompanyContracts(user, company) {
  var url_api = `/api/companies/${company.id}/contract_infos`;
  return fetch(url_api, {
    headers: user.identity,
  }).then(handleResponse);
}

/* Result Map */

export function requestFetchResultMapFeatures(user, site, trial_date) {
  var url_api = `/api/client/trials/${site.id}/features?date=${trial_date}`;
  return fetch(url_api, {
    headers: user.identity,
  })
    .then(handleResponse)
    .then((data) => handleNext(user, url_api, data, "features"));
}

export function requestFeaturesPropertiesByDate(user, site) {
  var url_api = `/api/client/trials/${site.id}/features?date=all`;
  if (currentUrlFetched.requestFeaturesPropertiesByDate !== url_api) {
    currentUrlFetched.requestFeaturesPropertiesByDate = url_api;
    return fetch(url_api, {
      headers: user.identity,
    })
      .then(handleResponse)
      .then((data) =>
        handleNext(
          user,
          url_api,
          data,
          "features",
          null,
          "requestFeaturesPropertiesByDate"
        )
      );
  } else {
    return Promise.reject(new Error("fetch already in progress"));
  }
}

/* Administration */

/* Administration - Companies */

export function requestFetchCompanies(user) {
  return fetch("/api/companies", {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestUpdateCompany(company, user) {
  return fetch(`/api/companies/${company.id}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(company),
  }).then(handleResponse);
}

export function requestCreateCompany(company, user) {
  return fetch(`/api/companies`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify({ name: company.name }),
  }).then(handleResponse);
}

/* Administration Contracts */

export function requestFetchContractInfo(contract, user) {
  return fetch(`/api/contract_infos/${contract.id}`, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestUpdateContractInfo(contract, user) {
  return fetch(`/api/contract_infos/${contract.id}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(contract),
  }).then(handleResponse);
}

export function requestCreateContractInfo(company, contract, user) {
  return fetch(`/api/companies/${company.id}/contract_infos`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(contract),
  }).then(handleResponse);
}

export function requestDeleteContractInfo(contract, user) {
  return fetch(`/api/contract_infos/${contract.id}`, {
    method: "DELETE",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function fetchUploadSessions(site, user) {
  return fetch(`/api/sites/${site.id}/upload_sessions`, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function fetchUploadSession(us, user) {
  const url = hasBackofficeAccess(user.self)
    ? `/api/upload_sessions/${us.id}`
    : `/api/client/upload_sessions/${us.id}`;
  return fetch(url, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function deleteUploadSession(us, user) {
  return fetch(`/api/upload_sessions/${us.id}`, {
    method: "DELETE",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function fetchAllUploadSessions(user, status = null, system = null) {
  let url = "/api/upload_sessions" + (status ? "?status=" + status : "");
  let sep = url.includes("?") ? "&" : "?";
  url += system ? sep + "system=" + system : "";

  return fetch(url, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

/* Administration Sites */

export function requestFetchSites(contract, user) {
  return fetch(`/api/contract_infos/${contract.id}/sites`, {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestUpdateSite(site, user) {
  return fetch(`/api/sites/${site.id}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(site),
  }).then(handleResponse);
}

//Update site from client endpoint is limited to experiment status
export function requestUpdateClientSite(site, user) {
  return fetch(`/api/client/sites/${site.id}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify({ experiment_status: site.experiment_status }),
  }).then(handleResponse);
}

export function requestUpdateClientSiteExperimentStatus(site, user) {
  return fetch(`/api/client/sites/${site.id}/experiment_status`, {
    method: "PUT",
    headers: jsonContentType(user.identity),
    body: JSON.stringify({ experiment_status: site.experiment_status }),
  }).then(handleResponse);
}

export function requestFetchSite(site, user) {
  return fetch(`/api/sites/${site.id}`, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestCreateSite(contract, site, user) {
  return fetch(`/api/contract_infos/${contract.id}/sites`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(site),
  }).then(handleResponse);
}

export function requestClientCreateSite(contract, site, user) {
  return fetch(`/api/client/contract_infos/${contract.id}/sites`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(site),
  }).then(handleResponse);
}

export function requestClientUpdateSite(site, user) {
  return fetch(`/api/client/sites/${site.id}`, {
    method: "PUT",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(site),
  }).then(handleResponse);
}

export function requestDeleteSite(site, user) {
  return fetch(`/api/sites/${site.id}`, {
    method: "DELETE",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchTrialBySite(site, user) {
  return fetch(`/api/trials/${site.id}`, {
    headers: user.identity,
  }).then(handleResponse);
}

/* Mission */
export function requestCreateMission(site, mission, user) {
  return fetch(`/api/sites/${site.id}/missions`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(mission),
  }).then(handleResponse);
}

export function requestDeleteMission(mission, user) {
  return fetch(`/api/missions/${mission.id}`, {
    method: "DELETE",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

/* Crop */

export function requestFetchCrops(user) {
  return fetch(`/api/crops`, {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestCreateCrop(crop, user) {
  return fetch(`/api/crops`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(crop),
  }).then(handleResponse);
}

export function requestDeleteCrop(crop, user) {
  return fetch(`/api/crops/${crop.id}`, {
    method: "DELETE",
    headers: user.identity,
  }).then(handleResponse);
}

export function requestUpdateCrop(crop, user) {
  return fetch(`/api/crops/${crop.id}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(crop),
  }).then(handleResponse);
}

// Experiments

export const requestFetchSiteExperiments = (site, user) => {
  return fetch(`/api/sites/${site.id}/experiments`, {
    headers: user.identity,
  }).then(handleResponse);
};

export const requestFetchClientExperiments = (site, user) => {
  return fetch(`/api/client/sites/${site.id}/experiments`, {
    headers: user.identity,
  }).then(handleResponse);
};

export const requestFetchClientSiteExperiments = (site, user) => {
  return fetch(`/api/client/sites/${site.id}/experiments`, {
    headers: user.identity,
  }).then(handleResponse);
};

export function requestCreateClientExperiment(site, user, experiment) {
  return fetch(`/api/client/sites/${site.id}/experiments`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(experiment),
  }).then(handleResponse);
}

export function requestUpdateClientExperiment(experiment, user) {
  return fetch(`/api/client/experiments/${experiment.id}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(experiment),
  }).then(handleResponse);
}

export function requestDeleteClientExperiment(experimentId, user) {
  return fetch(`/api/client/experiments/${experimentId}`, {
    method: "DELETE",
    headers: user.identity,
  }).then(handleResponse);
}

export function requestUpdateContractUsers(contractUserList, contractId, user) {
  return fetch(`/api/contract_infos/${contractId}/users`, {
    method: "PUT",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(contractUserList),
  }).then(handleResponse);
}

export function requestUpdateContractUser(contractId, user) {
  return fetch(`/api/contract_infos/${contractId}/user`, {
    method: "PUT",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

/* Mission Monitorings */
export function requestFetchMissionsMonitoring(contractUuids, user) {
  let params = "";
  contractUuids.forEach((id) => {
    params += `&contract_infos[]=${id}`;
  });

  return fetch(`/api/client/missions_monitoring?${params}`, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchMissionMonitoring(missionMonitoring, user) {
  return fetch(`/api/client/missions_monitoring/${missionMonitoring.id}`, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestDeleteMissionMonitoring(missionMonitoring, user) {
  return fetch(`/api/missions_monitoring/${missionMonitoring.id}`, {
    method: "DELETE",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

// Comments
export function requestFetchMissionsMonitoringComments(
  missionMonitoring,
  user
) {
  return fetch(`/api/missions_monitoring/${missionMonitoring.id}/comments`, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestCreateMissionsMonitoringComment(
  missionMonitoring,
  user,
  comment
) {
  return fetch(
    `/api${
      !hasBackofficeAccess(user.self) ? "/client" : ""
    }/missions_monitoring/${missionMonitoring.id}/comments`,
    {
      method: "POST",
      headers: jsonContentType(user.identity),
      body: JSON.stringify(comment),
    }
  ).then(handleResponse);
}

export function requestMarkAsReadMissionMonitoringComment(
  user,
  comment,
  recipient,
  date
) {
  let url = `/api/client/mission_monitoring/comments/${comment.id}/mark_as_read`;
  let body = { reading_date: date };

  if (hasBackofficeAccess(user.self)) {
    url = `/api/mission_monitoring/comments/${comment.id}/mark_as_read`;
    body = { recipient_id: recipient.id, reading_date: date };
  }

  return fetch(url, {
    method: "PUT",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(body),
  }).then(handleResponse);
}

// Flags & steps
export function requestFetchMissionsMonitoringFlags(missionMonitoring, user) {
  return fetch(`/api/missions_monitoring/${missionMonitoring.id}/flags`, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestCreateMissionsMonitoringFlag(
  missionMonitoring,
  user,
  flag
) {
  return fetch(`/api/missions_monitoring/${missionMonitoring.id}/flags`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(flag),
  }).then(handleResponse);
}

export function requestUpdateMissionsMonitoringFlag(flag, user) {
  return fetch(`/api/flags/${flag.id}`, {
    method: "PATCH",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(flag),
  }).then(handleResponse);
}

export function requestDeleteMissionsMonitoringFlag(flag, user) {
  return fetch(`/api/flags/${flag.id}`, {
    method: "DELETE",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFetchMissionsMonitoringSteps(missionMonitoring, user) {
  return fetch(`/api/missions_monitoring/${missionMonitoring.id}/steps`, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestFeaturePresignedUrl(site, plot, date, user) {
  return fetch(
    `/api/client/trials/${site.id}/features_sample/${plot.id}?date=${date}`,
    {
      method: "GET",
      headers: jsonContentType(user.identity),
    }
  ) // TODO
    .then(handleResponse)
    .then((res) => {
      return { url: res.url, plotId: plot.id };
    });
}

// Plot Rating
export function requestCreateOrUpdatePlotRating(
  site,
  feature,
  rating,
  date,
  user
) {
  return fetch(`/api/client/sites/${site.id}/plot_ratings`, {
    method: "PUT",
    headers: jsonContentType(user.identity),
    body: JSON.stringify({
      acquisition_date: date,
      rating: rating,
      plot_id: feature.id,
    }),
  });
}

export function requestFetchPlotRatings(user, site, trial_date) {
  var url_api = `/api/client/sites/${site.id}/plot_ratings?date=${trial_date}`;
  return fetch(url_api, {
    headers: user.identity,
  }).then(handleResponse);
}

// Filtering profiles
export function requestFetchFilteringProfile(user, id) {
  return fetch(`/api/client/filtering_profiles/${id}`, {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestFetchFilteringProfiles(user, site) {
  return fetch(`/api/client/sites/${site.id}/filtering_profiles`, {
    headers: user.identity,
  }).then(handleResponse);
}

export function requestCreateFilteringProfile(user, site, profile) {
  return fetch(`/api/client/sites/${site.id}/filtering_profiles`, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(profile),
  }).then(handleResponse);
}

export function requestUpdateFilteringProfile(user, profile) {
  return fetch(`/api/client/filtering_profiles/${profile.id}`, {
    method: "PUT",
    headers: jsonContentType(user.identity),
    body: JSON.stringify({
      ...profile,
      filters: profile.filters.map(({ date, traits, excludedGroups }) => ({
        date,
        traits,
        excluded_groups: excludedGroups,
      })),
    }),
  }).then(handleResponse);
}

export function requestDeleteFilteringProfile(user, profile) {
  return fetch(`/api/client/filtering_profiles/${profile.id}`, {
    method: "DELETE",
    headers: jsonContentType(user.identity),
    body: JSON.stringify(profile),
  }).then(handleResponse);
}

export function requestSetCompletedTour(user) {
  return fetch(`/api/client/set_completed_tour`, {
    method: "PUT",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

// Acquisition report
export function fetchAcquisitionReports(user, siteId) {
  var url_api = `/api/client/sites/${siteId}/hcc_missions`;
  return fetch(url_api, {
    headers: user.identity,
  }).then(handleResponse);
}

export function fetchHccMission(user, missionId) {
  var url_api = `/api/client/hcc_missions/${missionId}`;
  return fetch(url_api, {
    method: "GET",
    headers: jsonContentType(user.identity),
  }).then(handleResponse);
}

export function requestUpdatePlotRatingConfig(
  user,
  contract,
  plotRatingConfig
) {
  var url_api = `/api${
    hasBackofficeAccess(user.self) ? "" : "/client"
  }/contract_infos/${contract.id}`;
  return fetch(url_api, {
    method: "PUT",
    headers: jsonContentType(user.identity),
    body: JSON.stringify({ plot_rating_config: plotRatingConfig }),
  }).then(handleResponse);
}

export function requestCreateClientRequest(user, siteId, requestType) {
  var url_api = `/api/client/sites/${siteId}/client_requests`;
  return fetch(url_api, {
    method: "POST",
    headers: jsonContentType(user.identity),
    body: JSON.stringify({ request_type: requestType }),
  }).then(handleResponse);
}
