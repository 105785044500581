import {
  requestCreateCompany,
  requestFetchCompanies,
  requestUpdateCompany,
} from "../services/backendRequests";
import {
  COMPANY_UPDATE_ALERT,
  SET_COMPANIES
} from "./actionTypes";
import { addDangerAlert } from "./alerts";
import { setRefreshing } from "./dataTable";

function refreshingCompanies(refreshing = true) {
  return setRefreshing("companies", refreshing);
}

function setCompanies(companies) {
  return {
    type: SET_COMPANIES,
    companies
  };
}

export function fetchCompanies() {
  return (dispatch, getState) => {
    dispatch(refreshingCompanies());
    requestFetchCompanies(getState().user)
      .then(response => {
        dispatch(setCompanies(response));
        dispatch(refreshingCompanies(false));
      })
      .catch(err => dispatch(addDangerAlert(err)));
  };
}

export function updateCompanyAlert(alertMessage) {
  return {
    type: COMPANY_UPDATE_ALERT,
    updateAlert: alertMessage
  };
}

export function resetCompanyAlert() {
  return dispatch => dispatch(updateCompanyAlert(""));
}

export function updateCompanyInformation(company) {
  return (dispatch, getState) => {
    requestUpdateCompany(company, getState().user)
      .then(() => {
        dispatch(fetchCompanies());
      })
      .catch(err => dispatch(updateCompanyAlert(err)));
  };
}

export function createCompany(company) {
  return (dispatch, getState) => {
    requestCreateCompany(company, getState().user)
      .then(() => {
        dispatch(fetchCompanies());
      })
      .catch(err => dispatch(updateCompanyAlert(err)));
  };
}
