import { ADD_NEW_CONTRACT, SET_CONTRACTS } from "../actions/actionTypes";

import dataTableBehavior from "./dataTableBehavior";

const dataTable = dataTableBehavior("contracts", {
  sortField: "name"
});

const initialState = {
  all: [],
  table: dataTable(undefined, {}) // eslint-disable-line no-undefined
};

export default function contracts(state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_CONTRACT:
      return {
        ...state,
        all: [...state.all, action.newContract]
      };
    case SET_CONTRACTS:
      return {
        ...state,
        all: action.contracts
      };
    default:
      return {
        ...state,
        table: dataTable(state.table, action)
      };
  }
}
