import "./OffcanvasTabs.css";

import PropTypes from "prop-types";
import { useKeyboardShortcut } from "../../hooks/useKeyboardShortcut";

export const OffcanvasTabs = ({ isOffcanvasOpen, toggleOffcanvas }) => {
  useKeyboardShortcut("f", toggleOffcanvas);
  return (
    <div className="offcanvas-tabs">
      <div
        className={`tab p-3 powerdash-component me-0 d-flex flex-row align-items-center clickable gap-2 ${
          isOffcanvasOpen ? "active" : ""
        }`}
        onClick={toggleOffcanvas}
      >
        <i className="fa-solid fa-filter" />
        <span className="tab-text">Advanced filtering</span>
        <kbd>F</kbd>
      </div>
    </div>
  );
};

OffcanvasTabs.propTypes = {
  isOffcanvasOpen: PropTypes.bool.isRequired,
  toggleOffcanvas: PropTypes.func.isRequired,
};
