import "./mainList.css";

import { Badge, Row } from "reactstrap";
import {
  MISSION_MONITORING_FLAG_COLORS,
  MISSION_MONITORING_STATIC_STATUS_ICONS,
} from "../../../constants";
import {
  dateSort,
  formatDate,
  getContrastYIQ,
  groupBy,
  humanize,
  occurrences,
  sumKey,
} from "../../../utils";

import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import { CountryFlag } from "../../../../components/CountryFlag/CountryFlag";
import { HIPHEN_GREEN } from "../../../../constants";
import { MissionDetailsCard } from "../missionDetailsCard/missionDetailsCard";
import PropTypes from "prop-types";
import classnames from "classnames";
import { fetchAcquisitionReports } from "../../../../services/backendRequests";
import { useState } from "react";

const DetailsCounters = ({ element }) => {
  return (
    <div className="date-details">
      {element.count_active_flags === 0 ? (
        <>
          <i className="fa fa-flag discrete-icon" />
          {element.count_active_flags}
        </>
      ) : (
        <>
          <i
            style={{ color: MISSION_MONITORING_FLAG_COLORS.RAISED }}
            className="fa fa-flag"
          />
          {element.count_active_flags}{" "}
        </>
      )}
      {element.count_unread_comments === 0 ? (
        <>
          <i className="fa fa-commenting discrete-icon" />
          {element.count_unread_comments}
        </>
      ) : (
        <>
          <i style={{ color: HIPHEN_GREEN }} className="fa fa-commenting" />
          {element.count_unread_comments}
        </>
      )}
    </div>
  );
};

const ElementItem = ({
  element,
  getStatusColor,
  refresh,
  user,
  acquisitionReportResults,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const elementClasses = classnames("date-item powerdash-component clickable", {
    active: !collapsed,
  });

  return (
    <div className="date-item-wrapper">
      <div className={elementClasses} onClick={() => setCollapsed(!collapsed)}>
        <span className="date-item-name">
          <i
            className={`fa ${
              MISSION_MONITORING_STATIC_STATUS_ICONS[
                element.status.replaceAll(" ", "_")
              ] ?? "fa-circle"
            }`}
            style={{
              color:
                getStatusColor(element.substatus) ??
                getStatusColor(element.status),
            }}
          />{" "}
          {formatDate(element.acquisition_date)}
        </span>
        <span className="date-item-column">
          <i className="fa fa-camera discrete-icon" />
          <span>{element.sensor_type}</span>
        </span>
        <span className="date-item-column">
          <span>
            <i className="fa fa-leaf discrete-icon" /> {element.crop}
          </span>
        </span>
        <div
          className="date-item-column"
          style={{
            color:
              getStatusColor(element.substatus) ??
              getStatusColor(element.status),
            background: "white",
            fontSize: "smaller",
            fontWeight: "bold",
          }}
        >
          {humanize(element.substatus)}
        </div>
        <DetailsCounters element={element} />
      </div>
      {!collapsed && (
        <MissionDetailsCard
          element={element}
          getStatusColor={getStatusColor}
          refresh={refresh}
          user={user}
          acquisitionReportResults={acquisitionReportResults}
        />
      )}
    </div>
  );
};

const SiteItem = ({
  site,
  elements,
  statusList,
  getStatusColor,
  refresh,
  user,
}) => {
  const statusCounts = occurrences(statusList, elements, "status");
  const [collapsed, setCollapsed] = useState(true);
  const [acquisitionReportResults, setAcquisitionReportResults] =
    useState(null);
  const onClickAcquisitionReport = async () => {
    setCollapsed(!collapsed);
    try {
      const results = await fetchAcquisitionReports(user, site.id);
      setAcquisitionReportResults(results);
    } catch {}
  };

  return (
    <>
      <Row
        className="site-item powerdash-component clickable"
        onClick={onClickAcquisitionReport}
      >
        <CountryFlag alpha={site.country} />
        <div className="site-labels">
          <span className="site-name">{site.display_name}</span>
        </div>
        <div className="status-badges">
          {Object.entries(statusCounts)?.map(
            (statusCountEntry) =>
              statusCountEntry[1] > 0 && (
                <Badge
                  // if color is not valid "primary secondary...", the background color is overriden by the background color of the style
                  color="invalid background color"
                  pill
                  style={{
                    background: getStatusColor(statusCountEntry[0]),
                    color: getContrastYIQ(getStatusColor(statusCountEntry[0])),
                  }}
                  key={statusCountEntry[0]}
                >
                  {statusCountEntry[1]}
                </Badge>
              )
          )}
        </div>
        <DetailsCounters
          element={{
            count_active_flags: sumKey(elements, "count_active_flags"),
            count_unread_comments: sumKey(elements, "count_unread_comments"),
          }}
        />
      </Row>
      <div className="collapse-list">
        {!collapsed &&
          elements !== undefined &&
          elements
            ?.sort((a, b) => {
              return dateSort(a.acquisition_date, b.acquisition_date);
            })
            .map((element) => (
              <ElementItem
                key={element.id}
                element={element}
                getStatusColor={getStatusColor}
                refresh={refresh}
                user={user}
                acquisitionReportResults={acquisitionReportResults}
              />
            ))}
      </div>
    </>
  );
};

const ContractItem = ({
  name,
  elements,
  statusList,
  getStatusColor,
  refresh,
  user,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const caretClasses = classnames("fa fa-caret-right", {
    "fa-rotate-90": !collapsed,
  });
  return (
    <>
      <div
        className="clickable contract-item"
        title={name}
        onClick={() => setCollapsed(!collapsed)}
      >
        <h3>
          <i className={caretClasses} />
        </h3>
        <h3>{name}</h3>
      </div>
      {!collapsed &&
        Object.entries(groupBy(elements, "site", "id"))
          ?.sort((a, b) => {
            // Comparing sites of first elements in element arrays
            // because every element should has the same site
            const aname = a[1][0].site?.display_name.toLowerCase();
            const bname = b[1][0].site?.display_name.toLowerCase();
            if (aname < bname) return -1;

            if (aname > bname) return 1;

            return 0;
          })
          .map((element) => (
            <SiteItem
              key={element[0]}
              site={element[1][0].site}
              elements={element[1]}
              statusList={statusList}
              getStatusColor={getStatusColor}
              refresh={refresh}
              user={user}
            />
          ))}
    </>
  );
};

/*
 * Main list displays mission monitoring collapsed below site and contract levels
 * every item show its associated mision details card
 */
export const MainList = ({
  contracts,
  elements,
  statusList,
  getStatusColor,
  refresh,
  user,
}) => {
  return (
    <div className="main-list">
      {elements.length === 0 ? (
        <ComponentPlaceholder
          icon="fa-file-text"
          text="No campaign selected or no data"
        />
      ) : (
        <div className="overflow-list-wrapper">
          <div className="date-list">
            {Object.entries(groupBy(elements, "contract_info", "id"))?.map(
              (contract) => (
                <ContractItem
                  key={contract[0]}
                  name={contracts.find((c) => c.id === contract[0])?.name ?? ""}
                  elements={contract[1]}
                  statusList={statusList}
                  getStatusColor={getStatusColor}
                  refresh={refresh}
                  user={user}
                />
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ElementItem.propTypes = {
  element: PropTypes.object.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

SiteItem.propTypes = {
  site: PropTypes.object.isRequired,
  elements: PropTypes.array.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  statusList: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

ContractItem.propTypes = {
  name: PropTypes.string.isRequired,
  elements: PropTypes.array.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  statusList: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

MainList.propTypes = {
  contracts: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  statusList: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
