import {
  LOGIN_MESSAGE,
  RESET,
  RESET_USER_CONTRACTS_TRIALS,
  SET_USER,
  SET_USER_CONTRACTS,
  SET_USER_CONTRACTS_LOADING,
  SET_USER_CONTRACTS_TRIALS,
  SET_USER_SIDEBAR_COLLAPSE,
  SIGNUP_MESSAGE,
} from "./actionTypes";
import { addDangerAlert, addSuccessAlert } from "./alerts";
import {
  requestFetchUser,
  requestFetchUserContracts,
  requestFetchUserTrials,
  sendMail,
} from "../services/backendRequests";

import _ from "lodash";
import { onMount } from "./onMount";

/**
 * Log user into API
 */
export function login(email, password) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_MESSAGE,
      loginMessage: "",
    });

    return fetch("/api/users/sign_in", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }).then((response) => {
      const identity = {
        "access-token": response.headers.get("access-token"),
        client: response.headers.get("client"),
        expiry: response.headers.get("expiry"),
        "token-type": response.headers.get("token-type"),
        uid: response.headers.get("uid"),
      };

      response.json().then((userData) => {
        const user = userData.data;

        if (response.status === 200) {
          // Set user and identity
          dispatch({
            type: SET_USER,
            identity,
            user,
          });

          // Fetchs on mount executed when user log in or user is refreshed
          dispatch(onMount({ identity }));
        } else {
          dispatch({
            type: LOGIN_MESSAGE,
            loginMessage: "Email or password are incorrect.",
          });
        }
      });
    });
  };
}

/**
 * Create a new user, then log them in.
 */
export function signup(user) {
  return (dispatch) => {
    dispatch({
      type: SIGNUP_MESSAGE,
      signupMessage: "",
    });

    return fetch("/api/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...user,
        password_confirmation: user.password,
      }),
    }).then(async (response) => {
      if (response.status === 200) {
        dispatch(login(user.email, user.password));
      } else {
        const res = await response.json();

        dispatch({
          type: SIGNUP_MESSAGE,
          signupMessage: res.errors.full_messages
            ? res.errors.full_messages[0]
            : "An error has occurred, please try again",
        });
      }
    });
  };
}

export function refreshUser() {
  return (dispatch, getState) => {
    const { user } = getState();

    if (_.isEmpty(user.self)) return;

    requestFetchUser(user.self.id, user)
      .then((userData) => {
        dispatch({
          type: SET_USER,
          identity: user.identity,
          user: userData,
        });

        // Fetchs on mount executed when user log in or user is refreshed
        dispatch(onMount(user));
      })
      .catch((err) => {
        dispatch({
          type: RESET,
        });
        dispatch({
          type: LOGIN_MESSAGE,
          loginMessage: err,
        });
      });
  };
}

/**
 * Remove user object from store on logout
 */
export function logout() {
  return (dispatch, getState) => {
    return fetch("/api/users/sign_out", {
      method: "DELETE",
      headers: getState().user.identity,
    }).then(
      dispatch({
        type: RESET,
      })
    );
  };
}

function setUserContracts(contracts) {
  return {
    type: SET_USER_CONTRACTS,
    contracts,
  };
}

function setUserContractsTrials(contracts, trials) {
  return {
    type: SET_USER_CONTRACTS_TRIALS,
    contracts,
    trials,
  };
}

function setUserContractsLoading() {
  return {
    type: SET_USER_CONTRACTS_LOADING,
  };
}

export function fetchUserContracts() {
  return (dispatch, getState) => {
    dispatch(setUserContractsLoading());
    requestFetchUserContracts(getState().user)
      .then(([contracts]) => {
        dispatch(setUserContracts(contracts));
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

export function fetchUserContractsTrials() {
  return (dispatch, getState) => {
    dispatch(setUserContractsLoading());
    Promise.all([
      requestFetchUserContracts(getState().user),
      requestFetchUserTrials(getState().user),
    ])
      .then(([contracts, trials]) => {
        dispatch(setUserContractsTrials(contracts, trials));
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

/**
 * Remove user object from store on logout
 */
export function resetUserContractsTrials() {
  return (dispatch) => {
    dispatch({
      type: RESET_USER_CONTRACTS_TRIALS,
    });
  };
}

export function sendContactMail(mailContent) {
  return (dispatch, getState) => {
    sendMail(mailContent, getState().user)
      .then(() =>
        dispatch(
          addSuccessAlert(
            "Mail sent. We will come back to you as soon as possible."
          )
        )
      )
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

export function setSidebarCollapse() {
  return (dispatch) => {
    dispatch({
      type: SET_USER_SIDEBAR_COLLAPSE,
    });
  };
}
