import PageTitle from "../components/pageTitle";
import Placeholder from "./placeholder";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

class PendingAccess extends React.Component {
  componentDidMount() {
    // User is redirected to home page if he is granted access
    // This prevent users being soflocked in this page by accessing it via url
    if (this.props.user.self.access) this.props.history.push("/");
  }
  render() {
    return (
      <div>
        <div className="page-content container-fluid">
          <PageTitle title="Pending Access" />

          <Placeholder message="You do not have access to this page or your account is not yet enabled" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    user: store.user,
  };
}

export default connect(mapStateToProps)(PendingAccess);

PendingAccess.propTypes = {
  user: PropTypes.object.isRequired,
};
