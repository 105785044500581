import "./detailGrid.css";

import { Badge } from "reactstrap";
import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import { DataCard } from "../dataCard/dataCard";
import { LABEL_ALL } from "../../../../constants";
import PropTypes from "prop-types";
import { shouldBeFilteredByGroups } from "../..";
import { useMemo } from "react";

const generateCombinations = (input) => {
  const entries = Object.entries(input);
  return entries.reduce(
    (combinations, [group, groupValues]) => {
      return groupValues.flatMap((value) =>
        combinations.map((combination) => ({ ...combination, [group]: value }))
      );
    },
    [{}]
  );
};

/*
 * Grid display scrollable of smaller charts showing grouped content
 */
export const DetailGrid = ({
  data,
  statusList,
  getStatusColor,
  keyToCount,
  handleGroupClick,
  groupingObject,
  generateCombinationString,
}) => {
  const allCombinations = useMemo(
    () => generateCombinations(groupingObject),
    [groupingObject]
  );

  const detailGridNodes = useMemo(
    () =>
      allCombinations.map((combination) => {
        const groupData = data.filter((elem) =>
          shouldBeFilteredByGroups(elem, combination)
        );

        const title = generateCombinationString(combination);

        return (
          groupData.length > 0 && (
            <div className="detail-grid-item" key={title}>
              <DataCard
                title={title}
                data={groupData}
                statusList={statusList}
                getStatusColor={getStatusColor}
                keyToCount={keyToCount}
                showLegend={false}
                onClick={(label) => {
                  handleGroupClick(label, combination);
                }}
                topRightContent={
                  <Badge
                    className="clickable"
                    onClick={() => {
                      if (title !== LABEL_ALL)
                        handleGroupClick(null, combination);
                    }}
                  >
                    {groupData.length}
                  </Badge>
                }
              />
            </div>
          )
        );
      }),
    [
      allCombinations,
      data,
      generateCombinationString,
      getStatusColor,
      handleGroupClick,
      keyToCount,
      statusList,
    ]
  );

  return (
    <div className="overflow-list-wrapper ">
      {data.length === 0 ? (
        <ComponentPlaceholder
          icon="fa-file-text"
          text="No campaign selected or no data"
        />
      ) : (
        <div className="detail-grid">{detailGridNodes}</div>
      )}
    </div>
  );
};

DetailGrid.propTypes = {
  data: PropTypes.array.isRequired,
  statusList: PropTypes.array.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  keyToCount: PropTypes.string.isRequired,
  handleGroupClick: PropTypes.func.isRequired,
  groupingObject: PropTypes.object.isRequired,
  generateCombinationString: PropTypes.func.isRequired,
};
