import App from "./app";
//import { MaintenancePage } from "./maintenance";
import React from "react";
import { createRoot } from "react-dom/client";
import { unregister } from "./registerServiceWorker";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

unregister();
