import "./page404.css";

import { Button } from "reactstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import cloverfieldLogo from "../static/img/cloverfield_icon_base.png";
import { useSelector } from "react-redux";

export const CrashPage = ({ error }) => {
  const state = useSelector((state) => ({
    user: state.user.self,
    trial: state.resultMap.trial,
  }));
  return (
    <div className="page-404">
      <img
        className="cloverfield-logo"
        src={cloverfieldLogo}
        alt="cloverfield-logo"
      />
      <h1>
        <i className="fa fa-bomb" /> Something unexpected happened
      </h1>
      <h3>
        <CopyToClipboard
          text={`Error: ${error?.error}
          
          ${JSON.stringify(error?.errorInfo)}
          
          ${JSON.stringify(state)}`}
        >
          <Button className="hiphen-green-button">
            <i className="fa fa-copy" /> Copy error
          </Button>
        </CopyToClipboard>{" "}
        and send feedback to hiphen
      </h3>
      <hr></hr>
      <Button color="primary" href="/">
        Bring me back home
      </Button>
    </div>
  );
};
