import { createSelector } from "reselect";

export const selectTraitsIndexedOnTechnicalName = createSelector(
  (state) => state.traits.all,
  (traits) => {
    const traitsIndexedOnTechnicalName = {};
    traits.forEach((trait) => {
      traitsIndexedOnTechnicalName[trait.technical_name] = trait;
    });
    return traitsIndexedOnTechnicalName;
  }
);
