import { ComponentTitle } from "../../../components/componentTitle/componentTitle";
import { ExpandButton } from "../../../components/expandButton/expandButton";
import { LABEL_OVERVIEW } from "../../../../constants";
import { NULL_GROUP_LABEL } from "../../../constants";
import { PieChart } from "../../../components/charts/pieChart";
import PropTypes from "prop-types";
import { occurrences } from "../../../utils";
import { useState } from "react";

/*
 * Component wrapping a pie chart
 */
export const DataCard = ({
  data,
  title,
  statusList,
  keyToCount,
  getStatusColor,
  showLegend,
  topRightContent,
  onClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={`powerdash-component ${expanded ? "expanded" : ""}`}>
      <ComponentTitle
        icon={"fa fa-pie-chart"}
        title={title ?? NULL_GROUP_LABEL}
        topRightContent={
          <>
            {topRightContent}
            {title === LABEL_OVERVIEW && (
              <ExpandButton expanded={expanded} setExpanded={setExpanded} />
            )}
          </>
        }
      />

      <PieChart
        data={occurrences(statusList, data, keyToCount, true)}
        getStatusColor={getStatusColor}
        showLegend={showLegend}
        onClick={onClick}
      />
    </div>
  );
};

DataCard.propTypes = {
  data: PropTypes.array.isRequired,
  statusList: PropTypes.array.isRequired,
  keyToCount: PropTypes.string.isRequired,
  getStatusColor: PropTypes.func.isRequired,
  title: PropTypes.string,
  showLegend: PropTypes.bool,
  topRightContent: PropTypes.any,
  onClick: PropTypes.func,
};
