import { DEFAULT_ERROR_MESSAGE } from "../constants";
import chroma from "chroma-js";
import { selectOrtho } from "../selectors/resultMap";
import useSWRImmutable from "swr/immutable";
import { useSelector } from "react-redux";

const fetcher = async (url) => {
  let error;
  let data;
  let response;
  try {
    response = await fetch(url);
    const text = await response.text();
    const parser = new DOMParser();
    data = parser.parseFromString(text, "text/xml");
    data = Array.from(data.getElementsByTagName("sld:ColorMapEntry"))
      .map(({ attributes }) => ({
        color: attributes.color.nodeValue,
        quantity: attributes.quantity.nodeValue,
      }))
      .slice(1); // Remove -1 array first element
  } catch {
    throw new Error(DEFAULT_ERROR_MESSAGE);
  }

  // If response ok, create colorScale object
  if (response.ok) {
    const minValue = Number(data[0].quantity);
    const maxValue = Number(data[data.length - 1].quantity);

    return {
      func: chroma.scale(data.map((d) => d.color)).domain([minValue, maxValue]),
      minValue,
      maxValue,
      step: data.length,
    };
  }
  // If status is not 2XX, set error object using error returned by API
  else {
    error = new Error(DEFAULT_ERROR_MESSAGE);
    error.status = response.status;

    throw error;
  }
};

export const useWMSColorScale = () => {
  const ortho = useSelector(selectOrtho);
  const url = `${process.env.REACT_APP_GEOSERVER_URL}?&service=WMS&request=GetStyles&layers=${ortho}&version=1.1.1`;
  const { data } = useSWRImmutable(url, fetcher);

  return data;
};
