import { COMPANY_HIPHEN } from "../constants";
import { createSelector } from "reselect";

export const selectUsersWithCompany = createSelector(
  (state) => state.users,
  (users) =>
    users.all.map((user) => {
      let displayName = user.company ? String(user.company.name) + " | " : "";
      displayName += user.fullname;
      return { label: displayName, value: user.id };
    })
);

export const selectInternalUsers = createSelector(
  (state) => state.users,
  (users) =>
    users.all
      .filter(
        (user) =>
          user.company?.name.toLowerCase() === COMPANY_HIPHEN.toLowerCase()
      )
      .map((user) => {
        return { label: user.fullname, value: user.id };
      })
);
