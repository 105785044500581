import {
  FEATURE_PROPERTY_CATEGORIES,
  IMPORTED_DATA_TYPES,
} from "../../../../constants";

import { Badge } from "reactstrap";
import { PropTypes } from "prop-types";

export function UpdatedColumn({
  header,
  setType,
  count,
  isCastableToNumber,
  headerType,
}) {
  return (
    <div className="d-flex justify-content-between align-items-center gap-1">
      <span
        className="d-flex gap-1 align-items-center text-ellipsis attribute"
        title={header}
      >
        <Badge
          className="hiphen-badge small opacity-50"
          data-tooltip-id="tooltip"
          data-tooltip-html="Number of values"
        >
          {count}
        </Badge>
        <span className="text-ellipsis">{header}</span>
      </span>
      <div className="d-flex justify-content-between align-items-center gap-1">
        <Badge
          className={`datatype-badge small ${
            isCastableToNumber ? "clickable" : "disabled"
          }`}
          color="invalid" // Programatically set
          style={
            headerType === IMPORTED_DATA_TYPES.NUMERIC
              ? {
                  border: `1px solid ${FEATURE_PROPERTY_CATEGORIES.IMPORTED.color}`,
                  backgroundColor: FEATURE_PROPERTY_CATEGORIES.IMPORTED.light,
                  color: FEATURE_PROPERTY_CATEGORIES.IMPORTED.color,
                }
              : {}
          }
          onClick={() =>
            isCastableToNumber && setType(IMPORTED_DATA_TYPES.NUMERIC)
          }
        >
          {FEATURE_PROPERTY_CATEGORIES.IMPORTED.icon} numeric
        </Badge>
        <Badge
          className="datatype-badge clickable small attribute"
          color="invalid"
          style={
            headerType === IMPORTED_DATA_TYPES.ATTRIBUTE
              ? {
                  border: `1px solid ${FEATURE_PROPERTY_CATEGORIES.DEFAULT.color}`,
                  backgroundColor: FEATURE_PROPERTY_CATEGORIES.DEFAULT.light,
                  color: "black",
                }
              : {}
          }
          onClick={() => setType(IMPORTED_DATA_TYPES.ATTRIBUTE)}
        >
          {FEATURE_PROPERTY_CATEGORIES.DEFAULT.icon} attribute
        </Badge>
      </div>
    </div>
  );
}

UpdatedColumn.propTypes = {
  header: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  isCastableToNumber: PropTypes.bool.isRequired,
  headerType: PropTypes.string.isRequired,
};
