import "./PlotView.css";

import { Badge, Table } from "reactstrap";
import { HIPHEN_GREEN, THEME } from "../../constants";
import {
  selectFilteredAggregatedFeatures,
  selectMergedTraits,
} from "../../selectors/resultMap";
import { setSelectedPlotId, setSelectedTrait } from "../../actions/resultMap";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useKey, useTimeoutFn } from "react-use";

import { FigureZoom } from "../../components/FigureZoom";
import { FloatingCard } from "../../components/floatingCard/FloatingCard";
import { ImageHistory } from "./ImageHistory";
import { NULL_GROUP_LABEL } from "../../powerdash/constants";
import { PlotModal } from "../../components/PlotModal/PlotModal";
import PropTypes from "prop-types";
import { PropertiesMasonry } from "../../components/PropertiesMasonry/PropertiesMasonry";
import { PropertyBadge } from "../../components/Badges/PropertyBadge/PropertyBadge";
import { PropertyName } from "../../components/PropertyName/PropertyName";
import { VarietyBadge } from "../../components/Badges/VarietyBadge/VarietyBadge";
import { requestFeaturePresignedUrl } from "../../services/backendRequests";
import { truncateNumber } from "../../services/utils";
import { useGetPropertyCategory } from "../../hooks/useGetPropertyCategory";

export const PlotView = ({ feature }) => {
  const user = useSelector((state) => state.user);
  const [selectedTrait, trial, trialDate, selectedPlotId] = useSelector(
    ({ resultMap }) => [
      resultMap.selectedTrait,
      resultMap.trial,
      resultMap.trial_date,
      resultMap.selectedPlotId,
    ]
  );

  const aggregatedFeatures = useSelector(selectFilteredAggregatedFeatures);
  const variety = aggregatedFeatures.find(
    ({ group }) => group === feature.group
  );
  const traits = useSelector(selectMergedTraits);
  const getPropertyCategory = useGetPropertyCategory();
  const selectedTraitCategory = getPropertyCategory(
    selectedTrait.technical_name
  );
  const [images, setImages] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);

  const replicates = useMemo(
    () =>
      variety.features.toSorted(
        (a, b) =>
          b.properties[selectedTrait.technical_name] -
          a.properties[selectedTrait.technical_name]
      ),
    [selectedTrait.technical_name, variety.features]
  ).map((feature, _, array) => ({
    ...feature,
    diff:
      feature.properties[selectedTrait.technical_name] -
      array[0].properties[selectedTrait.technical_name],
  }));

  // Used to reset state whenever the selected plot changes
  const [prevFeature, setPrevFeature] = useState(null);
  if (prevFeature !== feature) {
    setImages({});
    setPrevFeature(feature);
  }

  // Fetch image at this date
  const fetchImage = useCallback(
    async (date) => {
      let url;
      try {
        const res = await requestFeaturePresignedUrl(
          trial,
          feature,
          date,
          user
        );
        url = res.url;
      } catch (e) {}
      setImages((currentState) => ({
        ...currentState,
        [date]: { url },
      }));
    },
    [feature, trial, user]
  );

  // Fetch current image to display in zoom view
  useEffect(() => {
    fetchImage(trialDate);
  }, [fetchImage, trialDate]);

  const [plotModalIsOpen, setPlotModalOpen] = useState(false);

  const handleImageClick = (date) => {
    setSelectedDate(date);
    setPlotModalOpen(true);
  };
  const handleClosePlotModal = () => {
    setPlotModalOpen(false);
  };

  const dispatch = useDispatch();
  const [showHistory, setShowHistory] = useState(false);

  const color = variety.modality
    ? THEME.modalityColorHash.hex(variety.modality)
    : HIPHEN_GREEN;

  // 100ms delay before closing history
  const [isReadyToCloseHistory, cancelCloseHistory, resetCloseHistory] =
    useTimeoutFn(() => setShowHistory(false), 100);

  const closeHistory = useCallback(() => {
    if (isReadyToCloseHistory() === false) cancelCloseHistory();
    else resetCloseHistory();
  }, [cancelCloseHistory, isReadyToCloseHistory, resetCloseHistory]);

  // Press escape to close PlotView
  useKey("Escape", () => dispatch(setSelectedPlotId(null)));

  return (
    <div className="plot-view powerdash-component" key={variety.group}>
      <PlotModal
        src={images[selectedDate]?.url}
        isOpen={plotModalIsOpen}
        toggle={handleClosePlotModal}
      />
      <div className="w-75 h-100 d-flex flex-column gap-1 variety-content">
        <VarietyBadge color={color} variety={variety} />
        {traits.length > 0 ? (
          <Table
            className="variety-table flex-grow-1 mb-0"
            size="sm"
            borderless
          >
            <thead>
              <tr>
                <th className="w-50"></th>
                <th>
                  <i className="fa fa-long-arrow-down" /> Min
                </th>
                <th>x̄ Mean</th>
                <th>
                  <i className="fa fa-long-arrow-up" /> Max
                </th>
              </tr>
            </thead>
            <tbody className="scrollbar-light">
              {traits.map((trait) => (
                <tr
                  key={trait.technical_name}
                  className={`clickable ${
                    trait.technical_name === selectedTrait.technical_name
                      ? "active"
                      : ""
                  }`}
                  onClick={() => dispatch(setSelectedTrait(trait))}
                >
                  <th className="w-50" scope="row">
                    <PropertyName
                      showIcon
                      showUnit
                      technicalName={trait.technical_name}
                    />
                  </th>
                  <td>
                    {truncateNumber(
                      variety.properties[trait.technical_name]?.min ??
                        NULL_GROUP_LABEL,
                      2
                    )}
                  </td>
                  <td>
                    {truncateNumber(
                      variety.properties[trait.technical_name]?.mean ??
                        NULL_GROUP_LABEL,
                      2
                    )}
                  </td>
                  <td>
                    {truncateNumber(
                      variety.properties[trait.technical_name]?.max ??
                        NULL_GROUP_LABEL,
                      2
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <span className="text-end font-style-italic">
            Details will be available when traits are delivered
          </span>
        )}
        <div className="variety-features d-flex flex-row gap-1">
          <div className="d-flex flex-column w-50 gap-1">
            <span className="font-weight-bold">
              Replicates ({variety.features.length})
            </span>
            <div className="d-flex flex-column gap-1 overflow-auto scrollbar-light">
              {replicates.map((feature, index) => (
                <Badge
                  key={feature.id}
                  className={`repetition hiphen-badge small d-flex justify-content-between align-items-center elevated clickable ${
                    selectedPlotId === feature.id ? "selected" : ""
                  }`}
                  onClick={() => dispatch(setSelectedPlotId(feature.id))}
                >
                  <span className="repetition-id">{feature.displayId}</span>

                  {selectedTrait.technical_name && (
                    <div className="flex-shrink-1 d-flex flex-row gap-1 align-items-center">
                      {index === 0 ? (
                        <Badge
                          color="invalid"
                          style={{ backgroundColor: HIPHEN_GREEN }}
                        >
                          BEST
                        </Badge>
                      ) : (
                        <i>{Number(truncateNumber(feature.diff)) || "="}</i>
                      )}
                      <PropertyBadge
                        hideName
                        technicalName={selectedTrait.technical_name}
                        value={feature.properties[selectedTrait.technical_name]}
                        category={selectedTraitCategory}
                      />
                    </div>
                  )}
                </Badge>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="powerdash-component plot-view-plot-card gap-1"
        key={feature.id}
      >
        <span className="plot-view-plot-card-title p-1 mb-1">
          {feature.displayId}
        </span>
        <div
          className="plot-view-plot-card-close clickable pe-2"
          onClick={() => dispatch(setSelectedPlotId(null))}
        >
          <i className="fa fa-close" />
        </div>
        <div
          className="plot-explorer"
          onMouseEnter={() => {
            cancelCloseHistory();
            setShowHistory(true);
          }}
          onMouseLeave={closeHistory}
        >
          {images[trialDate] ? (
            <FigureZoom
              src={images[trialDate].url}
              onClick={() => handleImageClick(trialDate)}
            />
          ) : (
            <div className="img-skeleton" />
          )}
          {showHistory && (
            <FloatingCard hide={() => setShowHistory(false)}>
              <ImageHistory
                trial={trial}
                images={images}
                trialDate={trialDate}
                handleImageClick={handleImageClick}
                fetchImage={fetchImage}
              />
            </FloatingCard>
          )}
          <div className="d-flex p-1 justify-content-between">
            <span className="informative">
              <i className="fa-solid fa-image" /> {trialDate}
            </span>
            <span
              className={`font-style-italic informative history-text ${
                showHistory ? "active" : ""
              }`}
            >
              Plot history <i className="fa-solid fa-angle-right" />
            </span>
          </div>
        </div>
        <PropertiesMasonry
          feature={feature}
          highlightedProperty={selectedTrait.technical_name}
        />
      </div>
    </div>
  );
};

PlotView.propTypes = {
  feature: PropTypes.object.isRequired,
};
