import {
  requestCreateOrUpdatePlotRating,
  requestFeaturePresignedUrl,
} from "../../../../services/backendRequests";
import { useCallback, useMemo, useState } from "react";

import { Badge } from "reactstrap";
import { DotFalling } from "../../../../components/dotFalling";
import { PlotModal } from "../../../../components/PlotModal/PlotModal";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { capitalize } from "../../../utils";
import no_image from "../../../../static/img/microplot_placeholder.png";

/*
 * RatingScale is displaying the key and the list of values to pick from
 */
const RatingScale = ({
  ratingKey,
  options,
  currentRating,
  handleUpdateRating,
}) => {
  const mappedOptions = useMemo(
    () =>
      [{ value: undefined, label: "No selection" }].concat(
        options.map((option) => ({ value: option, label: option }))
      ),
    [options]
  );
  const selectedOption = useMemo(
    () => mappedOptions.find((o) => o.value === currentRating[ratingKey]),
    [currentRating, mappedOptions, ratingKey]
  );

  return (
    <div className="plot-rating-scale">
      <span className="plot-rating-scale-key">{capitalize(ratingKey)}</span>
      {options.length < 10 ? (
        <div className="plot-rating-scale-options">
          {options.map((option) => (
            <div
              key={option}
              className={`clickable ${
                currentRating[ratingKey] === option ? "selected" : ""
              }`}
              onClick={() => handleUpdateRating(ratingKey, option)}
            >
              {option}
            </div>
          ))}
        </div>
      ) : (
        <ReactSelect
          className="flex-grow-1"
          value={selectedOption}
          onChange={({ value }) => handleUpdateRating(ratingKey, value)}
          options={mappedOptions}
        />
      )}
    </div>
  );
};

/*
 * PlotRating displays the image of a plot and features the possibility to give it one or several rating(s) based on
 * the config that has been defined in the contract.
 */
export const PlotRating = ({
  currentPlot,
  trial,
  user,
  date,
  varietyColor,
  plotRatingConfig,
  handleNext,
  handlePrevious,
  radarChart,
  renderProgression,
}) => {
  const [imageIsLoading, setImageLoading] = useState(false);
  const [plotImage, setPlotImage] = useState(null);
  const [currentRating, setCurrentRating] = useState({});
  const [plotModalIsOpen, setPlotModalOpen] = useState(false);

  const fetchImage = useCallback(() => {
    setPlotImage(null);
    setImageLoading(true);
    return requestFeaturePresignedUrl(trial, currentPlot, date, user)
      .then((res) => {
        setPlotImage({ url: res.url });
      })
      .catch(() => {
        setPlotImage({ url: no_image });
      });
  }, [currentPlot, trial, user, date]);

  // Keep track of previous plot to fetch next image and reset current rating when selected plot changes
  const [previousPlot, setPreviousPlot] = useState(null);
  if (currentPlot !== previousPlot) {
    fetchImage();
    setPreviousPlot(currentPlot);

    // Set current rating with existing values
    setCurrentRating(
      plotRatingConfig.reduce(
        (newRating, { label }) => ({
          ...newRating,
          [label]: currentPlot.properties[label],
        }),
        {}
      )
    );
  }

  // Save rating and update state then select next plot in list
  const saveAndNext = () => {
    requestCreateOrUpdatePlotRating(
      trial,
      currentPlot,
      currentRating,
      date,
      user
    )
      .then((res) => res.json())
      .then((data) => {
        currentPlot.properties = {
          ...currentPlot.properties,
          ...data.rating,
        };
        handleNext();
      });
  };

  // Rating is complete meaning every defined rating key has been attributed a value
  const ratingIsComplete = plotRatingConfig.every(
    ({ label }) => currentRating[label] != null
  );

  const handleUpdateRating = (key, value) =>
    setCurrentRating((currentState) => ({
      ...currentState,
      [key]: currentState[key] === value ? null : value, // Unset or set value
    }));

  return (
    <>
      <PlotModal
        src={plotImage?.url}
        isOpen={plotModalIsOpen}
        toggle={() => setPlotModalOpen(false)}
      />
      <div className="plot-rating">
        <div className="plot-rating-header">
          <div>
            <Badge
              // if color is not valid "primary secondary...", the background color is overriden by the background color of the style
              color="invalid background color"
              className="variety-badge"
              style={{ background: varietyColor }}
            >
              {currentPlot.group}
            </Badge>
            {currentPlot.displayId}
          </div>
          {renderProgression}
        </div>
        <div className="plot-rating-image">
          {imageIsLoading && (
            <div className="plot-rating-image-loading">
              <DotFalling />
            </div>
          )}
          {plotImage != null && (
            <img
              className="clickable"
              alt="plotimage"
              src={plotImage.url}
              onLoad={() => setImageLoading(false)}
              onClick={() => setPlotModalOpen(true)}
            />
          )}
        </div>
        <div className="plot-rating-main">
          <div className="plot-rating-main-radar">{radarChart}</div>
          <div className="plot-rating-main-rating vertical-stack">
            <div className="plot-rating-main-rating-scales vertical-stack">
              {plotRatingConfig.map(({ label, options }) => (
                <RatingScale
                  key={label}
                  ratingKey={label}
                  options={options}
                  currentRating={currentRating}
                  handleUpdateRating={handleUpdateRating}
                />
              ))}
            </div>
            <div className="plot-rating-main-rating-controls">
              <div
                className="plot-rating-main-rating-controls-button clickable"
                onClick={handlePrevious}
              >
                <i className="fa fa-arrow-left" /> Previous
              </div>
              <div
                className={`plot-rating-main-rating-controls-button clickable ${
                  ratingIsComplete ? "ready" : ""
                }`}
                onClick={saveAndNext}
              >
                Next <i className="fa fa-arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RatingScale.propTypes = {
  ratingKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  currentRating: PropTypes.object.isRequired,
  handleUpdateRating: PropTypes.func.isRequired,
};

PlotRating.propTypes = {
  currentPlot: PropTypes.object.isRequired,
  trial: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  varietyColor: PropTypes.string.isRequired,
  plotRatingConfig: PropTypes.array.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  radarChart: PropTypes.node.isRequired,
  renderProgression: PropTypes.node.isRequired,
};
