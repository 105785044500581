import { useKey } from "react-use";

export const useKeyboardShortcut = (key, fn, deps = []) => {
  return useKey(
    (event) => {
      if (
        event.target.tagName === "INPUT" ||
        event.target.tagName === "TEXTAREA" ||
        event.target.isContentEditable
      )
        return false;
      return event.key === key;
    },
    fn,
    undefined,
    deps
  );
};
