import { Navbar } from "./components/Navbar/Navbar";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

export const Layout = ({ children }) => {
  const alerts = useSelector((state) => state.alerts.all);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (alerts.length) {
      const latestAlert = alerts.at(-1);
      enqueueSnackbar(latestAlert.message, {
        variant: latestAlert.style,
        autoHideDuration: 4000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },

        action: (snackbarId) => (
          <span
            className="clickable font-weight-bold"
            onClick={() => {
              closeSnackbar(snackbarId);
            }}
          >
            Dismiss
          </span>
        ),
      });
    }
  }, [alerts, enqueueSnackbar, closeSnackbar]);

  return (
    <div className="wrapper">
      <Navbar />
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
