import "./TrialBadge.css";

import { useDispatch, useSelector } from "react-redux";

import { Badge } from "reactstrap";
import { CountryFlag } from "../../components/CountryFlag/CountryFlag";
import { HighlightedText } from "../../components/highlightedText/HighlightedText";
import PropTypes from "prop-types";
import { fetchResultMapFeatures } from "../../actions/resultMap";

export const TrialBadge = ({
  modeMarkers,
  trial,
  disableClick,
  highlight = "",
}) => {
  const site = useSelector(({ user }) =>
    user.contracts
      .flatMap((contract) =>
        contract.sites.map((site) => ({ ...site, contract }))
      )
      .find(({ id }) => id === trial.id)
  );
  const selectedTrial = useSelector(({ resultMap }) => resultMap.trial);
  const dispatch = useDispatch();

  return modeMarkers ? (
    <div className="trial-marker">
      <div className="trial-info p-2">
        <span className="font-style-italic">{site.contract.name}</span>
        <span className="d-flex flex-row gap-1 font-weight-bold text-ellipsis">
          {site.country && <CountryFlag size="sm" alpha={site.country} />}
          {trial.display_name}
        </span>
      </div>
      <div className="trial-marker-icon">
        <i className="fa-solid fa-leaf" />
      </div>
    </div>
  ) : (
    <div
      className={`trial-badge clickable p-1 ${
        selectedTrial === trial ? "active" : ""
      }`}
      onClick={() => {
        if (!disableClick)
          dispatch(fetchResultMapFeatures(trial, trial.trial_dates.at(-1)));
      }}
    >
      <div className="d-flex align-items-center justify-content-between gap-1">
        <div className="trial-name d-flex gap-1 align-items-center text-ellipsis">
          <CountryFlag size="sm" alpha={site.country} />
          <span className="font-weight-bold text-ellipsis">
            <HighlightedText text={trial.display_name} highlight={highlight} />
          </span>
        </div>
        <Badge className="hiphen-badge small font-style-italic">
          <i className="fa-solid fa-calendar-days discrete-icon me-1" />
          {trial.trial_dates.length}
        </Badge>
      </div>
    </div>
  );
};

TrialBadge.propTypes = {
  trial: PropTypes.shape({
    id: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    trial_dates: PropTypes.array.isRequired,
  }),
  modeMarkers: PropTypes.bool,
  disableClick: PropTypes.bool,
  highlight: PropTypes.string,
};
