import { Alert, Button, Modal, ModalBody, ModalFooter } from "reactstrap";

import PropTypes from "prop-types";
import React from "react";

export default function ExperimentErrorModal({
  csvFileMessagesError,
  setCsvFileMessagesError,
}) {
  return (
    <Modal
      isOpen={csvFileMessagesError.length}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        {csvFileMessagesError.length !== 0 && (
          <Alert className="mx-auto" color="danger">
            <div className="isa_error text-center">
              <i className="fa fa-times-circle m-3"></i>
              Errors were detected in your csv file
            </div>
            {csvFileMessagesError.map((message, index) => (
              <h6 key={index} className="mt-3 instructionStyleTitle">
                {" "}
                <li>{message.errorMessage}</li>
              </h6>
            ))}
          </Alert>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          color="primary"
          className="btn"
          onClick={() => {
            setCsvFileMessagesError([]);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ExperimentErrorModal.propTypes = {
  csvFileMessagesError: PropTypes.array.isRequired,
  setCsvFileMessagesError: PropTypes.func.isRequired,
};
