import { FEATURE_PROPERTY_CATEGORIES } from "../constants";
import afGif2 from "./res/af-gif2.gif";

export const WORLDMAP_GUIDED_TOUR_STEPS = [
  {
    content: (
      <div className="d-flex flex-column gap-1 align-items-start">
        <span className="tooltip-title">Site control</span>
        <p>
          Select your{" "}
          <span className="font-weight-bold">
            <i className="fa-solid fa-map-pin me-1" />
            trial
          </span>{" "}
          and customize your experience on Cloverfield by{" "}
          <span
            className="font-weight-bold"
            style={{ color: FEATURE_PROPERTY_CATEGORIES.IMPORTED.color }}
          >
            <i className="fa-solid fa-upload me-1" />
            uploading data
          </span>
        </p>
      </div>
    ),
    target: ".control-section",
    placement: "right",
  },
  {
    content: (
      <div className="d-flex flex-column gap-1 align-items-start">
        <span className="tooltip-title">Bottom bar</span>
        <p>
          Navigate between delivered{" "}
          <span className="font-weight-bold">
            <i className="fa-solid fa-calendar me-1" />
            dates
          </span>{" "}
          and select which <span className="font-weight-bold">trait</span> you
          want to explore among
          <ul>
            <li
              className="font-weight-bold"
              style={{ color: FEATURE_PROPERTY_CATEGORIES.HIPHEN.color }}
            >
              {FEATURE_PROPERTY_CATEGORIES.HIPHEN.icon} Hiphen delivered Traits
            </li>
            <li
              className="font-weight-bold"
              style={{ color: FEATURE_PROPERTY_CATEGORIES.IMPORTED.color }}
            >
              {FEATURE_PROPERTY_CATEGORIES.IMPORTED.icon} Numeric imported
              traits
            </li>
          </ul>
        </p>
      </div>
    ),
    target: ".bottom-bar",
    placement: "left",
  },
  {
    content: (
      <div className="d-flex flex-column gap-1 align-items-start">
        <span className="tooltip-title">Advanced Filtering</span>
        <ul>
          <li>
            Define the <span className="font-weight-bold">scope</span> of your
            experiments
          </li>
          <li>
            Create{" "}
            <span className="font-weight-bold">
              <i className="fa fa-solid fa-filter" />
              Filtering Profiles
            </span>{" "}
            you can reuse
          </li>
          <li>
            Filter using interactive sliders to extract the best out of your
            trial
            <img src={afGif2} alt="AF" width={"100%"} />
          </li>
        </ul>
      </div>
    ),

    target: ".offcanvas-tabs",
    placement: "left",
  },
  {
    content: (
      <div className="d-flex flex-column gap-1 align-items-start">
        <span className="tooltip-title">Map legend</span>
        <p>
          Control{" "}
          <span className="font-weight-bold">
            <i className="fa-solid fa-eye me-1" />
            visibility
          </span>{" "}
          and <span className="font-weight-bold">opacity</span> for each
          displayed{" "}
          <span className="font-weight-bold">
            <i className="fa-solid fa-layer-group me-1" />
            layer
          </span>{" "}
        </p>
      </div>
    ),

    target: "#worldmap-legend",
    placement: "left",
  },
  {
    content: (
      <div className="d-flex flex-column gap-1 align-items-start">
        <span className="tooltip-title">Map modes</span>
        <p>
          Switch between{" "}
          <span className="font-weight-bold">
            <i className="fa-solid fa-map me-1" />
            Map modes
          </span>{" "}
          <ul className="list-unstyled ms-2">
            <li className="backoffice-only">
              <span className="font-weight-bold">
                <i className="fa-solid fa-globe-europe me-1" /> Markers
              </span>{" "}
              All sites you have access to
              <br />
              <small className="font-style-italic">
                Only available if you have many campaigns
              </small>
            </li>
            <li>
              <span className="font-weight-bold">
                <i className="fa-solid fa-map-location-dot me-1" /> Cluster
              </span>{" "}
              Every site of the current campaign with extra information{" "}
            </li>
            <li>
              <span className="font-weight-bold">
                <i className="fa fa-th me-1" /> Trial
              </span>{" "}
              Current selected site
            </li>
          </ul>
          <span className="font-style-italic">
            <i className="fa-solid fa-lightbulb me-1 discrete-icon" /> Tip: Use{" "}
            <kbd className="backoffice-only">W</kbd> <kbd>X</kbd> <kbd>C</kbd>{" "}
            keyboard shortcuts to switch between modes quickly
          </span>
        </p>
      </div>
    ),
    target: ".distance-control",
    placement: "top",
  },
];
