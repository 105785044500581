import "./loading.css";

import React from "react";

export default function LoadingImg({ visible }) {
  return (
    visible && (
      <div className="loading-wrapper">
        <div className="cloverfield-loading">
          <div className="thin-ring"></div>
          <div className="dual-ring-1"></div>
          <div className="dual-ring-2"></div>
          <div className="icon-leaf"></div>
        </div>
      </div>
    )
  );
}
