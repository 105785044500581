import { BASIC_ROLES, ROLES } from "../../constants";
import { Col, FormGroup, Label, Row } from "reactstrap";
import Select, { components } from "react-select";

import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const allOption = { label: "All basic roles", value: "all*" };

const formatUserLabel = ({ company, fullname }) =>
  `${company ? `${company.name} | ` : ""}${fullname}`;

export const UsersRolesForm = ({
  contractUserList,
  isContractCreation,
  setContractUserList,
}) => {
  const userList = useSelector((store) =>
    store.users.all.filter(({ access }) => access)
  );

  const filteredUserList = useMemo(
    () =>
      userList
        .filter(
          ({ id }) => !contractUserList.find(({ user }) => user?.id === id)
        )
        .sort((a, b) => formatUserLabel(a).localeCompare(formatUserLabel(b))),
    [contractUserList, userList]
  );

  const addUser = (event) => {
    // New line added to the top of the list
    setContractUserList((prevState) => [
      { user: null, roles: [] },
      ...prevState,
    ]);
    event.preventDefault();
  };

  const deleteUser = (event, index) => {
    setContractUserList((prevState) => {
      const newState = [...prevState];
      // Remove 1 element at given index
      newState.splice(index, 1);

      return newState;
    });
    event.preventDefault();
  };

  const onChange = (value, actionMeta, index) =>
    setContractUserList((prevState) => {
      const newstate = [...prevState];
      // actionMeta.name is either "user" or "roles", it is set using the `name` props on each select
      newstate[index] = { ...newstate[index], [actionMeta.name]: value };

      return newstate;
    });

  const onRolesChange = (selectedOptions, actionMeta, index, roles) => {
    const { action, option } = actionMeta;
    const isAllOptionSelect =
      action === "select-option" && option.value === allOption.value;

    // selectedOptions can be null even with `isMulti`
    onChange(
      isAllOptionSelect
        ? [
            ...new Set([
              ...ROLES.filter(({ value }) => BASIC_ROLES.includes(value)),
              ...roles,
            ]),
          ]
        : selectedOptions ?? [],
      actionMeta,
      index
    );
  };

  const CustomOption = ({ value, ...props }) => (
    <components.Option
      {...props}
      className={value === allOption.value && "font-italic border-bottom"}
    />
  );

  const RequiredInput = (requiredInputProps) => (
    <components.Input {...requiredInputProps} required />
  );

  return contractUserList.map(({ roles, user }, index) => (
    <Row className="align-items-center mb-2" form key={index}>
      <Col md={5}>
        <FormGroup className="my-auto">
          {index === 0 && !isContractCreation && <Label for="user">User</Label>}
          <Select
            components={{
              Input: RequiredInput,
            }}
            isClearable
            options={filteredUserList}
            id="user"
            name="user"
            placeholder={`Select${isContractCreation ? " Users" : ""}...`}
            value={user}
            onChange={(value, actionMeta) => onChange(value, actionMeta, index)}
            getOptionValue={(option) => option.id}
            getOptionLabel={formatUserLabel}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup className="my-auto">
          {index === 0 && !isContractCreation && (
            <Label for="roles">Roles</Label>
          )}
          <Select
            closeMenuOnSelect={false}
            components={{
              Option: CustomOption,
            }}
            id="roles"
            options={
              BASIC_ROLES.some(
                (role) => !roles?.find(({ value }) => value === role)
              )
                ? [allOption, ...ROLES]
                : ROLES
            }
            isMulti
            name="roles"
            placeholder={`Select${isContractCreation ? " Roles" : ""}...`}
            // Close menu when all options are selected
            noOptionsMessage={() => null}
            value={roles}
            onChange={(value, actionMeta) =>
              onRolesChange(value, actionMeta, index, roles)
            }
          />
        </FormGroup>
      </Col>
      <Col md={1}>
        {index === 0 ? (
          <FontAwesome
            className={classnames({
              "bg-white border-0 text-success": true,
              "mt-3": !isContractCreation,
            })}
            name="plus-circle"
            border={false}
            size="2x"
            tag="button"
            onClick={addUser}
          />
        ) : (
          <FontAwesome
            className="bg-white border-0"
            name="trash"
            border={false}
            size="2x"
            style={{ color: "Crimson" }}
            tag="button"
            onClick={(event) => deleteUser(event, index)}
          />
        )}
      </Col>
    </Row>
  ));
};

UsersRolesForm.propTypes = {
  contractUserList: PropTypes.arrayOf(
    PropTypes.exact({
      user: PropTypes.PropTypes.shape({
        id: PropTypes.number.isRequired,
        fullname: PropTypes.string.isRequired,
      }),
      roles: PropTypes.arrayOf(
        PropTypes.exact({
          label: PropTypes.oneOf(ROLES.map((role) => role.label)).isRequired,
          value: PropTypes.oneOf(ROLES.map((role) => role.value)).isRequired,
        })
      ).isRequired,
    })
  ),
  isContractCreation: PropTypes.bool,
  setContractUserList: PropTypes.func.isRequired,
};
