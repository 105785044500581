import React, { Component } from "react";

import { Alert } from "reactstrap";
import _ from "lodash";
import { requestPasswordResetInstructions } from "../services/backendRequests";

class AskPasswordReset extends Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    this.state = {
      resetRequestStatus: {},
    };
  }

  onEmailSubmission(event) {
    event.preventDefault();
    requestPasswordResetInstructions(this.email.current.value).then(
      (response) => {
        this.setState({ resetRequestStatus: response });
      }
    );
  }

  displayRequestStatus() {
    if (
      _.isEmpty(this.state.resetRequestStatus) &&
      _.isEmpty(this.state.resetRequestStatus.statusText)
    ) {
      return null;
    }

    const message = this.state.resetRequestStatus.success
      ? this.state.resetRequestStatus.message
      : this.state.resetRequestStatus.statusText ||
        this.state.resetRequestStatus.errors.toString();

    return (
      <div className="row alert">
        <Alert
          className="stacked mx-auto"
          color={this.state.resetRequestStatus.success ? "success" : "danger"}
        >
          {message}
        </Alert>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="text-center my-5">
            <span className="h1">Password reset</span>
          </div>

          <form className="row">
            <div className="col" />
            <div className="form-container text-center col">
              <div className="mb-3">
                <label>
                  Enter the email address associated with your account
                </label>
                <input className="form-control" type="email" ref={this.email} />
              </div>
              <button
                className="btn btn-success"
                type="submit"
                onClick={(event) => this.onEmailSubmission(event)}
              >
                Request password reset
              </button>
              {this.displayRequestStatus()}
            </div>
            <div className="col" />
          </form>
        </div>
      </div>
    );
  }
}

export default AskPasswordReset;
