import "./VarietyBadge.css";

import {
  createModalityBadgeStyle,
  getContrastYIQ,
} from "../../../powerdash/utils";

import { Badge } from "reactstrap";
import { BlackListWhiteList } from "../../../powerdash/analyticsDashboard/components/advancedFiltering/blackListWhiteList/BlackListWhiteList";
import { HIPHEN_GREEN } from "../../../constants";
import PropTypes from "prop-types";
import chroma from "chroma-js";

export const VarietyBadge = ({ variety, color = HIPHEN_GREEN }) => (
  <Badge
    className="variety-badge hiphen-badge elevated ps-0 mt-1"
    color="invalid"
    style={{
      backgroundColor: chroma(color).brighten(1).alpha(0.75).hex(),
      color: getContrastYIQ(color),
    }}
  >
    <div
      className="hexagon-wrapper"
      style={{
        backgroundColor: chroma(color).brighten(1).alpha(0.75).hex(),
      }}
    >
      <div className="hexagon p-3" style={{ backgroundColor: color }}>
        <i className="fa-solid fa-dna" />
      </div>
    </div>
    <div className="d-flex align-items-center flex-grow-1 justify-content-between">
      <span className="label" style={{ color }}>
        {variety.genotype}
      </span>
      <div className="d-flex align-items-center gap-1">
        <Badge
          color="invalid"
          style={createModalityBadgeStyle(variety.modality)}
        >
          {variety.modality}
        </Badge>
        {variety.isControl && (
          <Badge className="hiphen-badge small">
            <i className="fa fa-pagelines control-variety" /> Control Variety
          </Badge>
        )}
        <BlackListWhiteList group={variety.group} />
      </div>
    </div>
  </Badge>
);

VarietyBadge.propTypes = {
  variety: PropTypes.shape({
    genotype: PropTypes.string.isRequired,
    modality: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    isControl: PropTypes.bool.isRequired,
  }).isRequired,
  color: PropTypes.string,
};
