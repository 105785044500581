import {
  COMPANY_UPDATE_ALERT,
  RESET,
  SET_COMPANIES,
} from "../actions/actionTypes";

import dataTableBehavior from "./dataTableBehavior";

const dataTable = dataTableBehavior("companies");

const initialState = {
  all: [],
  table: dataTable(undefined, {}), // eslint-disable-line no-undefined
  updateAlert: "",
  createAlert: "",
};

export default function companies(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANIES:
      return {
        ...state,
        all: action.companies,
        updateAlert: "",
      };
    case RESET:
      return initialState;

    case COMPANY_UPDATE_ALERT:
      return {
        ...state,
        updateAlert: action.updateAlert,
      };
    default:
      return {
        ...state,
        table: dataTable(state.table, action),
      };
  }
}
