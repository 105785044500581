import { selectFilteredTrials, selectSortedTrials } from "../../selectors/user";
import { useDispatch, useSelector } from "react-redux";

import L from "leaflet";
import { MAP_MODES } from "../../constants";
import { Marker } from "@adamscybot/react-leaflet-component-marker";
import { TrialBadge } from "./TrialBadge";
import { fetchResultMapFeatures } from "../../actions/resultMap";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

export const TrialMarkerCluster = () => {
  const [mapMode, selectedTrial] = useSelector(({ resultMap }) => [
    resultMap.mapMode,
    resultMap.trial,
  ]);

  // Display filtered trials based on selected contract if mode is markers/cluster
  // However if a trial is selected, display all trials in mode markers
  const trials = useSelector(
    selectedTrial && mapMode === MAP_MODES.MARKERS
      ? selectSortedTrials
      : selectFilteredTrials
  );

  const dispatch = useDispatch();

  const map = useMap();

  useEffect(() => {
    // Global zoom when mode is markers
    if (mapMode === MAP_MODES.MARKERS) map.setView([26.0, 30.0], 3);
    if (mapMode === MAP_MODES.CLUSTER && trials.length) {
      const bounds = L.latLngBounds(
        trials.map(({ trial_position }) => trial_position)
      );
      map.fitBounds(bounds, { maxZoom: 15 });
    }
  }, [map, mapMode, trials]);

  return trials.map((trial) => (
    <Marker
      key={trial.id}
      position={trial.trial_position}
      icon={
        <TrialBadge
          modeMarkers={mapMode === MAP_MODES.MARKERS}
          disableClick
          trial={trial}
        />
      }
      eventHandlers={{
        click: () =>
          dispatch(fetchResultMapFeatures(trial, trial.trial_dates.at(-1))),
      }}
    />
  ));
};
