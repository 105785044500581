import {
  ADD_NEW_PROVISION_SERVICE,
  RESET_PROVISION,
  SET_PROVISION,
  UPDATE_PROVISION_EDITION,
  UPDATE_PROVISION_SERVICE
} from "../actions/actionTypes";
import {
  STATUS_ACTIVE_VALUE,
  STATUS_PENDING_VALUE
} from "../services/provisionStatus";

const initialState = {
  self: {
    contract: {},
    trial: "",
    place: "",
    system_id: 1,
    start_date: "",
    end_date: "",
    users_ids: [],
    status: STATUS_PENDING_VALUE,
    switchStatus: false,
    provision_services: []
  },
  initialTrial: "...",
  isEditing: false
};

const newProvisionService = {
  name: "",
  available_tokens: 1,
  note: "",
  treatment_chain_id: null,
  treatment_chain_template_id: "0",
  can_access_result: false,
  can_be_deleted: true
};

export function missingFieldsForSave(provision) {
  const requiredFields = ["trial", "place"];
  return requiredFields.filter(field => !provision[field].length);
}

function validProvisionServices(provision) {
  const validServices = provision.provision_services.filter(
    service =>
      service.name !== "" && service.treatment_chain_id && !service.to_delete
  );
  return validServices.length > 0;
}

export function missingFieldsForActivation(provision) {
  if (provision.status === STATUS_ACTIVE_VALUE) {
    return [];
  }

  const missingFields = missingFieldsForSave(provision);
  if (!provision.users_ids.length) {
    missingFields.push("users");
  }
  if (!validProvisionServices(provision)) {
    missingFields.push("treatment chains");
  }
  return missingFields;
}

export function statusSwitchDisabled(provision) {
  return missingFieldsForActivation(provision).length > 0;
}

export default function provisionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROVISION:
      return {
        ...state,
        self: {
          ...action.provision
        },
        initialTrial: action.provision.trial,
        isEditing: false
      };
    case UPDATE_PROVISION_EDITION:
      const provisionToUpdate = {
        ...state.self,
        [action.inputName]: action.value
      };
      if (statusSwitchDisabled(provisionToUpdate)) {
        provisionToUpdate.switchStatus = false;
      }

      return {
        ...state,
        self: provisionToUpdate,
        isEditing: true
      };
    case UPDATE_PROVISION_SERVICE:
      // N.B : We use an index instead of ID because some services are not created yet.
      const allServices = state.self.provision_services.slice();
      const serviceToUpdate = {
        ...allServices[action.index],
        [action.inputName]: action.value
      };
      allServices.splice(action.index, 1, serviceToUpdate);

      const provisionWithServicesToUpdate = {
        ...state.self,
        provision_services: allServices
      };
      if (statusSwitchDisabled(provisionWithServicesToUpdate)) {
        provisionWithServicesToUpdate.switchStatus = false;
      }

      return {
        ...state,
        self: provisionWithServicesToUpdate,
        isEditing: true
      };
    case ADD_NEW_PROVISION_SERVICE:
      return {
        ...state,
        self: {
          ...state.self,
          provision_services: [
            ...state.self.provision_services,
            newProvisionService
          ]
        },
        isEditing: true
      };
    case RESET_PROVISION:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export function selectAvailableServicesForCreation(services) {
  return services
    .filter(service => service.treatment_chain_id !== null)
    .map(service => {
      return {
        id: service.id,
        name: service.name,
        treatmentTraits: service.treatment_chain_traits,
        availableTokens: service.available_tokens,
        disabled: service.available_tokens === 0
      };
    });
}
