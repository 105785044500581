import "./expandButton.css";

import { Button } from "reactstrap";
import PropTypes from "prop-types";

/*
 * Expand btn is a button to use in components switching the expanded state
 */
export const ExpandButton = ({ expanded, setExpanded }) => {
  return (
    <Button
      className="expand-btn"
      size="sm"
      outline
      onClick={() => setExpanded(!expanded)}
    >
      {!expanded ? (
        <i className="fa fa-sm fa-expand" />
      ) : (
        <i className="fa fa-sm fa-compress" />
      )}
    </Button>
  );
};

ExpandButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};
