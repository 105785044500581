import "./WorldMapLegend.css";

import { WorldMapLegendItem } from "./WorldMapLegendItem";
import { useSelector } from "react-redux";

export const WorldMapLegend = () => {
  const mapLayers = useSelector(({ resultMap }) => resultMap.mapLayers);

  return (
    <div id="worldmap-legend" className="d-flex flex-column">
      {mapLayers.map((layer) => (
        <WorldMapLegendItem layer={layer} key={layer.id} />
      ))}
    </div>
  );
};
