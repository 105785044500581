import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import { fullResetResultMap } from "../../../../actions/resultMap";

export const SimpleMode = ({ onClick, trial, refreshing }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const resetWorldMap = () => {
    dispatch(fullResetResultMap());
    const currentPath = location.pathname;
    if (currentPath.startsWith("/map")) history.push("/map");
  };

  const contracts = useSelector(({ user }) => user.contracts);
  const selectedContract = contracts.find(({ id }) => id === trial.contract_id);

  return (
    <div
      className={`simple-mode p-1 gap-1 clickable ${
        refreshing ? "refreshing" : ""
      }`}
      onClick={onClick}
      data-tooltip-id="tooltip"
      data-tooltip-content="Change trial"
      data-tooltip-place="right"
    >
      {refreshing ? (
        <>Harvesting fresh data ...</>
      ) : (
        <>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <span className="title ellipsis">
              <i className="fa-solid fa-file-signature discrete-icon" />
              {selectedContract.name}
            </span>
            <i
              className="fa-regular fa-circle-xmark"
              data-tooltip-id="tooltip"
              data-tooltip-content="Quit this site"
              data-tooltip-place="right"
              data-tooltip-variant="dark"
              onClick={resetWorldMap}
            />
          </div>
          <span className="d-flex justify-content-between align-items-center">
            <span className="ellipsis">
              <i className="fa-solid fa-map-pin discrete-icon ms-1 me-1" />
              {trial.display_name}
            </span>
            <span>
              <i className="fa-solid fa-calendar-days discrete-icon me-1" />
              {trial.trial_dates.length}
            </span>
          </span>
        </>
      )}
    </div>
  );
};

SimpleMode.propTypes = {
  onClick: PropTypes.func.isRequired,
  refreshing: PropTypes.bool.isRequired,
  trial: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    trial_dates: PropTypes.array.isRequired,
  }).isRequired,
};
