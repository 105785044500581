import "./plotModal.css";

import { Modal } from "reactstrap";

export const PlotModal = ({ isOpen, toggle, src }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="plot-modal" centered>
      <img src={src} alt="Plot" />
    </Modal>
  );
};
