import {
  SET_PROVISIONS,
  UPDATE_PROVISION_STATUS
} from "../actions/actionTypes";
import dataTableBehavior from "./dataTableBehavior";

const inPreparationDataTable = dataTableBehavior("provisionsInPreparation", {
  sortField: "place"
});
const activeDataTable = dataTableBehavior("activeProvisions", {
  sortField: "place"
});
const archivedDataTable = dataTableBehavior("archivedProvisions", {
  sortField: "place"
});

const initialState = {
  all: [],
  inPreparationTable: inPreparationDataTable(undefined, {}), // eslint-disable-line no-undefined
  activeTable: activeDataTable(undefined, {}), // eslint-disable-line no-undefined
  archivedTable: archivedDataTable(undefined, {}) // eslint-disable-line no-undefined
};

export default function provisions(state = initialState, action) {
  switch (action.type) {
    case SET_PROVISIONS:
      return {
        ...state,
        all: action.provisions
      };
    case UPDATE_PROVISION_STATUS:
      const {
        provision: { id },
        status
      } = action;

      const allProvisions = state.all.map(provision => {
        if (provision.id !== id) {
          return provision;
        }

        return {
          ...provision,
          status
        };
      });

      return {
        ...state,
        all: allProvisions
      };
    default:
      return {
        ...state,
        inPreparationTable: inPreparationDataTable(
          state.inPreparationTable,
          action
        ),
        activeTable: activeDataTable(state.activeTable, action),
        archivedTable: archivedDataTable(state.archivedTable, action)
      };
  }
}
