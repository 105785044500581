import { SET_USER_CONTRACTS } from "./actionTypes";
import { addDangerAlert } from "./alerts";
import { requestFetchUserContracts } from "../services/backendRequests";

export const onMount = (user) => (dispatch) => {
  requestFetchUserContracts(user)
    .then((contracts) => {
      dispatch({ type: SET_USER_CONTRACTS, contracts });
    })
    .catch((err) => dispatch(addDangerAlert(err)));
};
