import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import React, { useEffect, useState } from "react";

export default function CropsModal(props) {
  const [cropName, setCropName] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [cropId, setCropId] = useState(null);

  useEffect(() => {
    if (props.cropToUpdate) {
      setCropName(props.cropToUpdate.name);
      setCropId(props.cropToUpdate.id);
    }
  }, [props.cropToUpdate]);

  const addNewCrop = () => {
    /* const new_crop = { name: cropName }; */
    props.createCrop({ name: cropName });
    setCropName("");
  };

  const updateCurrentCrop = (crop) => {
    props.updateCrop(crop);
    setCropId(null);
    setCropName("");
  };

  const submitForm = () => {
    if (cropName === "") {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      if (cropId) {
        updateCurrentCrop({ id: cropId, name: cropName });
      } else {
        addNewCrop();
      }
      props.setCropToUpdate(null);
      props.setOpenModalCrop(false);
    }
  };
  const cancel = () => {
    setCropId(null);
    setCropName("");
    setShowAlert(false);
    props.setCropToUpdate(null);
    props.setOpenModalCrop(false);
  };

  return (
    <div>
      <Modal isOpen={props.isOpen} className="modal-lg">
        <ModalHeader> {cropId ? "Update" : "Create"} Crop</ModalHeader>
        <ModalBody>
          <div>
            {showAlert && (
              <Alert className="mx-auto col-10 text-center" color="danger">
                The crop's name is required
              </Alert>
            )}

            <div className="form-container mx-auto">
              <div className="mb-3 row">
                <label className="col-sm-3 col-form-label text-end my-auto">
                  Name *
                </label>
                <div className="col">
                  <input
                    className="form-control"
                    defaultValue={cropName}
                    type="text"
                    onChange={(e) => setCropName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="btn action-btn"
            type="submit"
            form="cropsForm"
            onClick={() => submitForm()}
          >
            {cropId ? "Update" : "Create"} crop
          </Button>
          <Button color="secondary" onClick={() => cancel()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
