import {
  COMMENT_ID_QUERY_PARAM,
  MISSION_MONITORING_STATIC_STATUS,
} from "../constants";
import {
  requestFetchMissionMonitoring,
  requestMarkAsReadMissionMonitoringComment,
} from "../../services/backendRequests";
import { useEffect, useState } from "react";

import { ComponentPlaceholder } from "../components/componentPlaceholder/componentPlaceholder";
import { Container } from "reactstrap";
import LoadingImg from "../../components/loading";
import { MissionDetailsCard } from "../missionMonitoringDashboard/components/missionDetailsCard/missionDetailsCard";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchAcquisitionReports } from "../../services/backendRequests";
import { getStatusColor } from "../utils";
import moment from "moment";
import { useLocation } from "react-use";

const MissionMonitoringResults = (props) => {
  const { user, mmId } = props;
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const commentId = params.get(COMMENT_ID_QUERY_PARAM);

  useEffect(() => {
    if (commentId)
      requestMarkAsReadMissionMonitoringComment(
        user,
        { id: commentId }, // comment object to be destructured
        { id: user.self.id }, // recipient id to be destructured
        moment()
      ).catch((error) => {
        console.error("Error:", error);
      });
  }, [commentId, user]);

  const [missionMonitoring, setMissionMonitoring] = useState(null);
  const [loading, setLoading] = useState(true);
  const [acquisitionReportResults, setAcquisitionReportResults] =
    useState(null);
  useEffect(
    () => async () => {
      try {
        setLoading(true);
        const res = await requestFetchMissionMonitoring({ id: mmId }, user);
        setMissionMonitoring(res);
        setLoading(false);

        if (res.site) {
          const results = await fetchAcquisitionReports(user, res.site.id);
          setAcquisitionReportResults(results);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    },
    [mmId, user]
  );

  return (
    <Container
      fluid
      className="page-content d-flex flex-column light-theme powerdash mission-monitoring-dashboard"
    >
      {!missionMonitoring ? (
        <>
          {loading ? (
            <LoadingImg visible={loading} />
          ) : (
            <ComponentPlaceholder text="It seems that the requested mission does not exist or you have no access" />
          )}
        </>
      ) : (
        <>
          <MissionDetailsCard
            element={missionMonitoring}
            user={user}
            getStatusColor={() =>
              getStatusColor(
                MISSION_MONITORING_STATIC_STATUS,
                missionMonitoring?.status
              )
            }
            alwaysExpanded
            refresh={() => {}}
            acquisitionReportResults={acquisitionReportResults}
          />
        </>
      )}
    </Container>
  );
};

function mapStateToProps(state, props) {
  const { mmId } = props.match.params;
  return {
    user: state.user,
    mmId: mmId,
  };
}

export default connect(mapStateToProps)(MissionMonitoringResults);

MissionMonitoringResults.propTypes = {
  user: PropTypes.object.isRequired,
};
