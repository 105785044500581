import "./TrialStats.css";

import {
  selectFilteredAggregatedFeatures,
  selectFilteredFeatures,
  selectNumericalPropertiesMetricsAndAggregatedFeatures,
} from "../../../../selectors/resultMap";

import { useSelector } from "react-redux";

export const TrialStats = () => {
  const filteredFeatures = useSelector(selectFilteredFeatures);
  const features = useSelector(({ resultMap }) => resultMap.features);
  const filteredAggregatedFeatures = useSelector(
    selectFilteredAggregatedFeatures
  );
  const [aggregatedPrefilteredFeatures] = useSelector(
    selectNumericalPropertiesMetricsAndAggregatedFeatures
  );

  return (
    <div className="powerdash-component trial-stats p-2 gap-1">
      <div className="trial-stats-item">
        <span>Plots</span>
        <span>
          {filteredFeatures.length}/{features.length}
        </span>
      </div>
      <div className="trial-stats-item">
        <span>Varieties</span>
        <span>
          {filteredAggregatedFeatures.length}/
          {aggregatedPrefilteredFeatures.length}
        </span>
      </div>
    </div>
  );
};
