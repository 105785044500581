import { boolStringToInt, truncateNumber } from "../services/utils";
import {
  selectColorScale,
  selectFilteredFeatures,
} from "../selectors/resultMap";
import { useDispatch, useSelector } from "react-redux";

import { DEFAULT_MAP_LAYERS } from "../constants";
import { GeoJSON } from "react-leaflet";
import { NULL_GROUP_LABEL } from "../powerdash/constants";
import PropTypes from "prop-types";
import { setSelectedPlotId } from "../actions/resultMap";
import { useMemo } from "react";

const FEATURE_DEFAULT_STYLE = {
  fillOpacity: 0.7,
  weight: 0.2,
  opacity: 1,
  color: "white",
};

const FEATURE_WITH_NO_TRAIT_STYLE = {
  weight: 3,
  opacity: 1,
  color: "skyblue",
  fillOpacity: 0,
};

const FEATURE_SELECTED_DEFAULT_STYLE = {
  weight: 3.5,
  color: "white",
  dashArray: "",
  fillOpacity: 0.7,
};

const FEATURE_SIMILAR_DEFAULT_STYLE = {
  weight: 2,
  color: "lightgray",
  dashArray: "",
  fillOpacity: 0.7,
};

export const FeaturesLayer = ({ geoJsonRef }) => {
  const dispatch = useDispatch();
  const features = useSelector(selectFilteredFeatures);

  const [mapLayers, selectedPlotId, selectedTrait] = useSelector(
    ({ resultMap }) => [
      resultMap.mapLayers,
      resultMap.selectedPlotId,
      resultMap.selectedTrait,
    ]
  );

  const layer = useMemo(
    () => mapLayers.find(({ id }) => id === DEFAULT_MAP_LAYERS.FEATURES_LAYER),
    [mapLayers]
  );
  const colorScale = useSelector(selectColorScale);

  const selectedFeature = features.find((f) => f.id === selectedPlotId);

  const getFeatureStyle = (feature) => {
    if (!feature.trait_list.length) return FEATURE_WITH_NO_TRAIT_STYLE;

    return {
      ...FEATURE_DEFAULT_STYLE,
      fillOpacity: layer.opacity,
      fillColor: colorScale.func(
        boolStringToInt(feature.properties[selectedTrait.technical_name])
      ),
    };
  };

  // function bind to each microplot on hover and on click
  const onEachFeature = (feature, layer) => {
    layer.bindTooltip(
      "<div><b>Plot id: </b> " +
        feature.displayId +
        "</div>" +
        (selectedTrait.name
          ? "<div><b>" +
            selectedTrait.name +
            "</b>: " +
            truncateNumber(
              feature.properties[selectedTrait.technical_name] ??
                NULL_GROUP_LABEL
            ) +
            "</div>"
          : "")
    );

    if (selectedPlotId)
      if (layer.feature.id === selectedPlotId) {
        layer.bringToFront();
        layer.setStyle(FEATURE_SELECTED_DEFAULT_STYLE);
      } else if (layer.feature.group === selectedFeature?.group) {
        layer.setStyle(FEATURE_SIMILAR_DEFAULT_STYLE);
      }

    layer.on({
      click: () => {
        dispatch(setSelectedPlotId(feature.id));
      },
    });
  };

  return (
    <GeoJSON
      ref={geoJsonRef}
      key={new Date().getTime()}
      data={features}
      style={getFeatureStyle}
      onEachFeature={onEachFeature}
    />
  );
};

FeaturesLayer.propTypes = {
  geoJsonRef: PropTypes.object.isRequired,
};
