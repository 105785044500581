import React, { useState } from "react";
import { OPTIONS_SYSTEM } from "../../constants";
import Select from "react-select";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export default function TemplateModal({
  id,
  isOpen,
  setOpen,
  onSubmit,
  defaults,
  errorMessage,
}) {
  const [name, setName] = useState(defaults?.name ?? "");
  const [description, setDescription] = useState(defaults?.description ?? "");
  const [vector, setVector] = useState(defaults?.vector ?? "");
  const [active, setActive] = useState(defaults?.active ?? true);
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{id ? "Update" : "Create"} template</ModalHeader>

      <ModalBody>
        <Form id="uploadTemplate">
          <FormGroup>
            <Label>Name</Label>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Input
              type="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Vector</Label>
            <Select
              value={OPTIONS_SYSTEM.find((e) => e.value === vector)}
              onChange={(e) => setVector(e?.value ?? "")}
              options={OPTIONS_SYSTEM}
            />
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                checked={active}
                type="checkbox"
                onChange={() => setActive((v) => !v)}
              />{" "}
              Active
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <div className="text-danger">{errorMessage}</div>

        <Button
          form="uploadTemplate"
          type="submit"
          color="primary"
          className="btn action-btn"
          onClick={(e) => {
            e.preventDefault();
            onSubmit({ name, vector, active, description, id });
            setOpen(false);
          }}
        >
          {id ? "Update" : "Create"}
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
