import "./TraitBadge.css";

import { Badge } from "reactstrap";
import PropTypes from "prop-types";
import { PropertyName } from "../../PropertyName/PropertyName";
import { useGetPropertyCategory } from "../../../hooks/useGetPropertyCategory";

export const TraitBadge = ({ className, trait, onClick }) => {
  const getPropertyCategory = useGetPropertyCategory();
  const category = getPropertyCategory(trait.technical_name);

  return (
    <Badge
      className={`trait-badge hiphen-badge p-1 elevated d-flex gap-2 align-items-center clickable ${
        className || ""
      }`}
      onClick={onClick}
      style={{ outline: `2px solid ${category.color}` }}
    >
      <img src={trait.traitGroup.icon} alt={trait.traitGroup.name} />
      <PropertyName technicalName={trait.technical_name} />
      <div className="flex-grow-1" />
    </Badge>
  );
};

TraitBadge.propTypes = {
  onClick: PropTypes.func.isRequired,
  trait: PropTypes.shape({
    traitGroup: PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }).isRequired,
    technical_name: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
};
