import {
  selectExcludedGroupsSet,
  selectNumericalPropertiesMetricsAndAggregatedFeatures,
} from "../../../selectors/resultMap";

import { Badge } from "reactstrap";
import { HIPHEN_GREEN } from "../../../constants";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const FilteringProfileBadge = ({
  className,
  filteringProfile,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [aggregatedFeatures] = useSelector(
    selectNumericalPropertiesMetricsAndAggregatedFeatures
  );
  const excludedGroupsSet = useSelector(selectExcludedGroupsSet);
  const excludedGroups = useMemo(
    () => Array.from(excludedGroupsSet),
    [excludedGroupsSet]
  );
  const notExcludedGroupsLength =
    aggregatedFeatures.length - excludedGroups.length;
  const proportion = (
    (notExcludedGroupsLength * 100) /
    aggregatedFeatures.length
  ).toFixed(1);

  return filteringProfile.id ? (
    <Badge
      className={`hiphen-badge elevated clickable d-flex align-items-center gap-2 ${
        className || ""
      }`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className="d-flex gap-1 label">
        {filteringProfile.favourite && (
          <i className="fa-solid fa-star golden" />
        )}
        {filteringProfile.name}
      </span>
      <div className="flex-grow-1" />
      <Badge className="hiphen-badge small active">
        {notExcludedGroupsLength} <i className="fa-solid fa-dna" />
      </Badge>
      <span
        className="text-italic"
        style={{ color: HIPHEN_GREEN, fontWeight: 600 }}
      >
        {proportion}%
      </span>
    </Badge>
  ) : (
    <Badge className={`hiphen-badge disabled ${className || ""}`}>
      No filtering profile selected
    </Badge>
  );
};

FilteringProfileBadge.propTypes = {
  filteringProfile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    blacklist: PropTypes.array,
    whitelist: PropTypes.array,
    favourite: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  className: PropTypes.string,
};
