import { RESET, SET_USERS, USER_UPDATE_ALERT } from "../actions/actionTypes";
import dataTableBehavior from "./dataTableBehavior";
import { extendUserWithRole } from "../services/roles";

const dataTable = dataTableBehavior("users");

const initialState = {
  all: [],
  table: dataTable(undefined, {}), // eslint-disable-line no-undefined
  updateAlert: ""
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        all: action.users.map(user => extendUserWithRole(user)),
        updateAlert: ""
      };

    case RESET:
      return initialState;

    case USER_UPDATE_ALERT:
      return {
        ...state,
        updateAlert: action.updateAlert
      };

    default:
      return {
        ...state,
        table: dataTable(state.table, action)
      };
  }
}
