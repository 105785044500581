import "./randomSelect.css";

import { useDispatch, useSelector } from "react-redux";

import { ANALYTICS_EVENTS } from "../../../../constants";
import { Button } from "reactstrap";
import { FloatingCard } from "../../../../components/floatingCard/FloatingCard";
import { MAX_GENOTYPE_SELECTION } from "../../../constants";
import ReactSlider from "react-slider";
import { selectFilteredAggregatedFeatures } from "../../../../selectors/resultMap";
import { setFocusedVarieties } from "../../../../actions/resultMap";
import { useState } from "react";
import { useTracking } from "../../../../analytics";

export const RandomSelectButton = () => {
  const aggregatedFeatures = useSelector(selectFilteredAggregatedFeatures);
  const max = Math.min(aggregatedFeatures.length, MAX_GENOTYPE_SELECTION);
  const [visible, setVisible] = useState(false);
  const [randomElementsToSelect, setRandomElementsToSelect] = useState(max);
  const { trackEvent } = useTracking();

  const dispatch = useDispatch();

  const handleApply = () => {
    const shuffled = aggregatedFeatures
      .toSorted(() => 0.5 - Math.random())
      .map(({ group }) => group);
    dispatch(setFocusedVarieties(shuffled.slice(0, randomElementsToSelect)));
    setVisible(false);
    trackEvent(ANALYTICS_EVENTS.RANDOM_SELECT);
  };

  return (
    aggregatedFeatures.length > 0 && (
      <div className="random-select">
        <i
          data-tooltip-id="tooltip"
          data-tooltip-content="Random selection"
          className="fa fa-random clickable"
          onClick={() => setVisible(true)}
          aria-hidden="true"
        />
        {visible && (
          <FloatingCard hide={() => setVisible(false)}>
            <div className="p-3 pt-2 pb-1">
              <span className="d-block mb-3">Size of random selection</span>
              <div className="random-select-item">
                <ReactSlider
                  value={randomElementsToSelect}
                  step={1}
                  min={0}
                  max={max}
                  onChange={setRandomElementsToSelect}
                  className="random-slider-track"
                  thumbClassName="random-slider-thumb"
                  withTracks={false}
                  renderThumb={(props, state) => (
                    <div {...props}>{state.valueNow}</div>
                  )}
                />
              </div>
            </div>

            <Button className="hiphen-green-button" onClick={handleApply}>
              Apply
            </Button>
          </FloatingCard>
        )}
      </div>
    )
  );
};
